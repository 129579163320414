import './SortHeader.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import React from 'react'
import { type SortOrder, getNextSortOrder, parseSortOrder } from './SortOrder'

export function SortHeader({
  canRemove,
  children,
  className,
  columnOrder,
  onChangeOrder,
  order,
  ...props
}: {
  canRemove?: boolean // Can the user remove the sort order via triple-click?
  children: React.ReactNode
  className?: string
  columnOrder: SortOrder | string
  onChangeOrder: (order: SortOrder) => void
  order: SortOrder
}) {
  const $columnOrder = parseSortOrder(columnOrder)
  const isActive = $columnOrder.field === order?.field

  function handleNextSortOrder() {
    const nextSortOrder = getNextSortOrder($columnOrder, order, canRemove)
    onChangeOrder(nextSortOrder)
  }

  return (
    <div
      {...props}
      className={classnames('sort-header', className)}
      onClick={handleNextSortOrder}
    >
      {children}
      <If condition={isActive}>
        <SortIcon className='tw-ml-1' desc={order.direction === 'desc'} />
      </If>
    </div>
  )
}

export const SortIcon: React.FC<{
  className?: string
  desc?: boolean
}> = ({ desc, ...props }) => (
  <FontAwesomeIcon
    {...props}
    css={{
      display: 'inline-block',
      transform: desc ? 'rotate(180deg)' : undefined,
      transition: 'all 160ms linear',
    }}
    icon='caret-down'
  />
)
