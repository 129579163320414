import type {
  BlissbookHandbookAcknowledgementForm,
  BlissbookHandbookBranding,
  BlissbookHandbookContent,
} from '@blissbook/lib/blissbook'
import type { DocumentVersion, Node } from '@blissbook/lib/document'
import type { HandbookSectionDataModel } from '@blissbook/lib/models'
import {
  canAccessDocument,
  canAccessSection,
} from '@blissbook/lib/personalization'
import {
  type HandbookSectionAccessControl,
  type PersonalizationInput,
  personalizeContent,
  personalizeHandbookContent,
} from '@blissbook/lib/personalization'
import clone from 'lodash/clone'

export interface IBlissbookTocHandbook {
  acknowledgementForm: BlissbookHandbookAcknowledgementForm
  branding: BlissbookHandbookBranding
  content: Node[]
  contactFragments: BlissbookHandbookContent
  defaultLanguageCode: string
  sectionsById: Map<number, HandbookSectionDataModel>
}

export type BlissbookTocItem = {
  bookmark: string
  level: number
  title: string
  isHidden?: boolean
  languageCode: string
  chapterId?: number
  documentId?: number
  document?: DocumentVersion
  sectionId?: number
  section?: HandbookSectionDataModel
}

export type PersonalizedRefs = {
  documentVersionNumbersMap: Map<number, number>
  sectionVersionNumbersMap: Map<number, number>
}

export function getTocItemsFromContent(
  handbook: IBlissbookTocHandbook,
  { documents = [] }: BlissbookTocOptions = {},
) {
  const items: BlissbookTocItem[] = []

  const documentsById = new Map<number, DocumentVersion>(
    documents.map((document) => [document.documentId, document]),
  )

  let parentSection: HandbookSectionDataModel
  for (const node of handbook.content) {
    const lastParentSection = parentSection
    parentSection = undefined

    // Document Node
    if (node.type === 'documentRef') {
      const { documentId, hideToc, tocTitle } = node.attrs
      const document = node.document || documentsById.get(documentId)
      const documentTitle = document
        ? document.title
        : `Document #${documentId}`
      const title = tocTitle || documentTitle

      // Is this a child section?
      const isChild = lastParentSection !== undefined
      const languageCode = lastParentSection?.languageCode

      // Add to table of contents
      items.push({
        bookmark: `document-${documentId}`,
        level: isChild ? 2 : 1,
        title,
        isHidden: hideToc ? true : !title,
        languageCode: languageCode || handbook.defaultLanguageCode,
        chapterId: isChild ? lastParentSection.id : undefined,
        documentId,
        document,
      })

      // Retain parent section?
      if (isChild) {
        parentSection = lastParentSection
      }
    }

    // Handbook Section node
    if (node.type === 'handbookSection') {
      const { sectionId } = node.attrs
      const section = handbook.sectionsById.get(sectionId)
      if (!section) continue

      // Is this a child section?
      const isChild = section.isChildOf(lastParentSection)
      const languageCode = isChild
        ? lastParentSection.languageCode
        : section.languageCode

      // Add to table of contents
      items.push({
        bookmark: section.bookmark,
        level: isChild ? 2 : 1,
        title: section.getTitle(),
        isHidden: section.hideToc ? true : !section.getTitle(),
        languageCode: languageCode || handbook.defaultLanguageCode,
        chapterId: isChild ? lastParentSection.id : undefined,
        sectionId: section.id,
        section,
      })

      // Retain parent section?
      if (isChild) {
        parentSection = lastParentSection
      }
      // Is this section a parent section
      else if (section.childTypes.length > 0) {
        parentSection = section
      }
    }
  }

  return items
}

export type BlissbookTocOptions = {
  documents?: DocumentVersion[]
}

export class BlissbookToc {
  handbook: IBlissbookTocHandbook
  items: BlissbookTocItem[]

  constructor(handbook: IBlissbookTocHandbook, options?: BlissbookTocOptions) {
    this.handbook = handbook
    this.items = getTocItemsFromContent(handbook, options)
  }

  clone() {
    const { sectionsById, ...handbook } = this.handbook
    return new BlissbookToc({
      ...handbook,
      sectionsById: clone(sectionsById),
    })
  }

  getCoverSection() {
    const section = this.items[0]?.section
    if (section?.schema?.isCover) return section
  }

  getHandbookSections() {
    const handbookSections: HandbookSectionDataModel[] = []
    for (const item of this.items) {
      const { section } = item
      if (section) handbookSections.push(section)
    }
    return handbookSections
  }

  addHandbookSection(section: HandbookSectionDataModel) {
    // Add to toc
    const { handbook } = this
    this.items.push({
      bookmark: section.bookmark,
      level: 1,
      title: section.getTitle(),
      isHidden: section.hideToc ? true : !section.getTitle(),
      languageCode: section.languageCode || handbook.defaultLanguageCode,
      sectionId: section.id,
      section,
    })

    // Add to sections
    this.handbook.sectionsById.set(section.id, section)
  }

  /** Personalize the handbook content */
  personalize(
    accessControl: HandbookSectionAccessControl,
    options: { tocOnly?: boolean } = {},
  ): PersonalizedRefs {
    this.resolveLanguageCode()

    // Personalize the table of contents
    const personalizedRefs = this.getPersonalizedRefs(accessControl)
    this.personalizeToc(personalizedRefs)

    // Personalize the inline content
    const { personalization } = accessControl
    if (!options.tocOnly && personalization) {
      this.personalizeContent(personalization)
    }

    return personalizedRefs
  }

  private resolveLanguageCode() {
    for (const item of this.items) {
      const { document, languageCode, section } = item
      if (document) {
        document.languageCode = languageCode
      }
      if (section) {
        section.languageCode = languageCode
      }
    }
  }

  /** Get the content that needs to be removed for personalization */
  getPersonalizedRefs(
    accessControl: HandbookSectionAccessControl,
  ): PersonalizedRefs {
    // Keep track of parent/chapter
    const parentIds = new Set<number>()
    const retainParentIds = new Set<number>()

    // Determine what documents/sections to remove
    const documentVersionNumbersMap = new Map<number, number>()
    const sectionVersionNumbersMap = new Map<number, number>()

    for (const item of this.items) {
      const { chapterId, document, documentId, section, sectionId } = item

      // Is this a chapter?
      if (chapterId) {
        parentIds.add(chapterId)
      }

      // If cannot access chapter, bail
      const canAccessChapter =
        !chapterId || sectionVersionNumbersMap.has(chapterId)
      if (!canAccessChapter) continue

      // Check Document node access control
      if (documentId) {
        if (!document) continue

        const canAccess = canAccessDocument(document, accessControl)
        if (!canAccess) continue

        const { versionNumber } = document
        documentVersionNumbersMap.set(documentId, versionNumber)
      }

      // Check Handbook Section node access control
      if (sectionId) {
        if (!section) continue

        const canAccess = canAccessSection(section, accessControl)
        if (!canAccess) continue

        sectionVersionNumbersMap.set(sectionId, section.version)
      }

      // If we made it this far, retain the parent/chapter
      if (chapterId) {
        retainParentIds.add(chapterId)
      }
    }

    // If a chapter had all sections removed, remove the chapter
    for (const parentId of parentIds) {
      if (!retainParentIds.has(parentId)) {
        sectionVersionNumbersMap.delete(parentId)
      }
    }

    return { documentVersionNumbersMap, sectionVersionNumbersMap }
  }

  /** Personalizat the table of contents to match this access control */
  private personalizeToc({
    documentVersionNumbersMap,
    sectionVersionNumbersMap,
  }: PersonalizedRefs) {
    // Remove from toc
    this.items = this.items.filter((item) => {
      const { documentId, sectionId } = item
      if (documentId) {
        return documentVersionNumbersMap.has(documentId)
      }
      if (sectionId) {
        return sectionVersionNumbersMap.has(sectionId)
      }
      return true
    })

    // Remove ununsed handbook content
    const { handbook } = this
    handbook.content = handbook.content.filter((node) => {
      if (node.type === 'documentRef') {
        const { documentId } = node.attrs
        return documentVersionNumbersMap.has(documentId)
      }
      if (node.type === 'handbookSection') {
        const { sectionId } = node.attrs
        return sectionVersionNumbersMap.has(sectionId)
      }
      return true
    })

    // Remove sections
    const { sectionsById } = handbook
    for (const sectionId of sectionsById.keys()) {
      if (!sectionVersionNumbersMap.has(sectionId)) {
        sectionsById.delete(sectionId)
      }
    }
  }

  /** Personalize inline handbook content */
  private personalizeContent(personalization: PersonalizationInput) {
    // Handbook-level
    const { handbook } = this
    personalizeHandbookContent(handbook.acknowledgementForm, personalization)
    personalizeHandbookContent(handbook.contactFragments, personalization)
    personalizeContent(handbook.content, personalization)

    // Handbook sections
    for (const section of this.handbook.sectionsById.values()) {
      section.personalizeContent(personalization)
    }
  }
}
