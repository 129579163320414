import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Color: any
  HandbookColor: any
  BodyContent: any
  EmailContent: any
  HelpContent: any
  TextContent: any
  TitleContent: any
  Domain: any
  Subdomain: any
  EmailAddress: any
  HTML: any
  PhoneNumber: any
  HttpUrl: any
  HttpsUrl: any
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  BigInt: any
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any
}

export type SendAuthResponse = {
  __typename?: 'SendAuthResponse'
  signInUrl?: Maybe<Scalars['HttpUrl']>
  userId?: Maybe<Scalars['Int']>
}

export enum OperandType {
  Date = 'date',
  Groups = 'groups',
  Handbooks = 'handbooks',
  Managers = 'managers',
  People = 'people',
  SavedSegments = 'savedSegments',
  Select = 'select',
  SelectIds = 'selectIds',
  Text = 'text',
}

export type Operand = {
  id?: Maybe<Scalars['ID']>
  type: OperandType
}

export enum DateOperandCondition {
  LessThanDaysAgo = 'lessThanDaysAgo',
  LessThanOrEqualToDaysAgo = 'lessThanOrEqualToDaysAgo',
  ExactlyDaysAgo = 'exactlyDaysAgo',
  MoreThanDaysAgo = 'moreThanDaysAgo',
  MoreThanOrEqualToDaysAgo = 'moreThanOrEqualToDaysAgo',
  LessThanDaysFromNow = 'lessThanDaysFromNow',
  LessThanOrEqualToDaysFromNow = 'lessThanOrEqualToDaysFromNow',
  ExactlyDaysFromNow = 'exactlyDaysFromNow',
  MoreThanDaysFromNow = 'moreThanDaysFromNow',
  MoreThanOrEqualToDaysFromNow = 'moreThanOrEqualToDaysFromNow',
  BeforeDate = 'beforeDate',
  OnDate = 'onDate',
  AfterDate = 'afterDate',
  HasNoValue = 'hasNoValue',
  HasValue = 'hasValue',
  IsOverdue = 'isOverdue',
  IsOnOrAfter = 'isOnOrAfter',
  IsOnOrBefore = 'isOnOrBefore',
}

export type DateOperand = Operand & {
  __typename?: 'DateOperand'
  id?: Maybe<Scalars['ID']>
  type: OperandType
  field: Scalars['String']
  dateCondition: DateOperandCondition
  days?: Maybe<Scalars['Int']>
  date?: Maybe<Scalars['Date']>
}

export type DateOperandInput = {
  id?: InputMaybe<Scalars['ID']>
  dateCondition: DateOperandCondition
  days?: InputMaybe<Scalars['Int']>
  date?: InputMaybe<Scalars['Date']>
}

export enum DocumentsDateOperandField {
  LastPublishedAt = 'lastPublishedAt',
  UpdatedAt = 'updatedAt',
}

export type DocumentsDateOperandInput = {
  id?: InputMaybe<Scalars['ID']>
  field: DocumentsDateOperandField
  dateCondition: DateOperandCondition
  days?: InputMaybe<Scalars['Int']>
  date?: InputMaybe<Scalars['Date']>
}

export enum HandbookRecipientsDateOperandField {
  LastHiredOn = 'lastHiredOn',
  LastNotifiedAt = 'lastNotifiedAt',
  LastSignedAt = 'lastSignedAt',
  LastViewedAt = 'lastViewedAt',
  SignatureDueDate = 'signatureDueDate',
}

export type HandbookRecipientsDateOperandInput = {
  id?: InputMaybe<Scalars['ID']>
  field: HandbookRecipientsDateOperandField
  dateCondition: DateOperandCondition
  days?: InputMaybe<Scalars['Int']>
  date?: InputMaybe<Scalars['Date']>
}

export enum HrisPeopleDateOperandField {
  LastHiredOn = 'lastHiredOn',
}

export type HrisPeopleDateOperandInput = {
  id?: InputMaybe<Scalars['ID']>
  field: HrisPeopleDateOperandField
  dateCondition: DateOperandCondition
  days?: InputMaybe<Scalars['Int']>
  date?: InputMaybe<Scalars['Date']>
}

export enum PeopleDateOperandField {
  LastEmployeeDigestQueuedAt = 'lastEmployeeDigestQueuedAt',
  LastHiredOn = 'lastHiredOn',
  LastNotifiedAt = 'lastNotifiedAt',
  LastManagerDigestQueuedAt = 'lastManagerDigestQueuedAt',
}

export type PeopleDateOperandInput = {
  id?: InputMaybe<Scalars['ID']>
  field: PeopleDateOperandField
  dateCondition: DateOperandCondition
  days?: InputMaybe<Scalars['Int']>
  date?: InputMaybe<Scalars['Date']>
}

export type IdsOperand = Operand & {
  __typename?: 'IdsOperand'
  id?: Maybe<Scalars['ID']>
  type: OperandType
  isEvery: Scalars['Boolean']
  isNot: Scalars['Boolean']
  ids: Array<Scalars['Int']>
}

export type IdsOperandInput = {
  id?: InputMaybe<Scalars['ID']>
  isEvery?: InputMaybe<Scalars['Boolean']>
  isNot?: InputMaybe<Scalars['Boolean']>
  ids: Array<Scalars['Int']>
}

export enum DocumentsPeopleOperandField {
  CreatedByPersonId = 'createdByPersonId',
}

export type DocumentsPeopleOperandInput = {
  id?: InputMaybe<Scalars['ID']>
  field: DocumentsPeopleOperandField
  isEvery?: InputMaybe<Scalars['Boolean']>
  isNot?: InputMaybe<Scalars['Boolean']>
  ids: Array<Scalars['Int']>
}

export type ManagersOperand = Operand & {
  __typename?: 'ManagersOperand'
  id?: Maybe<Scalars['ID']>
  type: OperandType
  isEvery: Scalars['Boolean']
  isNot: Scalars['Boolean']
  managesDirectly: Scalars['Boolean']
  ids: Array<Scalars['Int']>
}

export type ManagersOperandInput = {
  id?: InputMaybe<Scalars['ID']>
  isEvery?: InputMaybe<Scalars['Boolean']>
  isNot?: InputMaybe<Scalars['Boolean']>
  managesDirectly: Scalars['Boolean']
  ids: Array<Scalars['Int']>
}

export type SelectIdsOperand = Operand & {
  __typename?: 'SelectIdsOperand'
  id?: Maybe<Scalars['ID']>
  type: OperandType
  field: Scalars['String']
  isEvery: Scalars['Boolean']
  isNot: Scalars['Boolean']
  values?: Maybe<Array<Scalars['Int']>>
}

export type SelectIdsOperandInput = {
  id?: InputMaybe<Scalars['ID']>
  isEvery?: InputMaybe<Scalars['Boolean']>
  isNot?: InputMaybe<Scalars['Boolean']>
  values?: InputMaybe<Array<Scalars['Int']>>
}

export enum DocumentsSelectIdsOperandField {
  ParentFolderId = 'parentFolderId',
}

export type DocumentsSelectIdsOperandInput = {
  field: DocumentsSelectIdsOperandField
  id?: InputMaybe<Scalars['ID']>
  isEvery?: InputMaybe<Scalars['Boolean']>
  isNot?: InputMaybe<Scalars['Boolean']>
  values?: InputMaybe<Array<Scalars['Int']>>
}

export type SelectOperand = Operand & {
  __typename?: 'SelectOperand'
  id?: Maybe<Scalars['ID']>
  type: OperandType
  field: Scalars['String']
  path?: Maybe<Scalars['String']>
  isEvery: Scalars['Boolean']
  isNot: Scalars['Boolean']
  values?: Maybe<Array<Scalars['String']>>
}

export type SelectOperandInput = {
  id?: InputMaybe<Scalars['ID']>
  path?: InputMaybe<Scalars['String']>
  isEvery?: InputMaybe<Scalars['Boolean']>
  isNot?: InputMaybe<Scalars['Boolean']>
  values?: InputMaybe<Array<Scalars['String']>>
}

export enum AudienceSelectOperandField {
  Metadata = 'metadata',
}

export type AudienceSelectOperandInput = {
  id?: InputMaybe<Scalars['ID']>
  field: AudienceSelectOperandField
  path?: InputMaybe<Scalars['String']>
  isEvery?: InputMaybe<Scalars['Boolean']>
  isNot?: InputMaybe<Scalars['Boolean']>
  values?: InputMaybe<Array<Scalars['String']>>
}

export enum DocumentSelectOperandField {
  Status = 'status',
}

export type DocumentsSelectOperandInput = {
  id?: InputMaybe<Scalars['ID']>
  field: DocumentSelectOperandField
  isEvery?: InputMaybe<Scalars['Boolean']>
  isNot?: InputMaybe<Scalars['Boolean']>
  values?: InputMaybe<Array<Scalars['String']>>
}

export enum HrisPeopleSelectOperandField {
  GroupName = 'groupName',
  Metadata = 'metadata',
}

export type HrisPeopleSelectOperandInput = {
  id?: InputMaybe<Scalars['ID']>
  field: HrisPeopleSelectOperandField
  path?: InputMaybe<Scalars['String']>
  isEvery?: InputMaybe<Scalars['Boolean']>
  isNot?: InputMaybe<Scalars['Boolean']>
  values?: InputMaybe<Array<Scalars['String']>>
}

export enum PeopleSelectOperandField {
  ComplianceStatus = 'complianceStatus',
  GroupName = 'groupName',
  Level = 'level',
  Metadata = 'metadata',
}

export type PeopleSelectOperandInput = {
  id?: InputMaybe<Scalars['ID']>
  field: PeopleSelectOperandField
  path?: InputMaybe<Scalars['String']>
  isEvery?: InputMaybe<Scalars['Boolean']>
  isNot?: InputMaybe<Scalars['Boolean']>
  values?: InputMaybe<Array<Scalars['String']>>
}

export enum TextOperandCondition {
  Contains = 'contains',
  Equals = 'equals',
  StartsWith = 'startsWith',
  EndsWith = 'endsWith',
  HasValue = 'hasValue',
}

export type TextOperand = Operand & {
  __typename?: 'TextOperand'
  id?: Maybe<Scalars['ID']>
  type: OperandType
  field: Scalars['String']
  isNot: Scalars['Boolean']
  textCondition: TextOperandCondition
  text: Scalars['String']
}

export type TextOperandInput = {
  id?: InputMaybe<Scalars['ID']>
  isNot?: InputMaybe<Scalars['Boolean']>
  textCondition: TextOperandCondition
  text: Scalars['String']
}

export enum DocumentsTextOperandField {
  Content = 'content',
  Name = 'name',
}

export type DocumentsTextOperandInput = {
  id?: InputMaybe<Scalars['ID']>
  field: DocumentsTextOperandField
  isNot?: InputMaybe<Scalars['Boolean']>
  textCondition: TextOperandCondition
  text: Scalars['String']
}

export enum PeopleTextOperandField {
  Email = 'email',
  EmployeeId = 'employeeId',
  ExternalId = 'externalId',
  FullName = 'fullName',
}

export type PeopleTextOperandInput = {
  id?: InputMaybe<Scalars['ID']>
  field: PeopleTextOperandField
  isNot?: InputMaybe<Scalars['Boolean']>
  textCondition: TextOperandCondition
  text: Scalars['String']
}

export enum ExpressionConjunction {
  And = 'and',
  Or = 'or',
}

export type Expression = {
  __typename?: 'Expression'
  conjunction: ExpressionConjunction
  operands: Array<Operand>
}

export type RootExpression = {
  __typename?: 'RootExpression'
  conjunction: ExpressionConjunction
  expressions: Array<Expression>
}

export type AudienceOperandInput = {
  groups?: InputMaybe<IdsOperandInput>
  people?: InputMaybe<IdsOperandInput>
  savedSegments?: InputMaybe<IdsOperandInput>
  select?: InputMaybe<AudienceSelectOperandInput>
}

export type AudienceExpressionInput = {
  conjunction: ExpressionConjunction
  isNot?: InputMaybe<Scalars['Boolean']>
  operands: Array<AudienceOperandInput>
}

export type AudienceRootExpressionInput = {
  conjunction: ExpressionConjunction
  isNot?: InputMaybe<Scalars['Boolean']>
  expressions: Array<AudienceExpressionInput>
}

export type DocumentsOperandInput = {
  date?: InputMaybe<DocumentsDateOperandInput>
  handbooks?: InputMaybe<IdsOperandInput>
  people?: InputMaybe<DocumentsPeopleOperandInput>
  selectIds?: InputMaybe<DocumentsSelectIdsOperandInput>
  select?: InputMaybe<DocumentsSelectOperandInput>
  text?: InputMaybe<DocumentsTextOperandInput>
}

export type DocumentsExpressionInput = {
  conjunction: ExpressionConjunction
  isNot?: InputMaybe<Scalars['Boolean']>
  operands: Array<DocumentsOperandInput>
}

export type DocumentsRootExpressionInput = {
  conjunction: ExpressionConjunction
  isNot?: InputMaybe<Scalars['Boolean']>
  expressions: Array<DocumentsExpressionInput>
}

export type DocumentsRootExpressionsInput = {
  conjunction: ExpressionConjunction
  isNot?: InputMaybe<Scalars['Boolean']>
  expressions: Array<DocumentsRootExpressionInput>
}

export type HandbookRecipientsOperandInput = {
  date?: InputMaybe<HandbookRecipientsDateOperandInput>
  groups?: InputMaybe<IdsOperandInput>
  managers?: InputMaybe<ManagersOperandInput>
  people?: InputMaybe<IdsOperandInput>
  savedSegments?: InputMaybe<IdsOperandInput>
  select?: InputMaybe<PeopleSelectOperandInput>
  text?: InputMaybe<PeopleTextOperandInput>
}

export type HandbookRecipientsExpressionInput = {
  conjunction: ExpressionConjunction
  isNot?: InputMaybe<Scalars['Boolean']>
  operands: Array<HandbookRecipientsOperandInput>
}

export type HandbookRecipientsRootExpressionInput = {
  conjunction: ExpressionConjunction
  isNot?: InputMaybe<Scalars['Boolean']>
  expressions: Array<HandbookRecipientsExpressionInput>
}

export type HrisPeopleOperandInput = {
  date?: InputMaybe<HrisPeopleDateOperandInput>
  select?: InputMaybe<HrisPeopleSelectOperandInput>
  text?: InputMaybe<PeopleTextOperandInput>
}

export type HrisPeopleExpressionInput = {
  conjunction: ExpressionConjunction
  isNot?: InputMaybe<Scalars['Boolean']>
  operands: Array<HrisPeopleOperandInput>
}

export type HrisPeopleRootExpressionInput = {
  conjunction: ExpressionConjunction
  isNot?: InputMaybe<Scalars['Boolean']>
  expressions: Array<HrisPeopleExpressionInput>
}

export type PeopleOperandInput = {
  date?: InputMaybe<PeopleDateOperandInput>
  groups?: InputMaybe<IdsOperandInput>
  handbooks?: InputMaybe<IdsOperandInput>
  managers?: InputMaybe<ManagersOperandInput>
  people?: InputMaybe<IdsOperandInput>
  savedSegments?: InputMaybe<IdsOperandInput>
  select?: InputMaybe<PeopleSelectOperandInput>
  text?: InputMaybe<PeopleTextOperandInput>
}

export type PeopleExpressionInput = {
  conjunction: ExpressionConjunction
  isNot?: InputMaybe<Scalars['Boolean']>
  operands: Array<PeopleOperandInput>
}

export type PeopleRootExpressionInput = {
  conjunction: ExpressionConjunction
  isNot?: InputMaybe<Scalars['Boolean']>
  expressions: Array<PeopleExpressionInput>
}

export type PeopleRootExpressionsInput = {
  conjunction: ExpressionConjunction
  isNot?: InputMaybe<Scalars['Boolean']>
  expressions: Array<PeopleRootExpressionInput>
}

export enum IntegrationProviderId {
  Bamboohr = 'bamboohr',
  Mergedev = 'mergedev',
  Rippling = 'rippling',
  Sftp = 'sftp',
}

export type Integration = {
  id: Scalars['Int']
  providerId: IntegrationProviderId
  rejectPeopleExpression?: Maybe<RootExpression>
  lastSyncAt?: Maybe<Scalars['DateTime']>
  lastErrorAt?: Maybe<Scalars['DateTime']>
  lastError?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
}

export type IntegrationCustomField = {
  __typename?: 'IntegrationCustomField'
  key: Scalars['String']
  label: Scalars['String']
  description?: Maybe<Scalars['String']>
}

export type UpdateIntegrationInput = {
  rejectPeopleExpression?: InputMaybe<HrisPeopleRootExpressionInput>
  syncHourUtc?: InputMaybe<Scalars['Int']>
}

export enum BambooHrGroupId {
  State = 'state',
  Country = 'country',
  Location = 'location',
  Division = 'division',
  Department = 'department',
  EmploymentHistoryStatus = 'employmentHistoryStatus',
  Exempt = 'exempt',
  PayType = 'payType',
}

export enum BambooHrSyncTiming {
  HireDate = 'hireDate',
}

export type BambooHrIntegration = Integration & {
  __typename?: 'BambooHRIntegration'
  id: Scalars['Int']
  providerId: IntegrationProviderId
  rejectPeopleExpression?: Maybe<RootExpression>
  lastSyncAt?: Maybe<Scalars['DateTime']>
  lastErrorAt?: Maybe<Scalars['DateTime']>
  lastError?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  groups: Array<BambooHrGroupId>
  hostname: Scalars['String']
  subdomain: Scalars['String']
  syncTiming?: Maybe<BambooHrSyncTiming>
  userId?: Maybe<Scalars['String']>
}

export type UpdateBambooHrIntegrationInput = {
  groups: Array<BambooHrGroupId>
  syncTiming?: InputMaybe<BambooHrSyncTiming>
}

export enum RipplingGroupId {
  Country = 'country',
  State = 'state',
  City = 'city',
  Department = 'department',
  EmploymentType = 'employmentType',
}

export type RipplingIntegration = Integration & {
  __typename?: 'RipplingIntegration'
  id: Scalars['Int']
  providerId: IntegrationProviderId
  rejectPeopleExpression?: Maybe<RootExpression>
  lastSyncAt?: Maybe<Scalars['DateTime']>
  lastErrorAt?: Maybe<Scalars['DateTime']>
  lastError?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  customFields: Array<IntegrationCustomField>
  customFieldKeys: Array<Scalars['String']>
  ripplingGroupIds: Array<RipplingGroupId>
}

export type UpdateRipplingIntegrationInput = {
  customFieldKeys: Array<Scalars['String']>
  ripplingGroupIds: Array<RipplingGroupId>
}

export type SshFingerprints = {
  __typename?: 'SshFingerprints'
  md5: Scalars['String']
  sha256: Scalars['String']
}

export type SftpIntegration = Integration & {
  __typename?: 'SftpIntegration'
  id: Scalars['Int']
  providerId: IntegrationProviderId
  rejectPeopleExpression?: Maybe<RootExpression>
  fingerprints: SshFingerprints
  username: Scalars['String']
  lastSyncAt?: Maybe<Scalars['DateTime']>
  lastErrorAt?: Maybe<Scalars['DateTime']>
  lastError?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
}

export type NewSftpIntegration = Integration & {
  __typename?: 'NewSftpIntegration'
  id: Scalars['Int']
  providerId: IntegrationProviderId
  rejectPeopleExpression?: Maybe<RootExpression>
  fingerprints: SshFingerprints
  username: Scalars['String']
  password: Scalars['String']
  lastSyncAt?: Maybe<Scalars['DateTime']>
  lastErrorAt?: Maybe<Scalars['DateTime']>
  lastError?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
}

export type HttpsIntegration = Integration & {
  __typename?: 'HttpsIntegration'
  id: Scalars['Int']
  providerId: IntegrationProviderId
  rejectPeopleExpression?: Maybe<RootExpression>
  lastSyncAt?: Maybe<Scalars['DateTime']>
  lastErrorAt?: Maybe<Scalars['DateTime']>
  lastError?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
}

export type MergeDevIntegration = Integration & {
  __typename?: 'MergeDevIntegration'
  id: Scalars['Int']
  providerId: IntegrationProviderId
  rejectPeopleExpression?: Maybe<RootExpression>
  lastSyncAt?: Maybe<Scalars['DateTime']>
  lastErrorAt?: Maybe<Scalars['DateTime']>
  lastError?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  image: Scalars['String']
  squareImage: Scalars['String']
  integrationName: Scalars['String']
  integrationSlug: Scalars['String']
  externalId: Scalars['String']
  syncHourUtc?: Maybe<Scalars['Int']>
}

export enum AssetType {
  Image = 'image',
  Pdf = 'pdf',
}

export type Asset = {
  id: Scalars['Int']
  type: AssetType
  tag: Scalars['String']
  filename: Scalars['String']
  filesize: Scalars['Int']
  path: Scalars['String']
  thumbnailUrl?: Maybe<Scalars['String']>
  url: Scalars['String']
  mimeType?: Maybe<Scalars['String']>
  archived: Scalars['Boolean']
  createdAt: Scalars['DateTime']
}

export type Image = Asset & {
  __typename?: 'Image'
  id: Scalars['Int']
  type: AssetType
  tag: Scalars['String']
  filename: Scalars['String']
  filesize: Scalars['Int']
  path: Scalars['String']
  thumbnailUrl?: Maybe<Scalars['String']>
  url: Scalars['String']
  width?: Maybe<Scalars['Int']>
  height?: Maybe<Scalars['Int']>
  mimeType?: Maybe<Scalars['String']>
  archived: Scalars['Boolean']
  createdAt: Scalars['DateTime']
}

export type Pdf = Asset & {
  __typename?: 'Pdf'
  id: Scalars['Int']
  type: AssetType
  tag: Scalars['String']
  filename: Scalars['String']
  filesize: Scalars['Int']
  path: Scalars['String']
  thumbnailUrl?: Maybe<Scalars['String']>
  url: Scalars['String']
  mimeType?: Maybe<Scalars['String']>
  archived: Scalars['Boolean']
  createdAt: Scalars['DateTime']
}

export type Billing = {
  __typename?: 'Billing'
  name?: Maybe<Scalars['String']>
  contactName?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
  emailSettings: BillingEmailSettings
  toEmails: Array<Scalars['EmailAddress']>
  credits?: Maybe<Scalars['Int']>
  peopleCount: Scalars['Int']
  dailyCredits?: Maybe<Scalars['Int']>
  monthlyGrowthRate?: Maybe<Scalars['Float']>
  monthlyCreditPrice?: Maybe<Scalars['Float']>
  yearlyCreditPrice?: Maybe<Scalars['Float']>
  exempt: Scalars['Boolean']
  termStartCount?: Maybe<Scalars['Int']>
  termMonths?: Maybe<Scalars['Int']>
  termMonthlyGrowthRate?: Maybe<Scalars['Float']>
  paymentMethod?: Maybe<Scalars['String']>
  paymentDays?: Maybe<Scalars['Int']>
  expiredAt?: Maybe<Scalars['DateTime']>
  canceledAt?: Maybe<Scalars['DateTime']>
  terminatedAt?: Maybe<Scalars['DateTime']>
  creditCard?: Maybe<Scalars['JSON']>
}

export type BillingEmailSettings = {
  __typename?: 'BillingEmailSettings'
  toEmails: Array<Scalars['EmailAddress']>
  toAdmins: Scalars['Boolean']
}

export type CustomDomain = {
  __typename?: 'CustomDomain'
  domain: Scalars['Domain']
  domainTarget: Scalars['Domain']
  isDnsValid?: Maybe<Scalars['Boolean']>
  dnsLastValidAt?: Maybe<Scalars['DateTime']>
  isAutoRenew: Scalars['Boolean']
  renewAfter?: Maybe<Scalars['DateTime']>
  certificateDigest?: Maybe<Scalars['String']>
  certificate?: Maybe<CustomDomainCertificate>
  certificates: Array<CustomDomainCertificate>
}

export type CustomDomainCertificate = {
  __typename?: 'CustomDomainCertificate'
  digest: Scalars['String']
  expiresAt: Scalars['DateTime']
  createdAt: Scalars['DateTime']
}

export type Organization = {
  __typename?: 'Organization'
  id: Scalars['Int']
  name: Scalars['String']
  subdomain: Scalars['Subdomain']
  origin: Scalars['HttpUrl']
  blissbookOrigin: Scalars['HttpUrl']
  isFullServe: Scalars['Boolean']
  isCrmDisabled: Scalars['Boolean']
  isIntercomDisabled: Scalars['Boolean']
  isAuthDisabled: Scalars['Boolean']
  isHandbookPositionEnabled: Scalars['Boolean']
  showPrivacyPolicy: Scalars['Boolean']
  logoImage?: Maybe<Image>
  createdAt: Scalars['DateTime']
  createdBy?: Maybe<Scalars['Int']>
  updatedAt: Scalars['DateTime']
  preferences: Scalars['JSON']
  emailPeopleCount: Scalars['Int']
  employeeIdPeopleCount: Scalars['Int']
  friendsCount: Scalars['Int']
  handbooksCount: Scalars['Int']
  integrationsCount: Scalars['Int']
  lastHiredOnPeopleCount: Scalars['Int']
  managersCount: Scalars['Int']
  membersCount: Scalars['Int']
  peopleCount: Scalars['Int']
  phonePeopleCount: Scalars['Int']
  publishedAt?: Maybe<Scalars['DateTime']>
}

export enum HandbookCollaboratorRoleId {
  Editor = 'editor',
  Publisher = 'publisher',
}

export enum AdminRoleId {
  BrandingAdmin = 'brandingAdmin',
  BillingAdmin = 'billingAdmin',
  TechAdmin = 'techAdmin',
  OrgAdmin = 'orgAdmin',
}

export type AdminRole = {
  __typename?: 'AdminRole'
  id: AdminRoleId
  label: Scalars['String']
  description: Scalars['String']
  permissionIds: Array<Scalars['String']>
  permissions: Array<Permission>
}

export type Permission = {
  __typename?: 'Permission'
  id: Scalars['String']
  label: Scalars['String']
  description: Scalars['String']
}

export enum SuperUserRoleId {
  SuperOwner = 'superOwner',
  SuperAdmin = 'superAdmin',
  SuperBrander = 'superBrander',
  SuperUser = 'superUser',
}

export enum TeamRoleId {
  Editor = 'editor',
  Publisher = 'publisher',
  Owner = 'owner',
}

export type Session = {
  __typename?: 'Session'
  isImpersonating: Scalars['Boolean']
  person?: Maybe<SessionPerson>
  user?: Maybe<ISessionUser>
  expiresAt: Scalars['DateTime']
  permissionIds: Array<Scalars['String']>
  permissions: Array<Permission>
}

export type SessionPerson = {
  __typename?: 'SessionPerson'
  id: Scalars['Int']
  idHash?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['EmailAddress']>
  fullName?: Maybe<Scalars['String']>
  member: Scalars['Boolean']
  adminRoleId?: Maybe<AdminRoleId>
  createdAt: Scalars['DateTime']
}

export type ISessionUser = {
  id: Scalars['Int']
  type: UserType
  authType: UserAuthType
  authId: Scalars['String']
  createdAt: Scalars['DateTime']
  lastSignedInAt?: Maybe<Scalars['DateTime']>
}

export type SessionSuperUser = ISessionUser & {
  __typename?: 'SessionSuperUser'
  id: Scalars['Int']
  type: UserType
  authType: UserAuthType
  authId: Scalars['String']
  createdAt: Scalars['DateTime']
  lastSignedInAt?: Maybe<Scalars['DateTime']>
  email: Scalars['String']
  fullName: Scalars['String']
  roleId?: Maybe<SuperUserRoleId>
}

export type SessionUser = ISessionUser & {
  __typename?: 'SessionUser'
  id: Scalars['Int']
  type: UserType
  authType: UserAuthType
  authId: Scalars['String']
  createdAt: Scalars['DateTime']
  lastSignedInAt?: Maybe<Scalars['DateTime']>
}

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export type SortOrder = {
  __typename?: 'SortOrder'
  field: Scalars['String']
  direction: SortDirection
}

export enum UserAuthType {
  Email = 'email',
  EmployeeId = 'employeeId',
  Phone = 'phone',
}

export enum UserType {
  SuperUser = 'superUser',
  User = 'user',
}

export type WhodunnitPerson = {
  __typename?: 'WhodunnitPerson'
  id: Scalars['Int']
  fullName: Scalars['String']
  isBlissbook: Scalars['Boolean']
  avatarUrl?: Maybe<Scalars['String']>
}

export type ChargebeeSubscription = {
  __typename?: 'ChargebeeSubscription'
  status: Scalars['String']
  lastSyncAt?: Maybe<Scalars['DateTime']>
}

export type ChargebeeSubscriptionChargedItem = {
  __typename?: 'ChargebeeSubscriptionChargedItem'
  item_price_id?: Maybe<Scalars['String']>
}

export type ChargebeeSubscriptionItem = {
  __typename?: 'ChargebeeSubscriptionItem'
  item_id?: Maybe<Scalars['String']>
  item_price_id?: Maybe<Scalars['String']>
  item_type?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  external_name?: Maybe<Scalars['String']>
  amount?: Maybe<Scalars['Float']>
  currency_code?: Maybe<Scalars['String']>
}

export type ChargebeeInvoice = {
  __typename?: 'ChargebeeInvoice'
  id: Scalars['String']
  date?: Maybe<Scalars['DateTime']>
  paidAt?: Maybe<Scalars['DateTime']>
  total?: Maybe<Scalars['Float']>
  currency_code: Scalars['String']
  download_url?: Maybe<Scalars['String']>
}

export type ChargebeeSubscriptionDiscount = {
  __typename?: 'ChargebeeSubscriptionDiscount'
  amount: Scalars['Float']
}

export type ChargebeeSubscriptionDetails = {
  __typename?: 'ChargebeeSubscriptionDetails'
  currency_code: Scalars['String']
  customer_id: Scalars['String']
  id: Scalars['String']
  next_billing_at?: Maybe<Scalars['DateTime']>
  status: Scalars['String']
  subscription_items: Array<ChargebeeSubscriptionItem>
  charged_items?: Maybe<Array<ChargebeeSubscriptionChargedItem>>
  invoices: Array<ChargebeeInvoice>
  cancelled_at?: Maybe<Scalars['DateTime']>
  discounts?: Maybe<Array<ChargebeeSubscriptionDiscount>>
  payment_source_id?: Maybe<Scalars['String']>
}

export type ChargebeePortalSession = {
  __typename?: 'ChargebeePortalSession'
  id: Scalars['String']
  token: Scalars['String']
  access_url: Scalars['String']
  status: Scalars['String']
  customer_id: Scalars['String']
}

export enum DocumentStatus {
  Draft = 'draft',
  InReview = 'inReview',
  Published = 'published',
}

export enum DocumentType {
  Policy = 'policy',
}

export type DocumentSettings = {
  __typename?: 'DocumentSettings'
  organizationId: Scalars['Int']
  isPolicyWorkflowEnabled: Scalars['Boolean']
}

export type UpdateDocumentSettingsInput = {
  isPolicyWorkflowEnabled: Scalars['Boolean']
}

export type Document = {
  __typename?: 'Document'
  type: DocumentType
  id: Scalars['Int']
  ckeditorChannelId: Scalars['ID']
  parentFolderId?: Maybe<Scalars['Int']>
  parentFolder?: Maybe<Folder>
  sourceHandbookSectionId?: Maybe<Scalars['Int']>
  sourceHandbookId?: Maybe<Scalars['Int']>
  sortPosition: Scalars['Int']
  name: Scalars['String']
  archivedAt?: Maybe<Scalars['DateTime']>
  archivedBy?: Maybe<WhodunnitPerson>
  createdAt: Scalars['DateTime']
  createdBy?: Maybe<WhodunnitPerson>
  updatedAt: Scalars['DateTime']
  updatedBy?: Maybe<WhodunnitPerson>
  draftVersion?: Maybe<DocumentVersion>
  lastPublishedVersion?: Maybe<DocumentVersion>
  lastPublishedVersionNumber: Scalars['Int']
  publishedVersions: Array<DocumentVersion>
  reviewers: Array<DocumentReviewer>
  handbookIds: Array<Scalars['Int']>
  sortKey?: Maybe<Scalars['String']>
}

export type DocumentVersion = {
  __typename?: 'DocumentVersion'
  documentId: Scalars['Int']
  id: Scalars['Int']
  versionNumber: Scalars['Int']
  isPublished: Scalars['Boolean']
  audienceExpression?: Maybe<RootExpression>
  audienceExpressionHash: Scalars['BigInt']
  html?: Maybe<Scalars['HTML']>
  htmlHash: Scalars['BigInt']
  htmlTitle?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  createdBy?: Maybe<WhodunnitPerson>
  updatedAt: Scalars['DateTime']
  updatedBy?: Maybe<WhodunnitPerson>
  reviews: Array<DocumentReview>
}

export type DocumentsFilter = {
  documentIds?: InputMaybe<Array<Scalars['Int']>>
  expression?: InputMaybe<DocumentsRootExpressionInput>
  expressions?: InputMaybe<DocumentsRootExpressionsInput>
  isPublished?: InputMaybe<Scalars['Boolean']>
  search?: InputMaybe<Scalars['String']>
  types?: InputMaybe<Array<DocumentType>>
}

export enum DocumentSortField {
  LastPublishedAt = 'lastPublishedAt',
  LastPublishedVersionNumber = 'lastPublishedVersionNumber',
  LastUpdatedAt = 'lastUpdatedAt',
  Name = 'name',
  OwnerFullName = 'ownerFullName',
  ParentFolderName = 'parentFolderName',
  SortPosition = 'sortPosition',
  Status = 'status',
}

export type DocumentsSortOrder = {
  field: DocumentSortField
  direction: SortDirection
}

export enum DocumentsGroupByField {
  CreatedBy = 'createdBy',
  Folder = 'folder',
  Organization = 'organization',
}

export type DocumentGroup = {
  documentsCount: Scalars['Int']
  field: DocumentsGroupByField
}

export type FolderDocumentGroup = DocumentGroup & {
  __typename?: 'FolderDocumentGroup'
  documentsCount: Scalars['Int']
  field: DocumentsGroupByField
  folder?: Maybe<Folder>
}

export type PersonDocumentGroup = DocumentGroup & {
  __typename?: 'PersonDocumentGroup'
  documentsCount: Scalars['Int']
  field: DocumentsGroupByField
  person?: Maybe<WhodunnitPerson>
}

export type OrganizationDocumentGroup = DocumentGroup & {
  __typename?: 'OrganizationDocumentGroup'
  documentsCount: Scalars['Int']
  field: DocumentsGroupByField
}

export type CreateDocumentInput = {
  audienceExpression?: InputMaybe<PeopleRootExpressionInput>
  ckeditorChannelId?: InputMaybe<Scalars['ID']>
  html?: InputMaybe<Scalars['HTML']>
  name: Scalars['String']
  type: DocumentType
}

export type UpdateDocumentArgs = {
  documentId: Scalars['Int']
  input: UpdateDocumentInput
}

export type UpdateDocumentInput = {
  name?: InputMaybe<Scalars['String']>
  parentFolderId?: InputMaybe<Scalars['Int']>
}

export type MoveDocumentsInput = {
  documentId: Scalars['Int']
  after: Scalars['Boolean']
}

export type UpdateDocumentDraftVersionInput = {
  audienceExpression?: InputMaybe<PeopleRootExpressionInput>
  html?: InputMaybe<Scalars['HTML']>
}

export type Folder = {
  __typename?: 'Folder'
  id: Scalars['Int']
  parentFolderId?: Maybe<Scalars['Int']>
  name: Scalars['String']
}

export type CreateFolderInput = {
  name: Scalars['String']
  parentFolderId?: InputMaybe<Scalars['Int']>
}

export type UpdateFolderInput = {
  name?: InputMaybe<Scalars['String']>
  parentFolderId?: InputMaybe<Scalars['Int']>
}

export type DocumentReviewer = {
  __typename?: 'DocumentReviewer'
  id: Scalars['Int']
  documentId: Scalars['Int']
  personId: Scalars['Int']
  person: WhodunnitPerson
  createdAt: Scalars['DateTime']
  createdBy?: Maybe<WhodunnitPerson>
  updatedAt: Scalars['DateTime']
  updatedBy?: Maybe<WhodunnitPerson>
}

export type DocumentReview = {
  __typename?: 'DocumentReview'
  id: Scalars['Int']
  documentId: Scalars['Int']
  personId: Scalars['Int']
  person: WhodunnitPerson
  isApproved: Scalars['Boolean']
  createdAt: Scalars['DateTime']
}

export enum EventType {
  DocumentAddedEvent = 'DocumentAddedEvent',
  DocumentArchivedEvent = 'DocumentArchivedEvent',
  DocumentCreatedEvent = 'DocumentCreatedEvent',
  DocumentPublishedEvent = 'DocumentPublishedEvent',
  DocumentRemovedEvent = 'DocumentRemovedEvent',
  DocumentReviewerAddedEvent = 'DocumentReviewerAddedEvent',
  DocumentReviewerApprovedEvent = 'DocumentReviewerApprovedEvent',
  DocumentReviewerRejectedEvent = 'DocumentReviewerRejectedEvent',
  DocumentReviewerRemovedEvent = 'DocumentReviewerRemovedEvent',
  DocumentSettingsUpdatedEvent = 'DocumentSettingsUpdatedEvent',
  DocumentUnarchivedEvent = 'DocumentUnarchivedEvent',
  DocumentUpdatedEvent = 'DocumentUpdatedEvent',
  DocumentVersionCreatedEvent = 'DocumentVersionCreatedEvent',
  FeatureFlagAddedEvent = 'FeatureFlagAddedEvent',
  FeatureFlagRemovedEvent = 'FeatureFlagRemovedEvent',
  HandbookCreatedEvent = 'HandbookCreatedEvent',
  HandbookPublishedEvent = 'HandbookPublishedEvent',
  HandbookSignatureRoundCreatedEvent = 'HandbookSignatureRoundCreatedEvent',
  HandbookSignedEvent = 'HandbookSignedEvent',
  HandbookViewedEvent = 'HandbookViewedEvent',
  PeopleSyncEvent = 'PeopleSyncEvent',
  PersonNotifiedEvent = 'PersonNotifiedEvent',
  PersonCreatedEvent = 'PersonCreatedEvent',
}

export enum EventTargetType {
  Document = 'document',
  Handbook = 'handbook',
  Notification = 'notification',
  Organization = 'organization',
  Person = 'person',
}

export type Event = {
  id: Scalars['ID']
  type: EventType
  targetType: EventTargetType
  targetId: Scalars['Int']
  person?: Maybe<WhodunnitPerson>
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  sortKey: Scalars['String']
}

export type EventsFilter = {
  documentId?: InputMaybe<Scalars['Int']>
  handbookId?: InputMaybe<Scalars['Int']>
  personId?: InputMaybe<Scalars['Int']>
  superUserId?: InputMaybe<Scalars['Int']>
  types?: InputMaybe<Array<EventType>>
}

export enum EventsSortField {
  CreatedAt = 'createdAt',
}

export type EventsSortOrder = {
  field: EventsSortField
  direction: SortDirection
}

export type EventDocument = {
  __typename?: 'EventDocument'
  id: Scalars['Int']
  name: Scalars['String']
}

export type EventHandbook = {
  __typename?: 'EventHandbook'
  id: Scalars['Int']
  name: Scalars['String']
}

export type EventHandbookSignatureRound = {
  __typename?: 'EventHandbookSignatureRound'
  id: Scalars['Int']
  number: Scalars['Int']
}

export type EventNotification = {
  __typename?: 'EventNotification'
  id: Scalars['Int']
  subject?: Maybe<Scalars['String']>
  handbookId?: Maybe<Scalars['Int']>
}

export type MaybeRootExpressionUpdated = {
  __typename?: 'MaybeRootExpressionUpdated'
  prevValue?: Maybe<RootExpression>
  value?: Maybe<RootExpression>
}

export type StringUpdated = {
  __typename?: 'StringUpdated'
  prevValue: Scalars['String']
  value: Scalars['String']
}

export type DocumentAddedEvent = Event & {
  __typename?: 'DocumentAddedEvent'
  id: Scalars['ID']
  type: EventType
  targetType: EventTargetType
  targetId: Scalars['Int']
  person?: Maybe<WhodunnitPerson>
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  sortKey: Scalars['String']
  document: EventDocument
  handbook: EventHandbook
}

export type DocumentArchivedEvent = Event & {
  __typename?: 'DocumentArchivedEvent'
  id: Scalars['ID']
  type: EventType
  targetType: EventTargetType
  targetId: Scalars['Int']
  person?: Maybe<WhodunnitPerson>
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  sortKey: Scalars['String']
  document: EventDocument
}

export type DocumentCreatedEvent = Event & {
  __typename?: 'DocumentCreatedEvent'
  id: Scalars['ID']
  type: EventType
  targetType: EventTargetType
  targetId: Scalars['Int']
  person?: Maybe<WhodunnitPerson>
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  sortKey: Scalars['String']
  document: EventDocument
}

export type DocumentPublishedEventAttributes = {
  __typename?: 'DocumentPublishedEventAttributes'
  versionNumber: Scalars['Int']
}

export type DocumentPublishedEvent = Event & {
  __typename?: 'DocumentPublishedEvent'
  id: Scalars['ID']
  type: EventType
  targetType: EventTargetType
  targetId: Scalars['Int']
  person?: Maybe<WhodunnitPerson>
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  sortKey: Scalars['String']
  document: EventDocument
  attributes: DocumentPublishedEventAttributes
}

export type DocumentRemovedEvent = Event & {
  __typename?: 'DocumentRemovedEvent'
  id: Scalars['ID']
  type: EventType
  targetType: EventTargetType
  targetId: Scalars['Int']
  person?: Maybe<WhodunnitPerson>
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  sortKey: Scalars['String']
  document: EventDocument
  handbook: EventHandbook
}

export type DocumentReviewerAddedEventAttributes = {
  __typename?: 'DocumentReviewerAddedEventAttributes'
  documentVersionNumber: Scalars['Int']
}

export type DocumentReviewerAddedEvent = Event & {
  __typename?: 'DocumentReviewerAddedEvent'
  id: Scalars['ID']
  type: EventType
  targetType: EventTargetType
  targetId: Scalars['Int']
  person?: Maybe<WhodunnitPerson>
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  sortKey: Scalars['String']
  attributes: DocumentReviewerAddedEventAttributes
  document: EventDocument
  documentReviewerPerson: WhodunnitPerson
}

export type DocumentReviewerApprovedEventAttributes = {
  __typename?: 'DocumentReviewerApprovedEventAttributes'
  documentVersionNumber: Scalars['Int']
}

export type DocumentReviewerApprovedEvent = Event & {
  __typename?: 'DocumentReviewerApprovedEvent'
  id: Scalars['ID']
  type: EventType
  targetType: EventTargetType
  targetId: Scalars['Int']
  person?: Maybe<WhodunnitPerson>
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  sortKey: Scalars['String']
  attributes: DocumentReviewerApprovedEventAttributes
  document: EventDocument
}

export type DocumentReviewerRejectedEventAttributes = {
  __typename?: 'DocumentReviewerRejectedEventAttributes'
  documentVersionNumber: Scalars['Int']
}

export type DocumentReviewerRejectedEvent = Event & {
  __typename?: 'DocumentReviewerRejectedEvent'
  id: Scalars['ID']
  type: EventType
  targetType: EventTargetType
  targetId: Scalars['Int']
  person?: Maybe<WhodunnitPerson>
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  sortKey: Scalars['String']
  attributes: DocumentReviewerRejectedEventAttributes
  document: EventDocument
}

export type DocumentReviewerRemovedEventAttributes = {
  __typename?: 'DocumentReviewerRemovedEventAttributes'
  documentVersionNumber: Scalars['Int']
}

export type DocumentReviewerRemovedEvent = Event & {
  __typename?: 'DocumentReviewerRemovedEvent'
  id: Scalars['ID']
  type: EventType
  targetType: EventTargetType
  targetId: Scalars['Int']
  person?: Maybe<WhodunnitPerson>
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  sortKey: Scalars['String']
  attributes: DocumentReviewerRemovedEventAttributes
  document: EventDocument
  documentReviewerPerson: WhodunnitPerson
}

export type DocumentSettingsUpdatedEventAttributes = {
  __typename?: 'DocumentSettingsUpdatedEventAttributes'
  isPolicyWorkflowEnabled: Scalars['Boolean']
}

export type DocumentSettingsUpdatedEvent = Event & {
  __typename?: 'DocumentSettingsUpdatedEvent'
  id: Scalars['ID']
  type: EventType
  targetType: EventTargetType
  targetId: Scalars['Int']
  person?: Maybe<WhodunnitPerson>
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  sortKey: Scalars['String']
  attributes: DocumentSettingsUpdatedEventAttributes
}

export type DocumentUnarchivedEvent = Event & {
  __typename?: 'DocumentUnarchivedEvent'
  id: Scalars['ID']
  type: EventType
  targetType: EventTargetType
  targetId: Scalars['Int']
  person?: Maybe<WhodunnitPerson>
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  sortKey: Scalars['String']
  document: EventDocument
}

export type DocumentUpdatedEvent = Event & {
  __typename?: 'DocumentUpdatedEvent'
  id: Scalars['ID']
  type: EventType
  targetType: EventTargetType
  targetId: Scalars['Int']
  person?: Maybe<WhodunnitPerson>
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  sortKey: Scalars['String']
  document: EventDocument
  versionNumber: Scalars['Int']
  audienceExpression?: Maybe<MaybeRootExpressionUpdated>
  html: Scalars['Boolean']
  name?: Maybe<StringUpdated>
}

export type DocumentVersionCreatedEventAttributes = {
  __typename?: 'DocumentVersionCreatedEventAttributes'
  versionNumber: Scalars['Int']
}

export type DocumentVersionCreatedEvent = Event & {
  __typename?: 'DocumentVersionCreatedEvent'
  id: Scalars['ID']
  type: EventType
  targetType: EventTargetType
  targetId: Scalars['Int']
  person?: Maybe<WhodunnitPerson>
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  sortKey: Scalars['String']
  document: EventDocument
  attributes: DocumentVersionCreatedEventAttributes
}

export type FeatureFlagEventAttributes = {
  __typename?: 'FeatureFlagEventAttributes'
  featureFlagId: Scalars['String']
}

export type FeatureFlagAddedEvent = Event & {
  __typename?: 'FeatureFlagAddedEvent'
  id: Scalars['ID']
  type: EventType
  targetType: EventTargetType
  targetId: Scalars['Int']
  person?: Maybe<WhodunnitPerson>
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  sortKey: Scalars['String']
  attributes: FeatureFlagEventAttributes
}

export type FeatureFlagRemovedEvent = Event & {
  __typename?: 'FeatureFlagRemovedEvent'
  id: Scalars['ID']
  type: EventType
  targetType: EventTargetType
  targetId: Scalars['Int']
  person?: Maybe<WhodunnitPerson>
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  sortKey: Scalars['String']
  attributes: FeatureFlagEventAttributes
}

export type HandbookCreatedEvent = Event & {
  __typename?: 'HandbookCreatedEvent'
  id: Scalars['ID']
  type: EventType
  targetType: EventTargetType
  targetId: Scalars['Int']
  person?: Maybe<WhodunnitPerson>
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  sortKey: Scalars['String']
  handbook: EventHandbook
}

export type HandbookPublishedEventAttributes = {
  __typename?: 'HandbookPublishedEventAttributes'
  versionNumber: Scalars['Int']
}

export type HandbookPublishedEvent = Event & {
  __typename?: 'HandbookPublishedEvent'
  id: Scalars['ID']
  type: EventType
  targetType: EventTargetType
  targetId: Scalars['Int']
  person?: Maybe<WhodunnitPerson>
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  sortKey: Scalars['String']
  handbook: EventHandbook
  attributes: HandbookPublishedEventAttributes
}

export type HandbookSignatureRoundCreatedEvent = Event & {
  __typename?: 'HandbookSignatureRoundCreatedEvent'
  id: Scalars['ID']
  type: EventType
  targetType: EventTargetType
  targetId: Scalars['Int']
  person?: Maybe<WhodunnitPerson>
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  sortKey: Scalars['String']
  handbook: EventHandbook
  signatureRound: EventHandbookSignatureRound
}

export type HandbookSignedEventAttributes = {
  __typename?: 'HandbookSignedEventAttributes'
  handbookSignatureId: Scalars['Int']
}

export type HandbookSignedEvent = Event & {
  __typename?: 'HandbookSignedEvent'
  id: Scalars['ID']
  type: EventType
  targetType: EventTargetType
  targetId: Scalars['Int']
  person?: Maybe<WhodunnitPerson>
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  sortKey: Scalars['String']
  handbook: EventHandbook
  attributes: HandbookSignedEventAttributes
}

export type HandbookViewedEventAttributes = {
  __typename?: 'HandbookViewedEventAttributes'
  handbookSessionId: Scalars['Int']
}

export type HandbookViewedEvent = Event & {
  __typename?: 'HandbookViewedEvent'
  id: Scalars['ID']
  type: EventType
  targetType: EventTargetType
  targetId: Scalars['Int']
  person?: Maybe<WhodunnitPerson>
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  sortKey: Scalars['String']
  handbook: EventHandbook
  attributes: HandbookViewedEventAttributes
}

export type PeopleSyncEvent = Event & {
  __typename?: 'PeopleSyncEvent'
  id: Scalars['ID']
  type: EventType
  targetType: EventTargetType
  targetId: Scalars['Int']
  person?: Maybe<WhodunnitPerson>
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  sortKey: Scalars['String']
}

export type PersonCreatedEvent = Event & {
  __typename?: 'PersonCreatedEvent'
  type: EventType
  id: Scalars['ID']
  targetType: EventTargetType
  targetId: Scalars['Int']
  person?: Maybe<WhodunnitPerson>
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  sortKey: Scalars['String']
}

export type PersonNotifiedEvent = Event & {
  __typename?: 'PersonNotifiedEvent'
  type: EventType
  id: Scalars['ID']
  targetType: EventTargetType
  targetId: Scalars['Int']
  person?: Maybe<WhodunnitPerson>
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  sortKey: Scalars['String']
  notification: EventNotification
}

export type Handbook = {
  __typename?: 'Handbook'
  id: Scalars['Int']
  teamId: Scalars['Int']
  name: Scalars['String']
  defaultLanguageCode: Scalars['String']
  customDomain?: Maybe<Scalars['Domain']>
  customDomainOrigin?: Maybe<Scalars['HttpUrl']>
  isPrintDisabled: Scalars['Boolean']
  position: Scalars['Int']
  public: Scalars['Boolean']
  publicToken: Scalars['String']
  version: Scalars['Int']
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  lastModifiedAt?: Maybe<Scalars['DateTime']>
  lastModifiedBy?: Maybe<Scalars['Int']>
  publishedAt?: Maybe<Scalars['DateTime']>
  lastPublishedAt?: Maybe<Scalars['DateTime']>
  lastPublishedBy?: Maybe<Scalars['Int']>
  lastPublishedByPerson?: Maybe<WhodunnitPerson>
  url: Scalars['HttpUrl']
  printUrl: Scalars['HttpUrl']
  publishedUrl: Scalars['HttpUrl']
  permissionIds: Array<Scalars['String']>
  acknowledgementForm: HandbookAcknowledgement
  branding: HandbookBranding
  contactFragments: HandbookContact
  collaborators: Array<HandbookCollaborator>
  audience: HandbookAudience
  hasAudience: Scalars['Boolean']
  audienceInvitationsCount: Scalars['Int']
  viewersCount: Scalars['Int']
  archivedSections: Array<HandbookSection>
  assets: Array<HandbookAsset>
  content: Scalars['BodyContent']
  contentLength: Scalars['Int']
  deletePreviewLinks: Scalars['Boolean']
  documents: Array<Document>
  fonts: Array<HandbookFont>
  hasPersonalizedContent: Scalars['Boolean']
  sections: Array<HandbookSection>
  previewLinks: Array<HandbookPreviewLink>
  publishNotification?: Maybe<PublishHandbookNotification>
  signatureRounds: Array<HandbookSignatureRound>
  tasks: Array<HandbookTask>
  versions: Array<HandbookVersionSummary>
  statistics: HandbookStatistics
}

export type HandbookAudienceInvitationsCountArgs = {
  audience: HandbookAudienceInput
}

export type HandbookViewersCountArgs = {
  canNotify?: InputMaybe<Scalars['Boolean']>
  canSign?: InputMaybe<Scalars['Boolean']>
  notMe?: InputMaybe<Scalars['Boolean']>
}

export type HandbookAssetsArgs = {
  archived?: InputMaybe<Scalars['Boolean']>
  type?: InputMaybe<AssetType>
}

export type HandbookFontsArgs = {
  archived?: InputMaybe<Scalars['Boolean']>
}

export type HandbookStatisticsArgs = {
  canView?: InputMaybe<Scalars['Boolean']>
  groupIds?: InputMaybe<Array<Scalars['Int']>>
  managerId?: InputMaybe<Scalars['Int']>
  managesDirectly?: InputMaybe<Scalars['Boolean']>
  search?: InputMaybe<Scalars['String']>
}

export type HandbookInput = {
  acknowledgementForm?: InputMaybe<HandbookAcknowledgementInput>
  branding?: InputMaybe<HandbookBrandingInput>
  contactFragments?: InputMaybe<HandbookContactInput>
  defaultLanguageCode?: InputMaybe<Scalars['String']>
  deletePreviewLinks?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  isPrintDisabled?: InputMaybe<Scalars['Boolean']>
  public?: InputMaybe<Scalars['Boolean']>
}

export type HandbookCssInput = {
  branding?: InputMaybe<HandbookBrandingAdminInput>
}

export type HandbookAcknowledgement = {
  __typename?: 'HandbookAcknowledgement'
  title?: Maybe<Scalars['TitleContent']>
  body?: Maybe<Scalars['BodyContent']>
  forceSign: Scalars['Boolean']
}

export type HandbookAcknowledgementInput = {
  title?: InputMaybe<Scalars['TitleContent']>
  body?: InputMaybe<Scalars['BodyContent']>
  forceSign?: InputMaybe<Scalars['Boolean']>
}

export type HandbookAsset = {
  id: Scalars['Int']
  type: AssetType
  filename: Scalars['String']
  filesize: Scalars['Int']
  path: Scalars['String']
  thumbnailUrl?: Maybe<Scalars['String']>
  url: Scalars['String']
  mimeType?: Maybe<Scalars['String']>
  archived: Scalars['Boolean']
  createdAt: Scalars['DateTime']
}

export type HandbookImage = HandbookAsset & {
  __typename?: 'HandbookImage'
  id: Scalars['Int']
  type: AssetType
  filename: Scalars['String']
  filesize: Scalars['Int']
  path: Scalars['String']
  thumbnailUrl?: Maybe<Scalars['String']>
  url: Scalars['String']
  width?: Maybe<Scalars['Int']>
  height?: Maybe<Scalars['Int']>
  mimeType?: Maybe<Scalars['String']>
  archived: Scalars['Boolean']
  createdAt: Scalars['DateTime']
}

export type HandbookPdf = HandbookAsset & {
  __typename?: 'HandbookPdf'
  id: Scalars['Int']
  type: AssetType
  filename: Scalars['String']
  filesize: Scalars['Int']
  path: Scalars['String']
  thumbnailUrl?: Maybe<Scalars['String']>
  url: Scalars['String']
  mimeType?: Maybe<Scalars['String']>
  archived: Scalars['Boolean']
  createdAt: Scalars['DateTime']
}

export type HandbookBranding = {
  __typename?: 'HandbookBranding'
  blackColor: Scalars['Color']
  bodyFont: HandbookBrandingFont
  customHtml?: Maybe<Scalars['String']>
  favicon?: Maybe<Scalars['HttpUrl']>
  handbookCss?: Maybe<Scalars['String']>
  headingFont: HandbookBrandingFont
  lessButtonText: Scalars['String']
  moreButtonText: Scalars['String']
  primaryColor: Scalars['Color']
  printCss?: Maybe<Scalars['String']>
  scriptFont: HandbookBrandingFont
  whiteColor: Scalars['Color']
}

export type HandbookBrandingInput = {
  blackColor?: InputMaybe<Scalars['Color']>
  bodyFont?: InputMaybe<HandbookBrandingFontInput>
  favicon?: InputMaybe<Scalars['HttpUrl']>
  headingFont?: InputMaybe<HandbookBrandingFontInput>
  lessButtonText?: InputMaybe<Scalars['String']>
  moreButtonText?: InputMaybe<Scalars['String']>
  primaryColor?: InputMaybe<Scalars['Color']>
  scriptFont?: InputMaybe<HandbookBrandingFontInput>
  whiteColor?: InputMaybe<Scalars['Color']>
}

export type HandbookBrandingAdminInput = {
  handbookCss?: InputMaybe<Scalars['String']>
  printCss?: InputMaybe<Scalars['String']>
}

export enum HandbookBrandingFontType {
  Custom = 'custom',
  Google = 'google',
  System = 'system',
}

export type HandbookBrandingFont = {
  __typename?: 'HandbookBrandingFont'
  type: HandbookBrandingFontType
  family?: Maybe<Scalars['String']>
  fontId?: Maybe<Scalars['Int']>
  bold?: Maybe<Scalars['Int']>
  normal?: Maybe<Scalars['Int']>
  variants?: Maybe<Array<Scalars['String']>>
}

export type HandbookBrandingFontInput = {
  type: HandbookBrandingFontType
  family?: InputMaybe<Scalars['String']>
  fontId?: InputMaybe<Scalars['Int']>
  bold?: InputMaybe<Scalars['Int']>
  normal?: InputMaybe<Scalars['Int']>
  variants?: InputMaybe<Array<Scalars['String']>>
}

export type HandbookCollaborator = {
  __typename?: 'HandbookCollaborator'
  id: Scalars['Int']
  handbookId: Scalars['Int']
  personId: Scalars['Int']
  roleId?: Maybe<HandbookCollaboratorRoleId>
}

export type HandbookCollaboratorInput = {
  roleId?: InputMaybe<HandbookCollaboratorRoleId>
}

export type HandbookContact = {
  __typename?: 'HandbookContact'
  title?: Maybe<Scalars['TitleContent']>
  body?: Maybe<Scalars['BodyContent']>
}

export type HandbookContactInput = {
  title?: InputMaybe<Scalars['TitleContent']>
  body?: InputMaybe<Scalars['BodyContent']>
}

export type HandbookFont = {
  __typename?: 'HandbookFont'
  id: Scalars['Int']
  filename: Scalars['String']
  archived: Scalars['Boolean']
  createdAt: Scalars['DateTime']
}

export type HandbookNotification = {
  __typename?: 'HandbookNotification'
  id: Scalars['Int']
  handbookId?: Maybe<Scalars['Int']>
  personId: Scalars['Int']
  email: Scalars['EmailAddress']
  toName?: Maybe<Scalars['String']>
  fromEmail?: Maybe<Scalars['EmailAddress']>
  fromName?: Maybe<Scalars['String']>
  subject?: Maybe<Scalars['String']>
  body?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  sentAt?: Maybe<Scalars['DateTime']>
  lastError?: Maybe<Scalars['String']>
  lastErrorAt?: Maybe<Scalars['DateTime']>
}

export type HandbookPreviewLink = {
  __typename?: 'HandbookPreviewLink'
  handbookId: Scalars['Int']
  personId?: Maybe<Scalars['Int']>
  token: Scalars['String']
  previewUrl: Scalars['HttpUrl']
  createdAt: Scalars['DateTime']
}

export type HandbookAudience = {
  __typename?: 'HandbookAudience'
  member: Scalars['Boolean']
  groupIds: Array<Scalars['Int']>
  personIds: Array<Scalars['Int']>
  savedSegmentIds: Array<Scalars['Int']>
}

export type HandbookAudienceInput = {
  member?: InputMaybe<Scalars['Boolean']>
  groupIds?: InputMaybe<Array<Scalars['Int']>>
  personIds?: InputMaybe<Array<Scalars['Int']>>
  savedSegmentIds?: InputMaybe<Array<Scalars['Int']>>
  newPeople?: InputMaybe<Array<NewPersonInput>>
}

export type DocumentRefNodeAttrsInput = {
  hideToc: Scalars['Boolean']
  tocTitle?: InputMaybe<Scalars['String']>
}

export enum HandbookRecipientsColumnId {
  EmployeeId = 'employeeId',
  FullName = 'fullName',
  Email = 'email',
  DocumentStatus = 'documentStatus',
  LastHiredOn = 'lastHiredOn',
  Level = 'level',
  Manager = 'manager',
  LastSigned = 'lastSigned',
  ComplianceStatus = 'complianceStatus',
  SignatureDueDate = 'signatureDueDate',
  LastViewed = 'lastViewed',
  LastNotified = 'lastNotified',
  Groups = 'groups',
  Metadata = 'metadata',
}

export type HandbookRecipientsColumnKey = {
  field: HandbookRecipientsColumnId
  path?: InputMaybe<Scalars['String']>
}

export type HandbookRecipientsFilter = {
  canNotify?: InputMaybe<Scalars['Boolean']>
  canSign?: InputMaybe<Scalars['Boolean']>
  canView?: InputMaybe<Scalars['Boolean']>
  expression?: InputMaybe<HandbookRecipientsRootExpressionInput>
  isManager?: InputMaybe<Scalars['Boolean']>
  isSignatureRequired?: InputMaybe<Scalars['Boolean']>
  search?: InputMaybe<Scalars['String']>
}

export enum HandbookRecipientsSortField {
  Email = 'email',
  EmployeeId = 'employeeId',
  FullName = 'fullName',
  IsManager = 'isManager',
  IsSignatureRequired = 'isSignatureRequired',
  LastHiredOn = 'lastHiredOn',
  LastNotifiedAt = 'lastNotifiedAt',
  LastSignedAt = 'lastSignedAt',
  LastViewedAt = 'lastViewedAt',
  Score = 'score',
  SignatureDueDate = 'signatureDueDate',
}

export type HandbookRecipientsSortOrder = {
  field: HandbookRecipientsSortField
  direction: SortDirection
}

export type HandbookRecipient = {
  __typename?: 'HandbookRecipient'
  handbookId: Scalars['Int']
  personId: Scalars['Int']
  email?: Maybe<Scalars['EmailAddress']>
  employeeId?: Maybe<Scalars['String']>
  fullName: Scalars['String']
  groupIds: Array<Scalars['Int']>
  groupNames: Array<Scalars['String']>
  isManager: Scalars['Boolean']
  lastHiredOn?: Maybe<Scalars['Date']>
  managers: Array<PersonManager>
  metadata?: Maybe<Scalars['JSON']>
  canNotify: Scalars['Boolean']
  canView: Scalars['Boolean']
  signatureRoundId?: Maybe<Scalars['Int']>
  signatureRequestedAt?: Maybe<Scalars['DateTime']>
  signatureDueDate?: Maybe<Scalars['Date']>
  signatureDueAt?: Maybe<Scalars['DateTime']>
  isSignatureRequired: Scalars['Boolean']
  /** @deprecated Field no longer supported */
  signatureValid: Scalars['Boolean']
  hasValidSignature: Scalars['Boolean']
  lastNotifiedAt?: Maybe<Scalars['DateTime']>
  notifications: Array<HandbookNotification>
  lastNotificationQueuedAt?: Maybe<Scalars['DateTime']>
  lastViewedAt?: Maybe<Scalars['DateTime']>
  lastViewedVersion?: Maybe<Scalars['Int']>
  sessions: Array<HandbookSession>
  lastSignedAt?: Maybe<Scalars['DateTime']>
  lastSignedUserId?: Maybe<Scalars['Int']>
  lastSignedVersion?: Maybe<Scalars['Int']>
  signatures: Array<HandbookSignature>
  signatureDueDateOverrides: Array<HandbookSignatureDueDateOverride>
  signatureRoundIds: Array<Scalars['Int']>
  score: Scalars['Float']
  /** @deprecated Field no longer supported */
  maxScore: Scalars['Float']
  sortKey?: Maybe<Scalars['String']>
}

export enum HandbookReminderDate {
  Invitation = 'invitation',
  Due = 'due',
}

export type HandbookReminder = {
  __typename?: 'HandbookReminder'
  id: Scalars['Int']
  isEnabled: Scalars['Boolean']
  days: Scalars['Int']
  date: HandbookReminderDate
  cc: Array<Scalars['EmailAddress']>
  ccManagers: Scalars['Boolean']
  subjectContent: Scalars['TextContent']
  buttonContent: Scalars['TextContent']
  messageContent: Scalars['EmailContent']
  createdAt?: Maybe<Scalars['DateTime']>
  createdBy?: Maybe<Scalars['Int']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedBy?: Maybe<Scalars['Int']>
}

export type HandbookReminderInput = {
  days: Scalars['Int']
  date: HandbookReminderDate
  cc: Array<Scalars['EmailAddress']>
  ccManagers: Scalars['Boolean']
  subjectContent: Scalars['TextContent']
  buttonContent: Scalars['TextContent']
  messageContent: Scalars['EmailContent']
}

export enum HandbookSectionType {
  Beliefs = 'beliefs',
  Chapter = 'chapter',
  Cover = 'cover',
  Credo = 'credo',
  Credoh = 'credoh',
  Goal = 'goal',
  Hero = 'hero',
  History = 'history',
  Leaders = 'leaders',
  Letter = 'letter',
  List = 'list',
  Mission = 'mission',
  Origin = 'origin',
  Overview = 'overview',
  Principles = 'principles',
  Text = 'text',
  Timeline = 'timeline',
  Values = 'values',
  Worthiness = 'worthiness',
}

export type HandbookSection = {
  __typename?: 'HandbookSection'
  handbookId: Scalars['Int']
  id: Scalars['Int']
  type: HandbookSectionType
  version: Scalars['Int']
  dirty?: Maybe<Scalars['Boolean']>
  dirtyFields?: Maybe<Array<Scalars['String']>>
  theme?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  languageCode?: Maybe<Scalars['String']>
  hideToc: Scalars['Boolean']
  attrs: HandbookSectionAttributes
  fragments: HandbookSectionContent
  listEntries?: Maybe<Array<HandbookSectionEntry>>
  hidden: Scalars['Boolean']
  audienceExpression?: Maybe<RootExpression>
  viewAnnotation?: Maybe<Scalars['String']>
  editAnnotation?: Maybe<Scalars['String']>
  hideAnnotations: Array<Scalars['Int']>
  createdAt?: Maybe<Scalars['DateTime']>
  createdBy?: Maybe<Scalars['Int']>
  updatedAt?: Maybe<Scalars['DateTime']>
  updatedBy?: Maybe<Scalars['Int']>
  lastPublishedAt?: Maybe<Scalars['DateTime']>
  lastPublishedBy?: Maybe<Scalars['Int']>
}

export type HandbookSectionState = {
  __typename?: 'HandbookSectionState'
  dirty?: Maybe<Scalars['Boolean']>
  dirtyFields?: Maybe<Array<Scalars['String']>>
}

export type HandbookSectionAttributes = {
  __typename?: 'HandbookSectionAttributes'
  keys: Array<Scalars['String']>
  titleAlign?: Maybe<HandbookSectionTextAlign>
  subtitleAlign?: Maybe<HandbookSectionTextAlign>
  goalAlign?: Maybe<HandbookSectionTextAlign>
  statementAlign?: Maybe<HandbookSectionTextAlign>
  animation?: Maybe<Scalars['Boolean']>
  backgroundAnimation?: Maybe<Scalars['String']>
  backgroundColor?: Maybe<Scalars['HandbookColor']>
  backgroundImage?: Maybe<Scalars['HttpUrl']>
  backgroundOverlayColor?: Maybe<Scalars['HandbookColor']>
  backgroundOverlayOpacity?: Maybe<Scalars['Int']>
  bottom?: Maybe<Scalars['String']>
  bottomArrow?: Maybe<Scalars['Boolean']>
  headingBackgroundImage?: Maybe<Scalars['HttpUrl']>
  height?: Maybe<Scalars['String']>
  entryAngle?: Maybe<Scalars['String']>
  entryAnimation?: Maybe<Scalars['Boolean']>
  maxColumns?: Maybe<Scalars['Int']>
  showChapterIcon?: Maybe<Scalars['Boolean']>
  signatureType?: Maybe<Scalars['String']>
  textShadow?: Maybe<Scalars['Boolean']>
  titleHighlight?: Maybe<Scalars['String']>
  titleHighlightColor?: Maybe<Scalars['HandbookColor']>
  image?: Maybe<Scalars['HttpUrl']>
  photo?: Maybe<Scalars['HttpUrl']>
  signatureImage?: Maybe<Scalars['HttpUrl']>
  video?: Maybe<HandbookVideo>
}

export type HandbookSectionAttributesInput = {
  titleAlign?: InputMaybe<HandbookSectionTextAlign>
  subtitleAlign?: InputMaybe<HandbookSectionTextAlign>
  goalAlign?: InputMaybe<HandbookSectionTextAlign>
  statementAlign?: InputMaybe<HandbookSectionTextAlign>
  animation?: InputMaybe<Scalars['Boolean']>
  backgroundAnimation?: InputMaybe<Scalars['String']>
  backgroundColor?: InputMaybe<Scalars['HandbookColor']>
  backgroundImage?: InputMaybe<Scalars['HttpUrl']>
  backgroundOverlayColor?: InputMaybe<Scalars['HandbookColor']>
  backgroundOverlayOpacity?: InputMaybe<Scalars['Int']>
  bottom?: InputMaybe<Scalars['String']>
  bottomArrow?: InputMaybe<Scalars['Boolean']>
  headingBackgroundImage?: InputMaybe<Scalars['HttpUrl']>
  height?: InputMaybe<Scalars['String']>
  entryAngle?: InputMaybe<Scalars['String']>
  entryAnimation?: InputMaybe<Scalars['Boolean']>
  maxColumns?: InputMaybe<Scalars['Int']>
  showChapterIcon?: InputMaybe<Scalars['Boolean']>
  signatureType?: InputMaybe<Scalars['String']>
  textShadow?: InputMaybe<Scalars['Boolean']>
  titleHighlight?: InputMaybe<Scalars['String']>
  titleHighlightColor?: InputMaybe<Scalars['HandbookColor']>
  image?: InputMaybe<Scalars['HttpUrl']>
  photo?: InputMaybe<Scalars['HttpUrl']>
  signatureImage?: InputMaybe<Scalars['HttpUrl']>
  video?: InputMaybe<HandbookVideoInput>
}

export type HandbookSectionContent = {
  __typename?: 'HandbookSectionContent'
  keys: Array<Scalars['String']>
  title?: Maybe<Scalars['TitleContent']>
  subtitle?: Maybe<Scalars['TitleContent']>
  goal?: Maybe<Scalars['TitleContent']>
  statement?: Maybe<Scalars['TitleContent']>
  headline?: Maybe<Scalars['BodyContent']>
  introduction?: Maybe<Scalars['BodyContent']>
  body?: Maybe<Scalars['BodyContent']>
  description?: Maybe<Scalars['BodyContent']>
  signature?: Maybe<Scalars['BodyContent']>
}

export type HandbookSectionContentInput = {
  title?: InputMaybe<Scalars['TitleContent']>
  subtitle?: InputMaybe<Scalars['TitleContent']>
  goal?: InputMaybe<Scalars['TitleContent']>
  statement?: InputMaybe<Scalars['TitleContent']>
  headline?: InputMaybe<Scalars['BodyContent']>
  introduction?: InputMaybe<Scalars['BodyContent']>
  body?: InputMaybe<Scalars['BodyContent']>
  description?: InputMaybe<Scalars['BodyContent']>
  signature?: InputMaybe<Scalars['BodyContent']>
}

export type HandbookSectionEntry = {
  __typename?: 'HandbookSectionEntry'
  keys: Array<Scalars['String']>
  uid: Scalars['String']
  title?: Maybe<Scalars['TitleContent']>
  titleAlign?: Maybe<HandbookSectionTextAlign>
  subtitle?: Maybe<Scalars['TitleContent']>
  subtitleAlign?: Maybe<HandbookSectionTextAlign>
  body?: Maybe<Scalars['BodyContent']>
  image?: Maybe<Scalars['HttpUrl']>
}

export type HandbookSectionEntryInput = {
  title?: InputMaybe<Scalars['TitleContent']>
  titleAlign?: InputMaybe<HandbookSectionTextAlign>
  subtitle?: InputMaybe<Scalars['TitleContent']>
  subtitleAlign?: InputMaybe<HandbookSectionTextAlign>
  body?: InputMaybe<Scalars['BodyContent']>
  image?: InputMaybe<Scalars['HttpUrl']>
}

export type NewHandbookSectionInput = {
  type: HandbookSectionType
  theme?: InputMaybe<Scalars['String']>
  attrs?: InputMaybe<HandbookSectionAttributesInput>
  fragments?: InputMaybe<HandbookSectionContentInput>
  listEntries?: InputMaybe<Array<HandbookSectionEntryInput>>
  title?: InputMaybe<Scalars['String']>
}

export type InsertHandbookSectionsResult = {
  __typename?: 'InsertHandbookSectionsResult'
  position: Scalars['Int']
  sections: Array<HandbookSection>
}

export type AddHandbookSectionEntryResult = {
  __typename?: 'AddHandbookSectionEntryResult'
  sectionState: HandbookSectionState
  entry: HandbookSectionEntry
}

export type PatchHandbookSectionEntryResult = {
  __typename?: 'PatchHandbookSectionEntryResult'
  sectionState: HandbookSectionState
  entry: HandbookSectionEntry
}

export type MoveHandbookSectionEntryResult = {
  __typename?: 'MoveHandbookSectionEntryResult'
  sectionState: HandbookSectionState
}

export type RemoveHandbookSectionEntryResult = {
  __typename?: 'RemoveHandbookSectionEntryResult'
  sectionState: HandbookSectionState
}

export type RevertHandbookDocumentRefsResult = {
  __typename?: 'RevertHandbookDocumentRefsResult'
  content: Scalars['BodyContent']
  sections: Array<HandbookSection>
}

export type HandbookSectionInput = {
  attrs?: InputMaybe<HandbookSectionAttributesInput>
  editAnnotation?: InputMaybe<Scalars['String']>
  audienceExpression?: InputMaybe<AudienceRootExpressionInput>
  hidden?: InputMaybe<Scalars['Boolean']>
  hideAnnotations?: InputMaybe<Array<Scalars['Int']>>
  hideToc?: InputMaybe<Scalars['Boolean']>
  languageCode?: InputMaybe<Scalars['String']>
  fragments?: InputMaybe<HandbookSectionContentInput>
  theme?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  viewAnnotation?: InputMaybe<Scalars['String']>
}

export type HandbookSectionRead = {
  __typename?: 'HandbookSectionRead'
  sectionId: Scalars['Int']
  version: Scalars['Int']
  personId: Scalars['Int']
  createdAt: Scalars['DateTime']
}

export enum HandbookSectionTextAlign {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export type HandbookSectionVersion = {
  __typename?: 'HandbookSectionVersion'
  sectionId: Scalars['Int']
  sectionVersion: Scalars['Int']
  handbookId: Scalars['Int']
  handbookVersion: Scalars['Int']
  type: HandbookSectionType
  theme?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  languageCode?: Maybe<Scalars['String']>
  hideToc: Scalars['Boolean']
  attrs: HandbookSectionAttributes
  fragments: HandbookSectionContent
  listEntries?: Maybe<Array<HandbookSectionEntry>>
  hidden: Scalars['Boolean']
  audienceExpression?: Maybe<RootExpression>
  viewAnnotation?: Maybe<Scalars['String']>
  editAnnotation?: Maybe<Scalars['String']>
  hideAnnotations: Array<Scalars['Int']>
  createdAt?: Maybe<Scalars['DateTime']>
  createdBy?: Maybe<Scalars['Int']>
}

export enum HandbookSeedType {
  Empty = 'empty',
  Inspirational = 'inspirational',
}

export type Personalization = {
  __typename?: 'Personalization'
  groupIds: Array<Scalars['Int']>
  metadata?: Maybe<Scalars['JSON']>
  personId: Scalars['Int']
  savedSegmentIds: Array<Scalars['Int']>
}

export type HandbookSession = {
  __typename?: 'HandbookSession'
  id: Scalars['Int']
  handbookId: Scalars['Int']
  handbookVersion: Scalars['Int']
  personId: Scalars['Int']
  languageCode: Scalars['String']
  personalization: Personalization
  createdAt: Scalars['DateTime']
  lastViewedAt: Scalars['DateTime']
  user: HandbookUser
}

export type HandbookSignature = {
  __typename?: 'HandbookSignature'
  id: Scalars['Int']
  handbookId: Scalars['Int']
  handbookVersion: Scalars['Int']
  personId: Scalars['Int']
  roundId: Scalars['Int']
  acknowledgementForm: HandbookSignatureAcknowledgement
  signedAt: Scalars['DateTime']
  signature?: Maybe<Scalars['String']>
  languageCode: Scalars['String']
  personalization: Personalization
  toc: Array<HandbookSignatureTocItem>
  createdAt: Scalars['DateTime']
  recordedByPersonId?: Maybe<Scalars['Int']>
  recordedByUserId?: Maybe<Scalars['Int']>
  recordedByUser?: Maybe<HandbookUser>
  userId?: Maybe<Scalars['Int']>
  user?: Maybe<HandbookUser>
}

export type HandbookSignatureAcknowledgement = {
  __typename?: 'HandbookSignatureAcknowledgement'
  title: Scalars['TitleContent']
  body: Scalars['BodyContent']
  wysiwyg?: Maybe<Scalars['String']>
}

export type HandbookSignatureDueDateOverride = {
  __typename?: 'HandbookSignatureDueDateOverride'
  id: Scalars['Int']
  personId: Scalars['Int']
  roundId: Scalars['Int']
  previousDueDate?: Maybe<Scalars['Date']>
  dueDate: Scalars['Date']
  note?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  createdBy?: Maybe<Scalars['Int']>
}

export type HandbookSignatureTocItem = {
  __typename?: 'HandbookSignatureTocItem'
  bookmark: Scalars['String']
  title: Scalars['String']
  printUrl: Scalars['String']
}

export type HandbookSignatureRound = {
  __typename?: 'HandbookSignatureRound'
  id: Scalars['Int']
  handbookId: Scalars['Int']
  number: Scalars['Int']
  audienceExpression?: Maybe<RootExpression>
  /** @deprecated Field no longer supported */
  onlyFuture: Scalars['Boolean']
  onlyAtLaunch?: Maybe<HandbookSignatureRoundOnlyAtLaunch>
  onlyMembers: Scalars['Boolean']
  dueDays?: Maybe<Scalars['Int']>
  dueDaysFuture?: Maybe<Scalars['Int']>
  archived: Scalars['Boolean']
  active: Scalars['Boolean']
  createdAt: Scalars['DateTime']
  createdBy?: Maybe<WhodunnitPerson>
  publishedAt?: Maybe<Scalars['DateTime']>
  updatedAt: Scalars['DateTime']
  updatedBy?: Maybe<WhodunnitPerson>
  peopleCount: Scalars['Int']
}

export enum HandbookSignatureRoundOnlyAtLaunch {
  Existing = 'existing',
  Future = 'future',
}

export type HandbookSignatureRoundInput = {
  audienceExpression?: InputMaybe<AudienceRootExpressionInput>
  onlyAtLaunch?: InputMaybe<HandbookSignatureRoundOnlyAtLaunch>
  onlyMembers?: InputMaybe<Scalars['Boolean']>
  dueDays?: InputMaybe<Scalars['Int']>
  dueDaysFuture?: InputMaybe<Scalars['Int']>
}

export type HandbookStatistics = {
  __typename?: 'HandbookStatistics'
  peopleCount: Scalars['Int']
  canNotifyPeopleCount: Scalars['Int']
  canNotifyNonCompliantPeopleCount: Scalars['Int']
  nonCompliantPeopleCount: Scalars['Int']
  notifiedPeopleCount: Scalars['Int']
  maxNotifiedPeopleCount: Scalars['Int']
  viewedPeopleCount: Scalars['Int']
  maxViewedPeopleCount: Scalars['Int']
  signedPeopleCount: Scalars['Int']
  maxSignedPeopleCount: Scalars['Int']
  validSignedPeopleCount: Scalars['Int']
  maxValidSignedPeopleCount: Scalars['Int']
  score?: Maybe<Scalars['Float']>
}

export enum HandbookTaskId {
  ContentWizard = 'contentWizard',
  AudienceWizard = 'audienceWizard',
  Content = 'content',
  Help = 'help',
  Acknowledgement = 'acknowledgement',
  Audience = 'audience',
  Signatures = 'signatures',
  Notification = 'notification',
  Links = 'links',
}

export type HandbookTask = {
  __typename?: 'HandbookTask'
  id: HandbookTaskId
  completedAt?: Maybe<Scalars['DateTime']>
  completedBy?: Maybe<Scalars['Int']>
}

export type HandbookVersion = {
  __typename?: 'HandbookVersion'
  handbookId: Scalars['Int']
  version: Scalars['Int']
  label?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['DateTime']>
  createdBy?: Maybe<Scalars['Int']>
  createdByPerson?: Maybe<WhodunnitPerson>
  wysiwyg?: Maybe<Scalars['String']>
  acknowledgementForm: HandbookAcknowledgement
  branding: HandbookBranding
  contactFragments: HandbookContact
  content: Scalars['BodyContent']
  defaultLanguageCode: Scalars['String']
  languageCodes: Array<Scalars['String']>
  sections: Array<HandbookSectionVersion>
}

export type HandbookVersionSummary = {
  __typename?: 'HandbookVersionSummary'
  handbookId: Scalars['Int']
  version: Scalars['Int']
  label?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['DateTime']>
  createdBy?: Maybe<Scalars['Int']>
  createdByPerson?: Maybe<WhodunnitPerson>
  defaultLanguageCode: Scalars['String']
  languageCodes: Array<Scalars['String']>
  url: Scalars['HttpUrl']
  printUrl: Scalars['HttpUrl']
}

export type UpdateHandbookVersionInput = {
  label?: InputMaybe<Scalars['String']>
}

export type HandbookVideo = {
  __typename?: 'HandbookVideo'
  url: Scalars['HttpsUrl']
  height: Scalars['Int']
  width: Scalars['Int']
}

export type HandbookVideoInput = {
  url: Scalars['HttpsUrl']
  height: Scalars['Int']
  width: Scalars['Int']
}

export type HandbookUser = {
  __typename?: 'HandbookUser'
  id: Scalars['Int']
  authType: UserAuthType
  authId: Scalars['String']
}

export type PublishHandbookContentInput = {
  acknowledgement: Scalars['Boolean']
  branding: Scalars['Boolean']
  contact: Scalars['Boolean']
  sectionIds: Array<Scalars['Int']>
}

export type PublishHandbookNotification = {
  __typename?: 'PublishHandbookNotification'
  toViewers?: Maybe<Scalars['Boolean']>
  signers?: Maybe<Notification>
  viewers?: Maybe<Notification>
}

export type PublishHandbookNotificationInput = {
  toViewers?: InputMaybe<Scalars['Boolean']>
  signers?: InputMaybe<NotificationInput>
  viewers?: InputMaybe<NotificationInput>
}

export type HandbookViewersNotificationInput = {
  signersNotification?: InputMaybe<NotificationInput>
  viewersNotification?: InputMaybe<NotificationInput>
}

export type SignHandbookSessionInput = {
  languageCode?: InputMaybe<Scalars['String']>
  signature: Scalars['String']
}

export type RecordHandbookSignatureInput = {
  personId: Scalars['Int']
  languageCode?: InputMaybe<Scalars['String']>
  signedAt?: InputMaybe<Scalars['DateTime']>
}

export type AudienceDashboardSettings = {
  __typename?: 'AudienceDashboardSettings'
  primaryColor: Scalars['Color']
  accentColor: Scalars['Color']
  faviconImageId?: Maybe<Scalars['Int']>
  faviconImage?: Maybe<Image>
  logoImageId?: Maybe<Scalars['Int']>
  logoImage?: Maybe<Image>
  bannerImageId?: Maybe<Scalars['Int']>
  bannerImage?: Maybe<Image>
  createdAt?: Maybe<Scalars['Date']>
  updatedAt?: Maybe<Scalars['Date']>
}

export type AudienceDashboardSettingsInput = {
  primaryColor?: InputMaybe<Scalars['Color']>
  accentColor?: InputMaybe<Scalars['Color']>
  faviconImageId?: InputMaybe<Scalars['Int']>
  logoImageId?: InputMaybe<Scalars['Int']>
  bannerImageId?: InputMaybe<Scalars['Int']>
}

export type ApiKey = {
  __typename?: 'ApiKey'
  key: Scalars['String']
  createdAt: Scalars['DateTime']
  archivedAt?: Maybe<Scalars['DateTime']>
}

export type NewApiKey = {
  __typename?: 'NewApiKey'
  id: Scalars['Int']
  key: Scalars['String']
  secret: Scalars['String']
  createdAt: Scalars['DateTime']
  archivedAt?: Maybe<Scalars['DateTime']>
}

export type CertificateInput = {
  certificate: Scalars['String']
  privateKey: Scalars['String']
}

export type BillingInput = {
  name?: InputMaybe<Scalars['String']>
  contactName?: InputMaybe<Scalars['String']>
  address?: InputMaybe<Scalars['String']>
  emailSettings?: InputMaybe<BillingEmailSettingsInput>
}

export type BillingEmailSettingsInput = {
  toEmails: Array<Scalars['EmailAddress']>
  toAdmins: Scalars['Boolean']
}

export type BillingAdminInput = {
  exempt?: InputMaybe<Scalars['Boolean']>
  monthlyCreditPrice?: InputMaybe<Scalars['Float']>
  yearlyCreditPrice?: InputMaybe<Scalars['Float']>
  paymentDays?: InputMaybe<Scalars['Int']>
}

export type BillingNotification = {
  __typename?: 'BillingNotification'
  id: Scalars['Int']
  email: Scalars['EmailAddress']
  type: Scalars['String']
  subject: Scalars['String']
  body: Scalars['String']
  createdAt: Scalars['DateTime']
  sentAt?: Maybe<Scalars['DateTime']>
  lastError?: Maybe<Scalars['String']>
  lastErrorAt?: Maybe<Scalars['DateTime']>
}

export type EmailAuthSettings = {
  __typename?: 'EmailAuthSettings'
  isEnabled: Scalars['Boolean']
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type EmailAuthSettingsInput = {
  isEnabled: Scalars['Boolean']
}

export enum EmailDomainEmailStatus {
  Failed = 'failed',
  Pending = 'pending',
  Success = 'success',
}

export type EmailDomain = {
  __typename?: 'EmailDomain'
  id: Scalars['Int']
  domain: Scalars['Domain']
  environment: Scalars['String']
  fromEmail: Scalars['EmailAddress']
  fromName: Scalars['String']
  dkimStatus: EmailDomainDkimStatus
  returnPathStatus: EmailDomainReturnPathStatus
  updatedAt: Scalars['DateTime']
  createdAt: Scalars['DateTime']
}

export type EmailDomainStatus = {
  __typename?: 'EmailDomainStatus'
  dkimStatus: EmailDomainDkimStatus
  dkimDns: EmailDomainDkimDns
  returnPathStatus: EmailDomainReturnPathStatus
  returnPathDns: EmailDomainReturnPathDns
}

export type EmailDomainDkimStatus = {
  __typename?: 'EmailDomainDkimStatus'
  verified: Scalars['Boolean']
  lastVerifiedAt?: Maybe<Scalars['DateTime']>
}

export type EmailDomainDkimDns = {
  __typename?: 'EmailDomainDkimDns'
  host: Scalars['String']
  textValue: Scalars['String']
  pendingHost: Scalars['String']
  pendingTextValue: Scalars['String']
}

export type EmailDomainReturnPathStatus = {
  __typename?: 'EmailDomainReturnPathStatus'
  verified: Scalars['Boolean']
  lastVerifiedAt?: Maybe<Scalars['DateTime']>
}

export type EmailDomainReturnPathDns = {
  __typename?: 'EmailDomainReturnPathDns'
  domain: Scalars['Domain']
  cnameValue: Scalars['String']
}

export type EmailDomainInput = {
  fromEmail: Scalars['EmailAddress']
  fromName: Scalars['String']
}

export type EmailFooterLink = {
  __typename?: 'EmailFooterLink'
  text: Scalars['String']
  url: Scalars['HttpUrl']
}

export type EmailFooterLinkInput = {
  text: Scalars['String']
  url: Scalars['HttpUrl']
}

export type EmailSettings = {
  __typename?: 'EmailSettings'
  autoSendInvitations?: Maybe<Scalars['Boolean']>
  fromEmail?: Maybe<Scalars['EmailAddress']>
  fromName?: Maybe<Scalars['String']>
  replyToEmail?: Maybe<Scalars['EmailAddress']>
  defaultSenderFromName?: Maybe<Scalars['String']>
  defaultSenderReplyToEmail?: Maybe<Scalars['EmailAddress']>
  bannerImageId?: Maybe<Scalars['Int']>
  bannerImage?: Maybe<Image>
  bodyFont: Scalars['String']
  footerText: Scalars['String']
  footerLinks: Array<EmailFooterLink>
  logoImageId?: Maybe<Scalars['Int']>
  logoImage?: Maybe<Image>
  primaryColor: Scalars['Color']
  hasCustomBranding: Scalars['Boolean']
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type EmailBrandingInput = {
  bannerImageId?: InputMaybe<Scalars['Int']>
  bodyFont?: InputMaybe<Scalars['String']>
  footerText?: InputMaybe<Scalars['String']>
  footerLinks?: InputMaybe<Array<EmailFooterLinkInput>>
  logoImageId?: InputMaybe<Scalars['Int']>
  primaryColor?: InputMaybe<Scalars['Color']>
}

export type EmailSettingsInput = {
  autoSendInvitations?: InputMaybe<Scalars['Boolean']>
  fromEmail?: InputMaybe<Scalars['EmailAddress']>
  defaultSenderFromName?: InputMaybe<Scalars['String']>
  defaultSenderReplyToEmail?: InputMaybe<Scalars['EmailAddress']>
}

export enum EmailTemplateId {
  EmployeeDigest = 'employee_digest',
  ManagerDigest = 'manager_digest',
  InvitationView = 'invitation_view',
  InvitationViewSign = 'invitation_view_sign',
  ReminderView = 'reminder_view',
  ReminderSign = 'reminder_sign',
}

export type EmailTemplate = {
  __typename?: 'EmailTemplate'
  id: EmailTemplateId
  subjectContent: Scalars['TextContent']
  ccManagers: Scalars['Boolean']
  buttonContent: Scalars['TextContent']
  messageContent: Scalars['EmailContent']
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type EmailTemplateInput = {
  subjectContent: Scalars['TextContent']
  ccManagers?: InputMaybe<Scalars['Boolean']>
  buttonContent: Scalars['TextContent']
  messageContent: Scalars['EmailContent']
}

export enum FeatureFlagId {
  CustomHtml = 'customHtml',
  AllowCsvSync = 'allowCsvSync',
  AllowMergeDev = 'allowMergeDev',
  AllowPhoneAuth = 'allowPhoneAuth',
  ImportHandbooks = 'importHandbooks',
  AllowKioskMode = 'allowKioskMode',
  AllowManagers = 'allowManagers',
  AllowMultipleSamlSsos = 'allowMultipleSamlSsos',
  HideAdvancedReporting = 'hideAdvancedReporting',
  HideOverallGrade = 'hideOverallGrade',
  NoLaunchCountdown = 'noLaunchCountdown',
  ShowFriendsPage = 'showFriendsPage',
  HideLawChanges = 'hideLawChanges',
  ShowPolicyLibrary = 'showPolicyLibrary',
  AllowPolicyWorkflow = 'allowPolicyWorkflow',
}

export type Font = {
  __typename?: 'Font'
  id: Scalars['Int']
  filename: Scalars['String']
  archived: Scalars['Boolean']
  createdAt: Scalars['DateTime']
}

export type Group = {
  __typename?: 'Group'
  id: Scalars['Int']
  name: Scalars['String']
  archived: Scalars['Boolean']
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
  peopleCount: Scalars['Int']
}

export type GroupInput = {
  name: Scalars['String']
}

export type HrisPeopleFilter = {
  expression?: InputMaybe<HrisPeopleRootExpressionInput>
}

export type HrisPerson = {
  __typename?: 'HrisPerson'
  email?: Maybe<Scalars['EmailAddress']>
  externalId?: Maybe<Scalars['String']>
  employeeId?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['PhoneNumber']>
  fullName: Scalars['String']
  lastHiredOn?: Maybe<Scalars['Date']>
  groupNames?: Maybe<Array<Scalars['String']>>
  metadata?: Maybe<Scalars['JSON']>
  sortKey: Scalars['String']
}

export type HrisPeopleStatistics = {
  __typename?: 'HrisPeopleStatistics'
  totalCount: Scalars['Int']
}

export type Invoice = {
  __typename?: 'Invoice'
  id: Scalars['Int']
  termMonths?: Maybe<Scalars['Int']>
  credits?: Maybe<Scalars['Int']>
  creditPrice?: Maybe<Scalars['Float']>
  totalPrice?: Maybe<Scalars['Float']>
  dueDate?: Maybe<Scalars['Date']>
  paidAt?: Maybe<Scalars['DateTime']>
  capturedAt?: Maybe<Scalars['DateTime']>
  stripeChargeId?: Maybe<Scalars['String']>
  checkId?: Maybe<Scalars['String']>
  lastErrorAt?: Maybe<Scalars['DateTime']>
  lastError?: Maybe<Scalars['String']>
  createdBy?: Maybe<Scalars['Int']>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type KioskModeSettings = {
  __typename?: 'KioskModeSettings'
  isEnabled: Scalars['Boolean']
  inactivitySeconds: Scalars['Int']
  timeoutSeconds: Scalars['Int']
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type KioskModeSettingsInput = {
  isEnabled?: InputMaybe<Scalars['Boolean']>
  inactivitySeconds?: InputMaybe<Scalars['Int']>
  timeoutSeconds?: InputMaybe<Scalars['Int']>
}

export enum ManagerDigestDate {
  Invitation = 'invitation',
  Due = 'due',
}

export type ManagerDigestSettings = {
  __typename?: 'ManagerDigestSettings'
  isEnabled: Scalars['Boolean']
  isDirectReportsOnly: Scalars['Boolean']
  daysAfter: Scalars['Int']
  date: ManagerDigestDate
  subjectContent: Scalars['TextContent']
  buttonContent: Scalars['TextContent']
  messageContent: Scalars['EmailContent']
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type ManagerDigestSettingsInput = {
  isEnabled: Scalars['Boolean']
  isDirectReportsOnly: Scalars['Boolean']
  daysAfter: Scalars['Int']
  date: ManagerDigestDate
  subjectContent?: InputMaybe<Scalars['TextContent']>
  buttonContent?: InputMaybe<Scalars['TextContent']>
  messageContent?: InputMaybe<Scalars['EmailContent']>
}

export type ManagerSettings = {
  __typename?: 'ManagerSettings'
  allowNotificationsToReports: Scalars['Boolean']
  allowNotificationsToManagers: Scalars['Boolean']
  organizationId: Scalars['Int']
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type ManagerSettingsInput = {
  allowNotificationsToReports: Scalars['Boolean']
  allowNotificationsToManagers: Scalars['Boolean']
}

export type ManagerStatistics = {
  __typename?: 'ManagerStatistics'
  compliantCount: Scalars['Int']
  nonCompliantCount: Scalars['Int']
  notifyCount: Scalars['Int']
  totalCount: Scalars['Int']
}

export type ManagerPerson = {
  __typename?: 'ManagerPerson'
  personId: Scalars['Int']
  fullName: Scalars['String']
  isManager: Scalars['Boolean']
  isSignatureRequired: Scalars['Boolean']
  employeeStatistics: ManagerStatistics
  handbooks: Array<ManagerPersonHandbook>
  managers?: Maybe<Array<ManagerPersonManager>>
  managerStatistics: ManagerStatistics
}

export type ManagerPersonHandbook = {
  __typename?: 'ManagerPersonHandbook'
  dueDate?: Maybe<Scalars['DateTime']>
  handbookId: Scalars['Int']
  handbookName: Scalars['String']
  hasValidSignature: Scalars['Boolean']
  isSignatureRequired: Scalars['Boolean']
  lastSignedAt?: Maybe<Scalars['DateTime']>
}

export type ManagerPersonManager = {
  __typename?: 'ManagerPersonManager'
  id: Scalars['Int']
  fullName: Scalars['String']
}

export type ManagerPersonReport = {
  __typename?: 'ManagerPersonReport'
  personId: Scalars['Int']
  fullName: Scalars['String']
  isManager: Scalars['Boolean']
  isEmployeeSignatureRequired: Scalars['Boolean']
  isSignatureRequired: Scalars['Boolean']
  canSendEmployeeDigest: Scalars['Boolean']
  canSendManagerDigest: Scalars['Boolean']
  lastEmployeeDigestQueuedAt?: Maybe<Scalars['DateTime']>
  lastManagerDigestQueuedAt?: Maybe<Scalars['DateTime']>
  employeeStatistics?: Maybe<ManagerStatistics>
  handbooks: Array<ManagerPersonHandbook>
}

export type MetadataField = {
  __typename?: 'MetadataField'
  key: Scalars['String']
  label: Scalars['String']
}

export type Notification = {
  __typename?: 'Notification'
  subjectContent: Scalars['TextContent']
  buttonContent: Scalars['TextContent']
  messageContent: Scalars['EmailContent']
}

export type NotificationInput = {
  subjectContent: Scalars['TextContent']
  buttonContent: Scalars['TextContent']
  messageContent: Scalars['EmailContent']
}

export type OrganizationInput = {
  name?: InputMaybe<Scalars['String']>
  isHandbookPositionEnabled?: InputMaybe<Scalars['Boolean']>
  logoImageId?: InputMaybe<Scalars['Int']>
}

export type OrganizationAdminInput = {
  isFullServe?: InputMaybe<Scalars['Boolean']>
  isCrmDisabled?: InputMaybe<Scalars['Boolean']>
  isIntercomDisabled?: InputMaybe<Scalars['Boolean']>
  isAuthDisabled?: InputMaybe<Scalars['Boolean']>
  showPrivacyPolicy?: InputMaybe<Scalars['Boolean']>
}

export type OrganizationRoleQuery = {
  any?: InputMaybe<Scalars['Boolean']>
  ids?: InputMaybe<Array<AdminRoleId>>
}

export enum OrganizationTaskId {
  Authentication = 'authentication',
  EmailDeliverability = 'emailDeliverability',
  CustomDomain = 'customDomain',
  EmailBranding = 'emailBranding',
  AudienceDashboard = 'audienceDashboard',
  KioskMode = 'kioskMode',
  Sync = 'sync',
  Invitations = 'invitations',
  Reminders = 'reminders',
}

export type OrganizationTask = {
  __typename?: 'OrganizationTask'
  id: OrganizationTaskId
  completedAt?: Maybe<Scalars['DateTime']>
  completedBy?: Maybe<Scalars['Int']>
}

export enum PeopleColumnId {
  ComplianceStatus = 'complianceStatus',
  Email = 'email',
  EmployeeId = 'employeeId',
  Phone = 'phone',
  FullName = 'fullName',
  LastHiredOn = 'lastHiredOn',
  Level = 'level',
  Manager = 'manager',
  LastNotifiedAt = 'lastNotifiedAt',
  LastViewedAt = 'lastViewedAt',
  LastSignedAt = 'lastSignedAt',
  LastManagerDigestQueuedAt = 'lastManagerDigestQueuedAt',
  AllReports = 'allReports',
  DirectReports = 'directReports',
  Groups = 'groups',
  Metadata = 'metadata',
}

export type PeopleColumnKey = {
  field: PeopleColumnId
  path?: InputMaybe<Scalars['String']>
}

export type PeopleFilter = {
  canNotify?: InputMaybe<Scalars['Boolean']>
  expression?: InputMaybe<PeopleRootExpressionInput>
  expressions?: InputMaybe<PeopleRootExpressionsInput>
  handbookAudience?: InputMaybe<HandbookAudienceInput>
  isAudienceMember?: InputMaybe<Scalars['Boolean']>
  isEmployeeSignatureRequired?: InputMaybe<Scalars['Boolean']>
  isManager?: InputMaybe<Scalars['Boolean']>
  isSignatureRequired?: InputMaybe<Scalars['Boolean']>
  member?: InputMaybe<Scalars['Boolean']>
  search?: InputMaybe<Scalars['String']>
}

export enum PeopleSource {
  HrisPeople = 'hrisPeople',
  People = 'people',
}

export type PeopleStatistics = {
  __typename?: 'PeopleStatistics'
  canNotifyCount: Scalars['Int']
  canNotifyNonCompliantCount: Scalars['Int']
  canNotifyNonCompliantManagersCount: Scalars['Int']
  nonCompliantCount: Scalars['Int']
  nonCompliantManagersCount: Scalars['Int']
  totalCount: Scalars['Int']
  totalManagersCount: Scalars['Int']
}

export enum PeopleVariantType {
  Group = 'group',
  Person = 'person',
}

export type PeopleVariantInput = {
  type: PeopleVariantType
  id: Scalars['Int']
}

export enum PersonSortField {
  Archived = 'archived',
  Email = 'email',
  EmployeeId = 'employeeId',
  ExternalId = 'externalId',
  FullName = 'fullName',
  IsManager = 'isManager',
  IsSignatureRequired = 'isSignatureRequired',
  LastEmployeeDigestQueuedAt = 'lastEmployeeDigestQueuedAt',
  LastHiredOn = 'lastHiredOn',
  LastManagerDigestQueuedAt = 'lastManagerDigestQueuedAt',
  LastNotifiedAt = 'lastNotifiedAt',
  LastViewedAt = 'lastViewedAt',
  LastSignedAt = 'lastSignedAt',
  NonCompliantDirectReportsPercentage = 'nonCompliantDirectReportsPercentage',
  NonCompliantReportsPercentage = 'nonCompliantReportsPercentage',
  Phone = 'phone',
}

export type PeopleSortOrder = {
  field: PersonSortField
  direction: SortDirection
}

export type Person = {
  __typename?: 'Person'
  id: Scalars['Int']
  email?: Maybe<Scalars['EmailAddress']>
  externalId?: Maybe<Scalars['String']>
  employeeId?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['PhoneNumber']>
  fullName: Scalars['String']
  archived: Scalars['Boolean']
  archivedAt?: Maybe<Scalars['DateTime']>
  member: Scalars['Boolean']
  adminRoleId?: Maybe<AdminRoleId>
  isSuperUser: Scalars['Boolean']
  lastHiredOn?: Maybe<Scalars['Date']>
  avatarUrl?: Maybe<Scalars['String']>
  lastActivatedAt: Scalars['DateTime']
  autoSendReminders: Scalars['Boolean']
  isSignatureRequired: Scalars['Boolean']
  isEmployeeSignatureRequired: Scalars['Boolean']
  totalDirectReportsCount: Scalars['Int']
  nonCompliantDirectReportsCount: Scalars['Int']
  nonCompliantDirectReportsPercentage: Scalars['Int']
  totalReportsCount: Scalars['Int']
  nonCompliantReportsCount: Scalars['Int']
  nonCompliantReportsPercentage: Scalars['Int']
  lastEmployeeDigestQueuedAt?: Maybe<Scalars['DateTime']>
  lastManagerDigestQueuedAt?: Maybe<Scalars['DateTime']>
  lastNotifiedAt?: Maybe<Scalars['DateTime']>
  lastViewedAt?: Maybe<Scalars['DateTime']>
  lastSignedAt?: Maybe<Scalars['DateTime']>
  isManager: Scalars['Boolean']
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  metadata?: Maybe<Scalars['JSON']>
  groupIds: Array<Scalars['Int']>
  handbookCollaborators: Array<HandbookCollaborator>
  handbookRecipients: Array<HandbookRecipient>
  handbookIds: Array<Scalars['Int']>
  events: Array<Event>
  managerIds: Array<Scalars['Int']>
  managers: Array<PersonManager>
  notifications: Array<HandbookNotification>
  savedSegmentIds: Array<Scalars['Int']>
  sessions: Array<HandbookSession>
  signatures: Array<HandbookSignature>
  teammates: Array<Teammate>
  userIds: Array<Scalars['Int']>
  sortKey?: Maybe<Scalars['String']>
}

export type PersonInput = {
  id: Scalars['Int']
  email?: InputMaybe<Scalars['EmailAddress']>
  employeeId?: InputMaybe<Scalars['String']>
  fullName?: InputMaybe<Scalars['String']>
  member?: InputMaybe<Scalars['Boolean']>
  groupIds?: InputMaybe<Array<Scalars['Int']>>
  groupNames?: InputMaybe<Array<Scalars['String']>>
  autoSendReminders?: InputMaybe<Scalars['Boolean']>
}

export type PersonManager = {
  __typename?: 'PersonManager'
  id: Scalars['Int']
  email?: Maybe<Scalars['EmailAddress']>
  employeeId?: Maybe<Scalars['String']>
  fullName: Scalars['String']
}

export type NewPersonInput = {
  email: Scalars['EmailAddress']
  employeeId?: InputMaybe<Scalars['String']>
  fullName: Scalars['String']
  member?: InputMaybe<Scalars['Boolean']>
  groupIds?: InputMaybe<Array<Scalars['Int']>>
  groupNames?: InputMaybe<Array<Scalars['String']>>
  autoSendReminders?: InputMaybe<Scalars['Boolean']>
}

export type NewOrgCollaboratorInput = {
  email: Scalars['EmailAddress']
  fullName: Scalars['String']
  member?: InputMaybe<Scalars['Boolean']>
}

export type OrgCollaboratorNotificationInput = {
  messageContent?: InputMaybe<Scalars['EmailContent']>
}

export type PhoneAuthSettings = {
  __typename?: 'PhoneAuthSettings'
  isEnabled: Scalars['Boolean']
  helpContent?: Maybe<Scalars['HelpContent']>
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt?: Maybe<Scalars['DateTime']>
}

export type PhoneAuthSettingsInput = {
  isEnabled?: InputMaybe<Scalars['Boolean']>
  helpContent?: InputMaybe<Scalars['HelpContent']>
}

export type SecuritySettings = {
  __typename?: 'SecuritySettings'
  emailAuth: EmailAuthSettings
  kioskMode: KioskModeSettings
  phoneAuth: PhoneAuthSettings
  singleSignOns: Array<SingleSignOn>
}

export type SftpExportIntegrationInput = {
  host: Scalars['String']
  port: Scalars['Int']
  username: Scalars['String']
  password: Scalars['String']
  folderPath: Scalars['String']
}

export type SftpExportIntegration = {
  __typename?: 'SftpExportIntegration'
  host: Scalars['String']
  port: Scalars['Int']
  username: Scalars['String']
  folderPath: Scalars['String']
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
}

export enum SingleSignOnAuthKey {
  Email = 'email',
  EmployeeId = 'employeeId',
}

export enum SingleSignOnProviderId {
  Azure = 'azure',
  Google = 'google',
  Jwt = 'jwt',
  Okta = 'okta',
  Rippling = 'rippling',
  Saml = 'saml',
  SamlSecondary = 'saml_secondary',
}

export type SingleSignOn = {
  __typename?: 'SingleSignOn'
  providerId: SingleSignOnProviderId
  issuer?: Maybe<Scalars['HttpUrl']>
  loginUrl?: Maybe<Scalars['HttpsUrl']>
  authKey?: Maybe<SingleSignOnAuthKey>
  buttonText?: Maybe<Scalars['String']>
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  certificates: Array<SingleSignOnCertificate>
}

export type SingleSignOnCertificate = {
  __typename?: 'SingleSignOnCertificate'
  digest: Scalars['String']
  createdAt: Scalars['DateTime']
  archivedAt?: Maybe<Scalars['DateTime']>
}

export type NewSingleSignOnInput = {
  certificate: Scalars['String']
  loginUrl: Scalars['HttpsUrl']
  issuer?: InputMaybe<Scalars['HttpUrl']>
  authKey?: InputMaybe<SingleSignOnAuthKey>
  buttonText?: InputMaybe<Scalars['String']>
}

export type SingleSignOnInput = {
  loginUrl?: InputMaybe<Scalars['HttpsUrl']>
  issuer?: InputMaybe<Scalars['HttpUrl']>
  authKey?: InputMaybe<SingleSignOnAuthKey>
  buttonText?: InputMaybe<Scalars['String']>
}

export type SubscriptionInput = {
  termStartCount?: InputMaybe<Scalars['Int']>
  termMonths?: InputMaybe<Scalars['Int']>
  termMonthlyGrowthRate?: InputMaybe<Scalars['Float']>
}

export type Team = {
  __typename?: 'Team'
  id: Scalars['Int']
  createdAt: Scalars['DateTime']
  updatedAt: Scalars['DateTime']
  handbookCollaborators: Array<HandbookCollaborator>
  teammates: Array<Teammate>
  permissionIds: Array<Scalars['String']>
  /** @deprecated Field no longer supported */
  ckboxWorkspaceId?: Maybe<Scalars['String']>
}

export type Teammate = {
  __typename?: 'Teammate'
  id: Scalars['Int']
  teamId: Scalars['Int']
  personId: Scalars['Int']
  person: WhodunnitPerson
  canReport: Scalars['Boolean']
  canSuperBrand: Scalars['Boolean']
  roleId?: Maybe<TeamRoleId>
}

export type TeammateInput = {
  canReport?: InputMaybe<Scalars['Boolean']>
  canSuperBrand?: InputMaybe<Scalars['Boolean']>
  roleId?: InputMaybe<TeamRoleId>
}

export type CreateMergeDevLinkTokenResponse = {
  __typename?: 'CreateMergeDevLinkTokenResponse'
  linkToken: Scalars['String']
  integrationName: Scalars['String']
}

export enum HandbooksSort {
  RelevanceDesc = 'relevanceDesc',
  LastModifiedAtDesc = 'lastModifiedAtDesc',
  LastPublishedAtDesc = 'lastPublishedAtDesc',
  PublishedAtAsc = 'publishedAtAsc',
  PublishedAtDesc = 'publishedAtDesc',
  ViewersCountDesc = 'viewersCountDesc',
  NameAsc = 'nameAsc',
  NameDesc = 'nameDesc',
}

export enum HandbooksView {
  Cozy = 'cozy',
  Compact = 'compact',
}

export type Preferences = {
  __typename?: 'Preferences'
  defaultLanguageCode?: Maybe<Scalars['String']>
  documentationHandbooksSort?: Maybe<HandbooksSort>
  handbooksSort?: Maybe<HandbooksSort>
  handbooksView?: Maybe<HandbooksView>
  hideProTips: Array<Scalars['String']>
}

export type PreferencesInput = {
  defaultLanguageCode?: InputMaybe<Scalars['String']>
  documentationHandbooksSort?: InputMaybe<HandbooksSort>
  handbooksSort?: InputMaybe<HandbooksSort>
  handbooksView?: InputMaybe<HandbooksView>
}

export enum SavedReportType {
  Employees = 'employees',
  Managers = 'managers',
  Document = 'document',
}

export type SavedReport = {
  id: Scalars['Int']
  type: SavedReportType
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  expression: RootExpression
  showArchived: Scalars['Boolean']
  columnKeys: Array<SavedReportColumnKey>
  order: SortOrder
  createdAt: Scalars['DateTime']
  createdBy?: Maybe<WhodunnitPerson>
  updatedAt: Scalars['DateTime']
  updatedBy?: Maybe<WhodunnitPerson>
  archivedAt?: Maybe<Scalars['DateTime']>
}

export type SavedReportColumnKey = {
  __typename?: 'SavedReportColumnKey'
  field: Scalars['String']
  path?: Maybe<Scalars['String']>
}

export type DocumentSavedReport = SavedReport & {
  __typename?: 'DocumentSavedReport'
  id: Scalars['Int']
  handbookId: Scalars['Int']
  type: SavedReportType
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  expression: RootExpression
  showArchived: Scalars['Boolean']
  columnKeys: Array<SavedReportColumnKey>
  order: SortOrder
  createdAt: Scalars['DateTime']
  createdBy?: Maybe<WhodunnitPerson>
  updatedAt: Scalars['DateTime']
  updatedBy?: Maybe<WhodunnitPerson>
  archivedAt?: Maybe<Scalars['DateTime']>
}

export type CreateDocumentSavedReportInput = {
  handbookId: Scalars['Int']
  name: Scalars['String']
  description?: InputMaybe<Scalars['String']>
  expression: HandbookRecipientsRootExpressionInput
  showArchived: Scalars['Boolean']
  columnKeys: Array<HandbookRecipientsColumnKey>
  order: HandbookRecipientsSortOrder
}

export type UpdateDocumentSavedReportInput = {
  handbookId?: InputMaybe<Scalars['Int']>
  name?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  expression?: InputMaybe<HandbookRecipientsRootExpressionInput>
  showArchived?: InputMaybe<Scalars['Boolean']>
  columnKeys?: InputMaybe<Array<HandbookRecipientsColumnKey>>
  order?: InputMaybe<HandbookRecipientsSortOrder>
}

export enum PeopleSavedReportType {
  Employees = 'employees',
  Managers = 'managers',
}

export type PeopleSavedReport = SavedReport & {
  __typename?: 'PeopleSavedReport'
  id: Scalars['Int']
  type: SavedReportType
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  expression: RootExpression
  showArchived: Scalars['Boolean']
  columnKeys: Array<SavedReportColumnKey>
  order: SortOrder
  createdAt: Scalars['DateTime']
  createdBy?: Maybe<WhodunnitPerson>
  updatedAt: Scalars['DateTime']
  updatedBy?: Maybe<WhodunnitPerson>
  archivedAt?: Maybe<Scalars['DateTime']>
}

export type CreatePeopleSavedReportInput = {
  type: PeopleSavedReportType
  name: Scalars['String']
  description?: InputMaybe<Scalars['String']>
  expression: PeopleRootExpressionInput
  showArchived: Scalars['Boolean']
  columnKeys: Array<PeopleColumnKey>
  order: PeopleSortOrder
}

export type UpdatePeopleSavedReportInput = {
  name?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  expression?: InputMaybe<PeopleRootExpressionInput>
  showArchived?: InputMaybe<Scalars['Boolean']>
  columnKeys?: InputMaybe<Array<PeopleColumnKey>>
  order?: InputMaybe<PeopleSortOrder>
}

export type SavedSegment = {
  __typename?: 'SavedSegment'
  id: Scalars['Int']
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  expression: RootExpression
  peopleCount: Scalars['Int']
  createdAt: Scalars['DateTime']
  createdBy?: Maybe<WhodunnitPerson>
  updatedAt: Scalars['DateTime']
  updatedBy?: Maybe<WhodunnitPerson>
  archivedAt?: Maybe<Scalars['DateTime']>
}

export type CreateSavedSegmentInput = {
  name: Scalars['String']
  description?: InputMaybe<Scalars['String']>
  expression: PeopleRootExpressionInput
}

export type UpdateSavedSegmentInput = {
  name?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  expression?: InputMaybe<PeopleRootExpressionInput>
}

export type TextSearchResult = {
  __typename?: 'TextSearchResult'
  before: Scalars['String']
  match: Scalars['String']
  after: Scalars['String']
}

export enum HandbookNodeType {
  DocumentRef = 'documentRef',
  HandbookSection = 'handbookSection',
}

export type HandbookNodeSearchResult = {
  __typename?: 'HandbookNodeSearchResult'
  type: HandbookNodeType
  id: Scalars['Int']
  hash: Scalars['String']
  title: Scalars['String']
  languageCode?: Maybe<Scalars['String']>
  texts?: Maybe<Array<TextSearchResult>>
}

export type HandbookSearchResult = {
  __typename?: 'HandbookSearchResult'
  handbookId: Scalars['Int']
  node?: Maybe<HandbookNodeSearchResult>
}

export type UserAuth = {
  __typename?: 'UserAuth'
  email?: Maybe<Scalars['EmailAddress']>
  employeeId?: Maybe<Scalars['String']>
  fullName?: Maybe<Scalars['String']>
  organizationId?: Maybe<Scalars['Int']>
}

export type Mutation = {
  __typename?: 'Mutation'
  sendEmailAuth: SendAuthResponse
  sendPhoneAuth: SendAuthResponse
  enterAuthCode: Scalars['Boolean']
  setPreferences: Preferences
  hideProTip: Array<Scalars['String']>
  readHandbookSection?: Maybe<Array<HandbookSectionRead>>
  signHandbookSession: HandbookSignature
  sendRemindersToMyManagers: Scalars['Int']
  sendRemindersToMyReports: Scalars['Int']
  createApiKey: NewApiKey
  archiveApiKey: ApiKey
  unarchiveApiKey: ApiKey
  deleteApiKey: Scalars['Boolean']
  archiveHandbookFont: Scalars['Boolean']
  archiveHandbookAsset: Scalars['Boolean']
  archiveAsset: Scalars['Boolean']
  updateBilling: Billing
  updateSubscription: Billing
  setPayByCheck: Billing
  setPayByCreditCard: Billing
  cancelSubscription: Billing
  setCustomDomain: CustomDomain
  addCustomDomainCertificate: CustomDomain
  renewCustomDomainCertificate: CustomDomain
  setCustomDomainCertificate: CustomDomain
  deleteCustomDomainCertificate: CustomDomain
  removeCustomDomain: Scalars['Boolean']
  updateDocumentSettings: DocumentSettings
  createDocument: Document
  createDocuments: Array<Document>
  cloneDocuments: Array<Document>
  updateDocuments: Array<Document>
  moveDocuments: Array<Document>
  archiveDocuments: Array<Document>
  unarchiveDocuments: Array<Document>
  deleteDocuments: Scalars['Boolean']
  createDocumentDraftVersion: Document
  updateDocumentDraftVersion: Document
  publishDocuments: Array<Document>
  setDocumentReviewers: Array<DocumentReviewer>
  addDocumentReview: DocumentReview
  addEmailDomain: EmailDomain
  updateEmailDomain: EmailDomain
  deleteEmailDomain: Scalars['Boolean']
  sendTestEmail: Scalars['String']
  setManagerDigestSettings: ManagerDigestSettings
  updateEmailBranding: EmailSettings
  updateEmailSettings: EmailSettings
  updateEmailTemplate: EmailTemplate
  createFolder: Folder
  updateFolder: Folder
  deleteFolder: Scalars['Boolean']
  initializeHandbookContent: Handbook
  patchHandbook: Handbook
  patchHandbookCss: Handbook
  patchHandbookCustomHtml: Handbook
  setHandbookCustomDomain: Handbook
  addDocumentRefsToHandbook: Array<Scalars['String']>
  addHandbookSection: HandbookSection
  cloneHandbookSection: InsertHandbookSectionsResult
  patchHandbookSection: HandbookSection
  moveHandbookNode: Scalars['Boolean']
  moveHandbookSection: Scalars['Boolean']
  removeHandbookSection: Scalars['Boolean']
  updateDocumentRefNode: Scalars['Boolean']
  removeHandbookNode: Scalars['Boolean']
  convertHandbookSectionsToPolicies: Scalars['BodyContent']
  revertHandbookDocumentRefs: RevertHandbookDocumentRefsResult
  unarchiveHandbookSection: Array<Scalars['Int']>
  revertHandbookSection: HandbookSection
  addHandbookSectionEntry: AddHandbookSectionEntryResult
  patchHandbookSectionEntry: AddHandbookSectionEntryResult
  moveHandbookSectionEntry: MoveHandbookSectionEntryResult
  removeHandbookSectionEntry: RemoveHandbookSectionEntryResult
  createHandbookPreviewLink?: Maybe<HandbookPreviewLink>
  resetHandbookPreviewLinkTokens: Array<HandbookPreviewLink>
  deleteHandbookPreviewLink?: Maybe<Scalars['Boolean']>
  deleteHandbookPreviewLinks?: Maybe<Scalars['Boolean']>
  setSignatureDueDate: HandbookSignatureDueDateOverride
  inviteHandbookRecipient: HandbookNotification
  remindHandbookRecipient: HandbookNotification
  recordHandbookSignature: HandbookSignature
  createHandbookReminder?: Maybe<HandbookReminder>
  updateHandbookReminder?: Maybe<HandbookReminder>
  setHandbookReminderEnabled?: Maybe<HandbookReminder>
  deleteHandbookReminder: Scalars['Boolean']
  createHandbook: Handbook
  cloneHandbook: Handbook
  deleteHandbook?: Maybe<Scalars['Boolean']>
  setPublishHandbookNotification: PublishHandbookNotification
  publishHandbook: Handbook
  updateHandbookVersion: HandbookVersionSummary
  setHandbookTaskComplete: HandbookTask
  setHandbookPositions: Array<Handbook>
  initializeHandbookAudience: Handbook
  patchHandbookAudience: Handbook
  resetHandbookPublicToken: Handbook
  createHandbookSignatureRound: HandbookSignatureRound
  updateHandbookSignatureRound: HandbookSignatureRound
  deleteHandbookSignatureRound: Scalars['Boolean']
  triggerHrisSync: Scalars['Boolean']
  addBambooHRIntegration: BambooHrIntegration
  addBambooHRIntegrationViaKey: BambooHrIntegration
  addSftpIntegration: NewSftpIntegration
  updateBambooHRIntegration: BambooHrIntegration
  updateRipplingIntegration: RipplingIntegration
  updateIntegration: Integration
  deleteIntegration: Scalars['Boolean']
  setSftpExportIntegration: SftpExportIntegration
  deleteSftpExportIntegration: Scalars['Boolean']
  createMergeDevLinkToken: CreateMergeDevLinkTokenResponse
  createMergeDevIntegration: MergeDevIntegration
  updateOrganization: Organization
  setOrganizationTaskComplete: OrganizationTask
  setAudienceDashboardSettings: AudienceDashboardSettings
  setManagerSettings: ManagerSettings
  createGroup: Group
  updateGroup: Group
  archiveGroup: Scalars['Boolean']
  archivePerson: Person
  unarchivePerson: Person
  upsertPeople: Array<Person>
  inviteOrgCollaborator: Person
  addAdmin: Person
  addNewAdmin: Person
  updateAdmin: Person
  removeAdmin: Person
  addTeammate: Teammate
  addNewTeammate: Teammate
  removeTeammate: Scalars['Boolean']
  updateTeammate: Teammate
  addHandbookCollaborator: HandbookCollaborator
  addNewHandbookCollaborator: HandbookCollaborator
  removeHandbookCollaborator: Scalars['Boolean']
  updateHandbookCollaborator: HandbookCollaborator
  exportHandbookRecipientsToCSV: Scalars['String']
  exportPeopleToCSV: Scalars['String']
  sendHandbookReminders: Scalars['Int']
  sendEmployeeDigest: Scalars['Int']
  sendManagerDigest: Scalars['Int']
  createDocumentSavedReport: SavedReport
  updateDocumentSavedReport: SavedReport
  createPeopleSavedReport: SavedReport
  updatePeopleSavedReport: SavedReport
  archiveSavedReport: SavedReport
  unarchiveSavedReport: SavedReport
  createSavedSegment: SavedSegment
  updateSavedSegment: SavedSegment
  archiveSavedSegment: SavedSegment
  unarchiveSavedSegment: SavedSegment
  addSingleSignOn: SingleSignOn
  patchSingleSignOn: SingleSignOn
  removeSingleSignOn: SecuritySettings
  addSingleSignOnCertificate: SecuritySettings
  archiveSingleSignOnCertificate: SecuritySettings
  unarchiveSingleSignOnCertificate: SecuritySettings
  deleteSingleSignOnCertificate: SecuritySettings
  setEmailAuthSettings: SecuritySettings
  setKioskModeSettings: SecuritySettings
  setPhoneAuthSettings: SecuritySettings
  sendTestPhoneMessage: Scalars['String']
  addRipplingIntegration: RipplingIntegration
  updateBillingAdmin: Billing
  chargeInvoice: Invoice
  linkChargebeeSubscription: ChargebeeSubscription
  unlinkChargebeeSubscription?: Maybe<Scalars['Boolean']>
  setFeatureFlag: Array<Scalars['String']>
  clearHandbookSectionDirty: HandbookSection
  deleteHandbookSignature: Scalars['Boolean']
  mergeHandbook: Handbook
  moveHandbookSectionToHandbook: Scalars['Boolean']
  archiveHandbookSignatureRound: HandbookSignatureRound
  unarchiveHandbookSignatureRound: HandbookSignatureRound
  setSubdomain: Organization
  updateOrganizationAdmin: Organization
  deletePerson: Scalars['Boolean']
}

export type MutationSendEmailAuthArgs = {
  email: Scalars['EmailAddress']
}

export type MutationSendPhoneAuthArgs = {
  phone: Scalars['PhoneNumber']
}

export type MutationEnterAuthCodeArgs = {
  userId: Scalars['Int']
  authCode: Scalars['String']
}

export type MutationSetPreferencesArgs = {
  input: PreferencesInput
}

export type MutationHideProTipArgs = {
  tipId: Scalars['String']
}

export type MutationReadHandbookSectionArgs = {
  sectionId: Scalars['Int']
  versions?: InputMaybe<Array<Scalars['Int']>>
}

export type MutationSignHandbookSessionArgs = {
  handbookId: Scalars['Int']
  handbookSessionId: Scalars['Int']
  input: SignHandbookSessionInput
  signatureRoundId: Scalars['Int']
}

export type MutationSendRemindersToMyManagersArgs = {
  managerId?: InputMaybe<Scalars['Int']>
  personIds?: InputMaybe<Array<Scalars['Int']>>
}

export type MutationSendRemindersToMyReportsArgs = {
  managerId?: InputMaybe<Scalars['Int']>
  personIds?: InputMaybe<Array<Scalars['Int']>>
}

export type MutationArchiveApiKeyArgs = {
  key: Scalars['String']
}

export type MutationUnarchiveApiKeyArgs = {
  key: Scalars['String']
}

export type MutationDeleteApiKeyArgs = {
  key: Scalars['String']
}

export type MutationArchiveHandbookFontArgs = {
  handbookId: Scalars['Int']
  fontId: Scalars['Int']
}

export type MutationArchiveHandbookAssetArgs = {
  handbookId: Scalars['Int']
  assetId: Scalars['Int']
}

export type MutationArchiveAssetArgs = {
  assetId: Scalars['Int']
}

export type MutationUpdateBillingArgs = {
  input: BillingInput
}

export type MutationUpdateSubscriptionArgs = {
  input: SubscriptionInput
}

export type MutationSetPayByCreditCardArgs = {
  token: Scalars['String']
}

export type MutationSetCustomDomainArgs = {
  domain: Scalars['Domain']
  certificate?: InputMaybe<CertificateInput>
}

export type MutationAddCustomDomainCertificateArgs = {
  certificate: CertificateInput
}

export type MutationSetCustomDomainCertificateArgs = {
  digest: Scalars['String']
}

export type MutationDeleteCustomDomainCertificateArgs = {
  digest: Scalars['String']
}

export type MutationUpdateDocumentSettingsArgs = {
  input: UpdateDocumentSettingsInput
}

export type MutationCreateDocumentArgs = {
  input: CreateDocumentInput
}

export type MutationCreateDocumentsArgs = {
  inputs: Array<CreateDocumentInput>
}

export type MutationCloneDocumentsArgs = {
  documentIds: Array<Scalars['Int']>
}

export type MutationUpdateDocumentsArgs = {
  updates: Array<UpdateDocumentArgs>
}

export type MutationMoveDocumentsArgs = {
  documentIds: Array<Scalars['Int']>
  input: MoveDocumentsInput
}

export type MutationArchiveDocumentsArgs = {
  documentIds: Array<Scalars['Int']>
}

export type MutationUnarchiveDocumentsArgs = {
  documentIds: Array<Scalars['Int']>
}

export type MutationDeleteDocumentsArgs = {
  documentIds: Array<Scalars['Int']>
}

export type MutationCreateDocumentDraftVersionArgs = {
  documentId: Scalars['Int']
  input?: InputMaybe<UpdateDocumentDraftVersionInput>
}

export type MutationUpdateDocumentDraftVersionArgs = {
  documentId: Scalars['Int']
  input: UpdateDocumentDraftVersionInput
}

export type MutationPublishDocumentsArgs = {
  documentIds: Array<Scalars['Int']>
}

export type MutationSetDocumentReviewersArgs = {
  documentId: Scalars['Int']
  personIds: Array<Scalars['Int']>
}

export type MutationAddDocumentReviewArgs = {
  documentId: Scalars['Int']
  isApproved: Scalars['Boolean']
}

export type MutationAddEmailDomainArgs = {
  input: EmailDomainInput
}

export type MutationUpdateEmailDomainArgs = {
  id: Scalars['Int']
  input: EmailDomainInput
}

export type MutationDeleteEmailDomainArgs = {
  id: Scalars['Int']
}

export type MutationSendTestEmailArgs = {
  fromEmail?: InputMaybe<Scalars['EmailAddress']>
}

export type MutationSetManagerDigestSettingsArgs = {
  input: ManagerDigestSettingsInput
}

export type MutationUpdateEmailBrandingArgs = {
  input: EmailBrandingInput
}

export type MutationUpdateEmailSettingsArgs = {
  input: EmailSettingsInput
}

export type MutationUpdateEmailTemplateArgs = {
  id: EmailTemplateId
  input: EmailTemplateInput
}

export type MutationCreateFolderArgs = {
  input: CreateFolderInput
}

export type MutationUpdateFolderArgs = {
  folderId: Scalars['Int']
  input: UpdateFolderInput
}

export type MutationDeleteFolderArgs = {
  folderId: Scalars['Int']
}

export type MutationInitializeHandbookContentArgs = {
  id: Scalars['Int']
  seedType: HandbookSeedType
  branding?: InputMaybe<HandbookBrandingInput>
}

export type MutationPatchHandbookArgs = {
  handbookId: Scalars['Int']
  input: HandbookInput
}

export type MutationPatchHandbookCssArgs = {
  handbookId: Scalars['Int']
  input: HandbookCssInput
}

export type MutationPatchHandbookCustomHtmlArgs = {
  handbookId: Scalars['Int']
  customHtml: Scalars['String']
}

export type MutationSetHandbookCustomDomainArgs = {
  handbookId: Scalars['Int']
  customDomain?: InputMaybe<Scalars['Domain']>
}

export type MutationAddDocumentRefsToHandbookArgs = {
  documentIds: Array<Scalars['Int']>
  handbookId: Scalars['Int']
  index?: InputMaybe<Scalars['Int']>
}

export type MutationAddHandbookSectionArgs = {
  handbookId: Scalars['Int']
  input: NewHandbookSectionInput
  position?: InputMaybe<Scalars['Int']>
}

export type MutationCloneHandbookSectionArgs = {
  sectionId: Scalars['Int']
  children?: InputMaybe<Scalars['Boolean']>
}

export type MutationPatchHandbookSectionArgs = {
  sectionId: Scalars['Int']
  input: HandbookSectionInput
}

export type MutationMoveHandbookNodeArgs = {
  handbookId: Scalars['Int']
  fromIndex: Scalars['Int']
  toIndex: Scalars['Int']
}

export type MutationMoveHandbookSectionArgs = {
  sectionId: Scalars['Int']
  position: Scalars['Int']
}

export type MutationRemoveHandbookSectionArgs = {
  sectionId: Scalars['Int']
  children?: InputMaybe<Scalars['Boolean']>
}

export type MutationUpdateDocumentRefNodeArgs = {
  handbookId: Scalars['Int']
  nodeId: Scalars['String']
  attrs: DocumentRefNodeAttrsInput
}

export type MutationRemoveHandbookNodeArgs = {
  handbookId: Scalars['Int']
  nodeId: Scalars['String']
}

export type MutationConvertHandbookSectionsToPoliciesArgs = {
  sectionIds: Array<Scalars['Int']>
}

export type MutationRevertHandbookDocumentRefsArgs = {
  handbookId: Scalars['Int']
  documentIds: Array<Scalars['Int']>
}

export type MutationUnarchiveHandbookSectionArgs = {
  id: Scalars['Int']
}

export type MutationRevertHandbookSectionArgs = {
  id: Scalars['Int']
}

export type MutationAddHandbookSectionEntryArgs = {
  sectionId: Scalars['Int']
  input?: InputMaybe<HandbookSectionEntryInput>
}

export type MutationPatchHandbookSectionEntryArgs = {
  sectionId: Scalars['Int']
  entryUid: Scalars['String']
  input: HandbookSectionEntryInput
}

export type MutationMoveHandbookSectionEntryArgs = {
  sectionId: Scalars['Int']
  entryUid: Scalars['String']
  position: Scalars['Int']
}

export type MutationRemoveHandbookSectionEntryArgs = {
  sectionId: Scalars['Int']
  entryUid: Scalars['String']
}

export type MutationCreateHandbookPreviewLinkArgs = {
  handbookId: Scalars['Int']
  personId: Scalars['Int']
}

export type MutationResetHandbookPreviewLinkTokensArgs = {
  handbookId: Scalars['Int']
}

export type MutationDeleteHandbookPreviewLinkArgs = {
  handbookId: Scalars['Int']
  personId: Scalars['Int']
}

export type MutationDeleteHandbookPreviewLinksArgs = {
  handbookId: Scalars['Int']
}

export type MutationSetSignatureDueDateArgs = {
  handbookId: Scalars['Int']
  personId: Scalars['Int']
  roundId: Scalars['Int']
  note?: InputMaybe<Scalars['String']>
  dueDate: Scalars['Date']
}

export type MutationInviteHandbookRecipientArgs = {
  handbookId: Scalars['Int']
  personId: Scalars['Int']
}

export type MutationRemindHandbookRecipientArgs = {
  handbookId: Scalars['Int']
  personId: Scalars['Int']
}

export type MutationRecordHandbookSignatureArgs = {
  handbookId: Scalars['Int']
  input: RecordHandbookSignatureInput
}

export type MutationCreateHandbookReminderArgs = {
  input: HandbookReminderInput
}

export type MutationUpdateHandbookReminderArgs = {
  id: Scalars['Int']
  input: HandbookReminderInput
}

export type MutationSetHandbookReminderEnabledArgs = {
  id: Scalars['Int']
  isEnabled: Scalars['Boolean']
}

export type MutationDeleteHandbookReminderArgs = {
  id: Scalars['Int']
}

export type MutationCreateHandbookArgs = {
  name: Scalars['String']
}

export type MutationCloneHandbookArgs = {
  handbookId: Scalars['Int']
  name: Scalars['String']
}

export type MutationDeleteHandbookArgs = {
  id: Scalars['Int']
}

export type MutationSetPublishHandbookNotificationArgs = {
  handbookId: Scalars['Int']
  input: PublishHandbookNotificationInput
}

export type MutationPublishHandbookArgs = {
  handbookId: Scalars['Int']
  content?: InputMaybe<PublishHandbookContentInput>
  notifyViewers?: InputMaybe<HandbookViewersNotificationInput>
}

export type MutationUpdateHandbookVersionArgs = {
  handbookId: Scalars['Int']
  versionNumber: Scalars['Int']
  input: UpdateHandbookVersionInput
}

export type MutationSetHandbookTaskCompleteArgs = {
  handbookId: Scalars['Int']
  taskId: HandbookTaskId
  complete: Scalars['Boolean']
}

export type MutationSetHandbookPositionsArgs = {
  handbookIds: Array<Scalars['Int']>
}

export type MutationInitializeHandbookAudienceArgs = {
  id: Scalars['Int']
  hasAudience: Scalars['Boolean']
  hasSignatures: Scalars['Boolean']
}

export type MutationPatchHandbookAudienceArgs = {
  handbookId: Scalars['Int']
  audience: HandbookAudienceInput
  sendNotifications?: InputMaybe<Scalars['Boolean']>
}

export type MutationResetHandbookPublicTokenArgs = {
  handbookId: Scalars['Int']
}

export type MutationCreateHandbookSignatureRoundArgs = {
  handbookId: Scalars['Int']
  input: HandbookSignatureRoundInput
  notification?: InputMaybe<NotificationInput>
}

export type MutationUpdateHandbookSignatureRoundArgs = {
  id: Scalars['Int']
  input: HandbookSignatureRoundInput
}

export type MutationDeleteHandbookSignatureRoundArgs = {
  id: Scalars['Int']
}

export type MutationAddBambooHrIntegrationArgs = {
  email: Scalars['EmailAddress']
  hostname: Scalars['String']
  password: Scalars['String']
  subdomain: Scalars['String']
}

export type MutationAddBambooHrIntegrationViaKeyArgs = {
  hostname: Scalars['String']
  key: Scalars['String']
  subdomain: Scalars['String']
}

export type MutationUpdateBambooHrIntegrationArgs = {
  id: Scalars['Int']
  input: UpdateBambooHrIntegrationInput
}

export type MutationUpdateRipplingIntegrationArgs = {
  id: Scalars['Int']
  input: UpdateRipplingIntegrationInput
}

export type MutationUpdateIntegrationArgs = {
  id: Scalars['Int']
  input: UpdateIntegrationInput
}

export type MutationDeleteIntegrationArgs = {
  id: Scalars['Int']
}

export type MutationSetSftpExportIntegrationArgs = {
  input: SftpExportIntegrationInput
}

export type MutationCreateMergeDevIntegrationArgs = {
  publicToken: Scalars['String']
}

export type MutationUpdateOrganizationArgs = {
  input: OrganizationInput
}

export type MutationSetOrganizationTaskCompleteArgs = {
  taskId: OrganizationTaskId
  complete: Scalars['Boolean']
}

export type MutationSetAudienceDashboardSettingsArgs = {
  input: AudienceDashboardSettingsInput
}

export type MutationSetManagerSettingsArgs = {
  input: ManagerSettingsInput
}

export type MutationCreateGroupArgs = {
  name: Scalars['String']
}

export type MutationUpdateGroupArgs = {
  id: Scalars['Int']
  input: GroupInput
}

export type MutationArchiveGroupArgs = {
  id: Scalars['Int']
}

export type MutationArchivePersonArgs = {
  id: Scalars['Int']
}

export type MutationUnarchivePersonArgs = {
  id: Scalars['Int']
}

export type MutationUpsertPeopleArgs = {
  people?: InputMaybe<Array<PersonInput>>
  newPeople?: InputMaybe<Array<NewPersonInput>>
  sendNotifications: Scalars['Boolean']
}

export type MutationInviteOrgCollaboratorArgs = {
  personId: Scalars['Int']
}

export type MutationAddAdminArgs = {
  adminRoleId: AdminRoleId
  notification?: InputMaybe<OrgCollaboratorNotificationInput>
  personId: Scalars['Int']
}

export type MutationAddNewAdminArgs = {
  adminRoleId: AdminRoleId
  notification?: InputMaybe<OrgCollaboratorNotificationInput>
  personInput: NewOrgCollaboratorInput
}

export type MutationUpdateAdminArgs = {
  personId: Scalars['Int']
  adminRoleId: AdminRoleId
}

export type MutationRemoveAdminArgs = {
  personId: Scalars['Int']
}

export type MutationAddTeammateArgs = {
  notification?: InputMaybe<OrgCollaboratorNotificationInput>
  personId: Scalars['Int']
  teamId: Scalars['Int']
  teammateInput: TeammateInput
}

export type MutationAddNewTeammateArgs = {
  notification?: InputMaybe<OrgCollaboratorNotificationInput>
  personInput: NewOrgCollaboratorInput
  teamId: Scalars['Int']
  teammateInput: TeammateInput
}

export type MutationRemoveTeammateArgs = {
  personId: Scalars['Int']
  teamId: Scalars['Int']
}

export type MutationUpdateTeammateArgs = {
  teammateInput: TeammateInput
  personId: Scalars['Int']
  teamId: Scalars['Int']
}

export type MutationAddHandbookCollaboratorArgs = {
  handbookCollaboratorInput?: InputMaybe<HandbookCollaboratorInput>
  handbookId: Scalars['Int']
  notification?: InputMaybe<OrgCollaboratorNotificationInput>
  personId: Scalars['Int']
}

export type MutationAddNewHandbookCollaboratorArgs = {
  handbookCollaboratorInput?: InputMaybe<HandbookCollaboratorInput>
  handbookId: Scalars['Int']
  notification?: InputMaybe<OrgCollaboratorNotificationInput>
  personInput: NewOrgCollaboratorInput
}

export type MutationRemoveHandbookCollaboratorArgs = {
  handbookId: Scalars['Int']
  personId: Scalars['Int']
}

export type MutationUpdateHandbookCollaboratorArgs = {
  handbookCollaboratorInput: HandbookCollaboratorInput
  handbookId: Scalars['Int']
  personId: Scalars['Int']
}

export type MutationExportHandbookRecipientsToCsvArgs = {
  columnKeys: Array<HandbookRecipientsColumnKey>
  filter?: InputMaybe<HandbookRecipientsFilter>
  handbookId: Scalars['Int']
  order?: InputMaybe<HandbookRecipientsSortOrder>
}

export type MutationExportPeopleToCsvArgs = {
  columnKeys: Array<PeopleColumnKey>
  filter?: InputMaybe<PeopleFilter>
  order?: InputMaybe<PeopleSortOrder>
  showArchived?: InputMaybe<Scalars['Boolean']>
}

export type MutationSendHandbookRemindersArgs = {
  filter?: InputMaybe<HandbookRecipientsFilter>
  handbookId: Scalars['Int']
  isSignatureRequired: Scalars['Boolean']
  template?: InputMaybe<EmailTemplateInput>
}

export type MutationSendEmployeeDigestArgs = {
  filter?: InputMaybe<PeopleFilter>
  template?: InputMaybe<EmailTemplateInput>
}

export type MutationSendManagerDigestArgs = {
  filter?: InputMaybe<PeopleFilter>
  template?: InputMaybe<EmailTemplateInput>
}

export type MutationCreateDocumentSavedReportArgs = {
  input: CreateDocumentSavedReportInput
}

export type MutationUpdateDocumentSavedReportArgs = {
  savedReportId: Scalars['Int']
  input: UpdateDocumentSavedReportInput
}

export type MutationCreatePeopleSavedReportArgs = {
  input: CreatePeopleSavedReportInput
}

export type MutationUpdatePeopleSavedReportArgs = {
  savedReportId: Scalars['Int']
  input: UpdatePeopleSavedReportInput
}

export type MutationArchiveSavedReportArgs = {
  savedReportId: Scalars['Int']
}

export type MutationUnarchiveSavedReportArgs = {
  savedReportId: Scalars['Int']
}

export type MutationCreateSavedSegmentArgs = {
  input: CreateSavedSegmentInput
}

export type MutationUpdateSavedSegmentArgs = {
  id: Scalars['Int']
  input: UpdateSavedSegmentInput
  sendNotifications?: InputMaybe<Scalars['Boolean']>
}

export type MutationArchiveSavedSegmentArgs = {
  id: Scalars['Int']
}

export type MutationUnarchiveSavedSegmentArgs = {
  id: Scalars['Int']
}

export type MutationAddSingleSignOnArgs = {
  providerId: SingleSignOnProviderId
  input?: InputMaybe<NewSingleSignOnInput>
}

export type MutationPatchSingleSignOnArgs = {
  providerId: SingleSignOnProviderId
  input: SingleSignOnInput
}

export type MutationRemoveSingleSignOnArgs = {
  providerId: SingleSignOnProviderId
}

export type MutationAddSingleSignOnCertificateArgs = {
  providerId: SingleSignOnProviderId
  certificate: Scalars['String']
}

export type MutationArchiveSingleSignOnCertificateArgs = {
  providerId: SingleSignOnProviderId
  digest: Scalars['String']
}

export type MutationUnarchiveSingleSignOnCertificateArgs = {
  providerId: SingleSignOnProviderId
  digest: Scalars['String']
}

export type MutationDeleteSingleSignOnCertificateArgs = {
  providerId: SingleSignOnProviderId
  digest: Scalars['String']
}

export type MutationSetEmailAuthSettingsArgs = {
  input: EmailAuthSettingsInput
}

export type MutationSetKioskModeSettingsArgs = {
  input: KioskModeSettingsInput
}

export type MutationSetPhoneAuthSettingsArgs = {
  input: PhoneAuthSettingsInput
}

export type MutationSendTestPhoneMessageArgs = {
  phone: Scalars['PhoneNumber']
}

export type MutationAddRipplingIntegrationArgs = {
  code: Scalars['String']
}

export type MutationUpdateBillingAdminArgs = {
  input: BillingAdminInput
}

export type MutationChargeInvoiceArgs = {
  id: Scalars['Int']
}

export type MutationLinkChargebeeSubscriptionArgs = {
  subscriptionId: Scalars['String']
}

export type MutationSetFeatureFlagArgs = {
  flagId: FeatureFlagId
  enabled: Scalars['Boolean']
}

export type MutationClearHandbookSectionDirtyArgs = {
  sectionId: Scalars['Int']
}

export type MutationDeleteHandbookSignatureArgs = {
  signatureId: Scalars['Int']
}

export type MutationMergeHandbookArgs = {
  handbookId: Scalars['Int']
  mergeHandbookId: Scalars['Int']
}

export type MutationMoveHandbookSectionToHandbookArgs = {
  sectionId: Scalars['Int']
  handbookId: Scalars['Int']
}

export type MutationArchiveHandbookSignatureRoundArgs = {
  id: Scalars['Int']
}

export type MutationUnarchiveHandbookSignatureRoundArgs = {
  id: Scalars['Int']
}

export type MutationSetSubdomainArgs = {
  subdomain: Scalars['Subdomain']
}

export type MutationUpdateOrganizationAdminArgs = {
  input: OrganizationAdminInput
}

export type MutationDeletePersonArgs = {
  id: Scalars['Int']
}

export type Query = {
  __typename?: 'Query'
  searchHandbooks: Array<HandbookSearchResult>
  session?: Maybe<Session>
  preferences: Preferences
  manager: ManagerPerson
  managerReports: Array<ManagerPersonReport>
  chargebeeSubscription?: Maybe<ChargebeeSubscription>
  chargebeeSubscriptionDetails?: Maybe<ChargebeeSubscriptionDetails>
  chargebeePortalSession?: Maybe<ChargebeePortalSession>
  documentSettings: DocumentSettings
  documents: Array<Document>
  document: Document
  documentGroups: Array<DocumentGroup>
  handbooks: Array<Handbook>
  handbook: Handbook
  handbookVersion: HandbookVersion
  handbookRecipients: Array<HandbookRecipient>
  handbookStatistics: HandbookStatistics
  handbookReminders: Array<HandbookReminder>
  handbookSectionHistory: Array<HandbookSectionVersion>
  apiKeys: Array<ApiKey>
  audienceDashboardSettings: AudienceDashboardSettings
  assets: Array<Asset>
  billing?: Maybe<Billing>
  customDomain?: Maybe<CustomDomain>
  emailDomains: Array<EmailDomain>
  emailDomainStatus: EmailDomainStatus
  emailSettings: EmailSettings
  emailTemplates: Array<EmailTemplate>
  events: Array<Event>
  featureFlagIds: Array<Scalars['String']>
  folders: Array<Folder>
  fonts: Array<Font>
  groups: Array<Group>
  groupNames: Array<Scalars['String']>
  integrations: Array<Integration>
  invoices: Array<Invoice>
  hrisPeople: Array<HrisPerson>
  hrisPeopleStatistics: HrisPeopleStatistics
  managerDigestSettings: ManagerDigestSettings
  managerSettings: ManagerSettings
  metadataKeys: Array<Scalars['String']>
  metadataValues: Array<Scalars['String']>
  organization: Organization
  organizationTasks: Array<OrganizationTask>
  people: Array<Person>
  peopleStatistics: PeopleStatistics
  savedReports: Array<SavedReport>
  savedSegments: Array<SavedSegment>
  securitySettings: SecuritySettings
  sftpExportIntegration?: Maybe<SftpExportIntegration>
  teams: Array<Team>
  billingNotifications: Array<BillingNotification>
}

export type QuerySearchHandbooksArgs = {
  search: Scalars['String']
}

export type QueryManagerArgs = {
  managerId?: InputMaybe<Scalars['Int']>
}

export type QueryManagerReportsArgs = {
  isDirect?: InputMaybe<Scalars['Boolean']>
  managerId?: InputMaybe<Scalars['Int']>
  personIds?: InputMaybe<Array<Scalars['Int']>>
  search?: InputMaybe<Scalars['String']>
}

export type QueryChargebeePortalSessionArgs = {
  customerId: Scalars['String']
}

export type QueryDocumentsArgs = {
  after?: InputMaybe<Scalars['String']>
  limit?: InputMaybe<Scalars['Int']>
  filter?: InputMaybe<DocumentsFilter>
  order?: InputMaybe<DocumentsSortOrder>
  showArchived?: InputMaybe<Scalars['Boolean']>
}

export type QueryDocumentArgs = {
  documentId: Scalars['Int']
}

export type QueryDocumentGroupsArgs = {
  filter?: InputMaybe<DocumentsFilter>
  groupBy: DocumentsGroupByField
  showArchived?: InputMaybe<Scalars['Boolean']>
}

export type QueryHandbooksArgs = {
  ids?: InputMaybe<Array<Scalars['Int']>>
}

export type QueryHandbookArgs = {
  id: Scalars['Int']
}

export type QueryHandbookVersionArgs = {
  handbookId: Scalars['Int']
  versionNumber: Scalars['Int']
}

export type QueryHandbookRecipientsArgs = {
  after?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<HandbookRecipientsFilter>
  handbookId: Scalars['Int']
  limit?: InputMaybe<Scalars['Int']>
  order?: InputMaybe<HandbookRecipientsSortOrder>
  personId?: InputMaybe<Scalars['Int']>
}

export type QueryHandbookStatisticsArgs = {
  filter?: InputMaybe<HandbookRecipientsFilter>
  handbookId: Scalars['Int']
}

export type QueryHandbookSectionHistoryArgs = {
  sectionIds?: InputMaybe<Array<Scalars['Int']>>
}

export type QueryAssetsArgs = {
  archived?: InputMaybe<Scalars['Boolean']>
  tag?: InputMaybe<Scalars['String']>
  type?: InputMaybe<AssetType>
}

export type QueryEmailDomainStatusArgs = {
  id: Scalars['Int']
}

export type QueryEventsArgs = {
  after?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<EventsFilter>
  limit?: InputMaybe<Scalars['Int']>
  order?: InputMaybe<EventsSortOrder>
}

export type QueryFontsArgs = {
  archived?: InputMaybe<Scalars['Boolean']>
}

export type QueryGroupNamesArgs = {
  source?: InputMaybe<PeopleSource>
}

export type QueryHrisPeopleArgs = {
  after?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<HrisPeopleFilter>
  integrationId: Scalars['Int']
  limit?: InputMaybe<Scalars['Int']>
  order?: InputMaybe<PeopleSortOrder>
}

export type QueryHrisPeopleStatisticsArgs = {
  filter?: InputMaybe<HrisPeopleFilter>
  integrationId: Scalars['Int']
}

export type QueryMetadataKeysArgs = {
  source?: InputMaybe<PeopleSource>
}

export type QueryMetadataValuesArgs = {
  metadataKey: Scalars['String']
  source?: InputMaybe<PeopleSource>
}

export type QueryPeopleArgs = {
  after?: InputMaybe<Scalars['String']>
  canNotify?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<PeopleFilter>
  ids?: InputMaybe<Array<Scalars['Int']>>
  isOrgCollaborator?: InputMaybe<Scalars['Boolean']>
  limit?: InputMaybe<Scalars['Int']>
  notIds?: InputMaybe<Array<Scalars['Int']>>
  order?: InputMaybe<PeopleSortOrder>
  role?: InputMaybe<OrganizationRoleQuery>
  search?: InputMaybe<Scalars['String']>
  showArchived?: InputMaybe<Scalars['Boolean']>
}

export type QueryPeopleStatisticsArgs = {
  filter?: InputMaybe<PeopleFilter>
  showArchived?: InputMaybe<Scalars['Boolean']>
}

export type SessionFragment = {
  __typename?: 'Session'
  expiresAt: any
  isImpersonating: boolean
  permissionIds: Array<string>
  person?: {
    __typename?: 'SessionPerson'
    id: number
    idHash?: string | null
    email?: any | null
    fullName?: string | null
    member: boolean
    adminRoleId?: AdminRoleId | null
    createdAt: any
  } | null
  user?:
    | {
        __typename?: 'SessionSuperUser'
        email: string
        fullName: string
        roleId?: SuperUserRoleId | null
        id: number
        type: UserType
        authType: UserAuthType
        authId: string
        createdAt: any
        lastSignedInAt?: any | null
      }
    | {
        __typename?: 'SessionUser'
        id: number
        type: UserType
        authType: UserAuthType
        authId: string
        createdAt: any
        lastSignedInAt?: any | null
      }
    | null
}

type Asset_Image_Fragment = {
  __typename?: 'Image'
  width?: number | null
  height?: number | null
  id: number
  type: AssetType
  tag: string
  filename: string
  filesize: number
  path: string
  thumbnailUrl?: string | null
  url: string
  mimeType?: string | null
  archived: boolean
  createdAt: any
}

type Asset_Pdf_Fragment = {
  __typename?: 'Pdf'
  id: number
  type: AssetType
  tag: string
  filename: string
  filesize: number
  path: string
  thumbnailUrl?: string | null
  url: string
  mimeType?: string | null
  archived: boolean
  createdAt: any
}

export type AssetFragment = Asset_Image_Fragment | Asset_Pdf_Fragment

export type AudienceDashboardSettingsFragment = {
  __typename?: 'AudienceDashboardSettings'
  primaryColor: any
  accentColor: any
  updatedAt?: any | null
  createdAt?: any | null
  faviconImage?: {
    __typename?: 'Image'
    width?: number | null
    height?: number | null
    id: number
    type: AssetType
    tag: string
    filename: string
    filesize: number
    path: string
    thumbnailUrl?: string | null
    url: string
    mimeType?: string | null
    archived: boolean
    createdAt: any
  } | null
  logoImage?: {
    __typename?: 'Image'
    width?: number | null
    height?: number | null
    id: number
    type: AssetType
    tag: string
    filename: string
    filesize: number
    path: string
    thumbnailUrl?: string | null
    url: string
    mimeType?: string | null
    archived: boolean
    createdAt: any
  } | null
  bannerImage?: {
    __typename?: 'Image'
    width?: number | null
    height?: number | null
    id: number
    type: AssetType
    tag: string
    filename: string
    filesize: number
    path: string
    thumbnailUrl?: string | null
    url: string
    mimeType?: string | null
    archived: boolean
    createdAt: any
  } | null
}

export type BillingFragment = {
  __typename?: 'Billing'
  name?: string | null
  contactName?: string | null
  address?: string | null
  toEmails: Array<any>
  credits?: number | null
  peopleCount: number
  exempt: boolean
  monthlyGrowthRate?: number | null
  monthlyCreditPrice?: number | null
  yearlyCreditPrice?: number | null
  termStartCount?: number | null
  termMonths?: number | null
  termMonthlyGrowthRate?: number | null
  paymentMethod?: string | null
  paymentDays?: number | null
  expiredAt?: any | null
  canceledAt?: any | null
  terminatedAt?: any | null
  emailSettings: {
    __typename?: 'BillingEmailSettings'
    toAdmins: boolean
    toEmails: Array<any>
  }
}

export type ChargebeeSubscriptionDetailsFragment = {
  __typename?: 'ChargebeeSubscriptionDetails'
  currency_code: string
  customer_id: string
  id: string
  next_billing_at?: any | null
  status: string
  cancelled_at?: any | null
  payment_source_id?: string | null
  subscription_items: Array<{
    __typename?: 'ChargebeeSubscriptionItem'
    item_price_id?: string | null
    item_type?: string | null
    name?: string | null
    external_name?: string | null
    amount?: number | null
    currency_code?: string | null
  }>
  charged_items?: Array<{
    __typename?: 'ChargebeeSubscriptionChargedItem'
    item_price_id?: string | null
  }> | null
  invoices: Array<{
    __typename?: 'ChargebeeInvoice'
    id: string
    date?: any | null
    paidAt?: any | null
    total?: number | null
    currency_code: string
    download_url?: string | null
  }>
  discounts?: Array<{
    __typename?: 'ChargebeeSubscriptionDiscount'
    amount: number
  }> | null
}

export type ChargebeeSubscriptionFragment = {
  __typename?: 'ChargebeeSubscription'
  status: string
  lastSyncAt?: any | null
}

export type ChargebeePortalSessionFragment = {
  __typename?: 'ChargebeePortalSession'
  id: string
  token: string
  access_url: string
  status: string
  customer_id: string
}

export type CustomDomainFragment = {
  __typename?: 'CustomDomain'
  domain: any
  domainTarget: any
  isAutoRenew: boolean
  isDnsValid?: boolean | null
  dnsLastValidAt?: any | null
  certificateDigest?: string | null
  certificates: Array<{
    __typename?: 'CustomDomainCertificate'
    digest: string
    expiresAt: any
    createdAt: any
  }>
}

export type EmailAuthSettingsFragment = {
  __typename?: 'EmailAuthSettings'
  isEnabled: boolean
  updatedAt?: any | null
}

export type EmailDomainFragment = {
  __typename?: 'EmailDomain'
  id: number
  domain: any
  environment: string
  fromEmail: any
  fromName: string
  updatedAt: any
  createdAt: any
  dkimStatus: {
    __typename?: 'EmailDomainDkimStatus'
    verified: boolean
    lastVerifiedAt?: any | null
  }
  returnPathStatus: {
    __typename?: 'EmailDomainReturnPathStatus'
    verified: boolean
    lastVerifiedAt?: any | null
  }
}

export type EmailDomainStatusFragment = {
  __typename?: 'EmailDomainStatus'
  dkimStatus: {
    __typename?: 'EmailDomainDkimStatus'
    verified: boolean
    lastVerifiedAt?: any | null
  }
  dkimDns: {
    __typename?: 'EmailDomainDkimDns'
    host: string
    textValue: string
    pendingHost: string
    pendingTextValue: string
  }
  returnPathStatus: {
    __typename?: 'EmailDomainReturnPathStatus'
    verified: boolean
    lastVerifiedAt?: any | null
  }
  returnPathDns: {
    __typename?: 'EmailDomainReturnPathDns'
    domain: any
    cnameValue: string
  }
}

export type EmailSettingsFragment = {
  __typename?: 'EmailSettings'
  autoSendInvitations?: boolean | null
  fromEmail?: any | null
  fromName?: string | null
  replyToEmail?: any | null
  defaultSenderFromName?: string | null
  defaultSenderReplyToEmail?: any | null
  bodyFont: string
  footerText: string
  primaryColor: any
  hasCustomBranding: boolean
  updatedAt?: any | null
  bannerImage?: {
    __typename?: 'Image'
    width?: number | null
    height?: number | null
    id: number
    type: AssetType
    tag: string
    filename: string
    filesize: number
    path: string
    thumbnailUrl?: string | null
    url: string
    mimeType?: string | null
    archived: boolean
    createdAt: any
  } | null
  footerLinks: Array<{ __typename?: 'EmailFooterLink'; text: string; url: any }>
  logoImage?: {
    __typename?: 'Image'
    width?: number | null
    height?: number | null
    id: number
    type: AssetType
    tag: string
    filename: string
    filesize: number
    path: string
    thumbnailUrl?: string | null
    url: string
    mimeType?: string | null
    archived: boolean
    createdAt: any
  } | null
}

export type EmailTemplateFragment = {
  __typename?: 'EmailTemplate'
  id: EmailTemplateId
  subjectContent: any
  ccManagers: boolean
  buttonContent: any
  messageContent: any
  createdAt?: any | null
  updatedAt?: any | null
}

export type ExpressionFragment = {
  __typename?: 'RootExpression'
  conjunction: ExpressionConjunction
  expressions: Array<{
    __typename?: 'Expression'
    conjunction: ExpressionConjunction
    operands: Array<
      | {
          __typename?: 'DateOperand'
          field: string
          dateCondition: DateOperandCondition
          days?: number | null
          date?: any | null
          id?: string | null
          type: OperandType
        }
      | {
          __typename?: 'IdsOperand'
          isEvery: boolean
          isNot: boolean
          ids: Array<number>
          id?: string | null
          type: OperandType
        }
      | {
          __typename?: 'ManagersOperand'
          isEvery: boolean
          isNot: boolean
          managesDirectly: boolean
          ids: Array<number>
          id?: string | null
          type: OperandType
        }
      | {
          __typename?: 'SelectIdsOperand'
          id?: string | null
          type: OperandType
        }
      | {
          __typename?: 'SelectOperand'
          field: string
          path?: string | null
          isEvery: boolean
          isNot: boolean
          values?: Array<string> | null
          id?: string | null
          type: OperandType
        }
      | {
          __typename?: 'TextOperand'
          field: string
          isNot: boolean
          textCondition: TextOperandCondition
          text: string
          id?: string | null
          type: OperandType
        }
    >
  }>
}

export type GroupFragment = {
  __typename?: 'Group'
  id: number
  name: string
  archived: boolean
  createdAt?: any | null
  updatedAt?: any | null
  peopleCount: number
}

export type HandbookFragment = {
  __typename?: 'Handbook'
  content: any
  teamId: number
  id: number
  name: string
  defaultLanguageCode: string
  customDomain?: any | null
  customDomainOrigin?: any | null
  isPrintDisabled: boolean
  position: number
  public: boolean
  publicToken: string
  version: number
  createdAt?: any | null
  updatedAt?: any | null
  lastModifiedAt?: any | null
  lastModifiedBy?: number | null
  publishedAt?: any | null
  lastPublishedAt?: any | null
  url: any
  publishedUrl: any
  permissionIds: Array<string>
  contentLength: number
  deletePreviewLinks: boolean
  hasPersonalizedContent: boolean
  hasAudience: boolean
  viewersCount: number
  acknowledgementForm: {
    __typename?: 'HandbookAcknowledgement'
    title?: any | null
    body?: any | null
    forceSign: boolean
  }
  assets: Array<
    | {
        __typename?: 'HandbookImage'
        width?: number | null
        height?: number | null
        id: number
        type: AssetType
        filename: string
        filesize: number
        path: string
        thumbnailUrl?: string | null
        url: string
        archived: boolean
        mimeType?: string | null
        createdAt: any
      }
    | {
        __typename?: 'HandbookPdf'
        id: number
        type: AssetType
        filename: string
        filesize: number
        path: string
        thumbnailUrl?: string | null
        url: string
        archived: boolean
        mimeType?: string | null
        createdAt: any
      }
  >
  contactFragments: {
    __typename?: 'HandbookContact'
    title?: any | null
    body?: any | null
  }
  fonts: Array<{
    __typename?: 'HandbookFont'
    id: number
    filename: string
    archived: boolean
    createdAt: any
  }>
  publishNotification?: {
    __typename?: 'PublishHandbookNotification'
    toViewers?: boolean | null
    signers?: {
      __typename?: 'Notification'
      subjectContent: any
      buttonContent: any
      messageContent: any
    } | null
    viewers?: {
      __typename?: 'Notification'
      subjectContent: any
      buttonContent: any
      messageContent: any
    } | null
  } | null
  sections: Array<{
    __typename?: 'HandbookSection'
    handbookId: number
    id: number
    version: number
    createdAt?: any | null
    createdBy?: number | null
    updatedAt?: any | null
    updatedBy?: number | null
    lastPublishedAt?: any | null
    lastPublishedBy?: number | null
    dirtyFields?: Array<string> | null
    hidden: boolean
    hideToc: boolean
    languageCode?: string | null
    theme?: string | null
    title?: string | null
    type: HandbookSectionType
    hideAnnotations: Array<number>
    editAnnotation?: string | null
    viewAnnotation?: string | null
    attrs: {
      __typename?: 'HandbookSectionAttributes'
      keys: Array<string>
      goalAlign?: HandbookSectionTextAlign | null
      image?: any | null
      photo?: any | null
      signatureImage?: any | null
      statementAlign?: HandbookSectionTextAlign | null
      subtitleAlign?: HandbookSectionTextAlign | null
      titleAlign?: HandbookSectionTextAlign | null
      animation?: boolean | null
      backgroundAnimation?: string | null
      backgroundColor?: any | null
      backgroundImage?: any | null
      backgroundOverlayColor?: any | null
      backgroundOverlayOpacity?: number | null
      bottom?: string | null
      bottomArrow?: boolean | null
      headingBackgroundImage?: any | null
      height?: string | null
      entryAngle?: string | null
      entryAnimation?: boolean | null
      maxColumns?: number | null
      showChapterIcon?: boolean | null
      signatureType?: string | null
      textShadow?: boolean | null
      titleHighlight?: string | null
      titleHighlightColor?: any | null
      video?: {
        __typename?: 'HandbookVideo'
        url: any
        height: number
        width: number
      } | null
    }
    fragments: {
      __typename?: 'HandbookSectionContent'
      keys: Array<string>
      body?: any | null
      description?: any | null
      goal?: any | null
      headline?: any | null
      introduction?: any | null
      signature?: any | null
      statement?: any | null
      subtitle?: any | null
      title?: any | null
    }
    listEntries?: Array<{
      __typename?: 'HandbookSectionEntry'
      keys: Array<string>
      uid: string
      body?: any | null
      image?: any | null
      subtitle?: any | null
      subtitleAlign?: HandbookSectionTextAlign | null
      title?: any | null
      titleAlign?: HandbookSectionTextAlign | null
    }> | null
    audienceExpression?: {
      __typename?: 'RootExpression'
      conjunction: ExpressionConjunction
      expressions: Array<{
        __typename?: 'Expression'
        conjunction: ExpressionConjunction
        operands: Array<
          | {
              __typename?: 'DateOperand'
              field: string
              dateCondition: DateOperandCondition
              days?: number | null
              date?: any | null
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'IdsOperand'
              isEvery: boolean
              isNot: boolean
              ids: Array<number>
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'ManagersOperand'
              isEvery: boolean
              isNot: boolean
              managesDirectly: boolean
              ids: Array<number>
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'SelectIdsOperand'
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'SelectOperand'
              field: string
              path?: string | null
              isEvery: boolean
              isNot: boolean
              values?: Array<string> | null
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'TextOperand'
              field: string
              isNot: boolean
              textCondition: TextOperandCondition
              text: string
              id?: string | null
              type: OperandType
            }
        >
      }>
    } | null
  }>
  lastPublishedByPerson?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
  branding: {
    __typename?: 'HandbookBranding'
    blackColor: any
    favicon?: any | null
    handbookCss?: string | null
    lessButtonText: string
    moreButtonText: string
    primaryColor: any
    printCss?: string | null
    customHtml?: string | null
    whiteColor: any
    bodyFont: {
      __typename?: 'HandbookBrandingFont'
      type: HandbookBrandingFontType
      fontId?: number | null
      family?: string | null
      bold?: number | null
      normal?: number | null
      variants?: Array<string> | null
    }
    headingFont: {
      __typename?: 'HandbookBrandingFont'
      type: HandbookBrandingFontType
      fontId?: number | null
      family?: string | null
      bold?: number | null
      normal?: number | null
      variants?: Array<string> | null
    }
    scriptFont: {
      __typename?: 'HandbookBrandingFont'
      type: HandbookBrandingFontType
      fontId?: number | null
      family?: string | null
      bold?: number | null
      normal?: number | null
      variants?: Array<string> | null
    }
  }
  previewLinks: Array<{
    __typename?: 'HandbookPreviewLink'
    handbookId: number
    personId?: number | null
    token: string
    previewUrl: any
    createdAt: any
  }>
  signatureRounds: Array<{
    __typename?: 'HandbookSignatureRound'
    id: number
    number: number
    onlyAtLaunch?: HandbookSignatureRoundOnlyAtLaunch | null
    onlyMembers: boolean
    dueDays?: number | null
    dueDaysFuture?: number | null
    archived: boolean
    active: boolean
    createdAt: any
    updatedAt: any
    publishedAt?: any | null
    peopleCount: number
    audienceExpression?: {
      __typename?: 'RootExpression'
      conjunction: ExpressionConjunction
      expressions: Array<{
        __typename?: 'Expression'
        conjunction: ExpressionConjunction
        operands: Array<
          | {
              __typename?: 'DateOperand'
              field: string
              dateCondition: DateOperandCondition
              days?: number | null
              date?: any | null
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'IdsOperand'
              isEvery: boolean
              isNot: boolean
              ids: Array<number>
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'ManagersOperand'
              isEvery: boolean
              isNot: boolean
              managesDirectly: boolean
              ids: Array<number>
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'SelectIdsOperand'
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'SelectOperand'
              field: string
              path?: string | null
              isEvery: boolean
              isNot: boolean
              values?: Array<string> | null
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'TextOperand'
              field: string
              isNot: boolean
              textCondition: TextOperandCondition
              text: string
              id?: string | null
              type: OperandType
            }
        >
      }>
    } | null
    createdBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    updatedBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
  }>
  statistics: {
    __typename?: 'HandbookStatistics'
    peopleCount: number
    canNotifyPeopleCount: number
    canNotifyNonCompliantPeopleCount: number
    nonCompliantPeopleCount: number
    notifiedPeopleCount: number
    maxNotifiedPeopleCount: number
    viewedPeopleCount: number
    maxViewedPeopleCount: number
    signedPeopleCount: number
    maxSignedPeopleCount: number
    validSignedPeopleCount: number
    maxValidSignedPeopleCount: number
    score?: number | null
  }
  tasks: Array<{
    __typename?: 'HandbookTask'
    id: HandbookTaskId
    completedAt?: any | null
    completedBy?: number | null
  }>
  versions: Array<{
    __typename?: 'HandbookVersionSummary'
    createdAt?: any | null
    createdBy?: number | null
    defaultLanguageCode: string
    handbookId: number
    label?: string | null
    languageCodes: Array<string>
    printUrl: any
    url: any
    version: number
    createdByPerson?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
  }>
  audience: {
    __typename?: 'HandbookAudience'
    member: boolean
    groupIds: Array<number>
    personIds: Array<number>
    savedSegmentIds: Array<number>
  }
}

export type HandbookAcknowledgementFormFragment = {
  __typename?: 'HandbookAcknowledgement'
  title?: any | null
  body?: any | null
  forceSign: boolean
}

type HandbookAsset_HandbookImage_Fragment = {
  __typename?: 'HandbookImage'
  width?: number | null
  height?: number | null
  id: number
  type: AssetType
  filename: string
  filesize: number
  path: string
  thumbnailUrl?: string | null
  url: string
  archived: boolean
  mimeType?: string | null
  createdAt: any
}

type HandbookAsset_HandbookPdf_Fragment = {
  __typename?: 'HandbookPdf'
  id: number
  type: AssetType
  filename: string
  filesize: number
  path: string
  thumbnailUrl?: string | null
  url: string
  archived: boolean
  mimeType?: string | null
  createdAt: any
}

export type HandbookAssetFragment =
  | HandbookAsset_HandbookImage_Fragment
  | HandbookAsset_HandbookPdf_Fragment

export type HandbookAudienceFragment = {
  __typename?: 'HandbookAudience'
  member: boolean
  groupIds: Array<number>
  personIds: Array<number>
  savedSegmentIds: Array<number>
}

export type HandbookBrandingFragment = {
  __typename?: 'HandbookBranding'
  blackColor: any
  favicon?: any | null
  handbookCss?: string | null
  lessButtonText: string
  moreButtonText: string
  primaryColor: any
  printCss?: string | null
  customHtml?: string | null
  whiteColor: any
  bodyFont: {
    __typename?: 'HandbookBrandingFont'
    type: HandbookBrandingFontType
    fontId?: number | null
    family?: string | null
    bold?: number | null
    normal?: number | null
    variants?: Array<string> | null
  }
  headingFont: {
    __typename?: 'HandbookBrandingFont'
    type: HandbookBrandingFontType
    fontId?: number | null
    family?: string | null
    bold?: number | null
    normal?: number | null
    variants?: Array<string> | null
  }
  scriptFont: {
    __typename?: 'HandbookBrandingFont'
    type: HandbookBrandingFontType
    fontId?: number | null
    family?: string | null
    bold?: number | null
    normal?: number | null
    variants?: Array<string> | null
  }
}

export type HandbookBrandingFontFragment = {
  __typename?: 'HandbookBrandingFont'
  type: HandbookBrandingFontType
  fontId?: number | null
  family?: string | null
  bold?: number | null
  normal?: number | null
  variants?: Array<string> | null
}

export type HandbookCollaboratorFragment = {
  __typename?: 'HandbookCollaborator'
  id: number
  handbookId: number
  personId: number
  roleId?: HandbookCollaboratorRoleId | null
}

export type HandbookContactFragment = {
  __typename?: 'HandbookContact'
  title?: any | null
  body?: any | null
}

export type HandbookFontFragment = {
  __typename?: 'HandbookFont'
  id: number
  filename: string
  archived: boolean
  createdAt: any
}

export type HandbookNotificationFragment = {
  __typename?: 'HandbookNotification'
  id: number
  handbookId?: number | null
  personId: number
  email: any
  toName?: string | null
  fromEmail?: any | null
  fromName?: string | null
  subject?: string | null
  body?: string | null
  createdAt: any
  sentAt?: any | null
  lastError?: string | null
  lastErrorAt?: any | null
}

export type HandbookPreviewLinkFragment = {
  __typename?: 'HandbookPreviewLink'
  handbookId: number
  personId?: number | null
  token: string
  previewUrl: any
  createdAt: any
}

export type HandbookRecipientReportingFragment = {
  __typename?: 'HandbookRecipient'
  handbookId: number
  personId: number
  canView: boolean
  signatureRoundId?: number | null
  signatureRequestedAt?: any | null
  signatureDueDate?: any | null
  signatureDueAt?: any | null
  isSignatureRequired: boolean
  hasValidSignature: boolean
  lastNotificationQueuedAt?: any | null
  lastNotifiedAt?: any | null
  lastViewedAt?: any | null
  lastViewedVersion?: number | null
  lastSignedAt?: any | null
  lastSignedVersion?: number | null
  score: number
}

export type HandbookRecipientFragment = {
  __typename?: 'HandbookRecipient'
  canNotify: boolean
  email?: any | null
  fullName: string
  employeeId?: string | null
  groupIds: Array<number>
  isManager: boolean
  lastHiredOn?: any | null
  metadata?: any | null
  handbookId: number
  personId: number
  canView: boolean
  signatureRoundId?: number | null
  signatureRequestedAt?: any | null
  signatureDueDate?: any | null
  signatureDueAt?: any | null
  isSignatureRequired: boolean
  hasValidSignature: boolean
  lastNotificationQueuedAt?: any | null
  lastNotifiedAt?: any | null
  lastViewedAt?: any | null
  lastViewedVersion?: number | null
  lastSignedAt?: any | null
  lastSignedVersion?: number | null
  score: number
  managers: Array<{
    __typename?: 'PersonManager'
    id: number
    email?: any | null
    employeeId?: string | null
    fullName: string
  }>
}

export type HandbookReminderFragment = {
  __typename?: 'HandbookReminder'
  id: number
  isEnabled: boolean
  days: number
  date: HandbookReminderDate
  cc: Array<any>
  ccManagers: boolean
  subjectContent: any
  buttonContent: any
  messageContent: any
  createdAt?: any | null
  createdBy?: number | null
  updatedAt?: any | null
  updatedBy?: number | null
}

export type HandbookSectionSettingsFragment = {
  __typename?: 'HandbookSection'
  hidden: boolean
  hideToc: boolean
  languageCode?: string | null
  theme?: string | null
  title?: string | null
  type: HandbookSectionType
  hideAnnotations: Array<number>
  editAnnotation?: string | null
  viewAnnotation?: string | null
  audienceExpression?: {
    __typename?: 'RootExpression'
    conjunction: ExpressionConjunction
    expressions: Array<{
      __typename?: 'Expression'
      conjunction: ExpressionConjunction
      operands: Array<
        | {
            __typename?: 'DateOperand'
            field: string
            dateCondition: DateOperandCondition
            days?: number | null
            date?: any | null
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'IdsOperand'
            isEvery: boolean
            isNot: boolean
            ids: Array<number>
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'ManagersOperand'
            isEvery: boolean
            isNot: boolean
            managesDirectly: boolean
            ids: Array<number>
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'SelectIdsOperand'
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'SelectOperand'
            field: string
            path?: string | null
            isEvery: boolean
            isNot: boolean
            values?: Array<string> | null
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'TextOperand'
            field: string
            isNot: boolean
            textCondition: TextOperandCondition
            text: string
            id?: string | null
            type: OperandType
          }
      >
    }>
  } | null
}

export type HandbookSectionFragment = {
  __typename?: 'HandbookSection'
  handbookId: number
  id: number
  version: number
  createdAt?: any | null
  createdBy?: number | null
  updatedAt?: any | null
  updatedBy?: number | null
  lastPublishedAt?: any | null
  lastPublishedBy?: number | null
  dirtyFields?: Array<string> | null
  hidden: boolean
  hideToc: boolean
  languageCode?: string | null
  theme?: string | null
  title?: string | null
  type: HandbookSectionType
  hideAnnotations: Array<number>
  editAnnotation?: string | null
  viewAnnotation?: string | null
  attrs: {
    __typename?: 'HandbookSectionAttributes'
    keys: Array<string>
    goalAlign?: HandbookSectionTextAlign | null
    image?: any | null
    photo?: any | null
    signatureImage?: any | null
    statementAlign?: HandbookSectionTextAlign | null
    subtitleAlign?: HandbookSectionTextAlign | null
    titleAlign?: HandbookSectionTextAlign | null
    animation?: boolean | null
    backgroundAnimation?: string | null
    backgroundColor?: any | null
    backgroundImage?: any | null
    backgroundOverlayColor?: any | null
    backgroundOverlayOpacity?: number | null
    bottom?: string | null
    bottomArrow?: boolean | null
    headingBackgroundImage?: any | null
    height?: string | null
    entryAngle?: string | null
    entryAnimation?: boolean | null
    maxColumns?: number | null
    showChapterIcon?: boolean | null
    signatureType?: string | null
    textShadow?: boolean | null
    titleHighlight?: string | null
    titleHighlightColor?: any | null
    video?: {
      __typename?: 'HandbookVideo'
      url: any
      height: number
      width: number
    } | null
  }
  fragments: {
    __typename?: 'HandbookSectionContent'
    keys: Array<string>
    body?: any | null
    description?: any | null
    goal?: any | null
    headline?: any | null
    introduction?: any | null
    signature?: any | null
    statement?: any | null
    subtitle?: any | null
    title?: any | null
  }
  listEntries?: Array<{
    __typename?: 'HandbookSectionEntry'
    keys: Array<string>
    uid: string
    body?: any | null
    image?: any | null
    subtitle?: any | null
    subtitleAlign?: HandbookSectionTextAlign | null
    title?: any | null
    titleAlign?: HandbookSectionTextAlign | null
  }> | null
  audienceExpression?: {
    __typename?: 'RootExpression'
    conjunction: ExpressionConjunction
    expressions: Array<{
      __typename?: 'Expression'
      conjunction: ExpressionConjunction
      operands: Array<
        | {
            __typename?: 'DateOperand'
            field: string
            dateCondition: DateOperandCondition
            days?: number | null
            date?: any | null
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'IdsOperand'
            isEvery: boolean
            isNot: boolean
            ids: Array<number>
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'ManagersOperand'
            isEvery: boolean
            isNot: boolean
            managesDirectly: boolean
            ids: Array<number>
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'SelectIdsOperand'
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'SelectOperand'
            field: string
            path?: string | null
            isEvery: boolean
            isNot: boolean
            values?: Array<string> | null
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'TextOperand'
            field: string
            isNot: boolean
            textCondition: TextOperandCondition
            text: string
            id?: string | null
            type: OperandType
          }
      >
    }>
  } | null
}

export type HandbookSectionAttributesFragment = {
  __typename?: 'HandbookSectionAttributes'
  keys: Array<string>
  goalAlign?: HandbookSectionTextAlign | null
  image?: any | null
  photo?: any | null
  signatureImage?: any | null
  statementAlign?: HandbookSectionTextAlign | null
  subtitleAlign?: HandbookSectionTextAlign | null
  titleAlign?: HandbookSectionTextAlign | null
  animation?: boolean | null
  backgroundAnimation?: string | null
  backgroundColor?: any | null
  backgroundImage?: any | null
  backgroundOverlayColor?: any | null
  backgroundOverlayOpacity?: number | null
  bottom?: string | null
  bottomArrow?: boolean | null
  headingBackgroundImage?: any | null
  height?: string | null
  entryAngle?: string | null
  entryAnimation?: boolean | null
  maxColumns?: number | null
  showChapterIcon?: boolean | null
  signatureType?: string | null
  textShadow?: boolean | null
  titleHighlight?: string | null
  titleHighlightColor?: any | null
  video?: {
    __typename?: 'HandbookVideo'
    url: any
    height: number
    width: number
  } | null
}

export type HandbookSectionContentFragment = {
  __typename?: 'HandbookSectionContent'
  keys: Array<string>
  body?: any | null
  description?: any | null
  goal?: any | null
  headline?: any | null
  introduction?: any | null
  signature?: any | null
  statement?: any | null
  subtitle?: any | null
  title?: any | null
}

export type HandbookSectionEntryFragment = {
  __typename?: 'HandbookSectionEntry'
  keys: Array<string>
  uid: string
  body?: any | null
  image?: any | null
  subtitle?: any | null
  subtitleAlign?: HandbookSectionTextAlign | null
  title?: any | null
  titleAlign?: HandbookSectionTextAlign | null
}

export type HandbookSharingFragment = {
  __typename?: 'Handbook'
  hasAudience: boolean
  viewersCount: number
  audience: {
    __typename?: 'HandbookAudience'
    member: boolean
    groupIds: Array<number>
    personIds: Array<number>
    savedSegmentIds: Array<number>
  }
}

export type HandbookSignatureFragment = {
  __typename?: 'HandbookSignature'
  id: number
  createdAt: any
  handbookId: number
  handbookVersion: number
  languageCode: string
  personId: number
  recordedByPersonId?: number | null
  recordedByUserId?: number | null
  roundId: number
  signature?: string | null
  signedAt: any
  acknowledgementForm: {
    __typename?: 'HandbookSignatureAcknowledgement'
    body: any
    wysiwyg?: string | null
  }
  personalization: {
    __typename?: 'Personalization'
    groupIds: Array<number>
    metadata?: any | null
    personId: number
    savedSegmentIds: Array<number>
  }
  toc: Array<{
    __typename?: 'HandbookSignatureTocItem'
    bookmark: string
    printUrl: string
    title: string
  }>
  user?: {
    __typename?: 'HandbookUser'
    id: number
    authType: UserAuthType
    authId: string
  } | null
}

export type HandbookSignatureDueDateOverrideFragment = {
  __typename?: 'HandbookSignatureDueDateOverride'
  id: number
  roundId: number
  previousDueDate?: any | null
  dueDate: any
  note?: string | null
  createdAt: any
  createdBy?: number | null
}

export type HandbookSignatureRoundFragment = {
  __typename?: 'HandbookSignatureRound'
  id: number
  number: number
  onlyAtLaunch?: HandbookSignatureRoundOnlyAtLaunch | null
  onlyMembers: boolean
  dueDays?: number | null
  dueDaysFuture?: number | null
  archived: boolean
  active: boolean
  createdAt: any
  updatedAt: any
  publishedAt?: any | null
  peopleCount: number
  audienceExpression?: {
    __typename?: 'RootExpression'
    conjunction: ExpressionConjunction
    expressions: Array<{
      __typename?: 'Expression'
      conjunction: ExpressionConjunction
      operands: Array<
        | {
            __typename?: 'DateOperand'
            field: string
            dateCondition: DateOperandCondition
            days?: number | null
            date?: any | null
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'IdsOperand'
            isEvery: boolean
            isNot: boolean
            ids: Array<number>
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'ManagersOperand'
            isEvery: boolean
            isNot: boolean
            managesDirectly: boolean
            ids: Array<number>
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'SelectIdsOperand'
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'SelectOperand'
            field: string
            path?: string | null
            isEvery: boolean
            isNot: boolean
            values?: Array<string> | null
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'TextOperand'
            field: string
            isNot: boolean
            textCondition: TextOperandCondition
            text: string
            id?: string | null
            type: OperandType
          }
      >
    }>
  } | null
  createdBy?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
  updatedBy?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
}

export type HandbookStatisticsFragment = {
  __typename?: 'HandbookStatistics'
  peopleCount: number
  canNotifyPeopleCount: number
  canNotifyNonCompliantPeopleCount: number
  nonCompliantPeopleCount: number
  notifiedPeopleCount: number
  maxNotifiedPeopleCount: number
  viewedPeopleCount: number
  maxViewedPeopleCount: number
  signedPeopleCount: number
  maxSignedPeopleCount: number
  validSignedPeopleCount: number
  maxValidSignedPeopleCount: number
  score?: number | null
}

export type HandbookVersionSummaryFragment = {
  __typename?: 'HandbookVersionSummary'
  createdAt?: any | null
  createdBy?: number | null
  defaultLanguageCode: string
  handbookId: number
  label?: string | null
  languageCodes: Array<string>
  printUrl: any
  url: any
  version: number
  createdByPerson?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
}

export type HandbookSummaryFragment = {
  __typename?: 'Handbook'
  teamId: number
  id: number
  name: string
  defaultLanguageCode: string
  customDomain?: any | null
  customDomainOrigin?: any | null
  isPrintDisabled: boolean
  position: number
  public: boolean
  publicToken: string
  version: number
  createdAt?: any | null
  updatedAt?: any | null
  lastModifiedAt?: any | null
  lastModifiedBy?: number | null
  publishedAt?: any | null
  lastPublishedAt?: any | null
  url: any
  publishedUrl: any
  permissionIds: Array<string>
  contentLength: number
  deletePreviewLinks: boolean
  hasPersonalizedContent: boolean
  hasAudience: boolean
  viewersCount: number
  lastPublishedByPerson?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
  branding: {
    __typename?: 'HandbookBranding'
    blackColor: any
    favicon?: any | null
    handbookCss?: string | null
    lessButtonText: string
    moreButtonText: string
    primaryColor: any
    printCss?: string | null
    customHtml?: string | null
    whiteColor: any
    bodyFont: {
      __typename?: 'HandbookBrandingFont'
      type: HandbookBrandingFontType
      fontId?: number | null
      family?: string | null
      bold?: number | null
      normal?: number | null
      variants?: Array<string> | null
    }
    headingFont: {
      __typename?: 'HandbookBrandingFont'
      type: HandbookBrandingFontType
      fontId?: number | null
      family?: string | null
      bold?: number | null
      normal?: number | null
      variants?: Array<string> | null
    }
    scriptFont: {
      __typename?: 'HandbookBrandingFont'
      type: HandbookBrandingFontType
      fontId?: number | null
      family?: string | null
      bold?: number | null
      normal?: number | null
      variants?: Array<string> | null
    }
  }
  previewLinks: Array<{
    __typename?: 'HandbookPreviewLink'
    handbookId: number
    personId?: number | null
    token: string
    previewUrl: any
    createdAt: any
  }>
  signatureRounds: Array<{
    __typename?: 'HandbookSignatureRound'
    id: number
    number: number
    onlyAtLaunch?: HandbookSignatureRoundOnlyAtLaunch | null
    onlyMembers: boolean
    dueDays?: number | null
    dueDaysFuture?: number | null
    archived: boolean
    active: boolean
    createdAt: any
    updatedAt: any
    publishedAt?: any | null
    peopleCount: number
    audienceExpression?: {
      __typename?: 'RootExpression'
      conjunction: ExpressionConjunction
      expressions: Array<{
        __typename?: 'Expression'
        conjunction: ExpressionConjunction
        operands: Array<
          | {
              __typename?: 'DateOperand'
              field: string
              dateCondition: DateOperandCondition
              days?: number | null
              date?: any | null
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'IdsOperand'
              isEvery: boolean
              isNot: boolean
              ids: Array<number>
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'ManagersOperand'
              isEvery: boolean
              isNot: boolean
              managesDirectly: boolean
              ids: Array<number>
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'SelectIdsOperand'
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'SelectOperand'
              field: string
              path?: string | null
              isEvery: boolean
              isNot: boolean
              values?: Array<string> | null
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'TextOperand'
              field: string
              isNot: boolean
              textCondition: TextOperandCondition
              text: string
              id?: string | null
              type: OperandType
            }
        >
      }>
    } | null
    createdBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    updatedBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
  }>
  statistics: {
    __typename?: 'HandbookStatistics'
    peopleCount: number
    canNotifyPeopleCount: number
    canNotifyNonCompliantPeopleCount: number
    nonCompliantPeopleCount: number
    notifiedPeopleCount: number
    maxNotifiedPeopleCount: number
    viewedPeopleCount: number
    maxViewedPeopleCount: number
    signedPeopleCount: number
    maxSignedPeopleCount: number
    validSignedPeopleCount: number
    maxValidSignedPeopleCount: number
    score?: number | null
  }
  tasks: Array<{
    __typename?: 'HandbookTask'
    id: HandbookTaskId
    completedAt?: any | null
    completedBy?: number | null
  }>
  versions: Array<{
    __typename?: 'HandbookVersionSummary'
    createdAt?: any | null
    createdBy?: number | null
    defaultLanguageCode: string
    handbookId: number
    label?: string | null
    languageCodes: Array<string>
    printUrl: any
    url: any
    version: number
    createdByPerson?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
  }>
  audience: {
    __typename?: 'HandbookAudience'
    member: boolean
    groupIds: Array<number>
    personIds: Array<number>
    savedSegmentIds: Array<number>
  }
}

export type HandbookTaskFragment = {
  __typename?: 'HandbookTask'
  id: HandbookTaskId
  completedAt?: any | null
  completedBy?: number | null
}

type Integration_BambooHrIntegration_Fragment = {
  __typename?: 'BambooHRIntegration'
  groups: Array<BambooHrGroupId>
  hostname: string
  subdomain: string
  syncTiming?: BambooHrSyncTiming | null
  userId?: string | null
  id: number
  providerId: IntegrationProviderId
  lastSyncAt?: any | null
  lastErrorAt?: any | null
  lastError?: string | null
  createdAt: any
  updatedAt: any
  rejectPeopleExpression?: {
    __typename?: 'RootExpression'
    conjunction: ExpressionConjunction
    expressions: Array<{
      __typename?: 'Expression'
      conjunction: ExpressionConjunction
      operands: Array<
        | {
            __typename?: 'DateOperand'
            field: string
            dateCondition: DateOperandCondition
            days?: number | null
            date?: any | null
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'IdsOperand'
            isEvery: boolean
            isNot: boolean
            ids: Array<number>
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'ManagersOperand'
            isEvery: boolean
            isNot: boolean
            managesDirectly: boolean
            ids: Array<number>
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'SelectIdsOperand'
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'SelectOperand'
            field: string
            path?: string | null
            isEvery: boolean
            isNot: boolean
            values?: Array<string> | null
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'TextOperand'
            field: string
            isNot: boolean
            textCondition: TextOperandCondition
            text: string
            id?: string | null
            type: OperandType
          }
      >
    }>
  } | null
}

type Integration_RipplingIntegration_Fragment = {
  __typename?: 'RipplingIntegration'
  customFieldKeys: Array<string>
  ripplingGroupIds: Array<RipplingGroupId>
  id: number
  providerId: IntegrationProviderId
  lastSyncAt?: any | null
  lastErrorAt?: any | null
  lastError?: string | null
  createdAt: any
  updatedAt: any
  rejectPeopleExpression?: {
    __typename?: 'RootExpression'
    conjunction: ExpressionConjunction
    expressions: Array<{
      __typename?: 'Expression'
      conjunction: ExpressionConjunction
      operands: Array<
        | {
            __typename?: 'DateOperand'
            field: string
            dateCondition: DateOperandCondition
            days?: number | null
            date?: any | null
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'IdsOperand'
            isEvery: boolean
            isNot: boolean
            ids: Array<number>
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'ManagersOperand'
            isEvery: boolean
            isNot: boolean
            managesDirectly: boolean
            ids: Array<number>
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'SelectIdsOperand'
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'SelectOperand'
            field: string
            path?: string | null
            isEvery: boolean
            isNot: boolean
            values?: Array<string> | null
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'TextOperand'
            field: string
            isNot: boolean
            textCondition: TextOperandCondition
            text: string
            id?: string | null
            type: OperandType
          }
      >
    }>
  } | null
}

type Integration_SftpIntegration_Fragment = {
  __typename?: 'SftpIntegration'
  username: string
  id: number
  providerId: IntegrationProviderId
  lastSyncAt?: any | null
  lastErrorAt?: any | null
  lastError?: string | null
  createdAt: any
  updatedAt: any
  fingerprints: { __typename?: 'SshFingerprints'; sha256: string; md5: string }
  rejectPeopleExpression?: {
    __typename?: 'RootExpression'
    conjunction: ExpressionConjunction
    expressions: Array<{
      __typename?: 'Expression'
      conjunction: ExpressionConjunction
      operands: Array<
        | {
            __typename?: 'DateOperand'
            field: string
            dateCondition: DateOperandCondition
            days?: number | null
            date?: any | null
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'IdsOperand'
            isEvery: boolean
            isNot: boolean
            ids: Array<number>
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'ManagersOperand'
            isEvery: boolean
            isNot: boolean
            managesDirectly: boolean
            ids: Array<number>
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'SelectIdsOperand'
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'SelectOperand'
            field: string
            path?: string | null
            isEvery: boolean
            isNot: boolean
            values?: Array<string> | null
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'TextOperand'
            field: string
            isNot: boolean
            textCondition: TextOperandCondition
            text: string
            id?: string | null
            type: OperandType
          }
      >
    }>
  } | null
}

type Integration_NewSftpIntegration_Fragment = {
  __typename?: 'NewSftpIntegration'
  id: number
  providerId: IntegrationProviderId
  lastSyncAt?: any | null
  lastErrorAt?: any | null
  lastError?: string | null
  createdAt: any
  updatedAt: any
  rejectPeopleExpression?: {
    __typename?: 'RootExpression'
    conjunction: ExpressionConjunction
    expressions: Array<{
      __typename?: 'Expression'
      conjunction: ExpressionConjunction
      operands: Array<
        | {
            __typename?: 'DateOperand'
            field: string
            dateCondition: DateOperandCondition
            days?: number | null
            date?: any | null
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'IdsOperand'
            isEvery: boolean
            isNot: boolean
            ids: Array<number>
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'ManagersOperand'
            isEvery: boolean
            isNot: boolean
            managesDirectly: boolean
            ids: Array<number>
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'SelectIdsOperand'
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'SelectOperand'
            field: string
            path?: string | null
            isEvery: boolean
            isNot: boolean
            values?: Array<string> | null
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'TextOperand'
            field: string
            isNot: boolean
            textCondition: TextOperandCondition
            text: string
            id?: string | null
            type: OperandType
          }
      >
    }>
  } | null
}

type Integration_HttpsIntegration_Fragment = {
  __typename?: 'HttpsIntegration'
  id: number
  providerId: IntegrationProviderId
  lastSyncAt?: any | null
  lastErrorAt?: any | null
  lastError?: string | null
  createdAt: any
  updatedAt: any
  rejectPeopleExpression?: {
    __typename?: 'RootExpression'
    conjunction: ExpressionConjunction
    expressions: Array<{
      __typename?: 'Expression'
      conjunction: ExpressionConjunction
      operands: Array<
        | {
            __typename?: 'DateOperand'
            field: string
            dateCondition: DateOperandCondition
            days?: number | null
            date?: any | null
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'IdsOperand'
            isEvery: boolean
            isNot: boolean
            ids: Array<number>
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'ManagersOperand'
            isEvery: boolean
            isNot: boolean
            managesDirectly: boolean
            ids: Array<number>
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'SelectIdsOperand'
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'SelectOperand'
            field: string
            path?: string | null
            isEvery: boolean
            isNot: boolean
            values?: Array<string> | null
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'TextOperand'
            field: string
            isNot: boolean
            textCondition: TextOperandCondition
            text: string
            id?: string | null
            type: OperandType
          }
      >
    }>
  } | null
}

type Integration_MergeDevIntegration_Fragment = {
  __typename?: 'MergeDevIntegration'
  externalId: string
  integrationName: string
  syncHourUtc?: number | null
  id: number
  providerId: IntegrationProviderId
  lastSyncAt?: any | null
  lastErrorAt?: any | null
  lastError?: string | null
  createdAt: any
  updatedAt: any
  rejectPeopleExpression?: {
    __typename?: 'RootExpression'
    conjunction: ExpressionConjunction
    expressions: Array<{
      __typename?: 'Expression'
      conjunction: ExpressionConjunction
      operands: Array<
        | {
            __typename?: 'DateOperand'
            field: string
            dateCondition: DateOperandCondition
            days?: number | null
            date?: any | null
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'IdsOperand'
            isEvery: boolean
            isNot: boolean
            ids: Array<number>
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'ManagersOperand'
            isEvery: boolean
            isNot: boolean
            managesDirectly: boolean
            ids: Array<number>
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'SelectIdsOperand'
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'SelectOperand'
            field: string
            path?: string | null
            isEvery: boolean
            isNot: boolean
            values?: Array<string> | null
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'TextOperand'
            field: string
            isNot: boolean
            textCondition: TextOperandCondition
            text: string
            id?: string | null
            type: OperandType
          }
      >
    }>
  } | null
}

export type IntegrationFragment =
  | Integration_BambooHrIntegration_Fragment
  | Integration_RipplingIntegration_Fragment
  | Integration_SftpIntegration_Fragment
  | Integration_NewSftpIntegration_Fragment
  | Integration_HttpsIntegration_Fragment
  | Integration_MergeDevIntegration_Fragment

type IntegrationDetails_BambooHrIntegration_Fragment = {
  __typename?: 'BambooHRIntegration'
  groups: Array<BambooHrGroupId>
  hostname: string
  subdomain: string
  syncTiming?: BambooHrSyncTiming | null
  userId?: string | null
  id: number
  providerId: IntegrationProviderId
  lastSyncAt?: any | null
  lastErrorAt?: any | null
  lastError?: string | null
  createdAt: any
  updatedAt: any
  rejectPeopleExpression?: {
    __typename?: 'RootExpression'
    conjunction: ExpressionConjunction
    expressions: Array<{
      __typename?: 'Expression'
      conjunction: ExpressionConjunction
      operands: Array<
        | {
            __typename?: 'DateOperand'
            field: string
            dateCondition: DateOperandCondition
            days?: number | null
            date?: any | null
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'IdsOperand'
            isEvery: boolean
            isNot: boolean
            ids: Array<number>
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'ManagersOperand'
            isEvery: boolean
            isNot: boolean
            managesDirectly: boolean
            ids: Array<number>
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'SelectIdsOperand'
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'SelectOperand'
            field: string
            path?: string | null
            isEvery: boolean
            isNot: boolean
            values?: Array<string> | null
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'TextOperand'
            field: string
            isNot: boolean
            textCondition: TextOperandCondition
            text: string
            id?: string | null
            type: OperandType
          }
      >
    }>
  } | null
}

type IntegrationDetails_RipplingIntegration_Fragment = {
  __typename?: 'RipplingIntegration'
  customFieldKeys: Array<string>
  ripplingGroupIds: Array<RipplingGroupId>
  id: number
  providerId: IntegrationProviderId
  lastSyncAt?: any | null
  lastErrorAt?: any | null
  lastError?: string | null
  createdAt: any
  updatedAt: any
  customFields: Array<{
    __typename?: 'IntegrationCustomField'
    key: string
    label: string
    description?: string | null
  }>
  rejectPeopleExpression?: {
    __typename?: 'RootExpression'
    conjunction: ExpressionConjunction
    expressions: Array<{
      __typename?: 'Expression'
      conjunction: ExpressionConjunction
      operands: Array<
        | {
            __typename?: 'DateOperand'
            field: string
            dateCondition: DateOperandCondition
            days?: number | null
            date?: any | null
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'IdsOperand'
            isEvery: boolean
            isNot: boolean
            ids: Array<number>
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'ManagersOperand'
            isEvery: boolean
            isNot: boolean
            managesDirectly: boolean
            ids: Array<number>
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'SelectIdsOperand'
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'SelectOperand'
            field: string
            path?: string | null
            isEvery: boolean
            isNot: boolean
            values?: Array<string> | null
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'TextOperand'
            field: string
            isNot: boolean
            textCondition: TextOperandCondition
            text: string
            id?: string | null
            type: OperandType
          }
      >
    }>
  } | null
}

type IntegrationDetails_SftpIntegration_Fragment = {
  __typename?: 'SftpIntegration'
  username: string
  id: number
  providerId: IntegrationProviderId
  lastSyncAt?: any | null
  lastErrorAt?: any | null
  lastError?: string | null
  createdAt: any
  updatedAt: any
  fingerprints: { __typename?: 'SshFingerprints'; sha256: string; md5: string }
  rejectPeopleExpression?: {
    __typename?: 'RootExpression'
    conjunction: ExpressionConjunction
    expressions: Array<{
      __typename?: 'Expression'
      conjunction: ExpressionConjunction
      operands: Array<
        | {
            __typename?: 'DateOperand'
            field: string
            dateCondition: DateOperandCondition
            days?: number | null
            date?: any | null
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'IdsOperand'
            isEvery: boolean
            isNot: boolean
            ids: Array<number>
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'ManagersOperand'
            isEvery: boolean
            isNot: boolean
            managesDirectly: boolean
            ids: Array<number>
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'SelectIdsOperand'
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'SelectOperand'
            field: string
            path?: string | null
            isEvery: boolean
            isNot: boolean
            values?: Array<string> | null
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'TextOperand'
            field: string
            isNot: boolean
            textCondition: TextOperandCondition
            text: string
            id?: string | null
            type: OperandType
          }
      >
    }>
  } | null
}

type IntegrationDetails_NewSftpIntegration_Fragment = {
  __typename?: 'NewSftpIntegration'
  id: number
  providerId: IntegrationProviderId
  lastSyncAt?: any | null
  lastErrorAt?: any | null
  lastError?: string | null
  createdAt: any
  updatedAt: any
  rejectPeopleExpression?: {
    __typename?: 'RootExpression'
    conjunction: ExpressionConjunction
    expressions: Array<{
      __typename?: 'Expression'
      conjunction: ExpressionConjunction
      operands: Array<
        | {
            __typename?: 'DateOperand'
            field: string
            dateCondition: DateOperandCondition
            days?: number | null
            date?: any | null
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'IdsOperand'
            isEvery: boolean
            isNot: boolean
            ids: Array<number>
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'ManagersOperand'
            isEvery: boolean
            isNot: boolean
            managesDirectly: boolean
            ids: Array<number>
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'SelectIdsOperand'
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'SelectOperand'
            field: string
            path?: string | null
            isEvery: boolean
            isNot: boolean
            values?: Array<string> | null
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'TextOperand'
            field: string
            isNot: boolean
            textCondition: TextOperandCondition
            text: string
            id?: string | null
            type: OperandType
          }
      >
    }>
  } | null
}

type IntegrationDetails_HttpsIntegration_Fragment = {
  __typename?: 'HttpsIntegration'
  id: number
  providerId: IntegrationProviderId
  lastSyncAt?: any | null
  lastErrorAt?: any | null
  lastError?: string | null
  createdAt: any
  updatedAt: any
  rejectPeopleExpression?: {
    __typename?: 'RootExpression'
    conjunction: ExpressionConjunction
    expressions: Array<{
      __typename?: 'Expression'
      conjunction: ExpressionConjunction
      operands: Array<
        | {
            __typename?: 'DateOperand'
            field: string
            dateCondition: DateOperandCondition
            days?: number | null
            date?: any | null
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'IdsOperand'
            isEvery: boolean
            isNot: boolean
            ids: Array<number>
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'ManagersOperand'
            isEvery: boolean
            isNot: boolean
            managesDirectly: boolean
            ids: Array<number>
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'SelectIdsOperand'
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'SelectOperand'
            field: string
            path?: string | null
            isEvery: boolean
            isNot: boolean
            values?: Array<string> | null
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'TextOperand'
            field: string
            isNot: boolean
            textCondition: TextOperandCondition
            text: string
            id?: string | null
            type: OperandType
          }
      >
    }>
  } | null
}

type IntegrationDetails_MergeDevIntegration_Fragment = {
  __typename?: 'MergeDevIntegration'
  externalId: string
  integrationName: string
  syncHourUtc?: number | null
  id: number
  providerId: IntegrationProviderId
  lastSyncAt?: any | null
  lastErrorAt?: any | null
  lastError?: string | null
  createdAt: any
  updatedAt: any
  rejectPeopleExpression?: {
    __typename?: 'RootExpression'
    conjunction: ExpressionConjunction
    expressions: Array<{
      __typename?: 'Expression'
      conjunction: ExpressionConjunction
      operands: Array<
        | {
            __typename?: 'DateOperand'
            field: string
            dateCondition: DateOperandCondition
            days?: number | null
            date?: any | null
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'IdsOperand'
            isEvery: boolean
            isNot: boolean
            ids: Array<number>
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'ManagersOperand'
            isEvery: boolean
            isNot: boolean
            managesDirectly: boolean
            ids: Array<number>
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'SelectIdsOperand'
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'SelectOperand'
            field: string
            path?: string | null
            isEvery: boolean
            isNot: boolean
            values?: Array<string> | null
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'TextOperand'
            field: string
            isNot: boolean
            textCondition: TextOperandCondition
            text: string
            id?: string | null
            type: OperandType
          }
      >
    }>
  } | null
}

export type IntegrationDetailsFragment =
  | IntegrationDetails_BambooHrIntegration_Fragment
  | IntegrationDetails_RipplingIntegration_Fragment
  | IntegrationDetails_SftpIntegration_Fragment
  | IntegrationDetails_NewSftpIntegration_Fragment
  | IntegrationDetails_HttpsIntegration_Fragment
  | IntegrationDetails_MergeDevIntegration_Fragment

export type InvoiceFragment = {
  __typename?: 'Invoice'
  id: number
  termMonths?: number | null
  credits?: number | null
  creditPrice?: number | null
  totalPrice?: number | null
  dueDate?: any | null
  paidAt?: any | null
  capturedAt?: any | null
  stripeChargeId?: string | null
  checkId?: string | null
  lastErrorAt?: any | null
  lastError?: string | null
  createdBy?: number | null
  createdAt?: any | null
  updatedAt?: any | null
}

export type KioskModeSettingsFragment = {
  __typename?: 'KioskModeSettings'
  isEnabled: boolean
  inactivitySeconds: number
  timeoutSeconds: number
  updatedAt?: any | null
}

export type ManagerDigestSettingsFragment = {
  __typename?: 'ManagerDigestSettings'
  isEnabled: boolean
  isDirectReportsOnly: boolean
  daysAfter: number
  date: ManagerDigestDate
  subjectContent: any
  buttonContent: any
  messageContent: any
  updatedAt?: any | null
}

export type ManagerSettingsFragment = {
  __typename?: 'ManagerSettings'
  allowNotificationsToManagers: boolean
  allowNotificationsToReports: boolean
  organizationId: number
  updatedAt?: any | null
}

export type OrganizationFragment = {
  __typename?: 'Organization'
  id: number
  name: string
  subdomain: any
  origin: any
  blissbookOrigin: any
  isFullServe: boolean
  isCrmDisabled: boolean
  isIntercomDisabled: boolean
  isAuthDisabled: boolean
  isHandbookPositionEnabled: boolean
  showPrivacyPolicy: boolean
  createdAt: any
  createdBy?: number | null
  updatedAt: any
  emailPeopleCount: number
  employeeIdPeopleCount: number
  friendsCount: number
  lastHiredOnPeopleCount: number
  managersCount: number
  membersCount: number
  peopleCount: number
  phonePeopleCount: number
  publishedAt?: any | null
  logoImage?: {
    __typename?: 'Image'
    width?: number | null
    height?: number | null
    id: number
    type: AssetType
    tag: string
    filename: string
    filesize: number
    path: string
    thumbnailUrl?: string | null
    url: string
    mimeType?: string | null
    archived: boolean
    createdAt: any
  } | null
}

export type OrganizationQueryFragment = {
  __typename?: 'Query'
  featureFlagIds: Array<string>
  metadataKeys: Array<string>
  audienceDashboardSettings: {
    __typename?: 'AudienceDashboardSettings'
    primaryColor: any
    accentColor: any
    updatedAt?: any | null
    createdAt?: any | null
    faviconImage?: {
      __typename?: 'Image'
      width?: number | null
      height?: number | null
      id: number
      type: AssetType
      tag: string
      filename: string
      filesize: number
      path: string
      thumbnailUrl?: string | null
      url: string
      mimeType?: string | null
      archived: boolean
      createdAt: any
    } | null
    logoImage?: {
      __typename?: 'Image'
      width?: number | null
      height?: number | null
      id: number
      type: AssetType
      tag: string
      filename: string
      filesize: number
      path: string
      thumbnailUrl?: string | null
      url: string
      mimeType?: string | null
      archived: boolean
      createdAt: any
    } | null
    bannerImage?: {
      __typename?: 'Image'
      width?: number | null
      height?: number | null
      id: number
      type: AssetType
      tag: string
      filename: string
      filesize: number
      path: string
      thumbnailUrl?: string | null
      url: string
      mimeType?: string | null
      archived: boolean
      createdAt: any
    } | null
  }
  billing?: {
    __typename?: 'Billing'
    name?: string | null
    contactName?: string | null
    address?: string | null
    toEmails: Array<any>
    credits?: number | null
    peopleCount: number
    exempt: boolean
    monthlyGrowthRate?: number | null
    monthlyCreditPrice?: number | null
    yearlyCreditPrice?: number | null
    termStartCount?: number | null
    termMonths?: number | null
    termMonthlyGrowthRate?: number | null
    paymentMethod?: string | null
    paymentDays?: number | null
    expiredAt?: any | null
    canceledAt?: any | null
    terminatedAt?: any | null
    emailSettings: {
      __typename?: 'BillingEmailSettings'
      toAdmins: boolean
      toEmails: Array<any>
    }
  } | null
  chargebeeSubscription?: {
    __typename?: 'ChargebeeSubscription'
    status: string
    lastSyncAt?: any | null
  } | null
  customDomain?: {
    __typename?: 'CustomDomain'
    domain: any
    domainTarget: any
    isAutoRenew: boolean
    isDnsValid?: boolean | null
    dnsLastValidAt?: any | null
    certificateDigest?: string | null
    certificates: Array<{
      __typename?: 'CustomDomainCertificate'
      digest: string
      expiresAt: any
      createdAt: any
    }>
  } | null
  emailDomains: Array<{
    __typename?: 'EmailDomain'
    id: number
    domain: any
    environment: string
    fromEmail: any
    fromName: string
    updatedAt: any
    createdAt: any
    dkimStatus: {
      __typename?: 'EmailDomainDkimStatus'
      verified: boolean
      lastVerifiedAt?: any | null
    }
    returnPathStatus: {
      __typename?: 'EmailDomainReturnPathStatus'
      verified: boolean
      lastVerifiedAt?: any | null
    }
  }>
  emailSettings: {
    __typename?: 'EmailSettings'
    autoSendInvitations?: boolean | null
    fromEmail?: any | null
    fromName?: string | null
    replyToEmail?: any | null
    defaultSenderFromName?: string | null
    defaultSenderReplyToEmail?: any | null
    bodyFont: string
    footerText: string
    primaryColor: any
    hasCustomBranding: boolean
    updatedAt?: any | null
    bannerImage?: {
      __typename?: 'Image'
      width?: number | null
      height?: number | null
      id: number
      type: AssetType
      tag: string
      filename: string
      filesize: number
      path: string
      thumbnailUrl?: string | null
      url: string
      mimeType?: string | null
      archived: boolean
      createdAt: any
    } | null
    footerLinks: Array<{
      __typename?: 'EmailFooterLink'
      text: string
      url: any
    }>
    logoImage?: {
      __typename?: 'Image'
      width?: number | null
      height?: number | null
      id: number
      type: AssetType
      tag: string
      filename: string
      filesize: number
      path: string
      thumbnailUrl?: string | null
      url: string
      mimeType?: string | null
      archived: boolean
      createdAt: any
    } | null
  }
  emailTemplates: Array<{
    __typename?: 'EmailTemplate'
    id: EmailTemplateId
    subjectContent: any
    ccManagers: boolean
    buttonContent: any
    messageContent: any
    createdAt?: any | null
    updatedAt?: any | null
  }>
  groups: Array<{
    __typename?: 'Group'
    id: number
    name: string
    archived: boolean
    createdAt?: any | null
    updatedAt?: any | null
    peopleCount: number
  }>
  handbooks: Array<{
    __typename?: 'Handbook'
    teamId: number
    id: number
    name: string
    defaultLanguageCode: string
    customDomain?: any | null
    customDomainOrigin?: any | null
    isPrintDisabled: boolean
    position: number
    public: boolean
    publicToken: string
    version: number
    createdAt?: any | null
    updatedAt?: any | null
    lastModifiedAt?: any | null
    lastModifiedBy?: number | null
    publishedAt?: any | null
    lastPublishedAt?: any | null
    url: any
    publishedUrl: any
    permissionIds: Array<string>
    contentLength: number
    deletePreviewLinks: boolean
    hasPersonalizedContent: boolean
    hasAudience: boolean
    viewersCount: number
    lastPublishedByPerson?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    branding: {
      __typename?: 'HandbookBranding'
      blackColor: any
      favicon?: any | null
      handbookCss?: string | null
      lessButtonText: string
      moreButtonText: string
      primaryColor: any
      printCss?: string | null
      customHtml?: string | null
      whiteColor: any
      bodyFont: {
        __typename?: 'HandbookBrandingFont'
        type: HandbookBrandingFontType
        fontId?: number | null
        family?: string | null
        bold?: number | null
        normal?: number | null
        variants?: Array<string> | null
      }
      headingFont: {
        __typename?: 'HandbookBrandingFont'
        type: HandbookBrandingFontType
        fontId?: number | null
        family?: string | null
        bold?: number | null
        normal?: number | null
        variants?: Array<string> | null
      }
      scriptFont: {
        __typename?: 'HandbookBrandingFont'
        type: HandbookBrandingFontType
        fontId?: number | null
        family?: string | null
        bold?: number | null
        normal?: number | null
        variants?: Array<string> | null
      }
    }
    previewLinks: Array<{
      __typename?: 'HandbookPreviewLink'
      handbookId: number
      personId?: number | null
      token: string
      previewUrl: any
      createdAt: any
    }>
    signatureRounds: Array<{
      __typename?: 'HandbookSignatureRound'
      id: number
      number: number
      onlyAtLaunch?: HandbookSignatureRoundOnlyAtLaunch | null
      onlyMembers: boolean
      dueDays?: number | null
      dueDaysFuture?: number | null
      archived: boolean
      active: boolean
      createdAt: any
      updatedAt: any
      publishedAt?: any | null
      peopleCount: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
    statistics: {
      __typename?: 'HandbookStatistics'
      peopleCount: number
      canNotifyPeopleCount: number
      canNotifyNonCompliantPeopleCount: number
      nonCompliantPeopleCount: number
      notifiedPeopleCount: number
      maxNotifiedPeopleCount: number
      viewedPeopleCount: number
      maxViewedPeopleCount: number
      signedPeopleCount: number
      maxSignedPeopleCount: number
      validSignedPeopleCount: number
      maxValidSignedPeopleCount: number
      score?: number | null
    }
    tasks: Array<{
      __typename?: 'HandbookTask'
      id: HandbookTaskId
      completedAt?: any | null
      completedBy?: number | null
    }>
    versions: Array<{
      __typename?: 'HandbookVersionSummary'
      createdAt?: any | null
      createdBy?: number | null
      defaultLanguageCode: string
      handbookId: number
      label?: string | null
      languageCodes: Array<string>
      printUrl: any
      url: any
      version: number
      createdByPerson?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
    audience: {
      __typename?: 'HandbookAudience'
      member: boolean
      groupIds: Array<number>
      personIds: Array<number>
      savedSegmentIds: Array<number>
    }
  }>
  handbookReminders: Array<{
    __typename?: 'HandbookReminder'
    id: number
    isEnabled: boolean
    days: number
    date: HandbookReminderDate
    cc: Array<any>
    ccManagers: boolean
    subjectContent: any
    buttonContent: any
    messageContent: any
    createdAt?: any | null
    createdBy?: number | null
    updatedAt?: any | null
    updatedBy?: number | null
  }>
  integrations: Array<
    | {
        __typename?: 'BambooHRIntegration'
        groups: Array<BambooHrGroupId>
        hostname: string
        subdomain: string
        syncTiming?: BambooHrSyncTiming | null
        userId?: string | null
        id: number
        providerId: IntegrationProviderId
        lastSyncAt?: any | null
        lastErrorAt?: any | null
        lastError?: string | null
        createdAt: any
        updatedAt: any
        rejectPeopleExpression?: {
          __typename?: 'RootExpression'
          conjunction: ExpressionConjunction
          expressions: Array<{
            __typename?: 'Expression'
            conjunction: ExpressionConjunction
            operands: Array<
              | {
                  __typename?: 'DateOperand'
                  field: string
                  dateCondition: DateOperandCondition
                  days?: number | null
                  date?: any | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'IdsOperand'
                  isEvery: boolean
                  isNot: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'ManagersOperand'
                  isEvery: boolean
                  isNot: boolean
                  managesDirectly: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectIdsOperand'
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectOperand'
                  field: string
                  path?: string | null
                  isEvery: boolean
                  isNot: boolean
                  values?: Array<string> | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'TextOperand'
                  field: string
                  isNot: boolean
                  textCondition: TextOperandCondition
                  text: string
                  id?: string | null
                  type: OperandType
                }
            >
          }>
        } | null
      }
    | {
        __typename?: 'RipplingIntegration'
        customFieldKeys: Array<string>
        ripplingGroupIds: Array<RipplingGroupId>
        id: number
        providerId: IntegrationProviderId
        lastSyncAt?: any | null
        lastErrorAt?: any | null
        lastError?: string | null
        createdAt: any
        updatedAt: any
        rejectPeopleExpression?: {
          __typename?: 'RootExpression'
          conjunction: ExpressionConjunction
          expressions: Array<{
            __typename?: 'Expression'
            conjunction: ExpressionConjunction
            operands: Array<
              | {
                  __typename?: 'DateOperand'
                  field: string
                  dateCondition: DateOperandCondition
                  days?: number | null
                  date?: any | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'IdsOperand'
                  isEvery: boolean
                  isNot: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'ManagersOperand'
                  isEvery: boolean
                  isNot: boolean
                  managesDirectly: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectIdsOperand'
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectOperand'
                  field: string
                  path?: string | null
                  isEvery: boolean
                  isNot: boolean
                  values?: Array<string> | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'TextOperand'
                  field: string
                  isNot: boolean
                  textCondition: TextOperandCondition
                  text: string
                  id?: string | null
                  type: OperandType
                }
            >
          }>
        } | null
      }
    | {
        __typename?: 'SftpIntegration'
        username: string
        id: number
        providerId: IntegrationProviderId
        lastSyncAt?: any | null
        lastErrorAt?: any | null
        lastError?: string | null
        createdAt: any
        updatedAt: any
        fingerprints: {
          __typename?: 'SshFingerprints'
          sha256: string
          md5: string
        }
        rejectPeopleExpression?: {
          __typename?: 'RootExpression'
          conjunction: ExpressionConjunction
          expressions: Array<{
            __typename?: 'Expression'
            conjunction: ExpressionConjunction
            operands: Array<
              | {
                  __typename?: 'DateOperand'
                  field: string
                  dateCondition: DateOperandCondition
                  days?: number | null
                  date?: any | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'IdsOperand'
                  isEvery: boolean
                  isNot: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'ManagersOperand'
                  isEvery: boolean
                  isNot: boolean
                  managesDirectly: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectIdsOperand'
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectOperand'
                  field: string
                  path?: string | null
                  isEvery: boolean
                  isNot: boolean
                  values?: Array<string> | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'TextOperand'
                  field: string
                  isNot: boolean
                  textCondition: TextOperandCondition
                  text: string
                  id?: string | null
                  type: OperandType
                }
            >
          }>
        } | null
      }
    | {
        __typename?: 'NewSftpIntegration'
        id: number
        providerId: IntegrationProviderId
        lastSyncAt?: any | null
        lastErrorAt?: any | null
        lastError?: string | null
        createdAt: any
        updatedAt: any
        rejectPeopleExpression?: {
          __typename?: 'RootExpression'
          conjunction: ExpressionConjunction
          expressions: Array<{
            __typename?: 'Expression'
            conjunction: ExpressionConjunction
            operands: Array<
              | {
                  __typename?: 'DateOperand'
                  field: string
                  dateCondition: DateOperandCondition
                  days?: number | null
                  date?: any | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'IdsOperand'
                  isEvery: boolean
                  isNot: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'ManagersOperand'
                  isEvery: boolean
                  isNot: boolean
                  managesDirectly: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectIdsOperand'
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectOperand'
                  field: string
                  path?: string | null
                  isEvery: boolean
                  isNot: boolean
                  values?: Array<string> | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'TextOperand'
                  field: string
                  isNot: boolean
                  textCondition: TextOperandCondition
                  text: string
                  id?: string | null
                  type: OperandType
                }
            >
          }>
        } | null
      }
    | {
        __typename?: 'HttpsIntegration'
        id: number
        providerId: IntegrationProviderId
        lastSyncAt?: any | null
        lastErrorAt?: any | null
        lastError?: string | null
        createdAt: any
        updatedAt: any
        rejectPeopleExpression?: {
          __typename?: 'RootExpression'
          conjunction: ExpressionConjunction
          expressions: Array<{
            __typename?: 'Expression'
            conjunction: ExpressionConjunction
            operands: Array<
              | {
                  __typename?: 'DateOperand'
                  field: string
                  dateCondition: DateOperandCondition
                  days?: number | null
                  date?: any | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'IdsOperand'
                  isEvery: boolean
                  isNot: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'ManagersOperand'
                  isEvery: boolean
                  isNot: boolean
                  managesDirectly: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectIdsOperand'
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectOperand'
                  field: string
                  path?: string | null
                  isEvery: boolean
                  isNot: boolean
                  values?: Array<string> | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'TextOperand'
                  field: string
                  isNot: boolean
                  textCondition: TextOperandCondition
                  text: string
                  id?: string | null
                  type: OperandType
                }
            >
          }>
        } | null
      }
    | {
        __typename?: 'MergeDevIntegration'
        externalId: string
        integrationName: string
        syncHourUtc?: number | null
        id: number
        providerId: IntegrationProviderId
        lastSyncAt?: any | null
        lastErrorAt?: any | null
        lastError?: string | null
        createdAt: any
        updatedAt: any
        rejectPeopleExpression?: {
          __typename?: 'RootExpression'
          conjunction: ExpressionConjunction
          expressions: Array<{
            __typename?: 'Expression'
            conjunction: ExpressionConjunction
            operands: Array<
              | {
                  __typename?: 'DateOperand'
                  field: string
                  dateCondition: DateOperandCondition
                  days?: number | null
                  date?: any | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'IdsOperand'
                  isEvery: boolean
                  isNot: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'ManagersOperand'
                  isEvery: boolean
                  isNot: boolean
                  managesDirectly: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectIdsOperand'
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectOperand'
                  field: string
                  path?: string | null
                  isEvery: boolean
                  isNot: boolean
                  values?: Array<string> | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'TextOperand'
                  field: string
                  isNot: boolean
                  textCondition: TextOperandCondition
                  text: string
                  id?: string | null
                  type: OperandType
                }
            >
          }>
        } | null
      }
  >
  managerDigestSettings: {
    __typename?: 'ManagerDigestSettings'
    isEnabled: boolean
    isDirectReportsOnly: boolean
    daysAfter: number
    date: ManagerDigestDate
    subjectContent: any
    buttonContent: any
    messageContent: any
    updatedAt?: any | null
  }
  organization: {
    __typename?: 'Organization'
    id: number
    name: string
    subdomain: any
    origin: any
    blissbookOrigin: any
    isFullServe: boolean
    isCrmDisabled: boolean
    isIntercomDisabled: boolean
    isAuthDisabled: boolean
    isHandbookPositionEnabled: boolean
    showPrivacyPolicy: boolean
    createdAt: any
    createdBy?: number | null
    updatedAt: any
    emailPeopleCount: number
    employeeIdPeopleCount: number
    friendsCount: number
    lastHiredOnPeopleCount: number
    managersCount: number
    membersCount: number
    peopleCount: number
    phonePeopleCount: number
    publishedAt?: any | null
    logoImage?: {
      __typename?: 'Image'
      width?: number | null
      height?: number | null
      id: number
      type: AssetType
      tag: string
      filename: string
      filesize: number
      path: string
      thumbnailUrl?: string | null
      url: string
      mimeType?: string | null
      archived: boolean
      createdAt: any
    } | null
  }
  organizationTasks: Array<{
    __typename?: 'OrganizationTask'
    id: OrganizationTaskId
    completedAt?: any | null
    completedBy?: number | null
  }>
  securitySettings: {
    __typename?: 'SecuritySettings'
    emailAuth: {
      __typename?: 'EmailAuthSettings'
      isEnabled: boolean
      updatedAt?: any | null
    }
    kioskMode: {
      __typename?: 'KioskModeSettings'
      isEnabled: boolean
      inactivitySeconds: number
      timeoutSeconds: number
      updatedAt?: any | null
    }
    phoneAuth: {
      __typename?: 'PhoneAuthSettings'
      isEnabled: boolean
      helpContent?: any | null
      updatedAt?: any | null
    }
    singleSignOns: Array<{
      __typename?: 'SingleSignOn'
      providerId: SingleSignOnProviderId
      issuer?: any | null
      loginUrl?: any | null
      authKey?: SingleSignOnAuthKey | null
      buttonText?: string | null
      createdAt: any
      updatedAt: any
      certificates: Array<{
        __typename?: 'SingleSignOnCertificate'
        digest: string
        createdAt: any
        archivedAt?: any | null
      }>
    }>
  }
  teams: Array<{
    __typename?: 'Team'
    id: number
    permissionIds: Array<string>
    createdAt: any
    updatedAt: any
    handbookCollaborators: Array<{
      __typename?: 'HandbookCollaborator'
      id: number
      handbookId: number
      personId: number
      roleId?: HandbookCollaboratorRoleId | null
    }>
    teammates: Array<{
      __typename?: 'Teammate'
      id: number
      teamId: number
      personId: number
      canReport: boolean
      canSuperBrand: boolean
      roleId?: TeamRoleId | null
      person: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      }
    }>
  }>
}

export type OrganizationTaskFragment = {
  __typename?: 'OrganizationTask'
  id: OrganizationTaskId
  completedAt?: any | null
  completedBy?: number | null
}

export type HrisPersonFragment = {
  __typename?: 'HrisPerson'
  email?: any | null
  externalId?: string | null
  employeeId?: string | null
  phone?: any | null
  fullName: string
  lastHiredOn?: any | null
  groupNames?: Array<string> | null
  metadata?: any | null
  sortKey: string
}

export type PersonFragment = {
  __typename?: 'Person'
  id: number
  email?: any | null
  externalId?: string | null
  employeeId?: string | null
  phone?: any | null
  fullName: string
  archived: boolean
  archivedAt?: any | null
  member: boolean
  adminRoleId?: AdminRoleId | null
  isSuperUser: boolean
  lastHiredOn?: any | null
  avatarUrl?: string | null
  lastActivatedAt: any
  isSignatureRequired: boolean
  isEmployeeSignatureRequired: boolean
  totalDirectReportsCount: number
  nonCompliantDirectReportsCount: number
  nonCompliantDirectReportsPercentage: number
  totalReportsCount: number
  nonCompliantReportsCount: number
  nonCompliantReportsPercentage: number
  lastEmployeeDigestQueuedAt?: any | null
  lastManagerDigestQueuedAt?: any | null
  lastNotifiedAt?: any | null
  lastViewedAt?: any | null
  lastSignedAt?: any | null
  autoSendReminders: boolean
  isManager: boolean
  createdAt: any
  updatedAt: any
  groupIds: Array<number>
  handbookIds: Array<number>
  savedSegmentIds: Array<number>
  metadata?: any | null
  userIds: Array<number>
  sortKey?: string | null
  managers: Array<{
    __typename?: 'PersonManager'
    id: number
    email?: any | null
    employeeId?: string | null
    fullName: string
  }>
}

export type PersonalizationFragment = {
  __typename?: 'Personalization'
  groupIds: Array<number>
  metadata?: any | null
  personId: number
  savedSegmentIds: Array<number>
}

export type PhoneAuthSettingsFragment = {
  __typename?: 'PhoneAuthSettings'
  isEnabled: boolean
  helpContent?: any | null
  updatedAt?: any | null
}

export type PreferencesFragment = {
  __typename?: 'Preferences'
  documentationHandbooksSort?: HandbooksSort | null
  handbooksSort?: HandbooksSort | null
  handbooksView?: HandbooksView | null
  hideProTips: Array<string>
}

export type PublishHandbookNotificationFragment = {
  __typename?: 'PublishHandbookNotification'
  toViewers?: boolean | null
  signers?: {
    __typename?: 'Notification'
    subjectContent: any
    buttonContent: any
    messageContent: any
  } | null
  viewers?: {
    __typename?: 'Notification'
    subjectContent: any
    buttonContent: any
    messageContent: any
  } | null
}

type SavedReport_DocumentSavedReport_Fragment = {
  __typename?: 'DocumentSavedReport'
  handbookId: number
  id: number
  type: SavedReportType
  name: string
  description?: string | null
  showArchived: boolean
  createdAt: any
  updatedAt: any
  archivedAt?: any | null
  expression: {
    __typename?: 'RootExpression'
    conjunction: ExpressionConjunction
    expressions: Array<{
      __typename?: 'Expression'
      conjunction: ExpressionConjunction
      operands: Array<
        | {
            __typename?: 'DateOperand'
            field: string
            dateCondition: DateOperandCondition
            days?: number | null
            date?: any | null
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'IdsOperand'
            isEvery: boolean
            isNot: boolean
            ids: Array<number>
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'ManagersOperand'
            isEvery: boolean
            isNot: boolean
            managesDirectly: boolean
            ids: Array<number>
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'SelectIdsOperand'
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'SelectOperand'
            field: string
            path?: string | null
            isEvery: boolean
            isNot: boolean
            values?: Array<string> | null
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'TextOperand'
            field: string
            isNot: boolean
            textCondition: TextOperandCondition
            text: string
            id?: string | null
            type: OperandType
          }
      >
    }>
  }
  columnKeys: Array<{
    __typename?: 'SavedReportColumnKey'
    field: string
    path?: string | null
  }>
  order: { __typename?: 'SortOrder'; field: string; direction: SortDirection }
  createdBy?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
  updatedBy?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
}

type SavedReport_PeopleSavedReport_Fragment = {
  __typename?: 'PeopleSavedReport'
  id: number
  type: SavedReportType
  name: string
  description?: string | null
  showArchived: boolean
  createdAt: any
  updatedAt: any
  archivedAt?: any | null
  expression: {
    __typename?: 'RootExpression'
    conjunction: ExpressionConjunction
    expressions: Array<{
      __typename?: 'Expression'
      conjunction: ExpressionConjunction
      operands: Array<
        | {
            __typename?: 'DateOperand'
            field: string
            dateCondition: DateOperandCondition
            days?: number | null
            date?: any | null
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'IdsOperand'
            isEvery: boolean
            isNot: boolean
            ids: Array<number>
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'ManagersOperand'
            isEvery: boolean
            isNot: boolean
            managesDirectly: boolean
            ids: Array<number>
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'SelectIdsOperand'
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'SelectOperand'
            field: string
            path?: string | null
            isEvery: boolean
            isNot: boolean
            values?: Array<string> | null
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'TextOperand'
            field: string
            isNot: boolean
            textCondition: TextOperandCondition
            text: string
            id?: string | null
            type: OperandType
          }
      >
    }>
  }
  columnKeys: Array<{
    __typename?: 'SavedReportColumnKey'
    field: string
    path?: string | null
  }>
  order: { __typename?: 'SortOrder'; field: string; direction: SortDirection }
  createdBy?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
  updatedBy?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
}

export type SavedReportFragment =
  | SavedReport_DocumentSavedReport_Fragment
  | SavedReport_PeopleSavedReport_Fragment

export type SavedSegmentFragment = {
  __typename?: 'SavedSegment'
  id: number
  name: string
  description?: string | null
  peopleCount: number
  createdAt: any
  updatedAt: any
  archivedAt?: any | null
  expression: {
    __typename?: 'RootExpression'
    conjunction: ExpressionConjunction
    expressions: Array<{
      __typename?: 'Expression'
      conjunction: ExpressionConjunction
      operands: Array<
        | {
            __typename?: 'DateOperand'
            field: string
            dateCondition: DateOperandCondition
            days?: number | null
            date?: any | null
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'IdsOperand'
            isEvery: boolean
            isNot: boolean
            ids: Array<number>
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'ManagersOperand'
            isEvery: boolean
            isNot: boolean
            managesDirectly: boolean
            ids: Array<number>
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'SelectIdsOperand'
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'SelectOperand'
            field: string
            path?: string | null
            isEvery: boolean
            isNot: boolean
            values?: Array<string> | null
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'TextOperand'
            field: string
            isNot: boolean
            textCondition: TextOperandCondition
            text: string
            id?: string | null
            type: OperandType
          }
      >
    }>
  }
  createdBy?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
  updatedBy?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
}

export type SecuritySettingsFragment = {
  __typename?: 'SecuritySettings'
  emailAuth: {
    __typename?: 'EmailAuthSettings'
    isEnabled: boolean
    updatedAt?: any | null
  }
  kioskMode: {
    __typename?: 'KioskModeSettings'
    isEnabled: boolean
    inactivitySeconds: number
    timeoutSeconds: number
    updatedAt?: any | null
  }
  phoneAuth: {
    __typename?: 'PhoneAuthSettings'
    isEnabled: boolean
    helpContent?: any | null
    updatedAt?: any | null
  }
  singleSignOns: Array<{
    __typename?: 'SingleSignOn'
    providerId: SingleSignOnProviderId
    issuer?: any | null
    loginUrl?: any | null
    authKey?: SingleSignOnAuthKey | null
    buttonText?: string | null
    createdAt: any
    updatedAt: any
    certificates: Array<{
      __typename?: 'SingleSignOnCertificate'
      digest: string
      createdAt: any
      archivedAt?: any | null
    }>
  }>
}

export type SingleSignOnFragment = {
  __typename?: 'SingleSignOn'
  providerId: SingleSignOnProviderId
  issuer?: any | null
  loginUrl?: any | null
  authKey?: SingleSignOnAuthKey | null
  buttonText?: string | null
  createdAt: any
  updatedAt: any
  certificates: Array<{
    __typename?: 'SingleSignOnCertificate'
    digest: string
    createdAt: any
    archivedAt?: any | null
  }>
}

export type DocumentSettingsFragment = {
  __typename?: 'DocumentSettings'
  isPolicyWorkflowEnabled: boolean
  organizationId: number
}

export type DocumentFragment = {
  __typename?: 'Document'
  id: number
  ckeditorChannelId: string
  parentFolderId?: number | null
  sourceHandbookId?: number | null
  sourceHandbookSectionId?: number | null
  sortPosition: number
  type: DocumentType
  name: string
  archivedAt?: any | null
  createdAt: any
  updatedAt: any
  handbookIds: Array<number>
  lastPublishedVersionNumber: number
  archivedBy?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
  createdBy?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
  updatedBy?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
  draftVersion?: {
    __typename?: 'DocumentVersion'
    audienceExpressionHash: any
    createdAt: any
    documentId: number
    html?: any | null
    htmlHash: any
    htmlTitle?: string | null
    id: number
    isPublished: boolean
    updatedAt: any
    versionNumber: number
    audienceExpression?: {
      __typename?: 'RootExpression'
      conjunction: ExpressionConjunction
      expressions: Array<{
        __typename?: 'Expression'
        conjunction: ExpressionConjunction
        operands: Array<
          | {
              __typename?: 'DateOperand'
              field: string
              dateCondition: DateOperandCondition
              days?: number | null
              date?: any | null
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'IdsOperand'
              isEvery: boolean
              isNot: boolean
              ids: Array<number>
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'ManagersOperand'
              isEvery: boolean
              isNot: boolean
              managesDirectly: boolean
              ids: Array<number>
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'SelectIdsOperand'
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'SelectOperand'
              field: string
              path?: string | null
              isEvery: boolean
              isNot: boolean
              values?: Array<string> | null
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'TextOperand'
              field: string
              isNot: boolean
              textCondition: TextOperandCondition
              text: string
              id?: string | null
              type: OperandType
            }
        >
      }>
    } | null
    createdBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    reviews: Array<{
      __typename?: 'DocumentReview'
      id: number
      documentId: number
      personId: number
      isApproved: boolean
      createdAt: any
      person: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      }
    }>
    updatedBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
  } | null
  lastPublishedVersion?: {
    __typename?: 'DocumentVersion'
    audienceExpressionHash: any
    createdAt: any
    documentId: number
    html?: any | null
    htmlHash: any
    htmlTitle?: string | null
    id: number
    isPublished: boolean
    updatedAt: any
    versionNumber: number
    audienceExpression?: {
      __typename?: 'RootExpression'
      conjunction: ExpressionConjunction
      expressions: Array<{
        __typename?: 'Expression'
        conjunction: ExpressionConjunction
        operands: Array<
          | {
              __typename?: 'DateOperand'
              field: string
              dateCondition: DateOperandCondition
              days?: number | null
              date?: any | null
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'IdsOperand'
              isEvery: boolean
              isNot: boolean
              ids: Array<number>
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'ManagersOperand'
              isEvery: boolean
              isNot: boolean
              managesDirectly: boolean
              ids: Array<number>
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'SelectIdsOperand'
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'SelectOperand'
              field: string
              path?: string | null
              isEvery: boolean
              isNot: boolean
              values?: Array<string> | null
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'TextOperand'
              field: string
              isNot: boolean
              textCondition: TextOperandCondition
              text: string
              id?: string | null
              type: OperandType
            }
        >
      }>
    } | null
    createdBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    reviews: Array<{
      __typename?: 'DocumentReview'
      id: number
      documentId: number
      personId: number
      isApproved: boolean
      createdAt: any
      person: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      }
    }>
    updatedBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
  } | null
  reviewers: Array<{
    __typename?: 'DocumentReviewer'
    id: number
    documentId: number
    personId: number
    createdAt: any
    updatedAt: any
    person: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    }
    createdBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    updatedBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
  }>
}

export type PolicyLibraryDocumentFragment = {
  __typename?: 'Document'
  id: number
  parentFolderId?: number | null
  name: string
  archivedAt?: any | null
  lastPublishedVersionNumber: number
  sortPosition: number
  createdAt: any
  updatedAt: any
  sortKey?: string | null
  parentFolder?: {
    __typename?: 'Folder'
    id: number
    parentFolderId?: number | null
    name: string
  } | null
  createdBy?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
  updatedBy?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
  draftVersion?: {
    __typename?: 'DocumentVersion'
    id: number
    versionNumber: number
    audienceExpression?: {
      __typename?: 'RootExpression'
      conjunction: ExpressionConjunction
      expressions: Array<{
        __typename?: 'Expression'
        conjunction: ExpressionConjunction
        operands: Array<
          | {
              __typename?: 'DateOperand'
              field: string
              dateCondition: DateOperandCondition
              days?: number | null
              date?: any | null
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'IdsOperand'
              isEvery: boolean
              isNot: boolean
              ids: Array<number>
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'ManagersOperand'
              isEvery: boolean
              isNot: boolean
              managesDirectly: boolean
              ids: Array<number>
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'SelectIdsOperand'
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'SelectOperand'
              field: string
              path?: string | null
              isEvery: boolean
              isNot: boolean
              values?: Array<string> | null
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'TextOperand'
              field: string
              isNot: boolean
              textCondition: TextOperandCondition
              text: string
              id?: string | null
              type: OperandType
            }
        >
      }>
    } | null
    reviews: Array<{
      __typename?: 'DocumentReview'
      id: number
      documentId: number
      personId: number
      isApproved: boolean
      createdAt: any
      person: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      }
    }>
  } | null
  lastPublishedVersion?: {
    __typename?: 'DocumentVersion'
    id: number
    createdAt: any
    versionNumber: number
    audienceExpression?: {
      __typename?: 'RootExpression'
      conjunction: ExpressionConjunction
      expressions: Array<{
        __typename?: 'Expression'
        conjunction: ExpressionConjunction
        operands: Array<
          | {
              __typename?: 'DateOperand'
              field: string
              dateCondition: DateOperandCondition
              days?: number | null
              date?: any | null
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'IdsOperand'
              isEvery: boolean
              isNot: boolean
              ids: Array<number>
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'ManagersOperand'
              isEvery: boolean
              isNot: boolean
              managesDirectly: boolean
              ids: Array<number>
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'SelectIdsOperand'
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'SelectOperand'
              field: string
              path?: string | null
              isEvery: boolean
              isNot: boolean
              values?: Array<string> | null
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'TextOperand'
              field: string
              isNot: boolean
              textCondition: TextOperandCondition
              text: string
              id?: string | null
              type: OperandType
            }
        >
      }>
    } | null
    createdBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
  } | null
  reviewers: Array<{
    __typename?: 'DocumentReviewer'
    id: number
    documentId: number
    personId: number
    createdAt: any
    updatedAt: any
    person: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    }
    createdBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    updatedBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
  }>
}

export type HandbookEditorDocumentFragment = {
  __typename?: 'Query'
  documents: Array<{
    __typename?: 'Document'
    id: number
    type: DocumentType
    name: string
    archivedAt?: any | null
    lastPublishedVersionNumber: number
    parentFolderId?: number | null
    sourceHandbookId?: number | null
    sourceHandbookSectionId?: number | null
    draftVersion?: {
      __typename?: 'DocumentVersion'
      versionNumber: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
    } | null
    lastPublishedVersion?: {
      __typename?: 'DocumentVersion'
      versionNumber: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
    } | null
  }>
  folders: Array<{
    __typename?: 'Folder'
    id: number
    parentFolderId?: number | null
    name: string
  }>
}

type DocumentGroup_FolderDocumentGroup_Fragment = {
  __typename?: 'FolderDocumentGroup'
  field: DocumentsGroupByField
  documentsCount: number
  folder?: {
    __typename?: 'Folder'
    id: number
    parentFolderId?: number | null
    name: string
  } | null
}

type DocumentGroup_PersonDocumentGroup_Fragment = {
  __typename?: 'PersonDocumentGroup'
  field: DocumentsGroupByField
  documentsCount: number
  person?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
}

type DocumentGroup_OrganizationDocumentGroup_Fragment = {
  __typename?: 'OrganizationDocumentGroup'
  field: DocumentsGroupByField
  documentsCount: number
}

export type DocumentGroupFragment =
  | DocumentGroup_FolderDocumentGroup_Fragment
  | DocumentGroup_PersonDocumentGroup_Fragment
  | DocumentGroup_OrganizationDocumentGroup_Fragment

export type DocumentReviewFragment = {
  __typename?: 'DocumentReview'
  id: number
  documentId: number
  personId: number
  isApproved: boolean
  createdAt: any
  person: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  }
}

export type DocumentReviewerFragment = {
  __typename?: 'DocumentReviewer'
  id: number
  documentId: number
  personId: number
  createdAt: any
  updatedAt: any
  person: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  }
  createdBy?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
  updatedBy?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
}

export type DocumentVersionFragment = {
  __typename?: 'DocumentVersion'
  audienceExpressionHash: any
  createdAt: any
  documentId: number
  html?: any | null
  htmlHash: any
  htmlTitle?: string | null
  id: number
  isPublished: boolean
  updatedAt: any
  versionNumber: number
  audienceExpression?: {
    __typename?: 'RootExpression'
    conjunction: ExpressionConjunction
    expressions: Array<{
      __typename?: 'Expression'
      conjunction: ExpressionConjunction
      operands: Array<
        | {
            __typename?: 'DateOperand'
            field: string
            dateCondition: DateOperandCondition
            days?: number | null
            date?: any | null
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'IdsOperand'
            isEvery: boolean
            isNot: boolean
            ids: Array<number>
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'ManagersOperand'
            isEvery: boolean
            isNot: boolean
            managesDirectly: boolean
            ids: Array<number>
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'SelectIdsOperand'
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'SelectOperand'
            field: string
            path?: string | null
            isEvery: boolean
            isNot: boolean
            values?: Array<string> | null
            id?: string | null
            type: OperandType
          }
        | {
            __typename?: 'TextOperand'
            field: string
            isNot: boolean
            textCondition: TextOperandCondition
            text: string
            id?: string | null
            type: OperandType
          }
      >
    }>
  } | null
  createdBy?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
  reviews: Array<{
    __typename?: 'DocumentReview'
    id: number
    documentId: number
    personId: number
    isApproved: boolean
    createdAt: any
    person: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    }
  }>
  updatedBy?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
}

export type FolderFragment = {
  __typename?: 'Folder'
  id: number
  parentFolderId?: number | null
  name: string
}

export type TeamFragment = {
  __typename?: 'Team'
  id: number
  permissionIds: Array<string>
  createdAt: any
  updatedAt: any
  handbookCollaborators: Array<{
    __typename?: 'HandbookCollaborator'
    id: number
    handbookId: number
    personId: number
    roleId?: HandbookCollaboratorRoleId | null
  }>
  teammates: Array<{
    __typename?: 'Teammate'
    id: number
    teamId: number
    personId: number
    canReport: boolean
    canSuperBrand: boolean
    roleId?: TeamRoleId | null
    person: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    }
  }>
}

export type TeammateFragment = {
  __typename?: 'Teammate'
  id: number
  teamId: number
  personId: number
  canReport: boolean
  canSuperBrand: boolean
  roleId?: TeamRoleId | null
  person: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  }
}

type Event_DocumentAddedEvent_Fragment = {
  __typename?: 'DocumentAddedEvent'
  id: string
  type: EventType
  targetId: number
  targetType: EventTargetType
  createdAt: any
  updatedAt: any
  sortKey: string
  document: { __typename?: 'EventDocument'; id: number; name: string }
  handbook: { __typename?: 'EventHandbook'; id: number; name: string }
  person?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
}

type Event_DocumentArchivedEvent_Fragment = {
  __typename?: 'DocumentArchivedEvent'
  id: string
  type: EventType
  targetId: number
  targetType: EventTargetType
  createdAt: any
  updatedAt: any
  sortKey: string
  document: { __typename?: 'EventDocument'; id: number; name: string }
  person?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
}

type Event_DocumentCreatedEvent_Fragment = {
  __typename?: 'DocumentCreatedEvent'
  id: string
  type: EventType
  targetId: number
  targetType: EventTargetType
  createdAt: any
  updatedAt: any
  sortKey: string
  document: { __typename?: 'EventDocument'; id: number; name: string }
  person?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
}

type Event_DocumentPublishedEvent_Fragment = {
  __typename?: 'DocumentPublishedEvent'
  id: string
  type: EventType
  targetId: number
  targetType: EventTargetType
  createdAt: any
  updatedAt: any
  sortKey: string
  document: { __typename?: 'EventDocument'; id: number; name: string }
  attributes: {
    __typename?: 'DocumentPublishedEventAttributes'
    versionNumber: number
  }
  person?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
}

type Event_DocumentRemovedEvent_Fragment = {
  __typename?: 'DocumentRemovedEvent'
  id: string
  type: EventType
  targetId: number
  targetType: EventTargetType
  createdAt: any
  updatedAt: any
  sortKey: string
  document: { __typename?: 'EventDocument'; id: number; name: string }
  handbook: { __typename?: 'EventHandbook'; id: number; name: string }
  person?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
}

type Event_DocumentReviewerAddedEvent_Fragment = {
  __typename?: 'DocumentReviewerAddedEvent'
  id: string
  type: EventType
  targetId: number
  targetType: EventTargetType
  createdAt: any
  updatedAt: any
  sortKey: string
  attributes: {
    __typename?: 'DocumentReviewerAddedEventAttributes'
    documentVersionNumber: number
  }
  document: { __typename?: 'EventDocument'; id: number; name: string }
  documentReviewerPerson: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  }
  person?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
}

type Event_DocumentReviewerApprovedEvent_Fragment = {
  __typename?: 'DocumentReviewerApprovedEvent'
  id: string
  type: EventType
  targetId: number
  targetType: EventTargetType
  createdAt: any
  updatedAt: any
  sortKey: string
  attributes: {
    __typename?: 'DocumentReviewerApprovedEventAttributes'
    documentVersionNumber: number
  }
  document: { __typename?: 'EventDocument'; id: number; name: string }
  person?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
}

type Event_DocumentReviewerRejectedEvent_Fragment = {
  __typename?: 'DocumentReviewerRejectedEvent'
  id: string
  type: EventType
  targetId: number
  targetType: EventTargetType
  createdAt: any
  updatedAt: any
  sortKey: string
  attributes: {
    __typename?: 'DocumentReviewerRejectedEventAttributes'
    documentVersionNumber: number
  }
  document: { __typename?: 'EventDocument'; id: number; name: string }
  person?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
}

type Event_DocumentReviewerRemovedEvent_Fragment = {
  __typename?: 'DocumentReviewerRemovedEvent'
  id: string
  type: EventType
  targetId: number
  targetType: EventTargetType
  createdAt: any
  updatedAt: any
  sortKey: string
  attributes: {
    __typename?: 'DocumentReviewerRemovedEventAttributes'
    documentVersionNumber: number
  }
  document: { __typename?: 'EventDocument'; id: number; name: string }
  documentReviewerPerson: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  }
  person?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
}

type Event_DocumentSettingsUpdatedEvent_Fragment = {
  __typename?: 'DocumentSettingsUpdatedEvent'
  id: string
  type: EventType
  targetId: number
  targetType: EventTargetType
  createdAt: any
  updatedAt: any
  sortKey: string
  attributes: {
    __typename?: 'DocumentSettingsUpdatedEventAttributes'
    isPolicyWorkflowEnabled: boolean
  }
  person?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
}

type Event_DocumentUnarchivedEvent_Fragment = {
  __typename?: 'DocumentUnarchivedEvent'
  id: string
  type: EventType
  targetId: number
  targetType: EventTargetType
  createdAt: any
  updatedAt: any
  sortKey: string
  document: { __typename?: 'EventDocument'; id: number; name: string }
  person?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
}

type Event_DocumentUpdatedEvent_Fragment = {
  __typename?: 'DocumentUpdatedEvent'
  versionNumber: number
  html: boolean
  id: string
  type: EventType
  targetId: number
  targetType: EventTargetType
  createdAt: any
  updatedAt: any
  sortKey: string
  document: { __typename?: 'EventDocument'; id: number; name: string }
  audienceExpression?: {
    __typename?: 'MaybeRootExpressionUpdated'
    prevValue?: {
      __typename?: 'RootExpression'
      conjunction: ExpressionConjunction
      expressions: Array<{
        __typename?: 'Expression'
        conjunction: ExpressionConjunction
        operands: Array<
          | {
              __typename?: 'DateOperand'
              field: string
              dateCondition: DateOperandCondition
              days?: number | null
              date?: any | null
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'IdsOperand'
              isEvery: boolean
              isNot: boolean
              ids: Array<number>
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'ManagersOperand'
              isEvery: boolean
              isNot: boolean
              managesDirectly: boolean
              ids: Array<number>
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'SelectIdsOperand'
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'SelectOperand'
              field: string
              path?: string | null
              isEvery: boolean
              isNot: boolean
              values?: Array<string> | null
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'TextOperand'
              field: string
              isNot: boolean
              textCondition: TextOperandCondition
              text: string
              id?: string | null
              type: OperandType
            }
        >
      }>
    } | null
    value?: {
      __typename?: 'RootExpression'
      conjunction: ExpressionConjunction
      expressions: Array<{
        __typename?: 'Expression'
        conjunction: ExpressionConjunction
        operands: Array<
          | {
              __typename?: 'DateOperand'
              field: string
              dateCondition: DateOperandCondition
              days?: number | null
              date?: any | null
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'IdsOperand'
              isEvery: boolean
              isNot: boolean
              ids: Array<number>
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'ManagersOperand'
              isEvery: boolean
              isNot: boolean
              managesDirectly: boolean
              ids: Array<number>
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'SelectIdsOperand'
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'SelectOperand'
              field: string
              path?: string | null
              isEvery: boolean
              isNot: boolean
              values?: Array<string> | null
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'TextOperand'
              field: string
              isNot: boolean
              textCondition: TextOperandCondition
              text: string
              id?: string | null
              type: OperandType
            }
        >
      }>
    } | null
  } | null
  name?: {
    __typename?: 'StringUpdated'
    prevValue: string
    value: string
  } | null
  person?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
}

type Event_DocumentVersionCreatedEvent_Fragment = {
  __typename?: 'DocumentVersionCreatedEvent'
  id: string
  type: EventType
  targetId: number
  targetType: EventTargetType
  createdAt: any
  updatedAt: any
  sortKey: string
  document: { __typename?: 'EventDocument'; id: number; name: string }
  attributes: {
    __typename?: 'DocumentVersionCreatedEventAttributes'
    versionNumber: number
  }
  person?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
}

type Event_FeatureFlagAddedEvent_Fragment = {
  __typename?: 'FeatureFlagAddedEvent'
  id: string
  type: EventType
  targetId: number
  targetType: EventTargetType
  createdAt: any
  updatedAt: any
  sortKey: string
  attributes: {
    __typename?: 'FeatureFlagEventAttributes'
    featureFlagId: string
  }
  person?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
}

type Event_FeatureFlagRemovedEvent_Fragment = {
  __typename?: 'FeatureFlagRemovedEvent'
  id: string
  type: EventType
  targetId: number
  targetType: EventTargetType
  createdAt: any
  updatedAt: any
  sortKey: string
  attributes: {
    __typename?: 'FeatureFlagEventAttributes'
    featureFlagId: string
  }
  person?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
}

type Event_HandbookCreatedEvent_Fragment = {
  __typename?: 'HandbookCreatedEvent'
  id: string
  type: EventType
  targetId: number
  targetType: EventTargetType
  createdAt: any
  updatedAt: any
  sortKey: string
  handbook: { __typename?: 'EventHandbook'; id: number; name: string }
  person?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
}

type Event_HandbookPublishedEvent_Fragment = {
  __typename?: 'HandbookPublishedEvent'
  id: string
  type: EventType
  targetId: number
  targetType: EventTargetType
  createdAt: any
  updatedAt: any
  sortKey: string
  handbook: { __typename?: 'EventHandbook'; id: number; name: string }
  attributes: {
    __typename?: 'HandbookPublishedEventAttributes'
    versionNumber: number
  }
  person?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
}

type Event_HandbookSignatureRoundCreatedEvent_Fragment = {
  __typename?: 'HandbookSignatureRoundCreatedEvent'
  id: string
  type: EventType
  targetId: number
  targetType: EventTargetType
  createdAt: any
  updatedAt: any
  sortKey: string
  handbook: { __typename?: 'EventHandbook'; id: number; name: string }
  signatureRound: {
    __typename?: 'EventHandbookSignatureRound'
    id: number
    number: number
  }
  person?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
}

type Event_HandbookSignedEvent_Fragment = {
  __typename?: 'HandbookSignedEvent'
  id: string
  type: EventType
  targetId: number
  targetType: EventTargetType
  createdAt: any
  updatedAt: any
  sortKey: string
  handbook: { __typename?: 'EventHandbook'; id: number; name: string }
  attributes: {
    __typename?: 'HandbookSignedEventAttributes'
    handbookSignatureId: number
  }
  person?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
}

type Event_HandbookViewedEvent_Fragment = {
  __typename?: 'HandbookViewedEvent'
  id: string
  type: EventType
  targetId: number
  targetType: EventTargetType
  createdAt: any
  updatedAt: any
  sortKey: string
  handbook: { __typename?: 'EventHandbook'; id: number; name: string }
  attributes: {
    __typename?: 'HandbookViewedEventAttributes'
    handbookSessionId: number
  }
  person?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
}

type Event_PeopleSyncEvent_Fragment = {
  __typename?: 'PeopleSyncEvent'
  id: string
  type: EventType
  targetId: number
  targetType: EventTargetType
  createdAt: any
  updatedAt: any
  sortKey: string
  person?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
}

type Event_PersonCreatedEvent_Fragment = {
  __typename?: 'PersonCreatedEvent'
  id: string
  type: EventType
  targetId: number
  targetType: EventTargetType
  createdAt: any
  updatedAt: any
  sortKey: string
  person?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
}

type Event_PersonNotifiedEvent_Fragment = {
  __typename?: 'PersonNotifiedEvent'
  id: string
  type: EventType
  targetId: number
  targetType: EventTargetType
  createdAt: any
  updatedAt: any
  sortKey: string
  notification: {
    __typename?: 'EventNotification'
    id: number
    subject?: string | null
    handbookId?: number | null
  }
  person?: {
    __typename?: 'WhodunnitPerson'
    id: number
    fullName: string
    isBlissbook: boolean
    avatarUrl?: string | null
  } | null
}

export type EventFragment =
  | Event_DocumentAddedEvent_Fragment
  | Event_DocumentArchivedEvent_Fragment
  | Event_DocumentCreatedEvent_Fragment
  | Event_DocumentPublishedEvent_Fragment
  | Event_DocumentRemovedEvent_Fragment
  | Event_DocumentReviewerAddedEvent_Fragment
  | Event_DocumentReviewerApprovedEvent_Fragment
  | Event_DocumentReviewerRejectedEvent_Fragment
  | Event_DocumentReviewerRemovedEvent_Fragment
  | Event_DocumentSettingsUpdatedEvent_Fragment
  | Event_DocumentUnarchivedEvent_Fragment
  | Event_DocumentUpdatedEvent_Fragment
  | Event_DocumentVersionCreatedEvent_Fragment
  | Event_FeatureFlagAddedEvent_Fragment
  | Event_FeatureFlagRemovedEvent_Fragment
  | Event_HandbookCreatedEvent_Fragment
  | Event_HandbookPublishedEvent_Fragment
  | Event_HandbookSignatureRoundCreatedEvent_Fragment
  | Event_HandbookSignedEvent_Fragment
  | Event_HandbookViewedEvent_Fragment
  | Event_PeopleSyncEvent_Fragment
  | Event_PersonCreatedEvent_Fragment
  | Event_PersonNotifiedEvent_Fragment

export type WhodunnitFragment = {
  __typename?: 'WhodunnitPerson'
  id: number
  fullName: string
  isBlissbook: boolean
  avatarUrl?: string | null
}

export type ApplicationQueryVariables = Exact<{ [key: string]: never }>

export type ApplicationQuery = {
  __typename?: 'Query'
  featureFlagIds: Array<string>
  metadataKeys: Array<string>
  preferences: {
    __typename?: 'Preferences'
    documentationHandbooksSort?: HandbooksSort | null
    handbooksSort?: HandbooksSort | null
    handbooksView?: HandbooksView | null
    hideProTips: Array<string>
  }
  session?: {
    __typename?: 'Session'
    expiresAt: any
    isImpersonating: boolean
    permissionIds: Array<string>
    person?: {
      __typename?: 'SessionPerson'
      id: number
      idHash?: string | null
      email?: any | null
      fullName?: string | null
      member: boolean
      adminRoleId?: AdminRoleId | null
      createdAt: any
    } | null
    user?:
      | {
          __typename?: 'SessionSuperUser'
          email: string
          fullName: string
          roleId?: SuperUserRoleId | null
          id: number
          type: UserType
          authType: UserAuthType
          authId: string
          createdAt: any
          lastSignedInAt?: any | null
        }
      | {
          __typename?: 'SessionUser'
          id: number
          type: UserType
          authType: UserAuthType
          authId: string
          createdAt: any
          lastSignedInAt?: any | null
        }
      | null
  } | null
  audienceDashboardSettings: {
    __typename?: 'AudienceDashboardSettings'
    primaryColor: any
    accentColor: any
    updatedAt?: any | null
    createdAt?: any | null
    faviconImage?: {
      __typename?: 'Image'
      width?: number | null
      height?: number | null
      id: number
      type: AssetType
      tag: string
      filename: string
      filesize: number
      path: string
      thumbnailUrl?: string | null
      url: string
      mimeType?: string | null
      archived: boolean
      createdAt: any
    } | null
    logoImage?: {
      __typename?: 'Image'
      width?: number | null
      height?: number | null
      id: number
      type: AssetType
      tag: string
      filename: string
      filesize: number
      path: string
      thumbnailUrl?: string | null
      url: string
      mimeType?: string | null
      archived: boolean
      createdAt: any
    } | null
    bannerImage?: {
      __typename?: 'Image'
      width?: number | null
      height?: number | null
      id: number
      type: AssetType
      tag: string
      filename: string
      filesize: number
      path: string
      thumbnailUrl?: string | null
      url: string
      mimeType?: string | null
      archived: boolean
      createdAt: any
    } | null
  }
  billing?: {
    __typename?: 'Billing'
    name?: string | null
    contactName?: string | null
    address?: string | null
    toEmails: Array<any>
    credits?: number | null
    peopleCount: number
    exempt: boolean
    monthlyGrowthRate?: number | null
    monthlyCreditPrice?: number | null
    yearlyCreditPrice?: number | null
    termStartCount?: number | null
    termMonths?: number | null
    termMonthlyGrowthRate?: number | null
    paymentMethod?: string | null
    paymentDays?: number | null
    expiredAt?: any | null
    canceledAt?: any | null
    terminatedAt?: any | null
    emailSettings: {
      __typename?: 'BillingEmailSettings'
      toAdmins: boolean
      toEmails: Array<any>
    }
  } | null
  chargebeeSubscription?: {
    __typename?: 'ChargebeeSubscription'
    status: string
    lastSyncAt?: any | null
  } | null
  customDomain?: {
    __typename?: 'CustomDomain'
    domain: any
    domainTarget: any
    isAutoRenew: boolean
    isDnsValid?: boolean | null
    dnsLastValidAt?: any | null
    certificateDigest?: string | null
    certificates: Array<{
      __typename?: 'CustomDomainCertificate'
      digest: string
      expiresAt: any
      createdAt: any
    }>
  } | null
  emailDomains: Array<{
    __typename?: 'EmailDomain'
    id: number
    domain: any
    environment: string
    fromEmail: any
    fromName: string
    updatedAt: any
    createdAt: any
    dkimStatus: {
      __typename?: 'EmailDomainDkimStatus'
      verified: boolean
      lastVerifiedAt?: any | null
    }
    returnPathStatus: {
      __typename?: 'EmailDomainReturnPathStatus'
      verified: boolean
      lastVerifiedAt?: any | null
    }
  }>
  emailSettings: {
    __typename?: 'EmailSettings'
    autoSendInvitations?: boolean | null
    fromEmail?: any | null
    fromName?: string | null
    replyToEmail?: any | null
    defaultSenderFromName?: string | null
    defaultSenderReplyToEmail?: any | null
    bodyFont: string
    footerText: string
    primaryColor: any
    hasCustomBranding: boolean
    updatedAt?: any | null
    bannerImage?: {
      __typename?: 'Image'
      width?: number | null
      height?: number | null
      id: number
      type: AssetType
      tag: string
      filename: string
      filesize: number
      path: string
      thumbnailUrl?: string | null
      url: string
      mimeType?: string | null
      archived: boolean
      createdAt: any
    } | null
    footerLinks: Array<{
      __typename?: 'EmailFooterLink'
      text: string
      url: any
    }>
    logoImage?: {
      __typename?: 'Image'
      width?: number | null
      height?: number | null
      id: number
      type: AssetType
      tag: string
      filename: string
      filesize: number
      path: string
      thumbnailUrl?: string | null
      url: string
      mimeType?: string | null
      archived: boolean
      createdAt: any
    } | null
  }
  emailTemplates: Array<{
    __typename?: 'EmailTemplate'
    id: EmailTemplateId
    subjectContent: any
    ccManagers: boolean
    buttonContent: any
    messageContent: any
    createdAt?: any | null
    updatedAt?: any | null
  }>
  groups: Array<{
    __typename?: 'Group'
    id: number
    name: string
    archived: boolean
    createdAt?: any | null
    updatedAt?: any | null
    peopleCount: number
  }>
  handbooks: Array<{
    __typename?: 'Handbook'
    teamId: number
    id: number
    name: string
    defaultLanguageCode: string
    customDomain?: any | null
    customDomainOrigin?: any | null
    isPrintDisabled: boolean
    position: number
    public: boolean
    publicToken: string
    version: number
    createdAt?: any | null
    updatedAt?: any | null
    lastModifiedAt?: any | null
    lastModifiedBy?: number | null
    publishedAt?: any | null
    lastPublishedAt?: any | null
    url: any
    publishedUrl: any
    permissionIds: Array<string>
    contentLength: number
    deletePreviewLinks: boolean
    hasPersonalizedContent: boolean
    hasAudience: boolean
    viewersCount: number
    lastPublishedByPerson?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    branding: {
      __typename?: 'HandbookBranding'
      blackColor: any
      favicon?: any | null
      handbookCss?: string | null
      lessButtonText: string
      moreButtonText: string
      primaryColor: any
      printCss?: string | null
      customHtml?: string | null
      whiteColor: any
      bodyFont: {
        __typename?: 'HandbookBrandingFont'
        type: HandbookBrandingFontType
        fontId?: number | null
        family?: string | null
        bold?: number | null
        normal?: number | null
        variants?: Array<string> | null
      }
      headingFont: {
        __typename?: 'HandbookBrandingFont'
        type: HandbookBrandingFontType
        fontId?: number | null
        family?: string | null
        bold?: number | null
        normal?: number | null
        variants?: Array<string> | null
      }
      scriptFont: {
        __typename?: 'HandbookBrandingFont'
        type: HandbookBrandingFontType
        fontId?: number | null
        family?: string | null
        bold?: number | null
        normal?: number | null
        variants?: Array<string> | null
      }
    }
    previewLinks: Array<{
      __typename?: 'HandbookPreviewLink'
      handbookId: number
      personId?: number | null
      token: string
      previewUrl: any
      createdAt: any
    }>
    signatureRounds: Array<{
      __typename?: 'HandbookSignatureRound'
      id: number
      number: number
      onlyAtLaunch?: HandbookSignatureRoundOnlyAtLaunch | null
      onlyMembers: boolean
      dueDays?: number | null
      dueDaysFuture?: number | null
      archived: boolean
      active: boolean
      createdAt: any
      updatedAt: any
      publishedAt?: any | null
      peopleCount: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
    statistics: {
      __typename?: 'HandbookStatistics'
      peopleCount: number
      canNotifyPeopleCount: number
      canNotifyNonCompliantPeopleCount: number
      nonCompliantPeopleCount: number
      notifiedPeopleCount: number
      maxNotifiedPeopleCount: number
      viewedPeopleCount: number
      maxViewedPeopleCount: number
      signedPeopleCount: number
      maxSignedPeopleCount: number
      validSignedPeopleCount: number
      maxValidSignedPeopleCount: number
      score?: number | null
    }
    tasks: Array<{
      __typename?: 'HandbookTask'
      id: HandbookTaskId
      completedAt?: any | null
      completedBy?: number | null
    }>
    versions: Array<{
      __typename?: 'HandbookVersionSummary'
      createdAt?: any | null
      createdBy?: number | null
      defaultLanguageCode: string
      handbookId: number
      label?: string | null
      languageCodes: Array<string>
      printUrl: any
      url: any
      version: number
      createdByPerson?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
    audience: {
      __typename?: 'HandbookAudience'
      member: boolean
      groupIds: Array<number>
      personIds: Array<number>
      savedSegmentIds: Array<number>
    }
  }>
  handbookReminders: Array<{
    __typename?: 'HandbookReminder'
    id: number
    isEnabled: boolean
    days: number
    date: HandbookReminderDate
    cc: Array<any>
    ccManagers: boolean
    subjectContent: any
    buttonContent: any
    messageContent: any
    createdAt?: any | null
    createdBy?: number | null
    updatedAt?: any | null
    updatedBy?: number | null
  }>
  integrations: Array<
    | {
        __typename?: 'BambooHRIntegration'
        groups: Array<BambooHrGroupId>
        hostname: string
        subdomain: string
        syncTiming?: BambooHrSyncTiming | null
        userId?: string | null
        id: number
        providerId: IntegrationProviderId
        lastSyncAt?: any | null
        lastErrorAt?: any | null
        lastError?: string | null
        createdAt: any
        updatedAt: any
        rejectPeopleExpression?: {
          __typename?: 'RootExpression'
          conjunction: ExpressionConjunction
          expressions: Array<{
            __typename?: 'Expression'
            conjunction: ExpressionConjunction
            operands: Array<
              | {
                  __typename?: 'DateOperand'
                  field: string
                  dateCondition: DateOperandCondition
                  days?: number | null
                  date?: any | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'IdsOperand'
                  isEvery: boolean
                  isNot: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'ManagersOperand'
                  isEvery: boolean
                  isNot: boolean
                  managesDirectly: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectIdsOperand'
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectOperand'
                  field: string
                  path?: string | null
                  isEvery: boolean
                  isNot: boolean
                  values?: Array<string> | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'TextOperand'
                  field: string
                  isNot: boolean
                  textCondition: TextOperandCondition
                  text: string
                  id?: string | null
                  type: OperandType
                }
            >
          }>
        } | null
      }
    | {
        __typename?: 'RipplingIntegration'
        customFieldKeys: Array<string>
        ripplingGroupIds: Array<RipplingGroupId>
        id: number
        providerId: IntegrationProviderId
        lastSyncAt?: any | null
        lastErrorAt?: any | null
        lastError?: string | null
        createdAt: any
        updatedAt: any
        rejectPeopleExpression?: {
          __typename?: 'RootExpression'
          conjunction: ExpressionConjunction
          expressions: Array<{
            __typename?: 'Expression'
            conjunction: ExpressionConjunction
            operands: Array<
              | {
                  __typename?: 'DateOperand'
                  field: string
                  dateCondition: DateOperandCondition
                  days?: number | null
                  date?: any | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'IdsOperand'
                  isEvery: boolean
                  isNot: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'ManagersOperand'
                  isEvery: boolean
                  isNot: boolean
                  managesDirectly: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectIdsOperand'
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectOperand'
                  field: string
                  path?: string | null
                  isEvery: boolean
                  isNot: boolean
                  values?: Array<string> | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'TextOperand'
                  field: string
                  isNot: boolean
                  textCondition: TextOperandCondition
                  text: string
                  id?: string | null
                  type: OperandType
                }
            >
          }>
        } | null
      }
    | {
        __typename?: 'SftpIntegration'
        username: string
        id: number
        providerId: IntegrationProviderId
        lastSyncAt?: any | null
        lastErrorAt?: any | null
        lastError?: string | null
        createdAt: any
        updatedAt: any
        fingerprints: {
          __typename?: 'SshFingerprints'
          sha256: string
          md5: string
        }
        rejectPeopleExpression?: {
          __typename?: 'RootExpression'
          conjunction: ExpressionConjunction
          expressions: Array<{
            __typename?: 'Expression'
            conjunction: ExpressionConjunction
            operands: Array<
              | {
                  __typename?: 'DateOperand'
                  field: string
                  dateCondition: DateOperandCondition
                  days?: number | null
                  date?: any | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'IdsOperand'
                  isEvery: boolean
                  isNot: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'ManagersOperand'
                  isEvery: boolean
                  isNot: boolean
                  managesDirectly: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectIdsOperand'
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectOperand'
                  field: string
                  path?: string | null
                  isEvery: boolean
                  isNot: boolean
                  values?: Array<string> | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'TextOperand'
                  field: string
                  isNot: boolean
                  textCondition: TextOperandCondition
                  text: string
                  id?: string | null
                  type: OperandType
                }
            >
          }>
        } | null
      }
    | {
        __typename?: 'NewSftpIntegration'
        id: number
        providerId: IntegrationProviderId
        lastSyncAt?: any | null
        lastErrorAt?: any | null
        lastError?: string | null
        createdAt: any
        updatedAt: any
        rejectPeopleExpression?: {
          __typename?: 'RootExpression'
          conjunction: ExpressionConjunction
          expressions: Array<{
            __typename?: 'Expression'
            conjunction: ExpressionConjunction
            operands: Array<
              | {
                  __typename?: 'DateOperand'
                  field: string
                  dateCondition: DateOperandCondition
                  days?: number | null
                  date?: any | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'IdsOperand'
                  isEvery: boolean
                  isNot: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'ManagersOperand'
                  isEvery: boolean
                  isNot: boolean
                  managesDirectly: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectIdsOperand'
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectOperand'
                  field: string
                  path?: string | null
                  isEvery: boolean
                  isNot: boolean
                  values?: Array<string> | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'TextOperand'
                  field: string
                  isNot: boolean
                  textCondition: TextOperandCondition
                  text: string
                  id?: string | null
                  type: OperandType
                }
            >
          }>
        } | null
      }
    | {
        __typename?: 'HttpsIntegration'
        id: number
        providerId: IntegrationProviderId
        lastSyncAt?: any | null
        lastErrorAt?: any | null
        lastError?: string | null
        createdAt: any
        updatedAt: any
        rejectPeopleExpression?: {
          __typename?: 'RootExpression'
          conjunction: ExpressionConjunction
          expressions: Array<{
            __typename?: 'Expression'
            conjunction: ExpressionConjunction
            operands: Array<
              | {
                  __typename?: 'DateOperand'
                  field: string
                  dateCondition: DateOperandCondition
                  days?: number | null
                  date?: any | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'IdsOperand'
                  isEvery: boolean
                  isNot: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'ManagersOperand'
                  isEvery: boolean
                  isNot: boolean
                  managesDirectly: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectIdsOperand'
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectOperand'
                  field: string
                  path?: string | null
                  isEvery: boolean
                  isNot: boolean
                  values?: Array<string> | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'TextOperand'
                  field: string
                  isNot: boolean
                  textCondition: TextOperandCondition
                  text: string
                  id?: string | null
                  type: OperandType
                }
            >
          }>
        } | null
      }
    | {
        __typename?: 'MergeDevIntegration'
        externalId: string
        integrationName: string
        syncHourUtc?: number | null
        id: number
        providerId: IntegrationProviderId
        lastSyncAt?: any | null
        lastErrorAt?: any | null
        lastError?: string | null
        createdAt: any
        updatedAt: any
        rejectPeopleExpression?: {
          __typename?: 'RootExpression'
          conjunction: ExpressionConjunction
          expressions: Array<{
            __typename?: 'Expression'
            conjunction: ExpressionConjunction
            operands: Array<
              | {
                  __typename?: 'DateOperand'
                  field: string
                  dateCondition: DateOperandCondition
                  days?: number | null
                  date?: any | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'IdsOperand'
                  isEvery: boolean
                  isNot: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'ManagersOperand'
                  isEvery: boolean
                  isNot: boolean
                  managesDirectly: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectIdsOperand'
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectOperand'
                  field: string
                  path?: string | null
                  isEvery: boolean
                  isNot: boolean
                  values?: Array<string> | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'TextOperand'
                  field: string
                  isNot: boolean
                  textCondition: TextOperandCondition
                  text: string
                  id?: string | null
                  type: OperandType
                }
            >
          }>
        } | null
      }
  >
  managerDigestSettings: {
    __typename?: 'ManagerDigestSettings'
    isEnabled: boolean
    isDirectReportsOnly: boolean
    daysAfter: number
    date: ManagerDigestDate
    subjectContent: any
    buttonContent: any
    messageContent: any
    updatedAt?: any | null
  }
  organization: {
    __typename?: 'Organization'
    id: number
    name: string
    subdomain: any
    origin: any
    blissbookOrigin: any
    isFullServe: boolean
    isCrmDisabled: boolean
    isIntercomDisabled: boolean
    isAuthDisabled: boolean
    isHandbookPositionEnabled: boolean
    showPrivacyPolicy: boolean
    createdAt: any
    createdBy?: number | null
    updatedAt: any
    emailPeopleCount: number
    employeeIdPeopleCount: number
    friendsCount: number
    lastHiredOnPeopleCount: number
    managersCount: number
    membersCount: number
    peopleCount: number
    phonePeopleCount: number
    publishedAt?: any | null
    logoImage?: {
      __typename?: 'Image'
      width?: number | null
      height?: number | null
      id: number
      type: AssetType
      tag: string
      filename: string
      filesize: number
      path: string
      thumbnailUrl?: string | null
      url: string
      mimeType?: string | null
      archived: boolean
      createdAt: any
    } | null
  }
  organizationTasks: Array<{
    __typename?: 'OrganizationTask'
    id: OrganizationTaskId
    completedAt?: any | null
    completedBy?: number | null
  }>
  securitySettings: {
    __typename?: 'SecuritySettings'
    emailAuth: {
      __typename?: 'EmailAuthSettings'
      isEnabled: boolean
      updatedAt?: any | null
    }
    kioskMode: {
      __typename?: 'KioskModeSettings'
      isEnabled: boolean
      inactivitySeconds: number
      timeoutSeconds: number
      updatedAt?: any | null
    }
    phoneAuth: {
      __typename?: 'PhoneAuthSettings'
      isEnabled: boolean
      helpContent?: any | null
      updatedAt?: any | null
    }
    singleSignOns: Array<{
      __typename?: 'SingleSignOn'
      providerId: SingleSignOnProviderId
      issuer?: any | null
      loginUrl?: any | null
      authKey?: SingleSignOnAuthKey | null
      buttonText?: string | null
      createdAt: any
      updatedAt: any
      certificates: Array<{
        __typename?: 'SingleSignOnCertificate'
        digest: string
        createdAt: any
        archivedAt?: any | null
      }>
    }>
  }
  teams: Array<{
    __typename?: 'Team'
    id: number
    permissionIds: Array<string>
    createdAt: any
    updatedAt: any
    handbookCollaborators: Array<{
      __typename?: 'HandbookCollaborator'
      id: number
      handbookId: number
      personId: number
      roleId?: HandbookCollaboratorRoleId | null
    }>
    teammates: Array<{
      __typename?: 'Teammate'
      id: number
      teamId: number
      personId: number
      canReport: boolean
      canSuperBrand: boolean
      roleId?: TeamRoleId | null
      person: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      }
    }>
  }>
}

export type FeatureFlagsQueryVariables = Exact<{ [key: string]: never }>

export type FeatureFlagsQuery = {
  __typename?: 'Query'
  featureFlagIds: Array<string>
}

export type HandbooksQueryVariables = Exact<{ [key: string]: never }>

export type HandbooksQuery = {
  __typename?: 'Query'
  handbooks: Array<{
    __typename?: 'Handbook'
    teamId: number
    id: number
    name: string
    defaultLanguageCode: string
    customDomain?: any | null
    customDomainOrigin?: any | null
    isPrintDisabled: boolean
    position: number
    public: boolean
    publicToken: string
    version: number
    createdAt?: any | null
    updatedAt?: any | null
    lastModifiedAt?: any | null
    lastModifiedBy?: number | null
    publishedAt?: any | null
    lastPublishedAt?: any | null
    url: any
    publishedUrl: any
    permissionIds: Array<string>
    contentLength: number
    deletePreviewLinks: boolean
    hasPersonalizedContent: boolean
    hasAudience: boolean
    viewersCount: number
    lastPublishedByPerson?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    branding: {
      __typename?: 'HandbookBranding'
      blackColor: any
      favicon?: any | null
      handbookCss?: string | null
      lessButtonText: string
      moreButtonText: string
      primaryColor: any
      printCss?: string | null
      customHtml?: string | null
      whiteColor: any
      bodyFont: {
        __typename?: 'HandbookBrandingFont'
        type: HandbookBrandingFontType
        fontId?: number | null
        family?: string | null
        bold?: number | null
        normal?: number | null
        variants?: Array<string> | null
      }
      headingFont: {
        __typename?: 'HandbookBrandingFont'
        type: HandbookBrandingFontType
        fontId?: number | null
        family?: string | null
        bold?: number | null
        normal?: number | null
        variants?: Array<string> | null
      }
      scriptFont: {
        __typename?: 'HandbookBrandingFont'
        type: HandbookBrandingFontType
        fontId?: number | null
        family?: string | null
        bold?: number | null
        normal?: number | null
        variants?: Array<string> | null
      }
    }
    previewLinks: Array<{
      __typename?: 'HandbookPreviewLink'
      handbookId: number
      personId?: number | null
      token: string
      previewUrl: any
      createdAt: any
    }>
    signatureRounds: Array<{
      __typename?: 'HandbookSignatureRound'
      id: number
      number: number
      onlyAtLaunch?: HandbookSignatureRoundOnlyAtLaunch | null
      onlyMembers: boolean
      dueDays?: number | null
      dueDaysFuture?: number | null
      archived: boolean
      active: boolean
      createdAt: any
      updatedAt: any
      publishedAt?: any | null
      peopleCount: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
    statistics: {
      __typename?: 'HandbookStatistics'
      peopleCount: number
      canNotifyPeopleCount: number
      canNotifyNonCompliantPeopleCount: number
      nonCompliantPeopleCount: number
      notifiedPeopleCount: number
      maxNotifiedPeopleCount: number
      viewedPeopleCount: number
      maxViewedPeopleCount: number
      signedPeopleCount: number
      maxSignedPeopleCount: number
      validSignedPeopleCount: number
      maxValidSignedPeopleCount: number
      score?: number | null
    }
    tasks: Array<{
      __typename?: 'HandbookTask'
      id: HandbookTaskId
      completedAt?: any | null
      completedBy?: number | null
    }>
    versions: Array<{
      __typename?: 'HandbookVersionSummary'
      createdAt?: any | null
      createdBy?: number | null
      defaultLanguageCode: string
      handbookId: number
      label?: string | null
      languageCodes: Array<string>
      printUrl: any
      url: any
      version: number
      createdByPerson?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
    audience: {
      __typename?: 'HandbookAudience'
      member: boolean
      groupIds: Array<number>
      personIds: Array<number>
      savedSegmentIds: Array<number>
    }
  }>
}

export type HandbookQueryVariables = Exact<{
  handbookId: Scalars['Int']
}>

export type HandbookQuery = {
  __typename?: 'Query'
  handbook: {
    __typename?: 'Handbook'
    content: any
    teamId: number
    id: number
    name: string
    defaultLanguageCode: string
    customDomain?: any | null
    customDomainOrigin?: any | null
    isPrintDisabled: boolean
    position: number
    public: boolean
    publicToken: string
    version: number
    createdAt?: any | null
    updatedAt?: any | null
    lastModifiedAt?: any | null
    lastModifiedBy?: number | null
    publishedAt?: any | null
    lastPublishedAt?: any | null
    url: any
    publishedUrl: any
    permissionIds: Array<string>
    contentLength: number
    deletePreviewLinks: boolean
    hasPersonalizedContent: boolean
    hasAudience: boolean
    viewersCount: number
    acknowledgementForm: {
      __typename?: 'HandbookAcknowledgement'
      title?: any | null
      body?: any | null
      forceSign: boolean
    }
    assets: Array<
      | {
          __typename?: 'HandbookImage'
          width?: number | null
          height?: number | null
          id: number
          type: AssetType
          filename: string
          filesize: number
          path: string
          thumbnailUrl?: string | null
          url: string
          archived: boolean
          mimeType?: string | null
          createdAt: any
        }
      | {
          __typename?: 'HandbookPdf'
          id: number
          type: AssetType
          filename: string
          filesize: number
          path: string
          thumbnailUrl?: string | null
          url: string
          archived: boolean
          mimeType?: string | null
          createdAt: any
        }
    >
    contactFragments: {
      __typename?: 'HandbookContact'
      title?: any | null
      body?: any | null
    }
    fonts: Array<{
      __typename?: 'HandbookFont'
      id: number
      filename: string
      archived: boolean
      createdAt: any
    }>
    publishNotification?: {
      __typename?: 'PublishHandbookNotification'
      toViewers?: boolean | null
      signers?: {
        __typename?: 'Notification'
        subjectContent: any
        buttonContent: any
        messageContent: any
      } | null
      viewers?: {
        __typename?: 'Notification'
        subjectContent: any
        buttonContent: any
        messageContent: any
      } | null
    } | null
    sections: Array<{
      __typename?: 'HandbookSection'
      handbookId: number
      id: number
      version: number
      createdAt?: any | null
      createdBy?: number | null
      updatedAt?: any | null
      updatedBy?: number | null
      lastPublishedAt?: any | null
      lastPublishedBy?: number | null
      dirtyFields?: Array<string> | null
      hidden: boolean
      hideToc: boolean
      languageCode?: string | null
      theme?: string | null
      title?: string | null
      type: HandbookSectionType
      hideAnnotations: Array<number>
      editAnnotation?: string | null
      viewAnnotation?: string | null
      attrs: {
        __typename?: 'HandbookSectionAttributes'
        keys: Array<string>
        goalAlign?: HandbookSectionTextAlign | null
        image?: any | null
        photo?: any | null
        signatureImage?: any | null
        statementAlign?: HandbookSectionTextAlign | null
        subtitleAlign?: HandbookSectionTextAlign | null
        titleAlign?: HandbookSectionTextAlign | null
        animation?: boolean | null
        backgroundAnimation?: string | null
        backgroundColor?: any | null
        backgroundImage?: any | null
        backgroundOverlayColor?: any | null
        backgroundOverlayOpacity?: number | null
        bottom?: string | null
        bottomArrow?: boolean | null
        headingBackgroundImage?: any | null
        height?: string | null
        entryAngle?: string | null
        entryAnimation?: boolean | null
        maxColumns?: number | null
        showChapterIcon?: boolean | null
        signatureType?: string | null
        textShadow?: boolean | null
        titleHighlight?: string | null
        titleHighlightColor?: any | null
        video?: {
          __typename?: 'HandbookVideo'
          url: any
          height: number
          width: number
        } | null
      }
      fragments: {
        __typename?: 'HandbookSectionContent'
        keys: Array<string>
        body?: any | null
        description?: any | null
        goal?: any | null
        headline?: any | null
        introduction?: any | null
        signature?: any | null
        statement?: any | null
        subtitle?: any | null
        title?: any | null
      }
      listEntries?: Array<{
        __typename?: 'HandbookSectionEntry'
        keys: Array<string>
        uid: string
        body?: any | null
        image?: any | null
        subtitle?: any | null
        subtitleAlign?: HandbookSectionTextAlign | null
        title?: any | null
        titleAlign?: HandbookSectionTextAlign | null
      }> | null
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
    }>
    lastPublishedByPerson?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    branding: {
      __typename?: 'HandbookBranding'
      blackColor: any
      favicon?: any | null
      handbookCss?: string | null
      lessButtonText: string
      moreButtonText: string
      primaryColor: any
      printCss?: string | null
      customHtml?: string | null
      whiteColor: any
      bodyFont: {
        __typename?: 'HandbookBrandingFont'
        type: HandbookBrandingFontType
        fontId?: number | null
        family?: string | null
        bold?: number | null
        normal?: number | null
        variants?: Array<string> | null
      }
      headingFont: {
        __typename?: 'HandbookBrandingFont'
        type: HandbookBrandingFontType
        fontId?: number | null
        family?: string | null
        bold?: number | null
        normal?: number | null
        variants?: Array<string> | null
      }
      scriptFont: {
        __typename?: 'HandbookBrandingFont'
        type: HandbookBrandingFontType
        fontId?: number | null
        family?: string | null
        bold?: number | null
        normal?: number | null
        variants?: Array<string> | null
      }
    }
    previewLinks: Array<{
      __typename?: 'HandbookPreviewLink'
      handbookId: number
      personId?: number | null
      token: string
      previewUrl: any
      createdAt: any
    }>
    signatureRounds: Array<{
      __typename?: 'HandbookSignatureRound'
      id: number
      number: number
      onlyAtLaunch?: HandbookSignatureRoundOnlyAtLaunch | null
      onlyMembers: boolean
      dueDays?: number | null
      dueDaysFuture?: number | null
      archived: boolean
      active: boolean
      createdAt: any
      updatedAt: any
      publishedAt?: any | null
      peopleCount: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
    statistics: {
      __typename?: 'HandbookStatistics'
      peopleCount: number
      canNotifyPeopleCount: number
      canNotifyNonCompliantPeopleCount: number
      nonCompliantPeopleCount: number
      notifiedPeopleCount: number
      maxNotifiedPeopleCount: number
      viewedPeopleCount: number
      maxViewedPeopleCount: number
      signedPeopleCount: number
      maxSignedPeopleCount: number
      validSignedPeopleCount: number
      maxValidSignedPeopleCount: number
      score?: number | null
    }
    tasks: Array<{
      __typename?: 'HandbookTask'
      id: HandbookTaskId
      completedAt?: any | null
      completedBy?: number | null
    }>
    versions: Array<{
      __typename?: 'HandbookVersionSummary'
      createdAt?: any | null
      createdBy?: number | null
      defaultLanguageCode: string
      handbookId: number
      label?: string | null
      languageCodes: Array<string>
      printUrl: any
      url: any
      version: number
      createdByPerson?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
    audience: {
      __typename?: 'HandbookAudience'
      member: boolean
      groupIds: Array<number>
      personIds: Array<number>
      savedSegmentIds: Array<number>
    }
  }
  documents: Array<{
    __typename?: 'Document'
    id: number
    type: DocumentType
    name: string
    archivedAt?: any | null
    lastPublishedVersionNumber: number
    parentFolderId?: number | null
    sourceHandbookId?: number | null
    sourceHandbookSectionId?: number | null
    draftVersion?: {
      __typename?: 'DocumentVersion'
      versionNumber: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
    } | null
    lastPublishedVersion?: {
      __typename?: 'DocumentVersion'
      versionNumber: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
    } | null
  }>
  folders: Array<{
    __typename?: 'Folder'
    id: number
    parentFolderId?: number | null
    name: string
  }>
}

export type HandbookEditorQueryVariables = Exact<{
  handbookId: Scalars['Int']
}>

export type HandbookEditorQuery = {
  __typename?: 'Query'
  handbook: {
    __typename?: 'Handbook'
    content: any
    teamId: number
    id: number
    name: string
    defaultLanguageCode: string
    customDomain?: any | null
    customDomainOrigin?: any | null
    isPrintDisabled: boolean
    position: number
    public: boolean
    publicToken: string
    version: number
    createdAt?: any | null
    updatedAt?: any | null
    lastModifiedAt?: any | null
    lastModifiedBy?: number | null
    publishedAt?: any | null
    lastPublishedAt?: any | null
    url: any
    publishedUrl: any
    permissionIds: Array<string>
    contentLength: number
    deletePreviewLinks: boolean
    hasPersonalizedContent: boolean
    hasAudience: boolean
    viewersCount: number
    acknowledgementForm: {
      __typename?: 'HandbookAcknowledgement'
      title?: any | null
      body?: any | null
      forceSign: boolean
    }
    assets: Array<
      | {
          __typename?: 'HandbookImage'
          width?: number | null
          height?: number | null
          id: number
          type: AssetType
          filename: string
          filesize: number
          path: string
          thumbnailUrl?: string | null
          url: string
          archived: boolean
          mimeType?: string | null
          createdAt: any
        }
      | {
          __typename?: 'HandbookPdf'
          id: number
          type: AssetType
          filename: string
          filesize: number
          path: string
          thumbnailUrl?: string | null
          url: string
          archived: boolean
          mimeType?: string | null
          createdAt: any
        }
    >
    contactFragments: {
      __typename?: 'HandbookContact'
      title?: any | null
      body?: any | null
    }
    fonts: Array<{
      __typename?: 'HandbookFont'
      id: number
      filename: string
      archived: boolean
      createdAt: any
    }>
    publishNotification?: {
      __typename?: 'PublishHandbookNotification'
      toViewers?: boolean | null
      signers?: {
        __typename?: 'Notification'
        subjectContent: any
        buttonContent: any
        messageContent: any
      } | null
      viewers?: {
        __typename?: 'Notification'
        subjectContent: any
        buttonContent: any
        messageContent: any
      } | null
    } | null
    sections: Array<{
      __typename?: 'HandbookSection'
      handbookId: number
      id: number
      version: number
      createdAt?: any | null
      createdBy?: number | null
      updatedAt?: any | null
      updatedBy?: number | null
      lastPublishedAt?: any | null
      lastPublishedBy?: number | null
      dirtyFields?: Array<string> | null
      hidden: boolean
      hideToc: boolean
      languageCode?: string | null
      theme?: string | null
      title?: string | null
      type: HandbookSectionType
      hideAnnotations: Array<number>
      editAnnotation?: string | null
      viewAnnotation?: string | null
      attrs: {
        __typename?: 'HandbookSectionAttributes'
        keys: Array<string>
        goalAlign?: HandbookSectionTextAlign | null
        image?: any | null
        photo?: any | null
        signatureImage?: any | null
        statementAlign?: HandbookSectionTextAlign | null
        subtitleAlign?: HandbookSectionTextAlign | null
        titleAlign?: HandbookSectionTextAlign | null
        animation?: boolean | null
        backgroundAnimation?: string | null
        backgroundColor?: any | null
        backgroundImage?: any | null
        backgroundOverlayColor?: any | null
        backgroundOverlayOpacity?: number | null
        bottom?: string | null
        bottomArrow?: boolean | null
        headingBackgroundImage?: any | null
        height?: string | null
        entryAngle?: string | null
        entryAnimation?: boolean | null
        maxColumns?: number | null
        showChapterIcon?: boolean | null
        signatureType?: string | null
        textShadow?: boolean | null
        titleHighlight?: string | null
        titleHighlightColor?: any | null
        video?: {
          __typename?: 'HandbookVideo'
          url: any
          height: number
          width: number
        } | null
      }
      fragments: {
        __typename?: 'HandbookSectionContent'
        keys: Array<string>
        body?: any | null
        description?: any | null
        goal?: any | null
        headline?: any | null
        introduction?: any | null
        signature?: any | null
        statement?: any | null
        subtitle?: any | null
        title?: any | null
      }
      listEntries?: Array<{
        __typename?: 'HandbookSectionEntry'
        keys: Array<string>
        uid: string
        body?: any | null
        image?: any | null
        subtitle?: any | null
        subtitleAlign?: HandbookSectionTextAlign | null
        title?: any | null
        titleAlign?: HandbookSectionTextAlign | null
      }> | null
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
    }>
    lastPublishedByPerson?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    branding: {
      __typename?: 'HandbookBranding'
      blackColor: any
      favicon?: any | null
      handbookCss?: string | null
      lessButtonText: string
      moreButtonText: string
      primaryColor: any
      printCss?: string | null
      customHtml?: string | null
      whiteColor: any
      bodyFont: {
        __typename?: 'HandbookBrandingFont'
        type: HandbookBrandingFontType
        fontId?: number | null
        family?: string | null
        bold?: number | null
        normal?: number | null
        variants?: Array<string> | null
      }
      headingFont: {
        __typename?: 'HandbookBrandingFont'
        type: HandbookBrandingFontType
        fontId?: number | null
        family?: string | null
        bold?: number | null
        normal?: number | null
        variants?: Array<string> | null
      }
      scriptFont: {
        __typename?: 'HandbookBrandingFont'
        type: HandbookBrandingFontType
        fontId?: number | null
        family?: string | null
        bold?: number | null
        normal?: number | null
        variants?: Array<string> | null
      }
    }
    previewLinks: Array<{
      __typename?: 'HandbookPreviewLink'
      handbookId: number
      personId?: number | null
      token: string
      previewUrl: any
      createdAt: any
    }>
    signatureRounds: Array<{
      __typename?: 'HandbookSignatureRound'
      id: number
      number: number
      onlyAtLaunch?: HandbookSignatureRoundOnlyAtLaunch | null
      onlyMembers: boolean
      dueDays?: number | null
      dueDaysFuture?: number | null
      archived: boolean
      active: boolean
      createdAt: any
      updatedAt: any
      publishedAt?: any | null
      peopleCount: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
    statistics: {
      __typename?: 'HandbookStatistics'
      peopleCount: number
      canNotifyPeopleCount: number
      canNotifyNonCompliantPeopleCount: number
      nonCompliantPeopleCount: number
      notifiedPeopleCount: number
      maxNotifiedPeopleCount: number
      viewedPeopleCount: number
      maxViewedPeopleCount: number
      signedPeopleCount: number
      maxSignedPeopleCount: number
      validSignedPeopleCount: number
      maxValidSignedPeopleCount: number
      score?: number | null
    }
    tasks: Array<{
      __typename?: 'HandbookTask'
      id: HandbookTaskId
      completedAt?: any | null
      completedBy?: number | null
    }>
    versions: Array<{
      __typename?: 'HandbookVersionSummary'
      createdAt?: any | null
      createdBy?: number | null
      defaultLanguageCode: string
      handbookId: number
      label?: string | null
      languageCodes: Array<string>
      printUrl: any
      url: any
      version: number
      createdByPerson?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
    audience: {
      __typename?: 'HandbookAudience'
      member: boolean
      groupIds: Array<number>
      personIds: Array<number>
      savedSegmentIds: Array<number>
    }
  }
  documents: Array<{
    __typename?: 'Document'
    id: number
    type: DocumentType
    name: string
    archivedAt?: any | null
    lastPublishedVersionNumber: number
    parentFolderId?: number | null
    sourceHandbookId?: number | null
    sourceHandbookSectionId?: number | null
    draftVersion?: {
      __typename?: 'DocumentVersion'
      versionNumber: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
    } | null
    lastPublishedVersion?: {
      __typename?: 'DocumentVersion'
      versionNumber: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
    } | null
  }>
  folders: Array<{
    __typename?: 'Folder'
    id: number
    parentFolderId?: number | null
    name: string
  }>
}

export type HandbookEditorDocumentsQueryVariables = Exact<{
  [key: string]: never
}>

export type HandbookEditorDocumentsQuery = {
  __typename?: 'Query'
  documents: Array<{
    __typename?: 'Document'
    id: number
    type: DocumentType
    name: string
    archivedAt?: any | null
    lastPublishedVersionNumber: number
    parentFolderId?: number | null
    sourceHandbookId?: number | null
    sourceHandbookSectionId?: number | null
    draftVersion?: {
      __typename?: 'DocumentVersion'
      versionNumber: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
    } | null
    lastPublishedVersion?: {
      __typename?: 'DocumentVersion'
      versionNumber: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
    } | null
  }>
  folders: Array<{
    __typename?: 'Folder'
    id: number
    parentFolderId?: number | null
    name: string
  }>
}

export type ExportHandbookQueryVariables = Exact<{
  handbookId: Scalars['Int']
}>

export type ExportHandbookQuery = {
  __typename?: 'Query'
  handbook: {
    __typename?: 'Handbook'
    content: any
    teamId: number
    id: number
    name: string
    defaultLanguageCode: string
    customDomain?: any | null
    customDomainOrigin?: any | null
    isPrintDisabled: boolean
    position: number
    public: boolean
    publicToken: string
    version: number
    createdAt?: any | null
    updatedAt?: any | null
    lastModifiedAt?: any | null
    lastModifiedBy?: number | null
    publishedAt?: any | null
    lastPublishedAt?: any | null
    url: any
    publishedUrl: any
    permissionIds: Array<string>
    contentLength: number
    deletePreviewLinks: boolean
    hasPersonalizedContent: boolean
    hasAudience: boolean
    viewersCount: number
    documents: Array<{
      __typename?: 'Document'
      id: number
      name: string
      lastPublishedVersion?: {
        __typename?: 'DocumentVersion'
        html?: any | null
        versionNumber: number
      } | null
    }>
    acknowledgementForm: {
      __typename?: 'HandbookAcknowledgement'
      title?: any | null
      body?: any | null
      forceSign: boolean
    }
    assets: Array<
      | {
          __typename?: 'HandbookImage'
          width?: number | null
          height?: number | null
          id: number
          type: AssetType
          filename: string
          filesize: number
          path: string
          thumbnailUrl?: string | null
          url: string
          archived: boolean
          mimeType?: string | null
          createdAt: any
        }
      | {
          __typename?: 'HandbookPdf'
          id: number
          type: AssetType
          filename: string
          filesize: number
          path: string
          thumbnailUrl?: string | null
          url: string
          archived: boolean
          mimeType?: string | null
          createdAt: any
        }
    >
    contactFragments: {
      __typename?: 'HandbookContact'
      title?: any | null
      body?: any | null
    }
    fonts: Array<{
      __typename?: 'HandbookFont'
      id: number
      filename: string
      archived: boolean
      createdAt: any
    }>
    publishNotification?: {
      __typename?: 'PublishHandbookNotification'
      toViewers?: boolean | null
      signers?: {
        __typename?: 'Notification'
        subjectContent: any
        buttonContent: any
        messageContent: any
      } | null
      viewers?: {
        __typename?: 'Notification'
        subjectContent: any
        buttonContent: any
        messageContent: any
      } | null
    } | null
    sections: Array<{
      __typename?: 'HandbookSection'
      handbookId: number
      id: number
      version: number
      createdAt?: any | null
      createdBy?: number | null
      updatedAt?: any | null
      updatedBy?: number | null
      lastPublishedAt?: any | null
      lastPublishedBy?: number | null
      dirtyFields?: Array<string> | null
      hidden: boolean
      hideToc: boolean
      languageCode?: string | null
      theme?: string | null
      title?: string | null
      type: HandbookSectionType
      hideAnnotations: Array<number>
      editAnnotation?: string | null
      viewAnnotation?: string | null
      attrs: {
        __typename?: 'HandbookSectionAttributes'
        keys: Array<string>
        goalAlign?: HandbookSectionTextAlign | null
        image?: any | null
        photo?: any | null
        signatureImage?: any | null
        statementAlign?: HandbookSectionTextAlign | null
        subtitleAlign?: HandbookSectionTextAlign | null
        titleAlign?: HandbookSectionTextAlign | null
        animation?: boolean | null
        backgroundAnimation?: string | null
        backgroundColor?: any | null
        backgroundImage?: any | null
        backgroundOverlayColor?: any | null
        backgroundOverlayOpacity?: number | null
        bottom?: string | null
        bottomArrow?: boolean | null
        headingBackgroundImage?: any | null
        height?: string | null
        entryAngle?: string | null
        entryAnimation?: boolean | null
        maxColumns?: number | null
        showChapterIcon?: boolean | null
        signatureType?: string | null
        textShadow?: boolean | null
        titleHighlight?: string | null
        titleHighlightColor?: any | null
        video?: {
          __typename?: 'HandbookVideo'
          url: any
          height: number
          width: number
        } | null
      }
      fragments: {
        __typename?: 'HandbookSectionContent'
        keys: Array<string>
        body?: any | null
        description?: any | null
        goal?: any | null
        headline?: any | null
        introduction?: any | null
        signature?: any | null
        statement?: any | null
        subtitle?: any | null
        title?: any | null
      }
      listEntries?: Array<{
        __typename?: 'HandbookSectionEntry'
        keys: Array<string>
        uid: string
        body?: any | null
        image?: any | null
        subtitle?: any | null
        subtitleAlign?: HandbookSectionTextAlign | null
        title?: any | null
        titleAlign?: HandbookSectionTextAlign | null
      }> | null
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
    }>
    lastPublishedByPerson?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    branding: {
      __typename?: 'HandbookBranding'
      blackColor: any
      favicon?: any | null
      handbookCss?: string | null
      lessButtonText: string
      moreButtonText: string
      primaryColor: any
      printCss?: string | null
      customHtml?: string | null
      whiteColor: any
      bodyFont: {
        __typename?: 'HandbookBrandingFont'
        type: HandbookBrandingFontType
        fontId?: number | null
        family?: string | null
        bold?: number | null
        normal?: number | null
        variants?: Array<string> | null
      }
      headingFont: {
        __typename?: 'HandbookBrandingFont'
        type: HandbookBrandingFontType
        fontId?: number | null
        family?: string | null
        bold?: number | null
        normal?: number | null
        variants?: Array<string> | null
      }
      scriptFont: {
        __typename?: 'HandbookBrandingFont'
        type: HandbookBrandingFontType
        fontId?: number | null
        family?: string | null
        bold?: number | null
        normal?: number | null
        variants?: Array<string> | null
      }
    }
    previewLinks: Array<{
      __typename?: 'HandbookPreviewLink'
      handbookId: number
      personId?: number | null
      token: string
      previewUrl: any
      createdAt: any
    }>
    signatureRounds: Array<{
      __typename?: 'HandbookSignatureRound'
      id: number
      number: number
      onlyAtLaunch?: HandbookSignatureRoundOnlyAtLaunch | null
      onlyMembers: boolean
      dueDays?: number | null
      dueDaysFuture?: number | null
      archived: boolean
      active: boolean
      createdAt: any
      updatedAt: any
      publishedAt?: any | null
      peopleCount: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
    statistics: {
      __typename?: 'HandbookStatistics'
      peopleCount: number
      canNotifyPeopleCount: number
      canNotifyNonCompliantPeopleCount: number
      nonCompliantPeopleCount: number
      notifiedPeopleCount: number
      maxNotifiedPeopleCount: number
      viewedPeopleCount: number
      maxViewedPeopleCount: number
      signedPeopleCount: number
      maxSignedPeopleCount: number
      validSignedPeopleCount: number
      maxValidSignedPeopleCount: number
      score?: number | null
    }
    tasks: Array<{
      __typename?: 'HandbookTask'
      id: HandbookTaskId
      completedAt?: any | null
      completedBy?: number | null
    }>
    versions: Array<{
      __typename?: 'HandbookVersionSummary'
      createdAt?: any | null
      createdBy?: number | null
      defaultLanguageCode: string
      handbookId: number
      label?: string | null
      languageCodes: Array<string>
      printUrl: any
      url: any
      version: number
      createdByPerson?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
    audience: {
      __typename?: 'HandbookAudience'
      member: boolean
      groupIds: Array<number>
      personIds: Array<number>
      savedSegmentIds: Array<number>
    }
  }
}

export type HandbookRecipientsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<HandbookRecipientsFilter>
  handbookId: Scalars['Int']
  limit?: InputMaybe<Scalars['Int']>
  order?: InputMaybe<HandbookRecipientsSortOrder>
}>

export type HandbookRecipientsQuery = {
  __typename?: 'Query'
  handbookRecipients: Array<{
    __typename?: 'HandbookRecipient'
    sortKey?: string | null
    canNotify: boolean
    email?: any | null
    fullName: string
    employeeId?: string | null
    groupIds: Array<number>
    isManager: boolean
    lastHiredOn?: any | null
    metadata?: any | null
    handbookId: number
    personId: number
    canView: boolean
    signatureRoundId?: number | null
    signatureRequestedAt?: any | null
    signatureDueDate?: any | null
    signatureDueAt?: any | null
    isSignatureRequired: boolean
    hasValidSignature: boolean
    lastNotificationQueuedAt?: any | null
    lastNotifiedAt?: any | null
    lastViewedAt?: any | null
    lastViewedVersion?: number | null
    lastSignedAt?: any | null
    lastSignedVersion?: number | null
    score: number
    managers: Array<{
      __typename?: 'PersonManager'
      id: number
      email?: any | null
      employeeId?: string | null
      fullName: string
    }>
  }>
}

export type HandbookStatisticsQueryVariables = Exact<{
  filter?: InputMaybe<HandbookRecipientsFilter>
  handbookId: Scalars['Int']
}>

export type HandbookStatisticsQuery = {
  __typename?: 'Query'
  handbookStatistics: {
    __typename?: 'HandbookStatistics'
    peopleCount: number
    canNotifyPeopleCount: number
    canNotifyNonCompliantPeopleCount: number
    nonCompliantPeopleCount: number
    notifiedPeopleCount: number
    maxNotifiedPeopleCount: number
    viewedPeopleCount: number
    maxViewedPeopleCount: number
    signedPeopleCount: number
    maxSignedPeopleCount: number
    validSignedPeopleCount: number
    maxValidSignedPeopleCount: number
    score?: number | null
  }
}

export type HandbookSignatureRoundsQueryVariables = Exact<{
  handbookId: Scalars['Int']
}>

export type HandbookSignatureRoundsQuery = {
  __typename?: 'Query'
  handbook: {
    __typename?: 'Handbook'
    id: number
    signatureRounds: Array<{
      __typename?: 'HandbookSignatureRound'
      id: number
      number: number
      onlyAtLaunch?: HandbookSignatureRoundOnlyAtLaunch | null
      onlyMembers: boolean
      dueDays?: number | null
      dueDaysFuture?: number | null
      archived: boolean
      active: boolean
      createdAt: any
      updatedAt: any
      publishedAt?: any | null
      peopleCount: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
  }
}

export type ManagerSettingsQueryVariables = Exact<{ [key: string]: never }>

export type ManagerSettingsQuery = {
  __typename?: 'Query'
  managerSettings: {
    __typename?: 'ManagerSettings'
    allowNotificationsToManagers: boolean
    allowNotificationsToReports: boolean
    organizationId: number
    updatedAt?: any | null
  }
}

export type OrganizationQueryVariables = Exact<{ [key: string]: never }>

export type OrganizationQuery = {
  __typename?: 'Query'
  featureFlagIds: Array<string>
  metadataKeys: Array<string>
  audienceDashboardSettings: {
    __typename?: 'AudienceDashboardSettings'
    primaryColor: any
    accentColor: any
    updatedAt?: any | null
    createdAt?: any | null
    faviconImage?: {
      __typename?: 'Image'
      width?: number | null
      height?: number | null
      id: number
      type: AssetType
      tag: string
      filename: string
      filesize: number
      path: string
      thumbnailUrl?: string | null
      url: string
      mimeType?: string | null
      archived: boolean
      createdAt: any
    } | null
    logoImage?: {
      __typename?: 'Image'
      width?: number | null
      height?: number | null
      id: number
      type: AssetType
      tag: string
      filename: string
      filesize: number
      path: string
      thumbnailUrl?: string | null
      url: string
      mimeType?: string | null
      archived: boolean
      createdAt: any
    } | null
    bannerImage?: {
      __typename?: 'Image'
      width?: number | null
      height?: number | null
      id: number
      type: AssetType
      tag: string
      filename: string
      filesize: number
      path: string
      thumbnailUrl?: string | null
      url: string
      mimeType?: string | null
      archived: boolean
      createdAt: any
    } | null
  }
  billing?: {
    __typename?: 'Billing'
    name?: string | null
    contactName?: string | null
    address?: string | null
    toEmails: Array<any>
    credits?: number | null
    peopleCount: number
    exempt: boolean
    monthlyGrowthRate?: number | null
    monthlyCreditPrice?: number | null
    yearlyCreditPrice?: number | null
    termStartCount?: number | null
    termMonths?: number | null
    termMonthlyGrowthRate?: number | null
    paymentMethod?: string | null
    paymentDays?: number | null
    expiredAt?: any | null
    canceledAt?: any | null
    terminatedAt?: any | null
    emailSettings: {
      __typename?: 'BillingEmailSettings'
      toAdmins: boolean
      toEmails: Array<any>
    }
  } | null
  chargebeeSubscription?: {
    __typename?: 'ChargebeeSubscription'
    status: string
    lastSyncAt?: any | null
  } | null
  customDomain?: {
    __typename?: 'CustomDomain'
    domain: any
    domainTarget: any
    isAutoRenew: boolean
    isDnsValid?: boolean | null
    dnsLastValidAt?: any | null
    certificateDigest?: string | null
    certificates: Array<{
      __typename?: 'CustomDomainCertificate'
      digest: string
      expiresAt: any
      createdAt: any
    }>
  } | null
  emailDomains: Array<{
    __typename?: 'EmailDomain'
    id: number
    domain: any
    environment: string
    fromEmail: any
    fromName: string
    updatedAt: any
    createdAt: any
    dkimStatus: {
      __typename?: 'EmailDomainDkimStatus'
      verified: boolean
      lastVerifiedAt?: any | null
    }
    returnPathStatus: {
      __typename?: 'EmailDomainReturnPathStatus'
      verified: boolean
      lastVerifiedAt?: any | null
    }
  }>
  emailSettings: {
    __typename?: 'EmailSettings'
    autoSendInvitations?: boolean | null
    fromEmail?: any | null
    fromName?: string | null
    replyToEmail?: any | null
    defaultSenderFromName?: string | null
    defaultSenderReplyToEmail?: any | null
    bodyFont: string
    footerText: string
    primaryColor: any
    hasCustomBranding: boolean
    updatedAt?: any | null
    bannerImage?: {
      __typename?: 'Image'
      width?: number | null
      height?: number | null
      id: number
      type: AssetType
      tag: string
      filename: string
      filesize: number
      path: string
      thumbnailUrl?: string | null
      url: string
      mimeType?: string | null
      archived: boolean
      createdAt: any
    } | null
    footerLinks: Array<{
      __typename?: 'EmailFooterLink'
      text: string
      url: any
    }>
    logoImage?: {
      __typename?: 'Image'
      width?: number | null
      height?: number | null
      id: number
      type: AssetType
      tag: string
      filename: string
      filesize: number
      path: string
      thumbnailUrl?: string | null
      url: string
      mimeType?: string | null
      archived: boolean
      createdAt: any
    } | null
  }
  emailTemplates: Array<{
    __typename?: 'EmailTemplate'
    id: EmailTemplateId
    subjectContent: any
    ccManagers: boolean
    buttonContent: any
    messageContent: any
    createdAt?: any | null
    updatedAt?: any | null
  }>
  groups: Array<{
    __typename?: 'Group'
    id: number
    name: string
    archived: boolean
    createdAt?: any | null
    updatedAt?: any | null
    peopleCount: number
  }>
  handbooks: Array<{
    __typename?: 'Handbook'
    teamId: number
    id: number
    name: string
    defaultLanguageCode: string
    customDomain?: any | null
    customDomainOrigin?: any | null
    isPrintDisabled: boolean
    position: number
    public: boolean
    publicToken: string
    version: number
    createdAt?: any | null
    updatedAt?: any | null
    lastModifiedAt?: any | null
    lastModifiedBy?: number | null
    publishedAt?: any | null
    lastPublishedAt?: any | null
    url: any
    publishedUrl: any
    permissionIds: Array<string>
    contentLength: number
    deletePreviewLinks: boolean
    hasPersonalizedContent: boolean
    hasAudience: boolean
    viewersCount: number
    lastPublishedByPerson?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    branding: {
      __typename?: 'HandbookBranding'
      blackColor: any
      favicon?: any | null
      handbookCss?: string | null
      lessButtonText: string
      moreButtonText: string
      primaryColor: any
      printCss?: string | null
      customHtml?: string | null
      whiteColor: any
      bodyFont: {
        __typename?: 'HandbookBrandingFont'
        type: HandbookBrandingFontType
        fontId?: number | null
        family?: string | null
        bold?: number | null
        normal?: number | null
        variants?: Array<string> | null
      }
      headingFont: {
        __typename?: 'HandbookBrandingFont'
        type: HandbookBrandingFontType
        fontId?: number | null
        family?: string | null
        bold?: number | null
        normal?: number | null
        variants?: Array<string> | null
      }
      scriptFont: {
        __typename?: 'HandbookBrandingFont'
        type: HandbookBrandingFontType
        fontId?: number | null
        family?: string | null
        bold?: number | null
        normal?: number | null
        variants?: Array<string> | null
      }
    }
    previewLinks: Array<{
      __typename?: 'HandbookPreviewLink'
      handbookId: number
      personId?: number | null
      token: string
      previewUrl: any
      createdAt: any
    }>
    signatureRounds: Array<{
      __typename?: 'HandbookSignatureRound'
      id: number
      number: number
      onlyAtLaunch?: HandbookSignatureRoundOnlyAtLaunch | null
      onlyMembers: boolean
      dueDays?: number | null
      dueDaysFuture?: number | null
      archived: boolean
      active: boolean
      createdAt: any
      updatedAt: any
      publishedAt?: any | null
      peopleCount: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
    statistics: {
      __typename?: 'HandbookStatistics'
      peopleCount: number
      canNotifyPeopleCount: number
      canNotifyNonCompliantPeopleCount: number
      nonCompliantPeopleCount: number
      notifiedPeopleCount: number
      maxNotifiedPeopleCount: number
      viewedPeopleCount: number
      maxViewedPeopleCount: number
      signedPeopleCount: number
      maxSignedPeopleCount: number
      validSignedPeopleCount: number
      maxValidSignedPeopleCount: number
      score?: number | null
    }
    tasks: Array<{
      __typename?: 'HandbookTask'
      id: HandbookTaskId
      completedAt?: any | null
      completedBy?: number | null
    }>
    versions: Array<{
      __typename?: 'HandbookVersionSummary'
      createdAt?: any | null
      createdBy?: number | null
      defaultLanguageCode: string
      handbookId: number
      label?: string | null
      languageCodes: Array<string>
      printUrl: any
      url: any
      version: number
      createdByPerson?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
    audience: {
      __typename?: 'HandbookAudience'
      member: boolean
      groupIds: Array<number>
      personIds: Array<number>
      savedSegmentIds: Array<number>
    }
  }>
  handbookReminders: Array<{
    __typename?: 'HandbookReminder'
    id: number
    isEnabled: boolean
    days: number
    date: HandbookReminderDate
    cc: Array<any>
    ccManagers: boolean
    subjectContent: any
    buttonContent: any
    messageContent: any
    createdAt?: any | null
    createdBy?: number | null
    updatedAt?: any | null
    updatedBy?: number | null
  }>
  integrations: Array<
    | {
        __typename?: 'BambooHRIntegration'
        groups: Array<BambooHrGroupId>
        hostname: string
        subdomain: string
        syncTiming?: BambooHrSyncTiming | null
        userId?: string | null
        id: number
        providerId: IntegrationProviderId
        lastSyncAt?: any | null
        lastErrorAt?: any | null
        lastError?: string | null
        createdAt: any
        updatedAt: any
        rejectPeopleExpression?: {
          __typename?: 'RootExpression'
          conjunction: ExpressionConjunction
          expressions: Array<{
            __typename?: 'Expression'
            conjunction: ExpressionConjunction
            operands: Array<
              | {
                  __typename?: 'DateOperand'
                  field: string
                  dateCondition: DateOperandCondition
                  days?: number | null
                  date?: any | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'IdsOperand'
                  isEvery: boolean
                  isNot: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'ManagersOperand'
                  isEvery: boolean
                  isNot: boolean
                  managesDirectly: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectIdsOperand'
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectOperand'
                  field: string
                  path?: string | null
                  isEvery: boolean
                  isNot: boolean
                  values?: Array<string> | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'TextOperand'
                  field: string
                  isNot: boolean
                  textCondition: TextOperandCondition
                  text: string
                  id?: string | null
                  type: OperandType
                }
            >
          }>
        } | null
      }
    | {
        __typename?: 'RipplingIntegration'
        customFieldKeys: Array<string>
        ripplingGroupIds: Array<RipplingGroupId>
        id: number
        providerId: IntegrationProviderId
        lastSyncAt?: any | null
        lastErrorAt?: any | null
        lastError?: string | null
        createdAt: any
        updatedAt: any
        rejectPeopleExpression?: {
          __typename?: 'RootExpression'
          conjunction: ExpressionConjunction
          expressions: Array<{
            __typename?: 'Expression'
            conjunction: ExpressionConjunction
            operands: Array<
              | {
                  __typename?: 'DateOperand'
                  field: string
                  dateCondition: DateOperandCondition
                  days?: number | null
                  date?: any | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'IdsOperand'
                  isEvery: boolean
                  isNot: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'ManagersOperand'
                  isEvery: boolean
                  isNot: boolean
                  managesDirectly: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectIdsOperand'
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectOperand'
                  field: string
                  path?: string | null
                  isEvery: boolean
                  isNot: boolean
                  values?: Array<string> | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'TextOperand'
                  field: string
                  isNot: boolean
                  textCondition: TextOperandCondition
                  text: string
                  id?: string | null
                  type: OperandType
                }
            >
          }>
        } | null
      }
    | {
        __typename?: 'SftpIntegration'
        username: string
        id: number
        providerId: IntegrationProviderId
        lastSyncAt?: any | null
        lastErrorAt?: any | null
        lastError?: string | null
        createdAt: any
        updatedAt: any
        fingerprints: {
          __typename?: 'SshFingerprints'
          sha256: string
          md5: string
        }
        rejectPeopleExpression?: {
          __typename?: 'RootExpression'
          conjunction: ExpressionConjunction
          expressions: Array<{
            __typename?: 'Expression'
            conjunction: ExpressionConjunction
            operands: Array<
              | {
                  __typename?: 'DateOperand'
                  field: string
                  dateCondition: DateOperandCondition
                  days?: number | null
                  date?: any | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'IdsOperand'
                  isEvery: boolean
                  isNot: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'ManagersOperand'
                  isEvery: boolean
                  isNot: boolean
                  managesDirectly: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectIdsOperand'
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectOperand'
                  field: string
                  path?: string | null
                  isEvery: boolean
                  isNot: boolean
                  values?: Array<string> | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'TextOperand'
                  field: string
                  isNot: boolean
                  textCondition: TextOperandCondition
                  text: string
                  id?: string | null
                  type: OperandType
                }
            >
          }>
        } | null
      }
    | {
        __typename?: 'NewSftpIntegration'
        id: number
        providerId: IntegrationProviderId
        lastSyncAt?: any | null
        lastErrorAt?: any | null
        lastError?: string | null
        createdAt: any
        updatedAt: any
        rejectPeopleExpression?: {
          __typename?: 'RootExpression'
          conjunction: ExpressionConjunction
          expressions: Array<{
            __typename?: 'Expression'
            conjunction: ExpressionConjunction
            operands: Array<
              | {
                  __typename?: 'DateOperand'
                  field: string
                  dateCondition: DateOperandCondition
                  days?: number | null
                  date?: any | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'IdsOperand'
                  isEvery: boolean
                  isNot: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'ManagersOperand'
                  isEvery: boolean
                  isNot: boolean
                  managesDirectly: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectIdsOperand'
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectOperand'
                  field: string
                  path?: string | null
                  isEvery: boolean
                  isNot: boolean
                  values?: Array<string> | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'TextOperand'
                  field: string
                  isNot: boolean
                  textCondition: TextOperandCondition
                  text: string
                  id?: string | null
                  type: OperandType
                }
            >
          }>
        } | null
      }
    | {
        __typename?: 'HttpsIntegration'
        id: number
        providerId: IntegrationProviderId
        lastSyncAt?: any | null
        lastErrorAt?: any | null
        lastError?: string | null
        createdAt: any
        updatedAt: any
        rejectPeopleExpression?: {
          __typename?: 'RootExpression'
          conjunction: ExpressionConjunction
          expressions: Array<{
            __typename?: 'Expression'
            conjunction: ExpressionConjunction
            operands: Array<
              | {
                  __typename?: 'DateOperand'
                  field: string
                  dateCondition: DateOperandCondition
                  days?: number | null
                  date?: any | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'IdsOperand'
                  isEvery: boolean
                  isNot: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'ManagersOperand'
                  isEvery: boolean
                  isNot: boolean
                  managesDirectly: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectIdsOperand'
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectOperand'
                  field: string
                  path?: string | null
                  isEvery: boolean
                  isNot: boolean
                  values?: Array<string> | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'TextOperand'
                  field: string
                  isNot: boolean
                  textCondition: TextOperandCondition
                  text: string
                  id?: string | null
                  type: OperandType
                }
            >
          }>
        } | null
      }
    | {
        __typename?: 'MergeDevIntegration'
        externalId: string
        integrationName: string
        syncHourUtc?: number | null
        id: number
        providerId: IntegrationProviderId
        lastSyncAt?: any | null
        lastErrorAt?: any | null
        lastError?: string | null
        createdAt: any
        updatedAt: any
        rejectPeopleExpression?: {
          __typename?: 'RootExpression'
          conjunction: ExpressionConjunction
          expressions: Array<{
            __typename?: 'Expression'
            conjunction: ExpressionConjunction
            operands: Array<
              | {
                  __typename?: 'DateOperand'
                  field: string
                  dateCondition: DateOperandCondition
                  days?: number | null
                  date?: any | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'IdsOperand'
                  isEvery: boolean
                  isNot: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'ManagersOperand'
                  isEvery: boolean
                  isNot: boolean
                  managesDirectly: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectIdsOperand'
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectOperand'
                  field: string
                  path?: string | null
                  isEvery: boolean
                  isNot: boolean
                  values?: Array<string> | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'TextOperand'
                  field: string
                  isNot: boolean
                  textCondition: TextOperandCondition
                  text: string
                  id?: string | null
                  type: OperandType
                }
            >
          }>
        } | null
      }
  >
  managerDigestSettings: {
    __typename?: 'ManagerDigestSettings'
    isEnabled: boolean
    isDirectReportsOnly: boolean
    daysAfter: number
    date: ManagerDigestDate
    subjectContent: any
    buttonContent: any
    messageContent: any
    updatedAt?: any | null
  }
  organization: {
    __typename?: 'Organization'
    id: number
    name: string
    subdomain: any
    origin: any
    blissbookOrigin: any
    isFullServe: boolean
    isCrmDisabled: boolean
    isIntercomDisabled: boolean
    isAuthDisabled: boolean
    isHandbookPositionEnabled: boolean
    showPrivacyPolicy: boolean
    createdAt: any
    createdBy?: number | null
    updatedAt: any
    emailPeopleCount: number
    employeeIdPeopleCount: number
    friendsCount: number
    lastHiredOnPeopleCount: number
    managersCount: number
    membersCount: number
    peopleCount: number
    phonePeopleCount: number
    publishedAt?: any | null
    logoImage?: {
      __typename?: 'Image'
      width?: number | null
      height?: number | null
      id: number
      type: AssetType
      tag: string
      filename: string
      filesize: number
      path: string
      thumbnailUrl?: string | null
      url: string
      mimeType?: string | null
      archived: boolean
      createdAt: any
    } | null
  }
  organizationTasks: Array<{
    __typename?: 'OrganizationTask'
    id: OrganizationTaskId
    completedAt?: any | null
    completedBy?: number | null
  }>
  securitySettings: {
    __typename?: 'SecuritySettings'
    emailAuth: {
      __typename?: 'EmailAuthSettings'
      isEnabled: boolean
      updatedAt?: any | null
    }
    kioskMode: {
      __typename?: 'KioskModeSettings'
      isEnabled: boolean
      inactivitySeconds: number
      timeoutSeconds: number
      updatedAt?: any | null
    }
    phoneAuth: {
      __typename?: 'PhoneAuthSettings'
      isEnabled: boolean
      helpContent?: any | null
      updatedAt?: any | null
    }
    singleSignOns: Array<{
      __typename?: 'SingleSignOn'
      providerId: SingleSignOnProviderId
      issuer?: any | null
      loginUrl?: any | null
      authKey?: SingleSignOnAuthKey | null
      buttonText?: string | null
      createdAt: any
      updatedAt: any
      certificates: Array<{
        __typename?: 'SingleSignOnCertificate'
        digest: string
        createdAt: any
        archivedAt?: any | null
      }>
    }>
  }
  teams: Array<{
    __typename?: 'Team'
    id: number
    permissionIds: Array<string>
    createdAt: any
    updatedAt: any
    handbookCollaborators: Array<{
      __typename?: 'HandbookCollaborator'
      id: number
      handbookId: number
      personId: number
      roleId?: HandbookCollaboratorRoleId | null
    }>
    teammates: Array<{
      __typename?: 'Teammate'
      id: number
      teamId: number
      personId: number
      canReport: boolean
      canSuperBrand: boolean
      roleId?: TeamRoleId | null
      person: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      }
    }>
  }>
}

export type HrisPeopleQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<HrisPeopleFilter>
  integrationId: Scalars['Int']
  limit?: InputMaybe<Scalars['Int']>
  order?: InputMaybe<PeopleSortOrder>
}>

export type HrisPeopleQuery = {
  __typename?: 'Query'
  hrisPeople: Array<{
    __typename?: 'HrisPerson'
    email?: any | null
    externalId?: string | null
    employeeId?: string | null
    phone?: any | null
    fullName: string
    lastHiredOn?: any | null
    groupNames?: Array<string> | null
    metadata?: any | null
    sortKey: string
  }>
}

export type HrisPeopleStatisticsQueryVariables = Exact<{
  filter?: InputMaybe<HrisPeopleFilter>
  integrationId: Scalars['Int']
}>

export type HrisPeopleStatisticsQuery = {
  __typename?: 'Query'
  hrisPeopleStatistics: {
    __typename?: 'HrisPeopleStatistics'
    totalCount: number
  }
}

export type PersonQueryVariables = Exact<{
  personId: Scalars['Int']
}>

export type PersonQuery = {
  __typename?: 'Query'
  directReports: Array<{
    __typename?: 'Person'
    id: number
    email?: any | null
    employeeId?: string | null
    fullName: string
  }>
  people: Array<{
    __typename?: 'Person'
    id: number
    email?: any | null
    externalId?: string | null
    employeeId?: string | null
    phone?: any | null
    fullName: string
    archived: boolean
    archivedAt?: any | null
    member: boolean
    adminRoleId?: AdminRoleId | null
    isSuperUser: boolean
    lastHiredOn?: any | null
    avatarUrl?: string | null
    lastActivatedAt: any
    isSignatureRequired: boolean
    isEmployeeSignatureRequired: boolean
    totalDirectReportsCount: number
    nonCompliantDirectReportsCount: number
    nonCompliantDirectReportsPercentage: number
    totalReportsCount: number
    nonCompliantReportsCount: number
    nonCompliantReportsPercentage: number
    lastEmployeeDigestQueuedAt?: any | null
    lastManagerDigestQueuedAt?: any | null
    lastNotifiedAt?: any | null
    lastViewedAt?: any | null
    lastSignedAt?: any | null
    autoSendReminders: boolean
    isManager: boolean
    createdAt: any
    updatedAt: any
    groupIds: Array<number>
    handbookIds: Array<number>
    savedSegmentIds: Array<number>
    metadata?: any | null
    userIds: Array<number>
    sortKey?: string | null
    handbookRecipients: Array<{
      __typename?: 'HandbookRecipient'
      canNotify: boolean
      fullName: string
      signatureRoundIds: Array<number>
      handbookId: number
      personId: number
      canView: boolean
      signatureRoundId?: number | null
      signatureRequestedAt?: any | null
      signatureDueDate?: any | null
      signatureDueAt?: any | null
      isSignatureRequired: boolean
      hasValidSignature: boolean
      lastNotificationQueuedAt?: any | null
      lastNotifiedAt?: any | null
      lastViewedAt?: any | null
      lastViewedVersion?: number | null
      lastSignedAt?: any | null
      lastSignedVersion?: number | null
      score: number
      signatures: Array<{ __typename?: 'HandbookSignature'; id: number }>
    }>
    managers: Array<{
      __typename?: 'PersonManager'
      id: number
      email?: any | null
      employeeId?: string | null
      fullName: string
    }>
  }>
}

export type PersonSignaturesQueryVariables = Exact<{
  personId: Scalars['Int']
}>

export type PersonSignaturesQuery = {
  __typename?: 'Query'
  people: Array<{
    __typename?: 'Person'
    id: number
    email?: any | null
    externalId?: string | null
    employeeId?: string | null
    phone?: any | null
    fullName: string
    archived: boolean
    archivedAt?: any | null
    member: boolean
    adminRoleId?: AdminRoleId | null
    isSuperUser: boolean
    lastHiredOn?: any | null
    avatarUrl?: string | null
    lastActivatedAt: any
    isSignatureRequired: boolean
    isEmployeeSignatureRequired: boolean
    totalDirectReportsCount: number
    nonCompliantDirectReportsCount: number
    nonCompliantDirectReportsPercentage: number
    totalReportsCount: number
    nonCompliantReportsCount: number
    nonCompliantReportsPercentage: number
    lastEmployeeDigestQueuedAt?: any | null
    lastManagerDigestQueuedAt?: any | null
    lastNotifiedAt?: any | null
    lastViewedAt?: any | null
    lastSignedAt?: any | null
    autoSendReminders: boolean
    isManager: boolean
    createdAt: any
    updatedAt: any
    groupIds: Array<number>
    handbookIds: Array<number>
    savedSegmentIds: Array<number>
    metadata?: any | null
    userIds: Array<number>
    sortKey?: string | null
    handbookRecipients: Array<{
      __typename?: 'HandbookRecipient'
      canNotify: boolean
      fullName: string
      signatureRoundIds: Array<number>
      handbookId: number
      personId: number
      canView: boolean
      signatureRoundId?: number | null
      signatureRequestedAt?: any | null
      signatureDueDate?: any | null
      signatureDueAt?: any | null
      isSignatureRequired: boolean
      hasValidSignature: boolean
      lastNotificationQueuedAt?: any | null
      lastNotifiedAt?: any | null
      lastViewedAt?: any | null
      lastViewedVersion?: number | null
      lastSignedAt?: any | null
      lastSignedVersion?: number | null
      score: number
      signatures: Array<{
        __typename?: 'HandbookSignature'
        id: number
        createdAt: any
        handbookId: number
        handbookVersion: number
        languageCode: string
        personId: number
        recordedByPersonId?: number | null
        recordedByUserId?: number | null
        roundId: number
        signature?: string | null
        signedAt: any
        acknowledgementForm: {
          __typename?: 'HandbookSignatureAcknowledgement'
          body: any
          wysiwyg?: string | null
        }
        personalization: {
          __typename?: 'Personalization'
          groupIds: Array<number>
          metadata?: any | null
          personId: number
          savedSegmentIds: Array<number>
        }
        toc: Array<{
          __typename?: 'HandbookSignatureTocItem'
          bookmark: string
          printUrl: string
          title: string
        }>
        user?: {
          __typename?: 'HandbookUser'
          id: number
          authType: UserAuthType
          authId: string
        } | null
      }>
      signatureDueDateOverrides: Array<{
        __typename?: 'HandbookSignatureDueDateOverride'
        id: number
        roundId: number
        previousDueDate?: any | null
        dueDate: any
        note?: string | null
        createdAt: any
        createdBy?: number | null
      }>
    }>
    managers: Array<{
      __typename?: 'PersonManager'
      id: number
      email?: any | null
      employeeId?: string | null
      fullName: string
    }>
  }>
}

export type PersonEventDetailsQueryVariables = Exact<{
  personId: Scalars['Int']
}>

export type PersonEventDetailsQuery = {
  __typename?: 'Query'
  people: Array<{
    __typename?: 'Person'
    notifications: Array<{
      __typename?: 'HandbookNotification'
      id: number
      handbookId?: number | null
      personId: number
      email: any
      toName?: string | null
      fromEmail?: any | null
      fromName?: string | null
      subject?: string | null
      body?: string | null
      createdAt: any
      sentAt?: any | null
      lastError?: string | null
      lastErrorAt?: any | null
    }>
  }>
}

export type PeopleQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>
  canNotify?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<PeopleFilter>
  ids?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
  notIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>
  order?: InputMaybe<PeopleSortOrder>
  role?: InputMaybe<OrganizationRoleQuery>
  search?: InputMaybe<Scalars['String']>
  showArchived?: InputMaybe<Scalars['Boolean']>
}>

export type PeopleQuery = {
  __typename?: 'Query'
  people: Array<{
    __typename?: 'Person'
    id: number
    email?: any | null
    externalId?: string | null
    employeeId?: string | null
    phone?: any | null
    fullName: string
    archived: boolean
    archivedAt?: any | null
    member: boolean
    adminRoleId?: AdminRoleId | null
    isSuperUser: boolean
    lastHiredOn?: any | null
    avatarUrl?: string | null
    lastActivatedAt: any
    isSignatureRequired: boolean
    isEmployeeSignatureRequired: boolean
    totalDirectReportsCount: number
    nonCompliantDirectReportsCount: number
    nonCompliantDirectReportsPercentage: number
    totalReportsCount: number
    nonCompliantReportsCount: number
    nonCompliantReportsPercentage: number
    lastEmployeeDigestQueuedAt?: any | null
    lastManagerDigestQueuedAt?: any | null
    lastNotifiedAt?: any | null
    lastViewedAt?: any | null
    lastSignedAt?: any | null
    autoSendReminders: boolean
    isManager: boolean
    createdAt: any
    updatedAt: any
    groupIds: Array<number>
    handbookIds: Array<number>
    savedSegmentIds: Array<number>
    metadata?: any | null
    userIds: Array<number>
    sortKey?: string | null
    handbookRecipients: Array<{
      __typename?: 'HandbookRecipient'
      handbookId: number
      personId: number
      isSignatureRequired: boolean
    }>
    managers: Array<{
      __typename?: 'PersonManager'
      id: number
      email?: any | null
      employeeId?: string | null
      fullName: string
    }>
  }>
}

export type PeopleStatisticsQueryVariables = Exact<{
  filter?: InputMaybe<PeopleFilter>
  showArchived?: InputMaybe<Scalars['Boolean']>
}>

export type PeopleStatisticsQuery = {
  __typename?: 'Query'
  peopleStatistics: {
    __typename?: 'PeopleStatistics'
    canNotifyCount: number
    canNotifyNonCompliantCount: number
    canNotifyNonCompliantManagersCount: number
    nonCompliantCount: number
    nonCompliantManagersCount: number
    totalCount: number
    totalManagersCount: number
  }
}

export type SessionQueryVariables = Exact<{ [key: string]: never }>

export type SessionQuery = {
  __typename?: 'Query'
  session?: {
    __typename?: 'Session'
    expiresAt: any
    isImpersonating: boolean
    permissionIds: Array<string>
    person?: {
      __typename?: 'SessionPerson'
      id: number
      idHash?: string | null
      email?: any | null
      fullName?: string | null
      member: boolean
      adminRoleId?: AdminRoleId | null
      createdAt: any
    } | null
    user?:
      | {
          __typename?: 'SessionSuperUser'
          email: string
          fullName: string
          roleId?: SuperUserRoleId | null
          id: number
          type: UserType
          authType: UserAuthType
          authId: string
          createdAt: any
          lastSignedInAt?: any | null
        }
      | {
          __typename?: 'SessionUser'
          id: number
          type: UserType
          authType: UserAuthType
          authId: string
          createdAt: any
          lastSignedInAt?: any | null
        }
      | null
  } | null
}

export type ChargebeeSubscriptionDetailsQueryVariables = Exact<{
  [key: string]: never
}>

export type ChargebeeSubscriptionDetailsQuery = {
  __typename?: 'Query'
  chargebeeSubscriptionDetails?: {
    __typename?: 'ChargebeeSubscriptionDetails'
    currency_code: string
    customer_id: string
    id: string
    next_billing_at?: any | null
    status: string
    cancelled_at?: any | null
    payment_source_id?: string | null
    subscription_items: Array<{
      __typename?: 'ChargebeeSubscriptionItem'
      item_price_id?: string | null
      item_type?: string | null
      name?: string | null
      external_name?: string | null
      amount?: number | null
      currency_code?: string | null
    }>
    charged_items?: Array<{
      __typename?: 'ChargebeeSubscriptionChargedItem'
      item_price_id?: string | null
    }> | null
    invoices: Array<{
      __typename?: 'ChargebeeInvoice'
      id: string
      date?: any | null
      paidAt?: any | null
      total?: number | null
      currency_code: string
      download_url?: string | null
    }>
    discounts?: Array<{
      __typename?: 'ChargebeeSubscriptionDiscount'
      amount: number
    }> | null
  } | null
}

export type ChargebeeSubscriptionQueryVariables = Exact<{
  [key: string]: never
}>

export type ChargebeeSubscriptionQuery = {
  __typename?: 'Query'
  chargebeeSubscription?: {
    __typename?: 'ChargebeeSubscription'
    status: string
    lastSyncAt?: any | null
  } | null
}

export type ChargebeePortalSessionQueryVariables = Exact<{
  customerId: Scalars['String']
}>

export type ChargebeePortalSessionQuery = {
  __typename?: 'Query'
  chargebeePortalSession?: {
    __typename?: 'ChargebeePortalSession'
    id: string
    token: string
    access_url: string
    status: string
    customer_id: string
  } | null
}

export type GroupNamesQueryVariables = Exact<{
  source?: InputMaybe<PeopleSource>
}>

export type GroupNamesQuery = {
  __typename?: 'Query'
  groupNames: Array<string>
}

export type MetadataKeysQueryVariables = Exact<{
  source?: InputMaybe<PeopleSource>
}>

export type MetadataKeysQuery = {
  __typename?: 'Query'
  metadataKeys: Array<string>
}

export type MetadataValuesQueryVariables = Exact<{
  metadataKey: Scalars['String']
  source?: InputMaybe<PeopleSource>
}>

export type MetadataValuesQuery = {
  __typename?: 'Query'
  metadataValues: Array<string>
}

export type OrgCollaboratorsQueryVariables = Exact<{ [key: string]: never }>

export type OrgCollaboratorsQuery = {
  __typename?: 'Query'
  orgCollaborators: Array<{
    __typename?: 'Person'
    id: number
    email?: any | null
    externalId?: string | null
    employeeId?: string | null
    phone?: any | null
    fullName: string
    archived: boolean
    archivedAt?: any | null
    member: boolean
    adminRoleId?: AdminRoleId | null
    isSuperUser: boolean
    lastHiredOn?: any | null
    avatarUrl?: string | null
    lastActivatedAt: any
    isSignatureRequired: boolean
    isEmployeeSignatureRequired: boolean
    totalDirectReportsCount: number
    nonCompliantDirectReportsCount: number
    nonCompliantDirectReportsPercentage: number
    totalReportsCount: number
    nonCompliantReportsCount: number
    nonCompliantReportsPercentage: number
    lastEmployeeDigestQueuedAt?: any | null
    lastManagerDigestQueuedAt?: any | null
    lastNotifiedAt?: any | null
    lastViewedAt?: any | null
    lastSignedAt?: any | null
    autoSendReminders: boolean
    isManager: boolean
    createdAt: any
    updatedAt: any
    groupIds: Array<number>
    handbookIds: Array<number>
    savedSegmentIds: Array<number>
    metadata?: any | null
    userIds: Array<number>
    sortKey?: string | null
    managers: Array<{
      __typename?: 'PersonManager'
      id: number
      email?: any | null
      employeeId?: string | null
      fullName: string
    }>
  }>
}

export type TeamsQueryVariables = Exact<{ [key: string]: never }>

export type TeamsQuery = {
  __typename?: 'Query'
  teams: Array<{
    __typename?: 'Team'
    id: number
    permissionIds: Array<string>
    createdAt: any
    updatedAt: any
    handbookCollaborators: Array<{
      __typename?: 'HandbookCollaborator'
      id: number
      handbookId: number
      personId: number
      roleId?: HandbookCollaboratorRoleId | null
    }>
    teammates: Array<{
      __typename?: 'Teammate'
      id: number
      teamId: number
      personId: number
      canReport: boolean
      canSuperBrand: boolean
      roleId?: TeamRoleId | null
      person: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      }
    }>
  }>
}

export type DocumentSettingsQueryVariables = Exact<{ [key: string]: never }>

export type DocumentSettingsQuery = {
  __typename?: 'Query'
  documentSettings: {
    __typename?: 'DocumentSettings'
    isPolicyWorkflowEnabled: boolean
    organizationId: number
  }
}

export type DocumentsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<DocumentsFilter>
  limit?: InputMaybe<Scalars['Int']>
  order?: InputMaybe<DocumentsSortOrder>
}>

export type DocumentsQuery = {
  __typename?: 'Query'
  documents: Array<{
    __typename?: 'Document'
    sortKey?: string | null
    id: number
    ckeditorChannelId: string
    parentFolderId?: number | null
    sourceHandbookId?: number | null
    sourceHandbookSectionId?: number | null
    sortPosition: number
    type: DocumentType
    name: string
    archivedAt?: any | null
    createdAt: any
    updatedAt: any
    handbookIds: Array<number>
    lastPublishedVersionNumber: number
    archivedBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    createdBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    updatedBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    draftVersion?: {
      __typename?: 'DocumentVersion'
      audienceExpressionHash: any
      createdAt: any
      documentId: number
      html?: any | null
      htmlHash: any
      htmlTitle?: string | null
      id: number
      isPublished: boolean
      updatedAt: any
      versionNumber: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      reviews: Array<{
        __typename?: 'DocumentReview'
        id: number
        documentId: number
        personId: number
        isApproved: boolean
        createdAt: any
        person: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        }
      }>
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    } | null
    lastPublishedVersion?: {
      __typename?: 'DocumentVersion'
      audienceExpressionHash: any
      createdAt: any
      documentId: number
      html?: any | null
      htmlHash: any
      htmlTitle?: string | null
      id: number
      isPublished: boolean
      updatedAt: any
      versionNumber: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      reviews: Array<{
        __typename?: 'DocumentReview'
        id: number
        documentId: number
        personId: number
        isApproved: boolean
        createdAt: any
        person: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        }
      }>
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    } | null
    reviewers: Array<{
      __typename?: 'DocumentReviewer'
      id: number
      documentId: number
      personId: number
      createdAt: any
      updatedAt: any
      person: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      }
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
  }>
  folders: Array<{
    __typename?: 'Folder'
    id: number
    parentFolderId?: number | null
    name: string
  }>
}

export type DocumentQueryVariables = Exact<{
  documentId: Scalars['Int']
}>

export type DocumentQuery = {
  __typename?: 'Query'
  document: {
    __typename?: 'Document'
    id: number
    ckeditorChannelId: string
    parentFolderId?: number | null
    sourceHandbookId?: number | null
    sourceHandbookSectionId?: number | null
    sortPosition: number
    type: DocumentType
    name: string
    archivedAt?: any | null
    createdAt: any
    updatedAt: any
    handbookIds: Array<number>
    lastPublishedVersionNumber: number
    publishedVersions: Array<{
      __typename?: 'DocumentVersion'
      audienceExpressionHash: any
      createdAt: any
      documentId: number
      html?: any | null
      htmlHash: any
      htmlTitle?: string | null
      id: number
      isPublished: boolean
      updatedAt: any
      versionNumber: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      reviews: Array<{
        __typename?: 'DocumentReview'
        id: number
        documentId: number
        personId: number
        isApproved: boolean
        createdAt: any
        person: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        }
      }>
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
    archivedBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    createdBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    updatedBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    draftVersion?: {
      __typename?: 'DocumentVersion'
      audienceExpressionHash: any
      createdAt: any
      documentId: number
      html?: any | null
      htmlHash: any
      htmlTitle?: string | null
      id: number
      isPublished: boolean
      updatedAt: any
      versionNumber: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      reviews: Array<{
        __typename?: 'DocumentReview'
        id: number
        documentId: number
        personId: number
        isApproved: boolean
        createdAt: any
        person: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        }
      }>
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    } | null
    lastPublishedVersion?: {
      __typename?: 'DocumentVersion'
      audienceExpressionHash: any
      createdAt: any
      documentId: number
      html?: any | null
      htmlHash: any
      htmlTitle?: string | null
      id: number
      isPublished: boolean
      updatedAt: any
      versionNumber: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      reviews: Array<{
        __typename?: 'DocumentReview'
        id: number
        documentId: number
        personId: number
        isApproved: boolean
        createdAt: any
        person: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        }
      }>
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    } | null
    reviewers: Array<{
      __typename?: 'DocumentReviewer'
      id: number
      documentId: number
      personId: number
      createdAt: any
      updatedAt: any
      person: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      }
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
  }
  documentSettings: {
    __typename?: 'DocumentSettings'
    isPolicyWorkflowEnabled: boolean
    organizationId: number
  }
  documents: Array<{ __typename?: 'Document'; id: number; name: string }>
}

export type SetDocumentReviewersMutationVariables = Exact<{
  documentId: Scalars['Int']
  personIds: Array<Scalars['Int']> | Scalars['Int']
}>

export type SetDocumentReviewersMutation = {
  __typename?: 'Mutation'
  reviewers: Array<{
    __typename?: 'DocumentReviewer'
    id: number
    documentId: number
    personId: number
    createdAt: any
    updatedAt: any
    person: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    }
    createdBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    updatedBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
  }>
}

export type AddDocumentReviewMutationVariables = Exact<{
  documentId: Scalars['Int']
  isApproved: Scalars['Boolean']
}>

export type AddDocumentReviewMutation = {
  __typename?: 'Mutation'
  addDocumentReview: {
    __typename?: 'DocumentReview'
    id: number
    documentId: number
    personId: number
    isApproved: boolean
    createdAt: any
    person: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    }
  }
}

export type CreateFolderMutationVariables = Exact<{
  input: CreateFolderInput
}>

export type CreateFolderMutation = {
  __typename?: 'Mutation'
  folder: {
    __typename?: 'Folder'
    id: number
    parentFolderId?: number | null
    name: string
  }
}

export type UpdateFolderMutationVariables = Exact<{
  folderId: Scalars['Int']
  input: UpdateFolderInput
}>

export type UpdateFolderMutation = {
  __typename?: 'Mutation'
  folder: {
    __typename?: 'Folder'
    id: number
    parentFolderId?: number | null
    name: string
  }
}

export type DeleteFolderMutationVariables = Exact<{
  folderId: Scalars['Int']
}>

export type DeleteFolderMutation = {
  __typename?: 'Mutation'
  deleteFolder: boolean
}

export type LinkChargebeeSubscriptionMutationVariables = Exact<{
  subscriptionId: Scalars['String']
}>

export type LinkChargebeeSubscriptionMutation = {
  __typename?: 'Mutation'
  chargebeeSubscription: {
    __typename?: 'ChargebeeSubscription'
    status: string
    lastSyncAt?: any | null
  }
}

export type UnlinkChargebeeSubscriptionMutationVariables = Exact<{
  [key: string]: never
}>

export type UnlinkChargebeeSubscriptionMutation = {
  __typename?: 'Mutation'
  unlinked?: boolean | null
}

export type AddAdminMutationVariables = Exact<{
  adminRoleId: AdminRoleId
  notification?: InputMaybe<OrgCollaboratorNotificationInput>
  personId: Scalars['Int']
}>

export type AddAdminMutation = {
  __typename?: 'Mutation'
  addAdmin: {
    __typename?: 'Person'
    id: number
    email?: any | null
    externalId?: string | null
    employeeId?: string | null
    phone?: any | null
    fullName: string
    archived: boolean
    archivedAt?: any | null
    member: boolean
    adminRoleId?: AdminRoleId | null
    isSuperUser: boolean
    lastHiredOn?: any | null
    avatarUrl?: string | null
    lastActivatedAt: any
    isSignatureRequired: boolean
    isEmployeeSignatureRequired: boolean
    totalDirectReportsCount: number
    nonCompliantDirectReportsCount: number
    nonCompliantDirectReportsPercentage: number
    totalReportsCount: number
    nonCompliantReportsCount: number
    nonCompliantReportsPercentage: number
    lastEmployeeDigestQueuedAt?: any | null
    lastManagerDigestQueuedAt?: any | null
    lastNotifiedAt?: any | null
    lastViewedAt?: any | null
    lastSignedAt?: any | null
    autoSendReminders: boolean
    isManager: boolean
    createdAt: any
    updatedAt: any
    groupIds: Array<number>
    handbookIds: Array<number>
    savedSegmentIds: Array<number>
    metadata?: any | null
    userIds: Array<number>
    sortKey?: string | null
    managers: Array<{
      __typename?: 'PersonManager'
      id: number
      email?: any | null
      employeeId?: string | null
      fullName: string
    }>
  }
}

export type AddNewAdminMutationVariables = Exact<{
  adminRoleId: AdminRoleId
  notification?: InputMaybe<OrgCollaboratorNotificationInput>
  personInput: NewOrgCollaboratorInput
}>

export type AddNewAdminMutation = {
  __typename?: 'Mutation'
  addNewAdmin: {
    __typename?: 'Person'
    id: number
    email?: any | null
    externalId?: string | null
    employeeId?: string | null
    phone?: any | null
    fullName: string
    archived: boolean
    archivedAt?: any | null
    member: boolean
    adminRoleId?: AdminRoleId | null
    isSuperUser: boolean
    lastHiredOn?: any | null
    avatarUrl?: string | null
    lastActivatedAt: any
    isSignatureRequired: boolean
    isEmployeeSignatureRequired: boolean
    totalDirectReportsCount: number
    nonCompliantDirectReportsCount: number
    nonCompliantDirectReportsPercentage: number
    totalReportsCount: number
    nonCompliantReportsCount: number
    nonCompliantReportsPercentage: number
    lastEmployeeDigestQueuedAt?: any | null
    lastManagerDigestQueuedAt?: any | null
    lastNotifiedAt?: any | null
    lastViewedAt?: any | null
    lastSignedAt?: any | null
    autoSendReminders: boolean
    isManager: boolean
    createdAt: any
    updatedAt: any
    groupIds: Array<number>
    handbookIds: Array<number>
    savedSegmentIds: Array<number>
    metadata?: any | null
    userIds: Array<number>
    sortKey?: string | null
    managers: Array<{
      __typename?: 'PersonManager'
      id: number
      email?: any | null
      employeeId?: string | null
      fullName: string
    }>
  }
}

export type InviteOrgCollaboratorMutationVariables = Exact<{
  personId: Scalars['Int']
}>

export type InviteOrgCollaboratorMutation = {
  __typename?: 'Mutation'
  inviteOrgCollaborator: {
    __typename?: 'Person'
    id: number
    email?: any | null
    externalId?: string | null
    employeeId?: string | null
    phone?: any | null
    fullName: string
    archived: boolean
    archivedAt?: any | null
    member: boolean
    adminRoleId?: AdminRoleId | null
    isSuperUser: boolean
    lastHiredOn?: any | null
    avatarUrl?: string | null
    lastActivatedAt: any
    isSignatureRequired: boolean
    isEmployeeSignatureRequired: boolean
    totalDirectReportsCount: number
    nonCompliantDirectReportsCount: number
    nonCompliantDirectReportsPercentage: number
    totalReportsCount: number
    nonCompliantReportsCount: number
    nonCompliantReportsPercentage: number
    lastEmployeeDigestQueuedAt?: any | null
    lastManagerDigestQueuedAt?: any | null
    lastNotifiedAt?: any | null
    lastViewedAt?: any | null
    lastSignedAt?: any | null
    autoSendReminders: boolean
    isManager: boolean
    createdAt: any
    updatedAt: any
    groupIds: Array<number>
    handbookIds: Array<number>
    savedSegmentIds: Array<number>
    metadata?: any | null
    userIds: Array<number>
    sortKey?: string | null
    managers: Array<{
      __typename?: 'PersonManager'
      id: number
      email?: any | null
      employeeId?: string | null
      fullName: string
    }>
  }
}

export type UpdateAdminMutationVariables = Exact<{
  personId: Scalars['Int']
  adminRoleId: AdminRoleId
}>

export type UpdateAdminMutation = {
  __typename?: 'Mutation'
  updateAdmin: {
    __typename?: 'Person'
    id: number
    email?: any | null
    externalId?: string | null
    employeeId?: string | null
    phone?: any | null
    fullName: string
    archived: boolean
    archivedAt?: any | null
    member: boolean
    adminRoleId?: AdminRoleId | null
    isSuperUser: boolean
    lastHiredOn?: any | null
    avatarUrl?: string | null
    lastActivatedAt: any
    isSignatureRequired: boolean
    isEmployeeSignatureRequired: boolean
    totalDirectReportsCount: number
    nonCompliantDirectReportsCount: number
    nonCompliantDirectReportsPercentage: number
    totalReportsCount: number
    nonCompliantReportsCount: number
    nonCompliantReportsPercentage: number
    lastEmployeeDigestQueuedAt?: any | null
    lastManagerDigestQueuedAt?: any | null
    lastNotifiedAt?: any | null
    lastViewedAt?: any | null
    lastSignedAt?: any | null
    autoSendReminders: boolean
    isManager: boolean
    createdAt: any
    updatedAt: any
    groupIds: Array<number>
    handbookIds: Array<number>
    savedSegmentIds: Array<number>
    metadata?: any | null
    userIds: Array<number>
    sortKey?: string | null
    managers: Array<{
      __typename?: 'PersonManager'
      id: number
      email?: any | null
      employeeId?: string | null
      fullName: string
    }>
  }
}

export type RemoveAdminMutationVariables = Exact<{
  personId: Scalars['Int']
}>

export type RemoveAdminMutation = {
  __typename?: 'Mutation'
  removeAdmin: {
    __typename?: 'Person'
    id: number
    email?: any | null
    externalId?: string | null
    employeeId?: string | null
    phone?: any | null
    fullName: string
    archived: boolean
    archivedAt?: any | null
    member: boolean
    adminRoleId?: AdminRoleId | null
    isSuperUser: boolean
    lastHiredOn?: any | null
    avatarUrl?: string | null
    lastActivatedAt: any
    isSignatureRequired: boolean
    isEmployeeSignatureRequired: boolean
    totalDirectReportsCount: number
    nonCompliantDirectReportsCount: number
    nonCompliantDirectReportsPercentage: number
    totalReportsCount: number
    nonCompliantReportsCount: number
    nonCompliantReportsPercentage: number
    lastEmployeeDigestQueuedAt?: any | null
    lastManagerDigestQueuedAt?: any | null
    lastNotifiedAt?: any | null
    lastViewedAt?: any | null
    lastSignedAt?: any | null
    autoSendReminders: boolean
    isManager: boolean
    createdAt: any
    updatedAt: any
    groupIds: Array<number>
    handbookIds: Array<number>
    savedSegmentIds: Array<number>
    metadata?: any | null
    userIds: Array<number>
    sortKey?: string | null
    managers: Array<{
      __typename?: 'PersonManager'
      id: number
      email?: any | null
      employeeId?: string | null
      fullName: string
    }>
  }
}

export type AddTeammateMutationVariables = Exact<{
  notification?: InputMaybe<OrgCollaboratorNotificationInput>
  personId: Scalars['Int']
  teamId: Scalars['Int']
  teammateInput: TeammateInput
}>

export type AddTeammateMutation = {
  __typename?: 'Mutation'
  addTeammate: {
    __typename?: 'Teammate'
    id: number
    teamId: number
    personId: number
    canReport: boolean
    canSuperBrand: boolean
    roleId?: TeamRoleId | null
    person: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    }
  }
}

export type AddNewTeammateMutationVariables = Exact<{
  notification?: InputMaybe<OrgCollaboratorNotificationInput>
  personInput: NewOrgCollaboratorInput
  teamId: Scalars['Int']
  teammateInput: TeammateInput
}>

export type AddNewTeammateMutation = {
  __typename?: 'Mutation'
  addNewTeammate: {
    __typename?: 'Teammate'
    id: number
    teamId: number
    personId: number
    canReport: boolean
    canSuperBrand: boolean
    roleId?: TeamRoleId | null
    person: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    }
  }
}

export type RemoveTeammateMutationVariables = Exact<{
  teamId: Scalars['Int']
  personId: Scalars['Int']
}>

export type RemoveTeammateMutation = {
  __typename?: 'Mutation'
  removeTeammate: boolean
}

export type UpdateTeammateMutationVariables = Exact<{
  teamId: Scalars['Int']
  personId: Scalars['Int']
  teammateInput: TeammateInput
}>

export type UpdateTeammateMutation = {
  __typename?: 'Mutation'
  updateTeammate: {
    __typename?: 'Teammate'
    id: number
    teamId: number
    personId: number
    canReport: boolean
    canSuperBrand: boolean
    roleId?: TeamRoleId | null
    person: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    }
  }
}

export type AddHandbookCollaboratorMutationVariables = Exact<{
  handbookCollaboratorInput: HandbookCollaboratorInput
  handbookId: Scalars['Int']
  notification?: InputMaybe<OrgCollaboratorNotificationInput>
  personId: Scalars['Int']
}>

export type AddHandbookCollaboratorMutation = {
  __typename?: 'Mutation'
  addHandbookCollaborator: {
    __typename?: 'HandbookCollaborator'
    id: number
    handbookId: number
    personId: number
    roleId?: HandbookCollaboratorRoleId | null
  }
}

export type AddNewHandbookCollaboratorMutationVariables = Exact<{
  handbookCollaboratorInput: HandbookCollaboratorInput
  handbookId: Scalars['Int']
  notification?: InputMaybe<OrgCollaboratorNotificationInput>
  personInput: NewOrgCollaboratorInput
}>

export type AddNewHandbookCollaboratorMutation = {
  __typename?: 'Mutation'
  addNewHandbookCollaborator: {
    __typename?: 'HandbookCollaborator'
    id: number
    handbookId: number
    personId: number
    roleId?: HandbookCollaboratorRoleId | null
  }
}

export type RemoveHandbookCollaboratorMutationVariables = Exact<{
  handbookId: Scalars['Int']
  personId: Scalars['Int']
}>

export type RemoveHandbookCollaboratorMutation = {
  __typename?: 'Mutation'
  removeHandbookCollaborator: boolean
}

export type UpdateHandbookCollaboratorMutationVariables = Exact<{
  handbookCollaboratorInput: HandbookCollaboratorInput
  handbookId: Scalars['Int']
  personId: Scalars['Int']
}>

export type UpdateHandbookCollaboratorMutation = {
  __typename?: 'Mutation'
  updateHandbookCollaborator: {
    __typename?: 'HandbookCollaborator'
    id: number
    handbookId: number
    personId: number
    roleId?: HandbookCollaboratorRoleId | null
  }
}

export type SetFeatureFlagMutationVariables = Exact<{
  flagId: FeatureFlagId
  enabled: Scalars['Boolean']
}>

export type SetFeatureFlagMutation = {
  __typename?: 'Mutation'
  featureFlagIds: Array<string>
}

export type CreateHandbookMutationVariables = Exact<{
  name: Scalars['String']
}>

export type CreateHandbookMutation = {
  __typename?: 'Mutation'
  handbook: {
    __typename?: 'Handbook'
    content: any
    teamId: number
    id: number
    name: string
    defaultLanguageCode: string
    customDomain?: any | null
    customDomainOrigin?: any | null
    isPrintDisabled: boolean
    position: number
    public: boolean
    publicToken: string
    version: number
    createdAt?: any | null
    updatedAt?: any | null
    lastModifiedAt?: any | null
    lastModifiedBy?: number | null
    publishedAt?: any | null
    lastPublishedAt?: any | null
    url: any
    publishedUrl: any
    permissionIds: Array<string>
    contentLength: number
    deletePreviewLinks: boolean
    hasPersonalizedContent: boolean
    hasAudience: boolean
    viewersCount: number
    acknowledgementForm: {
      __typename?: 'HandbookAcknowledgement'
      title?: any | null
      body?: any | null
      forceSign: boolean
    }
    assets: Array<
      | {
          __typename?: 'HandbookImage'
          width?: number | null
          height?: number | null
          id: number
          type: AssetType
          filename: string
          filesize: number
          path: string
          thumbnailUrl?: string | null
          url: string
          archived: boolean
          mimeType?: string | null
          createdAt: any
        }
      | {
          __typename?: 'HandbookPdf'
          id: number
          type: AssetType
          filename: string
          filesize: number
          path: string
          thumbnailUrl?: string | null
          url: string
          archived: boolean
          mimeType?: string | null
          createdAt: any
        }
    >
    contactFragments: {
      __typename?: 'HandbookContact'
      title?: any | null
      body?: any | null
    }
    fonts: Array<{
      __typename?: 'HandbookFont'
      id: number
      filename: string
      archived: boolean
      createdAt: any
    }>
    publishNotification?: {
      __typename?: 'PublishHandbookNotification'
      toViewers?: boolean | null
      signers?: {
        __typename?: 'Notification'
        subjectContent: any
        buttonContent: any
        messageContent: any
      } | null
      viewers?: {
        __typename?: 'Notification'
        subjectContent: any
        buttonContent: any
        messageContent: any
      } | null
    } | null
    sections: Array<{
      __typename?: 'HandbookSection'
      handbookId: number
      id: number
      version: number
      createdAt?: any | null
      createdBy?: number | null
      updatedAt?: any | null
      updatedBy?: number | null
      lastPublishedAt?: any | null
      lastPublishedBy?: number | null
      dirtyFields?: Array<string> | null
      hidden: boolean
      hideToc: boolean
      languageCode?: string | null
      theme?: string | null
      title?: string | null
      type: HandbookSectionType
      hideAnnotations: Array<number>
      editAnnotation?: string | null
      viewAnnotation?: string | null
      attrs: {
        __typename?: 'HandbookSectionAttributes'
        keys: Array<string>
        goalAlign?: HandbookSectionTextAlign | null
        image?: any | null
        photo?: any | null
        signatureImage?: any | null
        statementAlign?: HandbookSectionTextAlign | null
        subtitleAlign?: HandbookSectionTextAlign | null
        titleAlign?: HandbookSectionTextAlign | null
        animation?: boolean | null
        backgroundAnimation?: string | null
        backgroundColor?: any | null
        backgroundImage?: any | null
        backgroundOverlayColor?: any | null
        backgroundOverlayOpacity?: number | null
        bottom?: string | null
        bottomArrow?: boolean | null
        headingBackgroundImage?: any | null
        height?: string | null
        entryAngle?: string | null
        entryAnimation?: boolean | null
        maxColumns?: number | null
        showChapterIcon?: boolean | null
        signatureType?: string | null
        textShadow?: boolean | null
        titleHighlight?: string | null
        titleHighlightColor?: any | null
        video?: {
          __typename?: 'HandbookVideo'
          url: any
          height: number
          width: number
        } | null
      }
      fragments: {
        __typename?: 'HandbookSectionContent'
        keys: Array<string>
        body?: any | null
        description?: any | null
        goal?: any | null
        headline?: any | null
        introduction?: any | null
        signature?: any | null
        statement?: any | null
        subtitle?: any | null
        title?: any | null
      }
      listEntries?: Array<{
        __typename?: 'HandbookSectionEntry'
        keys: Array<string>
        uid: string
        body?: any | null
        image?: any | null
        subtitle?: any | null
        subtitleAlign?: HandbookSectionTextAlign | null
        title?: any | null
        titleAlign?: HandbookSectionTextAlign | null
      }> | null
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
    }>
    lastPublishedByPerson?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    branding: {
      __typename?: 'HandbookBranding'
      blackColor: any
      favicon?: any | null
      handbookCss?: string | null
      lessButtonText: string
      moreButtonText: string
      primaryColor: any
      printCss?: string | null
      customHtml?: string | null
      whiteColor: any
      bodyFont: {
        __typename?: 'HandbookBrandingFont'
        type: HandbookBrandingFontType
        fontId?: number | null
        family?: string | null
        bold?: number | null
        normal?: number | null
        variants?: Array<string> | null
      }
      headingFont: {
        __typename?: 'HandbookBrandingFont'
        type: HandbookBrandingFontType
        fontId?: number | null
        family?: string | null
        bold?: number | null
        normal?: number | null
        variants?: Array<string> | null
      }
      scriptFont: {
        __typename?: 'HandbookBrandingFont'
        type: HandbookBrandingFontType
        fontId?: number | null
        family?: string | null
        bold?: number | null
        normal?: number | null
        variants?: Array<string> | null
      }
    }
    previewLinks: Array<{
      __typename?: 'HandbookPreviewLink'
      handbookId: number
      personId?: number | null
      token: string
      previewUrl: any
      createdAt: any
    }>
    signatureRounds: Array<{
      __typename?: 'HandbookSignatureRound'
      id: number
      number: number
      onlyAtLaunch?: HandbookSignatureRoundOnlyAtLaunch | null
      onlyMembers: boolean
      dueDays?: number | null
      dueDaysFuture?: number | null
      archived: boolean
      active: boolean
      createdAt: any
      updatedAt: any
      publishedAt?: any | null
      peopleCount: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
    statistics: {
      __typename?: 'HandbookStatistics'
      peopleCount: number
      canNotifyPeopleCount: number
      canNotifyNonCompliantPeopleCount: number
      nonCompliantPeopleCount: number
      notifiedPeopleCount: number
      maxNotifiedPeopleCount: number
      viewedPeopleCount: number
      maxViewedPeopleCount: number
      signedPeopleCount: number
      maxSignedPeopleCount: number
      validSignedPeopleCount: number
      maxValidSignedPeopleCount: number
      score?: number | null
    }
    tasks: Array<{
      __typename?: 'HandbookTask'
      id: HandbookTaskId
      completedAt?: any | null
      completedBy?: number | null
    }>
    versions: Array<{
      __typename?: 'HandbookVersionSummary'
      createdAt?: any | null
      createdBy?: number | null
      defaultLanguageCode: string
      handbookId: number
      label?: string | null
      languageCodes: Array<string>
      printUrl: any
      url: any
      version: number
      createdByPerson?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
    audience: {
      __typename?: 'HandbookAudience'
      member: boolean
      groupIds: Array<number>
      personIds: Array<number>
      savedSegmentIds: Array<number>
    }
  }
}

export type CloneHandbookMutationVariables = Exact<{
  handbookId: Scalars['Int']
  name: Scalars['String']
}>

export type CloneHandbookMutation = {
  __typename?: 'Mutation'
  handbook: {
    __typename?: 'Handbook'
    content: any
    teamId: number
    id: number
    name: string
    defaultLanguageCode: string
    customDomain?: any | null
    customDomainOrigin?: any | null
    isPrintDisabled: boolean
    position: number
    public: boolean
    publicToken: string
    version: number
    createdAt?: any | null
    updatedAt?: any | null
    lastModifiedAt?: any | null
    lastModifiedBy?: number | null
    publishedAt?: any | null
    lastPublishedAt?: any | null
    url: any
    publishedUrl: any
    permissionIds: Array<string>
    contentLength: number
    deletePreviewLinks: boolean
    hasPersonalizedContent: boolean
    hasAudience: boolean
    viewersCount: number
    acknowledgementForm: {
      __typename?: 'HandbookAcknowledgement'
      title?: any | null
      body?: any | null
      forceSign: boolean
    }
    assets: Array<
      | {
          __typename?: 'HandbookImage'
          width?: number | null
          height?: number | null
          id: number
          type: AssetType
          filename: string
          filesize: number
          path: string
          thumbnailUrl?: string | null
          url: string
          archived: boolean
          mimeType?: string | null
          createdAt: any
        }
      | {
          __typename?: 'HandbookPdf'
          id: number
          type: AssetType
          filename: string
          filesize: number
          path: string
          thumbnailUrl?: string | null
          url: string
          archived: boolean
          mimeType?: string | null
          createdAt: any
        }
    >
    contactFragments: {
      __typename?: 'HandbookContact'
      title?: any | null
      body?: any | null
    }
    fonts: Array<{
      __typename?: 'HandbookFont'
      id: number
      filename: string
      archived: boolean
      createdAt: any
    }>
    publishNotification?: {
      __typename?: 'PublishHandbookNotification'
      toViewers?: boolean | null
      signers?: {
        __typename?: 'Notification'
        subjectContent: any
        buttonContent: any
        messageContent: any
      } | null
      viewers?: {
        __typename?: 'Notification'
        subjectContent: any
        buttonContent: any
        messageContent: any
      } | null
    } | null
    sections: Array<{
      __typename?: 'HandbookSection'
      handbookId: number
      id: number
      version: number
      createdAt?: any | null
      createdBy?: number | null
      updatedAt?: any | null
      updatedBy?: number | null
      lastPublishedAt?: any | null
      lastPublishedBy?: number | null
      dirtyFields?: Array<string> | null
      hidden: boolean
      hideToc: boolean
      languageCode?: string | null
      theme?: string | null
      title?: string | null
      type: HandbookSectionType
      hideAnnotations: Array<number>
      editAnnotation?: string | null
      viewAnnotation?: string | null
      attrs: {
        __typename?: 'HandbookSectionAttributes'
        keys: Array<string>
        goalAlign?: HandbookSectionTextAlign | null
        image?: any | null
        photo?: any | null
        signatureImage?: any | null
        statementAlign?: HandbookSectionTextAlign | null
        subtitleAlign?: HandbookSectionTextAlign | null
        titleAlign?: HandbookSectionTextAlign | null
        animation?: boolean | null
        backgroundAnimation?: string | null
        backgroundColor?: any | null
        backgroundImage?: any | null
        backgroundOverlayColor?: any | null
        backgroundOverlayOpacity?: number | null
        bottom?: string | null
        bottomArrow?: boolean | null
        headingBackgroundImage?: any | null
        height?: string | null
        entryAngle?: string | null
        entryAnimation?: boolean | null
        maxColumns?: number | null
        showChapterIcon?: boolean | null
        signatureType?: string | null
        textShadow?: boolean | null
        titleHighlight?: string | null
        titleHighlightColor?: any | null
        video?: {
          __typename?: 'HandbookVideo'
          url: any
          height: number
          width: number
        } | null
      }
      fragments: {
        __typename?: 'HandbookSectionContent'
        keys: Array<string>
        body?: any | null
        description?: any | null
        goal?: any | null
        headline?: any | null
        introduction?: any | null
        signature?: any | null
        statement?: any | null
        subtitle?: any | null
        title?: any | null
      }
      listEntries?: Array<{
        __typename?: 'HandbookSectionEntry'
        keys: Array<string>
        uid: string
        body?: any | null
        image?: any | null
        subtitle?: any | null
        subtitleAlign?: HandbookSectionTextAlign | null
        title?: any | null
        titleAlign?: HandbookSectionTextAlign | null
      }> | null
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
    }>
    lastPublishedByPerson?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    branding: {
      __typename?: 'HandbookBranding'
      blackColor: any
      favicon?: any | null
      handbookCss?: string | null
      lessButtonText: string
      moreButtonText: string
      primaryColor: any
      printCss?: string | null
      customHtml?: string | null
      whiteColor: any
      bodyFont: {
        __typename?: 'HandbookBrandingFont'
        type: HandbookBrandingFontType
        fontId?: number | null
        family?: string | null
        bold?: number | null
        normal?: number | null
        variants?: Array<string> | null
      }
      headingFont: {
        __typename?: 'HandbookBrandingFont'
        type: HandbookBrandingFontType
        fontId?: number | null
        family?: string | null
        bold?: number | null
        normal?: number | null
        variants?: Array<string> | null
      }
      scriptFont: {
        __typename?: 'HandbookBrandingFont'
        type: HandbookBrandingFontType
        fontId?: number | null
        family?: string | null
        bold?: number | null
        normal?: number | null
        variants?: Array<string> | null
      }
    }
    previewLinks: Array<{
      __typename?: 'HandbookPreviewLink'
      handbookId: number
      personId?: number | null
      token: string
      previewUrl: any
      createdAt: any
    }>
    signatureRounds: Array<{
      __typename?: 'HandbookSignatureRound'
      id: number
      number: number
      onlyAtLaunch?: HandbookSignatureRoundOnlyAtLaunch | null
      onlyMembers: boolean
      dueDays?: number | null
      dueDaysFuture?: number | null
      archived: boolean
      active: boolean
      createdAt: any
      updatedAt: any
      publishedAt?: any | null
      peopleCount: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
    statistics: {
      __typename?: 'HandbookStatistics'
      peopleCount: number
      canNotifyPeopleCount: number
      canNotifyNonCompliantPeopleCount: number
      nonCompliantPeopleCount: number
      notifiedPeopleCount: number
      maxNotifiedPeopleCount: number
      viewedPeopleCount: number
      maxViewedPeopleCount: number
      signedPeopleCount: number
      maxSignedPeopleCount: number
      validSignedPeopleCount: number
      maxValidSignedPeopleCount: number
      score?: number | null
    }
    tasks: Array<{
      __typename?: 'HandbookTask'
      id: HandbookTaskId
      completedAt?: any | null
      completedBy?: number | null
    }>
    versions: Array<{
      __typename?: 'HandbookVersionSummary'
      createdAt?: any | null
      createdBy?: number | null
      defaultLanguageCode: string
      handbookId: number
      label?: string | null
      languageCodes: Array<string>
      printUrl: any
      url: any
      version: number
      createdByPerson?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
    audience: {
      __typename?: 'HandbookAudience'
      member: boolean
      groupIds: Array<number>
      personIds: Array<number>
      savedSegmentIds: Array<number>
    }
  }
}

export type InitializeHandbookAudienceMutationVariables = Exact<{
  id: Scalars['Int']
  hasAudience: Scalars['Boolean']
  hasSignatures: Scalars['Boolean']
}>

export type InitializeHandbookAudienceMutation = {
  __typename?: 'Mutation'
  handbook: {
    __typename?: 'Handbook'
    content: any
    teamId: number
    id: number
    name: string
    defaultLanguageCode: string
    customDomain?: any | null
    customDomainOrigin?: any | null
    isPrintDisabled: boolean
    position: number
    public: boolean
    publicToken: string
    version: number
    createdAt?: any | null
    updatedAt?: any | null
    lastModifiedAt?: any | null
    lastModifiedBy?: number | null
    publishedAt?: any | null
    lastPublishedAt?: any | null
    url: any
    publishedUrl: any
    permissionIds: Array<string>
    contentLength: number
    deletePreviewLinks: boolean
    hasPersonalizedContent: boolean
    hasAudience: boolean
    viewersCount: number
    acknowledgementForm: {
      __typename?: 'HandbookAcknowledgement'
      title?: any | null
      body?: any | null
      forceSign: boolean
    }
    assets: Array<
      | {
          __typename?: 'HandbookImage'
          width?: number | null
          height?: number | null
          id: number
          type: AssetType
          filename: string
          filesize: number
          path: string
          thumbnailUrl?: string | null
          url: string
          archived: boolean
          mimeType?: string | null
          createdAt: any
        }
      | {
          __typename?: 'HandbookPdf'
          id: number
          type: AssetType
          filename: string
          filesize: number
          path: string
          thumbnailUrl?: string | null
          url: string
          archived: boolean
          mimeType?: string | null
          createdAt: any
        }
    >
    contactFragments: {
      __typename?: 'HandbookContact'
      title?: any | null
      body?: any | null
    }
    fonts: Array<{
      __typename?: 'HandbookFont'
      id: number
      filename: string
      archived: boolean
      createdAt: any
    }>
    publishNotification?: {
      __typename?: 'PublishHandbookNotification'
      toViewers?: boolean | null
      signers?: {
        __typename?: 'Notification'
        subjectContent: any
        buttonContent: any
        messageContent: any
      } | null
      viewers?: {
        __typename?: 'Notification'
        subjectContent: any
        buttonContent: any
        messageContent: any
      } | null
    } | null
    sections: Array<{
      __typename?: 'HandbookSection'
      handbookId: number
      id: number
      version: number
      createdAt?: any | null
      createdBy?: number | null
      updatedAt?: any | null
      updatedBy?: number | null
      lastPublishedAt?: any | null
      lastPublishedBy?: number | null
      dirtyFields?: Array<string> | null
      hidden: boolean
      hideToc: boolean
      languageCode?: string | null
      theme?: string | null
      title?: string | null
      type: HandbookSectionType
      hideAnnotations: Array<number>
      editAnnotation?: string | null
      viewAnnotation?: string | null
      attrs: {
        __typename?: 'HandbookSectionAttributes'
        keys: Array<string>
        goalAlign?: HandbookSectionTextAlign | null
        image?: any | null
        photo?: any | null
        signatureImage?: any | null
        statementAlign?: HandbookSectionTextAlign | null
        subtitleAlign?: HandbookSectionTextAlign | null
        titleAlign?: HandbookSectionTextAlign | null
        animation?: boolean | null
        backgroundAnimation?: string | null
        backgroundColor?: any | null
        backgroundImage?: any | null
        backgroundOverlayColor?: any | null
        backgroundOverlayOpacity?: number | null
        bottom?: string | null
        bottomArrow?: boolean | null
        headingBackgroundImage?: any | null
        height?: string | null
        entryAngle?: string | null
        entryAnimation?: boolean | null
        maxColumns?: number | null
        showChapterIcon?: boolean | null
        signatureType?: string | null
        textShadow?: boolean | null
        titleHighlight?: string | null
        titleHighlightColor?: any | null
        video?: {
          __typename?: 'HandbookVideo'
          url: any
          height: number
          width: number
        } | null
      }
      fragments: {
        __typename?: 'HandbookSectionContent'
        keys: Array<string>
        body?: any | null
        description?: any | null
        goal?: any | null
        headline?: any | null
        introduction?: any | null
        signature?: any | null
        statement?: any | null
        subtitle?: any | null
        title?: any | null
      }
      listEntries?: Array<{
        __typename?: 'HandbookSectionEntry'
        keys: Array<string>
        uid: string
        body?: any | null
        image?: any | null
        subtitle?: any | null
        subtitleAlign?: HandbookSectionTextAlign | null
        title?: any | null
        titleAlign?: HandbookSectionTextAlign | null
      }> | null
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
    }>
    lastPublishedByPerson?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    branding: {
      __typename?: 'HandbookBranding'
      blackColor: any
      favicon?: any | null
      handbookCss?: string | null
      lessButtonText: string
      moreButtonText: string
      primaryColor: any
      printCss?: string | null
      customHtml?: string | null
      whiteColor: any
      bodyFont: {
        __typename?: 'HandbookBrandingFont'
        type: HandbookBrandingFontType
        fontId?: number | null
        family?: string | null
        bold?: number | null
        normal?: number | null
        variants?: Array<string> | null
      }
      headingFont: {
        __typename?: 'HandbookBrandingFont'
        type: HandbookBrandingFontType
        fontId?: number | null
        family?: string | null
        bold?: number | null
        normal?: number | null
        variants?: Array<string> | null
      }
      scriptFont: {
        __typename?: 'HandbookBrandingFont'
        type: HandbookBrandingFontType
        fontId?: number | null
        family?: string | null
        bold?: number | null
        normal?: number | null
        variants?: Array<string> | null
      }
    }
    previewLinks: Array<{
      __typename?: 'HandbookPreviewLink'
      handbookId: number
      personId?: number | null
      token: string
      previewUrl: any
      createdAt: any
    }>
    signatureRounds: Array<{
      __typename?: 'HandbookSignatureRound'
      id: number
      number: number
      onlyAtLaunch?: HandbookSignatureRoundOnlyAtLaunch | null
      onlyMembers: boolean
      dueDays?: number | null
      dueDaysFuture?: number | null
      archived: boolean
      active: boolean
      createdAt: any
      updatedAt: any
      publishedAt?: any | null
      peopleCount: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
    statistics: {
      __typename?: 'HandbookStatistics'
      peopleCount: number
      canNotifyPeopleCount: number
      canNotifyNonCompliantPeopleCount: number
      nonCompliantPeopleCount: number
      notifiedPeopleCount: number
      maxNotifiedPeopleCount: number
      viewedPeopleCount: number
      maxViewedPeopleCount: number
      signedPeopleCount: number
      maxSignedPeopleCount: number
      validSignedPeopleCount: number
      maxValidSignedPeopleCount: number
      score?: number | null
    }
    tasks: Array<{
      __typename?: 'HandbookTask'
      id: HandbookTaskId
      completedAt?: any | null
      completedBy?: number | null
    }>
    versions: Array<{
      __typename?: 'HandbookVersionSummary'
      createdAt?: any | null
      createdBy?: number | null
      defaultLanguageCode: string
      handbookId: number
      label?: string | null
      languageCodes: Array<string>
      printUrl: any
      url: any
      version: number
      createdByPerson?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
    audience: {
      __typename?: 'HandbookAudience'
      member: boolean
      groupIds: Array<number>
      personIds: Array<number>
      savedSegmentIds: Array<number>
    }
  }
}

export type InitializeHandbookContentMutationVariables = Exact<{
  id: Scalars['Int']
  seedType: HandbookSeedType
  branding?: InputMaybe<HandbookBrandingInput>
}>

export type InitializeHandbookContentMutation = {
  __typename?: 'Mutation'
  handbook: {
    __typename?: 'Handbook'
    content: any
    teamId: number
    id: number
    name: string
    defaultLanguageCode: string
    customDomain?: any | null
    customDomainOrigin?: any | null
    isPrintDisabled: boolean
    position: number
    public: boolean
    publicToken: string
    version: number
    createdAt?: any | null
    updatedAt?: any | null
    lastModifiedAt?: any | null
    lastModifiedBy?: number | null
    publishedAt?: any | null
    lastPublishedAt?: any | null
    url: any
    publishedUrl: any
    permissionIds: Array<string>
    contentLength: number
    deletePreviewLinks: boolean
    hasPersonalizedContent: boolean
    hasAudience: boolean
    viewersCount: number
    acknowledgementForm: {
      __typename?: 'HandbookAcknowledgement'
      title?: any | null
      body?: any | null
      forceSign: boolean
    }
    assets: Array<
      | {
          __typename?: 'HandbookImage'
          width?: number | null
          height?: number | null
          id: number
          type: AssetType
          filename: string
          filesize: number
          path: string
          thumbnailUrl?: string | null
          url: string
          archived: boolean
          mimeType?: string | null
          createdAt: any
        }
      | {
          __typename?: 'HandbookPdf'
          id: number
          type: AssetType
          filename: string
          filesize: number
          path: string
          thumbnailUrl?: string | null
          url: string
          archived: boolean
          mimeType?: string | null
          createdAt: any
        }
    >
    contactFragments: {
      __typename?: 'HandbookContact'
      title?: any | null
      body?: any | null
    }
    fonts: Array<{
      __typename?: 'HandbookFont'
      id: number
      filename: string
      archived: boolean
      createdAt: any
    }>
    publishNotification?: {
      __typename?: 'PublishHandbookNotification'
      toViewers?: boolean | null
      signers?: {
        __typename?: 'Notification'
        subjectContent: any
        buttonContent: any
        messageContent: any
      } | null
      viewers?: {
        __typename?: 'Notification'
        subjectContent: any
        buttonContent: any
        messageContent: any
      } | null
    } | null
    sections: Array<{
      __typename?: 'HandbookSection'
      handbookId: number
      id: number
      version: number
      createdAt?: any | null
      createdBy?: number | null
      updatedAt?: any | null
      updatedBy?: number | null
      lastPublishedAt?: any | null
      lastPublishedBy?: number | null
      dirtyFields?: Array<string> | null
      hidden: boolean
      hideToc: boolean
      languageCode?: string | null
      theme?: string | null
      title?: string | null
      type: HandbookSectionType
      hideAnnotations: Array<number>
      editAnnotation?: string | null
      viewAnnotation?: string | null
      attrs: {
        __typename?: 'HandbookSectionAttributes'
        keys: Array<string>
        goalAlign?: HandbookSectionTextAlign | null
        image?: any | null
        photo?: any | null
        signatureImage?: any | null
        statementAlign?: HandbookSectionTextAlign | null
        subtitleAlign?: HandbookSectionTextAlign | null
        titleAlign?: HandbookSectionTextAlign | null
        animation?: boolean | null
        backgroundAnimation?: string | null
        backgroundColor?: any | null
        backgroundImage?: any | null
        backgroundOverlayColor?: any | null
        backgroundOverlayOpacity?: number | null
        bottom?: string | null
        bottomArrow?: boolean | null
        headingBackgroundImage?: any | null
        height?: string | null
        entryAngle?: string | null
        entryAnimation?: boolean | null
        maxColumns?: number | null
        showChapterIcon?: boolean | null
        signatureType?: string | null
        textShadow?: boolean | null
        titleHighlight?: string | null
        titleHighlightColor?: any | null
        video?: {
          __typename?: 'HandbookVideo'
          url: any
          height: number
          width: number
        } | null
      }
      fragments: {
        __typename?: 'HandbookSectionContent'
        keys: Array<string>
        body?: any | null
        description?: any | null
        goal?: any | null
        headline?: any | null
        introduction?: any | null
        signature?: any | null
        statement?: any | null
        subtitle?: any | null
        title?: any | null
      }
      listEntries?: Array<{
        __typename?: 'HandbookSectionEntry'
        keys: Array<string>
        uid: string
        body?: any | null
        image?: any | null
        subtitle?: any | null
        subtitleAlign?: HandbookSectionTextAlign | null
        title?: any | null
        titleAlign?: HandbookSectionTextAlign | null
      }> | null
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
    }>
    lastPublishedByPerson?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    branding: {
      __typename?: 'HandbookBranding'
      blackColor: any
      favicon?: any | null
      handbookCss?: string | null
      lessButtonText: string
      moreButtonText: string
      primaryColor: any
      printCss?: string | null
      customHtml?: string | null
      whiteColor: any
      bodyFont: {
        __typename?: 'HandbookBrandingFont'
        type: HandbookBrandingFontType
        fontId?: number | null
        family?: string | null
        bold?: number | null
        normal?: number | null
        variants?: Array<string> | null
      }
      headingFont: {
        __typename?: 'HandbookBrandingFont'
        type: HandbookBrandingFontType
        fontId?: number | null
        family?: string | null
        bold?: number | null
        normal?: number | null
        variants?: Array<string> | null
      }
      scriptFont: {
        __typename?: 'HandbookBrandingFont'
        type: HandbookBrandingFontType
        fontId?: number | null
        family?: string | null
        bold?: number | null
        normal?: number | null
        variants?: Array<string> | null
      }
    }
    previewLinks: Array<{
      __typename?: 'HandbookPreviewLink'
      handbookId: number
      personId?: number | null
      token: string
      previewUrl: any
      createdAt: any
    }>
    signatureRounds: Array<{
      __typename?: 'HandbookSignatureRound'
      id: number
      number: number
      onlyAtLaunch?: HandbookSignatureRoundOnlyAtLaunch | null
      onlyMembers: boolean
      dueDays?: number | null
      dueDaysFuture?: number | null
      archived: boolean
      active: boolean
      createdAt: any
      updatedAt: any
      publishedAt?: any | null
      peopleCount: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
    statistics: {
      __typename?: 'HandbookStatistics'
      peopleCount: number
      canNotifyPeopleCount: number
      canNotifyNonCompliantPeopleCount: number
      nonCompliantPeopleCount: number
      notifiedPeopleCount: number
      maxNotifiedPeopleCount: number
      viewedPeopleCount: number
      maxViewedPeopleCount: number
      signedPeopleCount: number
      maxSignedPeopleCount: number
      validSignedPeopleCount: number
      maxValidSignedPeopleCount: number
      score?: number | null
    }
    tasks: Array<{
      __typename?: 'HandbookTask'
      id: HandbookTaskId
      completedAt?: any | null
      completedBy?: number | null
    }>
    versions: Array<{
      __typename?: 'HandbookVersionSummary'
      createdAt?: any | null
      createdBy?: number | null
      defaultLanguageCode: string
      handbookId: number
      label?: string | null
      languageCodes: Array<string>
      printUrl: any
      url: any
      version: number
      createdByPerson?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
    audience: {
      __typename?: 'HandbookAudience'
      member: boolean
      groupIds: Array<number>
      personIds: Array<number>
      savedSegmentIds: Array<number>
    }
  }
}

export type PatchHandbookMutationVariables = Exact<{
  handbookId: Scalars['Int']
  input: HandbookInput
}>

export type PatchHandbookMutation = {
  __typename?: 'Mutation'
  handbook: {
    __typename?: 'Handbook'
    customDomain?: any | null
    customDomainOrigin?: any | null
    defaultLanguageCode: string
    isPrintDisabled: boolean
    name: string
    public: boolean
    publicToken: string
    updatedAt?: any | null
  }
}

export type PatchHandbookCssMutationVariables = Exact<{
  handbookId: Scalars['Int']
  input: HandbookCssInput
}>

export type PatchHandbookCssMutation = {
  __typename?: 'Mutation'
  handbook: { __typename?: 'Handbook'; updatedAt?: any | null }
}

export type PatchHandbookCustomHtmlMutationVariables = Exact<{
  handbookId: Scalars['Int']
  customHtml: Scalars['String']
}>

export type PatchHandbookCustomHtmlMutation = {
  __typename?: 'Mutation'
  handbook: { __typename?: 'Handbook'; updatedAt?: any | null }
}

export type PublishHandbookMutationVariables = Exact<{
  handbookId: Scalars['Int']
  content?: InputMaybe<PublishHandbookContentInput>
  notifyViewers?: InputMaybe<HandbookViewersNotificationInput>
}>

export type PublishHandbookMutation = {
  __typename?: 'Mutation'
  handbook: {
    __typename?: 'Handbook'
    content: any
    teamId: number
    id: number
    name: string
    defaultLanguageCode: string
    customDomain?: any | null
    customDomainOrigin?: any | null
    isPrintDisabled: boolean
    position: number
    public: boolean
    publicToken: string
    version: number
    createdAt?: any | null
    updatedAt?: any | null
    lastModifiedAt?: any | null
    lastModifiedBy?: number | null
    publishedAt?: any | null
    lastPublishedAt?: any | null
    url: any
    publishedUrl: any
    permissionIds: Array<string>
    contentLength: number
    deletePreviewLinks: boolean
    hasPersonalizedContent: boolean
    hasAudience: boolean
    viewersCount: number
    acknowledgementForm: {
      __typename?: 'HandbookAcknowledgement'
      title?: any | null
      body?: any | null
      forceSign: boolean
    }
    assets: Array<
      | {
          __typename?: 'HandbookImage'
          width?: number | null
          height?: number | null
          id: number
          type: AssetType
          filename: string
          filesize: number
          path: string
          thumbnailUrl?: string | null
          url: string
          archived: boolean
          mimeType?: string | null
          createdAt: any
        }
      | {
          __typename?: 'HandbookPdf'
          id: number
          type: AssetType
          filename: string
          filesize: number
          path: string
          thumbnailUrl?: string | null
          url: string
          archived: boolean
          mimeType?: string | null
          createdAt: any
        }
    >
    contactFragments: {
      __typename?: 'HandbookContact'
      title?: any | null
      body?: any | null
    }
    fonts: Array<{
      __typename?: 'HandbookFont'
      id: number
      filename: string
      archived: boolean
      createdAt: any
    }>
    publishNotification?: {
      __typename?: 'PublishHandbookNotification'
      toViewers?: boolean | null
      signers?: {
        __typename?: 'Notification'
        subjectContent: any
        buttonContent: any
        messageContent: any
      } | null
      viewers?: {
        __typename?: 'Notification'
        subjectContent: any
        buttonContent: any
        messageContent: any
      } | null
    } | null
    sections: Array<{
      __typename?: 'HandbookSection'
      handbookId: number
      id: number
      version: number
      createdAt?: any | null
      createdBy?: number | null
      updatedAt?: any | null
      updatedBy?: number | null
      lastPublishedAt?: any | null
      lastPublishedBy?: number | null
      dirtyFields?: Array<string> | null
      hidden: boolean
      hideToc: boolean
      languageCode?: string | null
      theme?: string | null
      title?: string | null
      type: HandbookSectionType
      hideAnnotations: Array<number>
      editAnnotation?: string | null
      viewAnnotation?: string | null
      attrs: {
        __typename?: 'HandbookSectionAttributes'
        keys: Array<string>
        goalAlign?: HandbookSectionTextAlign | null
        image?: any | null
        photo?: any | null
        signatureImage?: any | null
        statementAlign?: HandbookSectionTextAlign | null
        subtitleAlign?: HandbookSectionTextAlign | null
        titleAlign?: HandbookSectionTextAlign | null
        animation?: boolean | null
        backgroundAnimation?: string | null
        backgroundColor?: any | null
        backgroundImage?: any | null
        backgroundOverlayColor?: any | null
        backgroundOverlayOpacity?: number | null
        bottom?: string | null
        bottomArrow?: boolean | null
        headingBackgroundImage?: any | null
        height?: string | null
        entryAngle?: string | null
        entryAnimation?: boolean | null
        maxColumns?: number | null
        showChapterIcon?: boolean | null
        signatureType?: string | null
        textShadow?: boolean | null
        titleHighlight?: string | null
        titleHighlightColor?: any | null
        video?: {
          __typename?: 'HandbookVideo'
          url: any
          height: number
          width: number
        } | null
      }
      fragments: {
        __typename?: 'HandbookSectionContent'
        keys: Array<string>
        body?: any | null
        description?: any | null
        goal?: any | null
        headline?: any | null
        introduction?: any | null
        signature?: any | null
        statement?: any | null
        subtitle?: any | null
        title?: any | null
      }
      listEntries?: Array<{
        __typename?: 'HandbookSectionEntry'
        keys: Array<string>
        uid: string
        body?: any | null
        image?: any | null
        subtitle?: any | null
        subtitleAlign?: HandbookSectionTextAlign | null
        title?: any | null
        titleAlign?: HandbookSectionTextAlign | null
      }> | null
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
    }>
    lastPublishedByPerson?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    branding: {
      __typename?: 'HandbookBranding'
      blackColor: any
      favicon?: any | null
      handbookCss?: string | null
      lessButtonText: string
      moreButtonText: string
      primaryColor: any
      printCss?: string | null
      customHtml?: string | null
      whiteColor: any
      bodyFont: {
        __typename?: 'HandbookBrandingFont'
        type: HandbookBrandingFontType
        fontId?: number | null
        family?: string | null
        bold?: number | null
        normal?: number | null
        variants?: Array<string> | null
      }
      headingFont: {
        __typename?: 'HandbookBrandingFont'
        type: HandbookBrandingFontType
        fontId?: number | null
        family?: string | null
        bold?: number | null
        normal?: number | null
        variants?: Array<string> | null
      }
      scriptFont: {
        __typename?: 'HandbookBrandingFont'
        type: HandbookBrandingFontType
        fontId?: number | null
        family?: string | null
        bold?: number | null
        normal?: number | null
        variants?: Array<string> | null
      }
    }
    previewLinks: Array<{
      __typename?: 'HandbookPreviewLink'
      handbookId: number
      personId?: number | null
      token: string
      previewUrl: any
      createdAt: any
    }>
    signatureRounds: Array<{
      __typename?: 'HandbookSignatureRound'
      id: number
      number: number
      onlyAtLaunch?: HandbookSignatureRoundOnlyAtLaunch | null
      onlyMembers: boolean
      dueDays?: number | null
      dueDaysFuture?: number | null
      archived: boolean
      active: boolean
      createdAt: any
      updatedAt: any
      publishedAt?: any | null
      peopleCount: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
    statistics: {
      __typename?: 'HandbookStatistics'
      peopleCount: number
      canNotifyPeopleCount: number
      canNotifyNonCompliantPeopleCount: number
      nonCompliantPeopleCount: number
      notifiedPeopleCount: number
      maxNotifiedPeopleCount: number
      viewedPeopleCount: number
      maxViewedPeopleCount: number
      signedPeopleCount: number
      maxSignedPeopleCount: number
      validSignedPeopleCount: number
      maxValidSignedPeopleCount: number
      score?: number | null
    }
    tasks: Array<{
      __typename?: 'HandbookTask'
      id: HandbookTaskId
      completedAt?: any | null
      completedBy?: number | null
    }>
    versions: Array<{
      __typename?: 'HandbookVersionSummary'
      createdAt?: any | null
      createdBy?: number | null
      defaultLanguageCode: string
      handbookId: number
      label?: string | null
      languageCodes: Array<string>
      printUrl: any
      url: any
      version: number
      createdByPerson?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
    audience: {
      __typename?: 'HandbookAudience'
      member: boolean
      groupIds: Array<number>
      personIds: Array<number>
      savedSegmentIds: Array<number>
    }
  }
}

export type DeleteHandbookMutationVariables = Exact<{
  handbookId: Scalars['Int']
}>

export type DeleteHandbookMutation = {
  __typename?: 'Mutation'
  deleteHandbook?: boolean | null
}

export type AddDocumentRefsToHandbookMutationVariables = Exact<{
  documentIds: Array<Scalars['Int']> | Scalars['Int']
  handbookId: Scalars['Int']
  index?: InputMaybe<Scalars['Int']>
}>

export type AddDocumentRefsToHandbookMutation = {
  __typename?: 'Mutation'
  nodeIds: Array<string>
}

export type AddHandbookSectionMutationVariables = Exact<{
  handbookId: Scalars['Int']
  input: NewHandbookSectionInput
  position?: InputMaybe<Scalars['Int']>
}>

export type AddHandbookSectionMutation = {
  __typename?: 'Mutation'
  section: {
    __typename?: 'HandbookSection'
    handbookId: number
    id: number
    version: number
    createdAt?: any | null
    createdBy?: number | null
    updatedAt?: any | null
    updatedBy?: number | null
    lastPublishedAt?: any | null
    lastPublishedBy?: number | null
    dirtyFields?: Array<string> | null
    hidden: boolean
    hideToc: boolean
    languageCode?: string | null
    theme?: string | null
    title?: string | null
    type: HandbookSectionType
    hideAnnotations: Array<number>
    editAnnotation?: string | null
    viewAnnotation?: string | null
    attrs: {
      __typename?: 'HandbookSectionAttributes'
      keys: Array<string>
      goalAlign?: HandbookSectionTextAlign | null
      image?: any | null
      photo?: any | null
      signatureImage?: any | null
      statementAlign?: HandbookSectionTextAlign | null
      subtitleAlign?: HandbookSectionTextAlign | null
      titleAlign?: HandbookSectionTextAlign | null
      animation?: boolean | null
      backgroundAnimation?: string | null
      backgroundColor?: any | null
      backgroundImage?: any | null
      backgroundOverlayColor?: any | null
      backgroundOverlayOpacity?: number | null
      bottom?: string | null
      bottomArrow?: boolean | null
      headingBackgroundImage?: any | null
      height?: string | null
      entryAngle?: string | null
      entryAnimation?: boolean | null
      maxColumns?: number | null
      showChapterIcon?: boolean | null
      signatureType?: string | null
      textShadow?: boolean | null
      titleHighlight?: string | null
      titleHighlightColor?: any | null
      video?: {
        __typename?: 'HandbookVideo'
        url: any
        height: number
        width: number
      } | null
    }
    fragments: {
      __typename?: 'HandbookSectionContent'
      keys: Array<string>
      body?: any | null
      description?: any | null
      goal?: any | null
      headline?: any | null
      introduction?: any | null
      signature?: any | null
      statement?: any | null
      subtitle?: any | null
      title?: any | null
    }
    listEntries?: Array<{
      __typename?: 'HandbookSectionEntry'
      keys: Array<string>
      uid: string
      body?: any | null
      image?: any | null
      subtitle?: any | null
      subtitleAlign?: HandbookSectionTextAlign | null
      title?: any | null
      titleAlign?: HandbookSectionTextAlign | null
    }> | null
    audienceExpression?: {
      __typename?: 'RootExpression'
      conjunction: ExpressionConjunction
      expressions: Array<{
        __typename?: 'Expression'
        conjunction: ExpressionConjunction
        operands: Array<
          | {
              __typename?: 'DateOperand'
              field: string
              dateCondition: DateOperandCondition
              days?: number | null
              date?: any | null
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'IdsOperand'
              isEvery: boolean
              isNot: boolean
              ids: Array<number>
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'ManagersOperand'
              isEvery: boolean
              isNot: boolean
              managesDirectly: boolean
              ids: Array<number>
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'SelectIdsOperand'
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'SelectOperand'
              field: string
              path?: string | null
              isEvery: boolean
              isNot: boolean
              values?: Array<string> | null
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'TextOperand'
              field: string
              isNot: boolean
              textCondition: TextOperandCondition
              text: string
              id?: string | null
              type: OperandType
            }
        >
      }>
    } | null
  }
}

export type CloneHandbookSectionMutationVariables = Exact<{
  sectionId: Scalars['Int']
  children?: InputMaybe<Scalars['Boolean']>
}>

export type CloneHandbookSectionMutation = {
  __typename?: 'Mutation'
  cloneHandbookSection: {
    __typename?: 'InsertHandbookSectionsResult'
    position: number
    sections: Array<{
      __typename?: 'HandbookSection'
      handbookId: number
      id: number
      version: number
      createdAt?: any | null
      createdBy?: number | null
      updatedAt?: any | null
      updatedBy?: number | null
      lastPublishedAt?: any | null
      lastPublishedBy?: number | null
      dirtyFields?: Array<string> | null
      hidden: boolean
      hideToc: boolean
      languageCode?: string | null
      theme?: string | null
      title?: string | null
      type: HandbookSectionType
      hideAnnotations: Array<number>
      editAnnotation?: string | null
      viewAnnotation?: string | null
      attrs: {
        __typename?: 'HandbookSectionAttributes'
        keys: Array<string>
        goalAlign?: HandbookSectionTextAlign | null
        image?: any | null
        photo?: any | null
        signatureImage?: any | null
        statementAlign?: HandbookSectionTextAlign | null
        subtitleAlign?: HandbookSectionTextAlign | null
        titleAlign?: HandbookSectionTextAlign | null
        animation?: boolean | null
        backgroundAnimation?: string | null
        backgroundColor?: any | null
        backgroundImage?: any | null
        backgroundOverlayColor?: any | null
        backgroundOverlayOpacity?: number | null
        bottom?: string | null
        bottomArrow?: boolean | null
        headingBackgroundImage?: any | null
        height?: string | null
        entryAngle?: string | null
        entryAnimation?: boolean | null
        maxColumns?: number | null
        showChapterIcon?: boolean | null
        signatureType?: string | null
        textShadow?: boolean | null
        titleHighlight?: string | null
        titleHighlightColor?: any | null
        video?: {
          __typename?: 'HandbookVideo'
          url: any
          height: number
          width: number
        } | null
      }
      fragments: {
        __typename?: 'HandbookSectionContent'
        keys: Array<string>
        body?: any | null
        description?: any | null
        goal?: any | null
        headline?: any | null
        introduction?: any | null
        signature?: any | null
        statement?: any | null
        subtitle?: any | null
        title?: any | null
      }
      listEntries?: Array<{
        __typename?: 'HandbookSectionEntry'
        keys: Array<string>
        uid: string
        body?: any | null
        image?: any | null
        subtitle?: any | null
        subtitleAlign?: HandbookSectionTextAlign | null
        title?: any | null
        titleAlign?: HandbookSectionTextAlign | null
      }> | null
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
    }>
  }
}

export type PatchHandbookSectionMutationVariables = Exact<{
  sectionId: Scalars['Int']
  input: HandbookSectionInput
}>

export type PatchHandbookSectionMutation = {
  __typename?: 'Mutation'
  section: {
    __typename?: 'HandbookSection'
    id: number
    dirtyFields?: Array<string> | null
    hidden: boolean
    hideToc: boolean
    languageCode?: string | null
    theme?: string | null
    title?: string | null
    type: HandbookSectionType
    hideAnnotations: Array<number>
    editAnnotation?: string | null
    viewAnnotation?: string | null
    audienceExpression?: {
      __typename?: 'RootExpression'
      conjunction: ExpressionConjunction
      expressions: Array<{
        __typename?: 'Expression'
        conjunction: ExpressionConjunction
        operands: Array<
          | {
              __typename?: 'DateOperand'
              field: string
              dateCondition: DateOperandCondition
              days?: number | null
              date?: any | null
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'IdsOperand'
              isEvery: boolean
              isNot: boolean
              ids: Array<number>
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'ManagersOperand'
              isEvery: boolean
              isNot: boolean
              managesDirectly: boolean
              ids: Array<number>
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'SelectIdsOperand'
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'SelectOperand'
              field: string
              path?: string | null
              isEvery: boolean
              isNot: boolean
              values?: Array<string> | null
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'TextOperand'
              field: string
              isNot: boolean
              textCondition: TextOperandCondition
              text: string
              id?: string | null
              type: OperandType
            }
        >
      }>
    } | null
  }
}

export type MoveHandbookNodeMutationVariables = Exact<{
  handbookId: Scalars['Int']
  fromIndex: Scalars['Int']
  toIndex: Scalars['Int']
}>

export type MoveHandbookNodeMutation = {
  __typename?: 'Mutation'
  moveHandbookNode: boolean
}

export type MoveHandbookSectionToHandbookMutationVariables = Exact<{
  sectionId: Scalars['Int']
  handbookId: Scalars['Int']
}>

export type MoveHandbookSectionToHandbookMutation = {
  __typename?: 'Mutation'
  moveHandbookSectionToHandbook: boolean
}

export type UpdateDocumentRefNodeMutationVariables = Exact<{
  handbookId: Scalars['Int']
  nodeId: Scalars['String']
  attrs: DocumentRefNodeAttrsInput
}>

export type UpdateDocumentRefNodeMutation = {
  __typename?: 'Mutation'
  updateDocumentRefNode: boolean
}

export type RemoveHandbookNodeMutationVariables = Exact<{
  handbookId: Scalars['Int']
  nodeId: Scalars['String']
}>

export type RemoveHandbookNodeMutation = {
  __typename?: 'Mutation'
  removeHandbookNode: boolean
}

export type RemoveHandbookSectionMutationVariables = Exact<{
  sectionId: Scalars['Int']
  children?: InputMaybe<Scalars['Boolean']>
}>

export type RemoveHandbookSectionMutation = {
  __typename?: 'Mutation'
  removeHandbookSection: boolean
}

export type AddHandbookSectionEntryMutationVariables = Exact<{
  sectionId: Scalars['Int']
  input?: InputMaybe<HandbookSectionEntryInput>
}>

export type AddHandbookSectionEntryMutation = {
  __typename?: 'Mutation'
  addHandbookSectionEntry: {
    __typename?: 'AddHandbookSectionEntryResult'
    sectionState: {
      __typename?: 'HandbookSectionState'
      dirtyFields?: Array<string> | null
    }
    entry: {
      __typename?: 'HandbookSectionEntry'
      keys: Array<string>
      uid: string
      body?: any | null
      image?: any | null
      subtitle?: any | null
      subtitleAlign?: HandbookSectionTextAlign | null
      title?: any | null
      titleAlign?: HandbookSectionTextAlign | null
    }
  }
}

export type PatchHandbookSectionEntryMutationVariables = Exact<{
  sectionId: Scalars['Int']
  entryUid: Scalars['String']
  input: HandbookSectionEntryInput
}>

export type PatchHandbookSectionEntryMutation = {
  __typename?: 'Mutation'
  patchHandbookSectionEntry: {
    __typename?: 'AddHandbookSectionEntryResult'
    sectionState: {
      __typename?: 'HandbookSectionState'
      dirtyFields?: Array<string> | null
    }
  }
}

export type MoveHandbookSectionEntryMutationVariables = Exact<{
  sectionId: Scalars['Int']
  entryUid: Scalars['String']
  position: Scalars['Int']
}>

export type MoveHandbookSectionEntryMutation = {
  __typename?: 'Mutation'
  moveHandbookSectionEntry: {
    __typename?: 'MoveHandbookSectionEntryResult'
    sectionState: {
      __typename?: 'HandbookSectionState'
      dirtyFields?: Array<string> | null
    }
  }
}

export type RemoveHandbookSectionEntryMutationVariables = Exact<{
  sectionId: Scalars['Int']
  entryUid: Scalars['String']
}>

export type RemoveHandbookSectionEntryMutation = {
  __typename?: 'Mutation'
  removeHandbookSectionEntry: {
    __typename?: 'RemoveHandbookSectionEntryResult'
    sectionState: {
      __typename?: 'HandbookSectionState'
      dirtyFields?: Array<string> | null
    }
  }
}

export type ClearHandbookSectionDirtyMutationVariables = Exact<{
  sectionId: Scalars['Int']
}>

export type ClearHandbookSectionDirtyMutation = {
  __typename?: 'Mutation'
  section: {
    __typename?: 'HandbookSection'
    handbookId: number
    id: number
    version: number
    createdAt?: any | null
    createdBy?: number | null
    updatedAt?: any | null
    updatedBy?: number | null
    lastPublishedAt?: any | null
    lastPublishedBy?: number | null
    dirtyFields?: Array<string> | null
    hidden: boolean
    hideToc: boolean
    languageCode?: string | null
    theme?: string | null
    title?: string | null
    type: HandbookSectionType
    hideAnnotations: Array<number>
    editAnnotation?: string | null
    viewAnnotation?: string | null
    attrs: {
      __typename?: 'HandbookSectionAttributes'
      keys: Array<string>
      goalAlign?: HandbookSectionTextAlign | null
      image?: any | null
      photo?: any | null
      signatureImage?: any | null
      statementAlign?: HandbookSectionTextAlign | null
      subtitleAlign?: HandbookSectionTextAlign | null
      titleAlign?: HandbookSectionTextAlign | null
      animation?: boolean | null
      backgroundAnimation?: string | null
      backgroundColor?: any | null
      backgroundImage?: any | null
      backgroundOverlayColor?: any | null
      backgroundOverlayOpacity?: number | null
      bottom?: string | null
      bottomArrow?: boolean | null
      headingBackgroundImage?: any | null
      height?: string | null
      entryAngle?: string | null
      entryAnimation?: boolean | null
      maxColumns?: number | null
      showChapterIcon?: boolean | null
      signatureType?: string | null
      textShadow?: boolean | null
      titleHighlight?: string | null
      titleHighlightColor?: any | null
      video?: {
        __typename?: 'HandbookVideo'
        url: any
        height: number
        width: number
      } | null
    }
    fragments: {
      __typename?: 'HandbookSectionContent'
      keys: Array<string>
      body?: any | null
      description?: any | null
      goal?: any | null
      headline?: any | null
      introduction?: any | null
      signature?: any | null
      statement?: any | null
      subtitle?: any | null
      title?: any | null
    }
    listEntries?: Array<{
      __typename?: 'HandbookSectionEntry'
      keys: Array<string>
      uid: string
      body?: any | null
      image?: any | null
      subtitle?: any | null
      subtitleAlign?: HandbookSectionTextAlign | null
      title?: any | null
      titleAlign?: HandbookSectionTextAlign | null
    }> | null
    audienceExpression?: {
      __typename?: 'RootExpression'
      conjunction: ExpressionConjunction
      expressions: Array<{
        __typename?: 'Expression'
        conjunction: ExpressionConjunction
        operands: Array<
          | {
              __typename?: 'DateOperand'
              field: string
              dateCondition: DateOperandCondition
              days?: number | null
              date?: any | null
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'IdsOperand'
              isEvery: boolean
              isNot: boolean
              ids: Array<number>
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'ManagersOperand'
              isEvery: boolean
              isNot: boolean
              managesDirectly: boolean
              ids: Array<number>
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'SelectIdsOperand'
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'SelectOperand'
              field: string
              path?: string | null
              isEvery: boolean
              isNot: boolean
              values?: Array<string> | null
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'TextOperand'
              field: string
              isNot: boolean
              textCondition: TextOperandCondition
              text: string
              id?: string | null
              type: OperandType
            }
        >
      }>
    } | null
  }
}

export type ConvertHandbookSectionsToPoliciesMutationVariables = Exact<{
  sectionIds: Array<Scalars['Int']> | Scalars['Int']
}>

export type ConvertHandbookSectionsToPoliciesMutation = {
  __typename?: 'Mutation'
  content: any
}

export type RevertHandbookDocumentRefsMutationVariables = Exact<{
  handbookId: Scalars['Int']
  documentIds: Array<Scalars['Int']> | Scalars['Int']
}>

export type RevertHandbookDocumentRefsMutation = {
  __typename?: 'Mutation'
  revertHandbookDocumentRefs: {
    __typename?: 'RevertHandbookDocumentRefsResult'
    content: any
    sections: Array<{
      __typename?: 'HandbookSection'
      handbookId: number
      id: number
      version: number
      createdAt?: any | null
      createdBy?: number | null
      updatedAt?: any | null
      updatedBy?: number | null
      lastPublishedAt?: any | null
      lastPublishedBy?: number | null
      dirtyFields?: Array<string> | null
      hidden: boolean
      hideToc: boolean
      languageCode?: string | null
      theme?: string | null
      title?: string | null
      type: HandbookSectionType
      hideAnnotations: Array<number>
      editAnnotation?: string | null
      viewAnnotation?: string | null
      attrs: {
        __typename?: 'HandbookSectionAttributes'
        keys: Array<string>
        goalAlign?: HandbookSectionTextAlign | null
        image?: any | null
        photo?: any | null
        signatureImage?: any | null
        statementAlign?: HandbookSectionTextAlign | null
        subtitleAlign?: HandbookSectionTextAlign | null
        titleAlign?: HandbookSectionTextAlign | null
        animation?: boolean | null
        backgroundAnimation?: string | null
        backgroundColor?: any | null
        backgroundImage?: any | null
        backgroundOverlayColor?: any | null
        backgroundOverlayOpacity?: number | null
        bottom?: string | null
        bottomArrow?: boolean | null
        headingBackgroundImage?: any | null
        height?: string | null
        entryAngle?: string | null
        entryAnimation?: boolean | null
        maxColumns?: number | null
        showChapterIcon?: boolean | null
        signatureType?: string | null
        textShadow?: boolean | null
        titleHighlight?: string | null
        titleHighlightColor?: any | null
        video?: {
          __typename?: 'HandbookVideo'
          url: any
          height: number
          width: number
        } | null
      }
      fragments: {
        __typename?: 'HandbookSectionContent'
        keys: Array<string>
        body?: any | null
        description?: any | null
        goal?: any | null
        headline?: any | null
        introduction?: any | null
        signature?: any | null
        statement?: any | null
        subtitle?: any | null
        title?: any | null
      }
      listEntries?: Array<{
        __typename?: 'HandbookSectionEntry'
        keys: Array<string>
        uid: string
        body?: any | null
        image?: any | null
        subtitle?: any | null
        subtitleAlign?: HandbookSectionTextAlign | null
        title?: any | null
        titleAlign?: HandbookSectionTextAlign | null
      }> | null
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
    }>
  }
}

export type ArchiveHandbookFontMutationVariables = Exact<{
  handbookId: Scalars['Int']
  fontId: Scalars['Int']
}>

export type ArchiveHandbookFontMutation = {
  __typename?: 'Mutation'
  archiveHandbookFont: boolean
}

export type SetManagerSettingsMutationVariables = Exact<{
  input: ManagerSettingsInput
}>

export type SetManagerSettingsMutation = {
  __typename?: 'Mutation'
  managerSettings: {
    __typename?: 'ManagerSettings'
    allowNotificationsToManagers: boolean
    allowNotificationsToReports: boolean
    organizationId: number
    updatedAt?: any | null
  }
}

export type CreateDocumentMutationVariables = Exact<{
  input: CreateDocumentInput
}>

export type CreateDocumentMutation = {
  __typename?: 'Mutation'
  document: {
    __typename?: 'Document'
    id: number
    ckeditorChannelId: string
    parentFolderId?: number | null
    sourceHandbookId?: number | null
    sourceHandbookSectionId?: number | null
    sortPosition: number
    type: DocumentType
    name: string
    archivedAt?: any | null
    createdAt: any
    updatedAt: any
    handbookIds: Array<number>
    lastPublishedVersionNumber: number
    publishedVersions: Array<{
      __typename?: 'DocumentVersion'
      audienceExpressionHash: any
      createdAt: any
      documentId: number
      html?: any | null
      htmlHash: any
      htmlTitle?: string | null
      id: number
      isPublished: boolean
      updatedAt: any
      versionNumber: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      reviews: Array<{
        __typename?: 'DocumentReview'
        id: number
        documentId: number
        personId: number
        isApproved: boolean
        createdAt: any
        person: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        }
      }>
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
    archivedBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    createdBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    updatedBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    draftVersion?: {
      __typename?: 'DocumentVersion'
      audienceExpressionHash: any
      createdAt: any
      documentId: number
      html?: any | null
      htmlHash: any
      htmlTitle?: string | null
      id: number
      isPublished: boolean
      updatedAt: any
      versionNumber: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      reviews: Array<{
        __typename?: 'DocumentReview'
        id: number
        documentId: number
        personId: number
        isApproved: boolean
        createdAt: any
        person: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        }
      }>
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    } | null
    lastPublishedVersion?: {
      __typename?: 'DocumentVersion'
      audienceExpressionHash: any
      createdAt: any
      documentId: number
      html?: any | null
      htmlHash: any
      htmlTitle?: string | null
      id: number
      isPublished: boolean
      updatedAt: any
      versionNumber: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      reviews: Array<{
        __typename?: 'DocumentReview'
        id: number
        documentId: number
        personId: number
        isApproved: boolean
        createdAt: any
        person: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        }
      }>
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    } | null
    reviewers: Array<{
      __typename?: 'DocumentReviewer'
      id: number
      documentId: number
      personId: number
      createdAt: any
      updatedAt: any
      person: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      }
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
  }
}

export type CreateDocumentsMutationVariables = Exact<{
  inputs: Array<CreateDocumentInput> | CreateDocumentInput
}>

export type CreateDocumentsMutation = {
  __typename?: 'Mutation'
  documents: Array<{
    __typename?: 'Document'
    id: number
    ckeditorChannelId: string
    parentFolderId?: number | null
    sourceHandbookId?: number | null
    sourceHandbookSectionId?: number | null
    sortPosition: number
    type: DocumentType
    name: string
    archivedAt?: any | null
    createdAt: any
    updatedAt: any
    handbookIds: Array<number>
    lastPublishedVersionNumber: number
    publishedVersions: Array<{
      __typename?: 'DocumentVersion'
      audienceExpressionHash: any
      createdAt: any
      documentId: number
      html?: any | null
      htmlHash: any
      htmlTitle?: string | null
      id: number
      isPublished: boolean
      updatedAt: any
      versionNumber: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      reviews: Array<{
        __typename?: 'DocumentReview'
        id: number
        documentId: number
        personId: number
        isApproved: boolean
        createdAt: any
        person: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        }
      }>
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
    archivedBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    createdBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    updatedBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    draftVersion?: {
      __typename?: 'DocumentVersion'
      audienceExpressionHash: any
      createdAt: any
      documentId: number
      html?: any | null
      htmlHash: any
      htmlTitle?: string | null
      id: number
      isPublished: boolean
      updatedAt: any
      versionNumber: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      reviews: Array<{
        __typename?: 'DocumentReview'
        id: number
        documentId: number
        personId: number
        isApproved: boolean
        createdAt: any
        person: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        }
      }>
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    } | null
    lastPublishedVersion?: {
      __typename?: 'DocumentVersion'
      audienceExpressionHash: any
      createdAt: any
      documentId: number
      html?: any | null
      htmlHash: any
      htmlTitle?: string | null
      id: number
      isPublished: boolean
      updatedAt: any
      versionNumber: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      reviews: Array<{
        __typename?: 'DocumentReview'
        id: number
        documentId: number
        personId: number
        isApproved: boolean
        createdAt: any
        person: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        }
      }>
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    } | null
    reviewers: Array<{
      __typename?: 'DocumentReviewer'
      id: number
      documentId: number
      personId: number
      createdAt: any
      updatedAt: any
      person: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      }
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
  }>
}

export type CloneDocumentsMutationVariables = Exact<{
  documentIds: Array<Scalars['Int']> | Scalars['Int']
}>

export type CloneDocumentsMutation = {
  __typename?: 'Mutation'
  documents: Array<{
    __typename?: 'Document'
    id: number
    ckeditorChannelId: string
    parentFolderId?: number | null
    sourceHandbookId?: number | null
    sourceHandbookSectionId?: number | null
    sortPosition: number
    type: DocumentType
    name: string
    archivedAt?: any | null
    createdAt: any
    updatedAt: any
    handbookIds: Array<number>
    lastPublishedVersionNumber: number
    publishedVersions: Array<{
      __typename?: 'DocumentVersion'
      audienceExpressionHash: any
      createdAt: any
      documentId: number
      html?: any | null
      htmlHash: any
      htmlTitle?: string | null
      id: number
      isPublished: boolean
      updatedAt: any
      versionNumber: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      reviews: Array<{
        __typename?: 'DocumentReview'
        id: number
        documentId: number
        personId: number
        isApproved: boolean
        createdAt: any
        person: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        }
      }>
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
    archivedBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    createdBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    updatedBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    draftVersion?: {
      __typename?: 'DocumentVersion'
      audienceExpressionHash: any
      createdAt: any
      documentId: number
      html?: any | null
      htmlHash: any
      htmlTitle?: string | null
      id: number
      isPublished: boolean
      updatedAt: any
      versionNumber: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      reviews: Array<{
        __typename?: 'DocumentReview'
        id: number
        documentId: number
        personId: number
        isApproved: boolean
        createdAt: any
        person: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        }
      }>
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    } | null
    lastPublishedVersion?: {
      __typename?: 'DocumentVersion'
      audienceExpressionHash: any
      createdAt: any
      documentId: number
      html?: any | null
      htmlHash: any
      htmlTitle?: string | null
      id: number
      isPublished: boolean
      updatedAt: any
      versionNumber: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      reviews: Array<{
        __typename?: 'DocumentReview'
        id: number
        documentId: number
        personId: number
        isApproved: boolean
        createdAt: any
        person: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        }
      }>
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    } | null
    reviewers: Array<{
      __typename?: 'DocumentReviewer'
      id: number
      documentId: number
      personId: number
      createdAt: any
      updatedAt: any
      person: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      }
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
  }>
}

export type ArchiveDocumentsMutationVariables = Exact<{
  documentIds: Array<Scalars['Int']> | Scalars['Int']
}>

export type ArchiveDocumentsMutation = {
  __typename?: 'Mutation'
  archiveDocuments: Array<{
    __typename?: 'Document'
    id: number
    ckeditorChannelId: string
    parentFolderId?: number | null
    sourceHandbookId?: number | null
    sourceHandbookSectionId?: number | null
    sortPosition: number
    type: DocumentType
    name: string
    archivedAt?: any | null
    createdAt: any
    updatedAt: any
    handbookIds: Array<number>
    lastPublishedVersionNumber: number
    archivedBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    createdBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    updatedBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    draftVersion?: {
      __typename?: 'DocumentVersion'
      audienceExpressionHash: any
      createdAt: any
      documentId: number
      html?: any | null
      htmlHash: any
      htmlTitle?: string | null
      id: number
      isPublished: boolean
      updatedAt: any
      versionNumber: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      reviews: Array<{
        __typename?: 'DocumentReview'
        id: number
        documentId: number
        personId: number
        isApproved: boolean
        createdAt: any
        person: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        }
      }>
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    } | null
    lastPublishedVersion?: {
      __typename?: 'DocumentVersion'
      audienceExpressionHash: any
      createdAt: any
      documentId: number
      html?: any | null
      htmlHash: any
      htmlTitle?: string | null
      id: number
      isPublished: boolean
      updatedAt: any
      versionNumber: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      reviews: Array<{
        __typename?: 'DocumentReview'
        id: number
        documentId: number
        personId: number
        isApproved: boolean
        createdAt: any
        person: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        }
      }>
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    } | null
    reviewers: Array<{
      __typename?: 'DocumentReviewer'
      id: number
      documentId: number
      personId: number
      createdAt: any
      updatedAt: any
      person: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      }
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
  }>
}

export type UnarchiveDocumentsMutationVariables = Exact<{
  documentIds: Array<Scalars['Int']> | Scalars['Int']
}>

export type UnarchiveDocumentsMutation = {
  __typename?: 'Mutation'
  unarchiveDocuments: Array<{
    __typename?: 'Document'
    id: number
    ckeditorChannelId: string
    parentFolderId?: number | null
    sourceHandbookId?: number | null
    sourceHandbookSectionId?: number | null
    sortPosition: number
    type: DocumentType
    name: string
    archivedAt?: any | null
    createdAt: any
    updatedAt: any
    handbookIds: Array<number>
    lastPublishedVersionNumber: number
    archivedBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    createdBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    updatedBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    draftVersion?: {
      __typename?: 'DocumentVersion'
      audienceExpressionHash: any
      createdAt: any
      documentId: number
      html?: any | null
      htmlHash: any
      htmlTitle?: string | null
      id: number
      isPublished: boolean
      updatedAt: any
      versionNumber: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      reviews: Array<{
        __typename?: 'DocumentReview'
        id: number
        documentId: number
        personId: number
        isApproved: boolean
        createdAt: any
        person: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        }
      }>
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    } | null
    lastPublishedVersion?: {
      __typename?: 'DocumentVersion'
      audienceExpressionHash: any
      createdAt: any
      documentId: number
      html?: any | null
      htmlHash: any
      htmlTitle?: string | null
      id: number
      isPublished: boolean
      updatedAt: any
      versionNumber: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      reviews: Array<{
        __typename?: 'DocumentReview'
        id: number
        documentId: number
        personId: number
        isApproved: boolean
        createdAt: any
        person: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        }
      }>
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    } | null
    reviewers: Array<{
      __typename?: 'DocumentReviewer'
      id: number
      documentId: number
      personId: number
      createdAt: any
      updatedAt: any
      person: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      }
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
  }>
}

export type DeleteDocumentsMutationVariables = Exact<{
  documentIds: Array<Scalars['Int']> | Scalars['Int']
}>

export type DeleteDocumentsMutation = {
  __typename?: 'Mutation'
  deleteDocuments: boolean
}

export type CreateDocumentDraftVersionMutationVariables = Exact<{
  documentId: Scalars['Int']
  input?: InputMaybe<UpdateDocumentDraftVersionInput>
}>

export type CreateDocumentDraftVersionMutation = {
  __typename?: 'Mutation'
  document: {
    __typename?: 'Document'
    id: number
    ckeditorChannelId: string
    parentFolderId?: number | null
    sourceHandbookId?: number | null
    sourceHandbookSectionId?: number | null
    sortPosition: number
    type: DocumentType
    name: string
    archivedAt?: any | null
    createdAt: any
    updatedAt: any
    handbookIds: Array<number>
    lastPublishedVersionNumber: number
    publishedVersions: Array<{
      __typename?: 'DocumentVersion'
      audienceExpressionHash: any
      createdAt: any
      documentId: number
      html?: any | null
      htmlHash: any
      htmlTitle?: string | null
      id: number
      isPublished: boolean
      updatedAt: any
      versionNumber: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      reviews: Array<{
        __typename?: 'DocumentReview'
        id: number
        documentId: number
        personId: number
        isApproved: boolean
        createdAt: any
        person: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        }
      }>
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
    archivedBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    createdBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    updatedBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    draftVersion?: {
      __typename?: 'DocumentVersion'
      audienceExpressionHash: any
      createdAt: any
      documentId: number
      html?: any | null
      htmlHash: any
      htmlTitle?: string | null
      id: number
      isPublished: boolean
      updatedAt: any
      versionNumber: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      reviews: Array<{
        __typename?: 'DocumentReview'
        id: number
        documentId: number
        personId: number
        isApproved: boolean
        createdAt: any
        person: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        }
      }>
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    } | null
    lastPublishedVersion?: {
      __typename?: 'DocumentVersion'
      audienceExpressionHash: any
      createdAt: any
      documentId: number
      html?: any | null
      htmlHash: any
      htmlTitle?: string | null
      id: number
      isPublished: boolean
      updatedAt: any
      versionNumber: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      reviews: Array<{
        __typename?: 'DocumentReview'
        id: number
        documentId: number
        personId: number
        isApproved: boolean
        createdAt: any
        person: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        }
      }>
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    } | null
    reviewers: Array<{
      __typename?: 'DocumentReviewer'
      id: number
      documentId: number
      personId: number
      createdAt: any
      updatedAt: any
      person: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      }
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
  }
}

export type UpdateDocumentsMutationVariables = Exact<{
  updates: Array<UpdateDocumentArgs> | UpdateDocumentArgs
}>

export type UpdateDocumentsMutation = {
  __typename?: 'Mutation'
  documents: Array<{
    __typename?: 'Document'
    id: number
    ckeditorChannelId: string
    parentFolderId?: number | null
    sourceHandbookId?: number | null
    sourceHandbookSectionId?: number | null
    sortPosition: number
    type: DocumentType
    name: string
    archivedAt?: any | null
    createdAt: any
    updatedAt: any
    handbookIds: Array<number>
    lastPublishedVersionNumber: number
    archivedBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    createdBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    updatedBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    draftVersion?: {
      __typename?: 'DocumentVersion'
      audienceExpressionHash: any
      createdAt: any
      documentId: number
      html?: any | null
      htmlHash: any
      htmlTitle?: string | null
      id: number
      isPublished: boolean
      updatedAt: any
      versionNumber: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      reviews: Array<{
        __typename?: 'DocumentReview'
        id: number
        documentId: number
        personId: number
        isApproved: boolean
        createdAt: any
        person: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        }
      }>
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    } | null
    lastPublishedVersion?: {
      __typename?: 'DocumentVersion'
      audienceExpressionHash: any
      createdAt: any
      documentId: number
      html?: any | null
      htmlHash: any
      htmlTitle?: string | null
      id: number
      isPublished: boolean
      updatedAt: any
      versionNumber: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      reviews: Array<{
        __typename?: 'DocumentReview'
        id: number
        documentId: number
        personId: number
        isApproved: boolean
        createdAt: any
        person: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        }
      }>
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    } | null
    reviewers: Array<{
      __typename?: 'DocumentReviewer'
      id: number
      documentId: number
      personId: number
      createdAt: any
      updatedAt: any
      person: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      }
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
  }>
}

export type UpdateDocumentDraftVersionMutationVariables = Exact<{
  documentId: Scalars['Int']
  input: UpdateDocumentDraftVersionInput
}>

export type UpdateDocumentDraftVersionMutation = {
  __typename?: 'Mutation'
  document: {
    __typename?: 'Document'
    id: number
    ckeditorChannelId: string
    parentFolderId?: number | null
    sourceHandbookId?: number | null
    sourceHandbookSectionId?: number | null
    sortPosition: number
    type: DocumentType
    name: string
    archivedAt?: any | null
    createdAt: any
    updatedAt: any
    handbookIds: Array<number>
    lastPublishedVersionNumber: number
    publishedVersions: Array<{
      __typename?: 'DocumentVersion'
      audienceExpressionHash: any
      createdAt: any
      documentId: number
      html?: any | null
      htmlHash: any
      htmlTitle?: string | null
      id: number
      isPublished: boolean
      updatedAt: any
      versionNumber: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      reviews: Array<{
        __typename?: 'DocumentReview'
        id: number
        documentId: number
        personId: number
        isApproved: boolean
        createdAt: any
        person: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        }
      }>
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
    archivedBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    createdBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    updatedBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    draftVersion?: {
      __typename?: 'DocumentVersion'
      audienceExpressionHash: any
      createdAt: any
      documentId: number
      html?: any | null
      htmlHash: any
      htmlTitle?: string | null
      id: number
      isPublished: boolean
      updatedAt: any
      versionNumber: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      reviews: Array<{
        __typename?: 'DocumentReview'
        id: number
        documentId: number
        personId: number
        isApproved: boolean
        createdAt: any
        person: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        }
      }>
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    } | null
    lastPublishedVersion?: {
      __typename?: 'DocumentVersion'
      audienceExpressionHash: any
      createdAt: any
      documentId: number
      html?: any | null
      htmlHash: any
      htmlTitle?: string | null
      id: number
      isPublished: boolean
      updatedAt: any
      versionNumber: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      reviews: Array<{
        __typename?: 'DocumentReview'
        id: number
        documentId: number
        personId: number
        isApproved: boolean
        createdAt: any
        person: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        }
      }>
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    } | null
    reviewers: Array<{
      __typename?: 'DocumentReviewer'
      id: number
      documentId: number
      personId: number
      createdAt: any
      updatedAt: any
      person: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      }
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
  }
}

export type MoveDocumentsMutationVariables = Exact<{
  documentIds: Array<Scalars['Int']> | Scalars['Int']
  input: MoveDocumentsInput
}>

export type MoveDocumentsMutation = {
  __typename?: 'Mutation'
  documents: Array<{
    __typename?: 'Document'
    id: number
    sortPosition: number
  }>
}

export type PublishDocumentsMutationVariables = Exact<{
  documentIds: Array<Scalars['Int']> | Scalars['Int']
}>

export type PublishDocumentsMutation = {
  __typename?: 'Mutation'
  documents: Array<{
    __typename?: 'Document'
    id: number
    ckeditorChannelId: string
    parentFolderId?: number | null
    sourceHandbookId?: number | null
    sourceHandbookSectionId?: number | null
    sortPosition: number
    type: DocumentType
    name: string
    archivedAt?: any | null
    createdAt: any
    updatedAt: any
    handbookIds: Array<number>
    lastPublishedVersionNumber: number
    publishedVersions: Array<{
      __typename?: 'DocumentVersion'
      audienceExpressionHash: any
      createdAt: any
      documentId: number
      html?: any | null
      htmlHash: any
      htmlTitle?: string | null
      id: number
      isPublished: boolean
      updatedAt: any
      versionNumber: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      reviews: Array<{
        __typename?: 'DocumentReview'
        id: number
        documentId: number
        personId: number
        isApproved: boolean
        createdAt: any
        person: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        }
      }>
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
    archivedBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    createdBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    updatedBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    draftVersion?: {
      __typename?: 'DocumentVersion'
      audienceExpressionHash: any
      createdAt: any
      documentId: number
      html?: any | null
      htmlHash: any
      htmlTitle?: string | null
      id: number
      isPublished: boolean
      updatedAt: any
      versionNumber: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      reviews: Array<{
        __typename?: 'DocumentReview'
        id: number
        documentId: number
        personId: number
        isApproved: boolean
        createdAt: any
        person: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        }
      }>
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    } | null
    lastPublishedVersion?: {
      __typename?: 'DocumentVersion'
      audienceExpressionHash: any
      createdAt: any
      documentId: number
      html?: any | null
      htmlHash: any
      htmlTitle?: string | null
      id: number
      isPublished: boolean
      updatedAt: any
      versionNumber: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      reviews: Array<{
        __typename?: 'DocumentReview'
        id: number
        documentId: number
        personId: number
        isApproved: boolean
        createdAt: any
        person: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        }
      }>
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    } | null
    reviewers: Array<{
      __typename?: 'DocumentReviewer'
      id: number
      documentId: number
      personId: number
      createdAt: any
      updatedAt: any
      person: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      }
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
  }>
}

export type UpdateDocumentSettingsMutationVariables = Exact<{
  input: UpdateDocumentSettingsInput
}>

export type UpdateDocumentSettingsMutation = {
  __typename?: 'Mutation'
  documentSettings: {
    __typename?: 'DocumentSettings'
    isPolicyWorkflowEnabled: boolean
    organizationId: number
  }
}

export type CreateMergeDevLinkTokenMutationVariables = Exact<{
  [key: string]: never
}>

export type CreateMergeDevLinkTokenMutation = {
  __typename?: 'Mutation'
  createMergeDevLinkToken: {
    __typename?: 'CreateMergeDevLinkTokenResponse'
    linkToken: string
  }
}

export type CreateMergeDevIntegrationMutationVariables = Exact<{
  publicToken: Scalars['String']
}>

export type CreateMergeDevIntegrationMutation = {
  __typename?: 'Mutation'
  integration: {
    __typename?: 'MergeDevIntegration'
    externalId: string
    integrationName: string
    syncHourUtc?: number | null
    id: number
    providerId: IntegrationProviderId
    lastSyncAt?: any | null
    lastErrorAt?: any | null
    lastError?: string | null
    createdAt: any
    updatedAt: any
    rejectPeopleExpression?: {
      __typename?: 'RootExpression'
      conjunction: ExpressionConjunction
      expressions: Array<{
        __typename?: 'Expression'
        conjunction: ExpressionConjunction
        operands: Array<
          | {
              __typename?: 'DateOperand'
              field: string
              dateCondition: DateOperandCondition
              days?: number | null
              date?: any | null
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'IdsOperand'
              isEvery: boolean
              isNot: boolean
              ids: Array<number>
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'ManagersOperand'
              isEvery: boolean
              isNot: boolean
              managesDirectly: boolean
              ids: Array<number>
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'SelectIdsOperand'
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'SelectOperand'
              field: string
              path?: string | null
              isEvery: boolean
              isNot: boolean
              values?: Array<string> | null
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'TextOperand'
              field: string
              isNot: boolean
              textCondition: TextOperandCondition
              text: string
              id?: string | null
              type: OperandType
            }
        >
      }>
    } | null
  }
}

export type UpdateIntegrationMutationVariables = Exact<{
  id: Scalars['Int']
  input: UpdateIntegrationInput
}>

export type UpdateIntegrationMutation = {
  __typename?: 'Mutation'
  integration:
    | {
        __typename?: 'BambooHRIntegration'
        groups: Array<BambooHrGroupId>
        hostname: string
        subdomain: string
        syncTiming?: BambooHrSyncTiming | null
        userId?: string | null
        id: number
        providerId: IntegrationProviderId
        lastSyncAt?: any | null
        lastErrorAt?: any | null
        lastError?: string | null
        createdAt: any
        updatedAt: any
        rejectPeopleExpression?: {
          __typename?: 'RootExpression'
          conjunction: ExpressionConjunction
          expressions: Array<{
            __typename?: 'Expression'
            conjunction: ExpressionConjunction
            operands: Array<
              | {
                  __typename?: 'DateOperand'
                  field: string
                  dateCondition: DateOperandCondition
                  days?: number | null
                  date?: any | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'IdsOperand'
                  isEvery: boolean
                  isNot: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'ManagersOperand'
                  isEvery: boolean
                  isNot: boolean
                  managesDirectly: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectIdsOperand'
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectOperand'
                  field: string
                  path?: string | null
                  isEvery: boolean
                  isNot: boolean
                  values?: Array<string> | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'TextOperand'
                  field: string
                  isNot: boolean
                  textCondition: TextOperandCondition
                  text: string
                  id?: string | null
                  type: OperandType
                }
            >
          }>
        } | null
      }
    | {
        __typename?: 'RipplingIntegration'
        customFieldKeys: Array<string>
        ripplingGroupIds: Array<RipplingGroupId>
        id: number
        providerId: IntegrationProviderId
        lastSyncAt?: any | null
        lastErrorAt?: any | null
        lastError?: string | null
        createdAt: any
        updatedAt: any
        rejectPeopleExpression?: {
          __typename?: 'RootExpression'
          conjunction: ExpressionConjunction
          expressions: Array<{
            __typename?: 'Expression'
            conjunction: ExpressionConjunction
            operands: Array<
              | {
                  __typename?: 'DateOperand'
                  field: string
                  dateCondition: DateOperandCondition
                  days?: number | null
                  date?: any | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'IdsOperand'
                  isEvery: boolean
                  isNot: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'ManagersOperand'
                  isEvery: boolean
                  isNot: boolean
                  managesDirectly: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectIdsOperand'
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectOperand'
                  field: string
                  path?: string | null
                  isEvery: boolean
                  isNot: boolean
                  values?: Array<string> | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'TextOperand'
                  field: string
                  isNot: boolean
                  textCondition: TextOperandCondition
                  text: string
                  id?: string | null
                  type: OperandType
                }
            >
          }>
        } | null
      }
    | {
        __typename?: 'SftpIntegration'
        username: string
        id: number
        providerId: IntegrationProviderId
        lastSyncAt?: any | null
        lastErrorAt?: any | null
        lastError?: string | null
        createdAt: any
        updatedAt: any
        fingerprints: {
          __typename?: 'SshFingerprints'
          sha256: string
          md5: string
        }
        rejectPeopleExpression?: {
          __typename?: 'RootExpression'
          conjunction: ExpressionConjunction
          expressions: Array<{
            __typename?: 'Expression'
            conjunction: ExpressionConjunction
            operands: Array<
              | {
                  __typename?: 'DateOperand'
                  field: string
                  dateCondition: DateOperandCondition
                  days?: number | null
                  date?: any | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'IdsOperand'
                  isEvery: boolean
                  isNot: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'ManagersOperand'
                  isEvery: boolean
                  isNot: boolean
                  managesDirectly: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectIdsOperand'
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectOperand'
                  field: string
                  path?: string | null
                  isEvery: boolean
                  isNot: boolean
                  values?: Array<string> | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'TextOperand'
                  field: string
                  isNot: boolean
                  textCondition: TextOperandCondition
                  text: string
                  id?: string | null
                  type: OperandType
                }
            >
          }>
        } | null
      }
    | {
        __typename?: 'NewSftpIntegration'
        id: number
        providerId: IntegrationProviderId
        lastSyncAt?: any | null
        lastErrorAt?: any | null
        lastError?: string | null
        createdAt: any
        updatedAt: any
        rejectPeopleExpression?: {
          __typename?: 'RootExpression'
          conjunction: ExpressionConjunction
          expressions: Array<{
            __typename?: 'Expression'
            conjunction: ExpressionConjunction
            operands: Array<
              | {
                  __typename?: 'DateOperand'
                  field: string
                  dateCondition: DateOperandCondition
                  days?: number | null
                  date?: any | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'IdsOperand'
                  isEvery: boolean
                  isNot: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'ManagersOperand'
                  isEvery: boolean
                  isNot: boolean
                  managesDirectly: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectIdsOperand'
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectOperand'
                  field: string
                  path?: string | null
                  isEvery: boolean
                  isNot: boolean
                  values?: Array<string> | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'TextOperand'
                  field: string
                  isNot: boolean
                  textCondition: TextOperandCondition
                  text: string
                  id?: string | null
                  type: OperandType
                }
            >
          }>
        } | null
      }
    | {
        __typename?: 'HttpsIntegration'
        id: number
        providerId: IntegrationProviderId
        lastSyncAt?: any | null
        lastErrorAt?: any | null
        lastError?: string | null
        createdAt: any
        updatedAt: any
        rejectPeopleExpression?: {
          __typename?: 'RootExpression'
          conjunction: ExpressionConjunction
          expressions: Array<{
            __typename?: 'Expression'
            conjunction: ExpressionConjunction
            operands: Array<
              | {
                  __typename?: 'DateOperand'
                  field: string
                  dateCondition: DateOperandCondition
                  days?: number | null
                  date?: any | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'IdsOperand'
                  isEvery: boolean
                  isNot: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'ManagersOperand'
                  isEvery: boolean
                  isNot: boolean
                  managesDirectly: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectIdsOperand'
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectOperand'
                  field: string
                  path?: string | null
                  isEvery: boolean
                  isNot: boolean
                  values?: Array<string> | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'TextOperand'
                  field: string
                  isNot: boolean
                  textCondition: TextOperandCondition
                  text: string
                  id?: string | null
                  type: OperandType
                }
            >
          }>
        } | null
      }
    | {
        __typename?: 'MergeDevIntegration'
        externalId: string
        integrationName: string
        syncHourUtc?: number | null
        id: number
        providerId: IntegrationProviderId
        lastSyncAt?: any | null
        lastErrorAt?: any | null
        lastError?: string | null
        createdAt: any
        updatedAt: any
        rejectPeopleExpression?: {
          __typename?: 'RootExpression'
          conjunction: ExpressionConjunction
          expressions: Array<{
            __typename?: 'Expression'
            conjunction: ExpressionConjunction
            operands: Array<
              | {
                  __typename?: 'DateOperand'
                  field: string
                  dateCondition: DateOperandCondition
                  days?: number | null
                  date?: any | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'IdsOperand'
                  isEvery: boolean
                  isNot: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'ManagersOperand'
                  isEvery: boolean
                  isNot: boolean
                  managesDirectly: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectIdsOperand'
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectOperand'
                  field: string
                  path?: string | null
                  isEvery: boolean
                  isNot: boolean
                  values?: Array<string> | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'TextOperand'
                  field: string
                  isNot: boolean
                  textCondition: TextOperandCondition
                  text: string
                  id?: string | null
                  type: OperandType
                }
            >
          }>
        } | null
      }
}

export type ExportHandbookRecipientsToCsvMutationVariables = Exact<{
  columnKeys: Array<HandbookRecipientsColumnKey> | HandbookRecipientsColumnKey
  filter?: InputMaybe<HandbookRecipientsFilter>
  handbookId: Scalars['Int']
  order?: InputMaybe<HandbookRecipientsSortOrder>
}>

export type ExportHandbookRecipientsToCsvMutation = {
  __typename?: 'Mutation'
  exportHandbookRecipientsToCSV: string
}

export type ExportPeopleToCsvMutationVariables = Exact<{
  columnKeys: Array<PeopleColumnKey> | PeopleColumnKey
  filter?: InputMaybe<PeopleFilter>
  order?: InputMaybe<PeopleSortOrder>
  showArchived?: InputMaybe<Scalars['Boolean']>
}>

export type ExportPeopleToCsvMutation = {
  __typename?: 'Mutation'
  exportPeopleToCSV: string
}

export type SendHandbookRemindersMutationVariables = Exact<{
  filter?: InputMaybe<HandbookRecipientsFilter>
  handbookId: Scalars['Int']
  isSignatureRequired: Scalars['Boolean']
  template?: InputMaybe<EmailTemplateInput>
}>

export type SendHandbookRemindersMutation = {
  __typename?: 'Mutation'
  sendHandbookReminders: number
}

export type SendEmployeeDigestMutationVariables = Exact<{
  filter?: InputMaybe<PeopleFilter>
  template?: InputMaybe<EmailTemplateInput>
}>

export type SendEmployeeDigestMutation = {
  __typename?: 'Mutation'
  sendEmployeeDigest: number
}

export type SendManagerDigestMutationVariables = Exact<{
  filter?: InputMaybe<PeopleFilter>
  template?: InputMaybe<EmailTemplateInput>
}>

export type SendManagerDigestMutation = {
  __typename?: 'Mutation'
  sendManagerDigest: number
}

export type SavedReportsQueryVariables = Exact<{ [key: string]: never }>

export type SavedReportsQuery = {
  __typename?: 'Query'
  savedReports: Array<
    | {
        __typename?: 'DocumentSavedReport'
        handbookId: number
        id: number
        type: SavedReportType
        name: string
        description?: string | null
        showArchived: boolean
        createdAt: any
        updatedAt: any
        archivedAt?: any | null
        expression: {
          __typename?: 'RootExpression'
          conjunction: ExpressionConjunction
          expressions: Array<{
            __typename?: 'Expression'
            conjunction: ExpressionConjunction
            operands: Array<
              | {
                  __typename?: 'DateOperand'
                  field: string
                  dateCondition: DateOperandCondition
                  days?: number | null
                  date?: any | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'IdsOperand'
                  isEvery: boolean
                  isNot: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'ManagersOperand'
                  isEvery: boolean
                  isNot: boolean
                  managesDirectly: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectIdsOperand'
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectOperand'
                  field: string
                  path?: string | null
                  isEvery: boolean
                  isNot: boolean
                  values?: Array<string> | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'TextOperand'
                  field: string
                  isNot: boolean
                  textCondition: TextOperandCondition
                  text: string
                  id?: string | null
                  type: OperandType
                }
            >
          }>
        }
        columnKeys: Array<{
          __typename?: 'SavedReportColumnKey'
          field: string
          path?: string | null
        }>
        order: {
          __typename?: 'SortOrder'
          field: string
          direction: SortDirection
        }
        createdBy?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
        updatedBy?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
      }
    | {
        __typename?: 'PeopleSavedReport'
        id: number
        type: SavedReportType
        name: string
        description?: string | null
        showArchived: boolean
        createdAt: any
        updatedAt: any
        archivedAt?: any | null
        expression: {
          __typename?: 'RootExpression'
          conjunction: ExpressionConjunction
          expressions: Array<{
            __typename?: 'Expression'
            conjunction: ExpressionConjunction
            operands: Array<
              | {
                  __typename?: 'DateOperand'
                  field: string
                  dateCondition: DateOperandCondition
                  days?: number | null
                  date?: any | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'IdsOperand'
                  isEvery: boolean
                  isNot: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'ManagersOperand'
                  isEvery: boolean
                  isNot: boolean
                  managesDirectly: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectIdsOperand'
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectOperand'
                  field: string
                  path?: string | null
                  isEvery: boolean
                  isNot: boolean
                  values?: Array<string> | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'TextOperand'
                  field: string
                  isNot: boolean
                  textCondition: TextOperandCondition
                  text: string
                  id?: string | null
                  type: OperandType
                }
            >
          }>
        }
        columnKeys: Array<{
          __typename?: 'SavedReportColumnKey'
          field: string
          path?: string | null
        }>
        order: {
          __typename?: 'SortOrder'
          field: string
          direction: SortDirection
        }
        createdBy?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
        updatedBy?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
      }
  >
}

export type CreateDocumentSavedReportMutationVariables = Exact<{
  input: CreateDocumentSavedReportInput
}>

export type CreateDocumentSavedReportMutation = {
  __typename?: 'Mutation'
  savedReport:
    | {
        __typename?: 'DocumentSavedReport'
        handbookId: number
        id: number
        type: SavedReportType
        name: string
        description?: string | null
        showArchived: boolean
        createdAt: any
        updatedAt: any
        archivedAt?: any | null
        expression: {
          __typename?: 'RootExpression'
          conjunction: ExpressionConjunction
          expressions: Array<{
            __typename?: 'Expression'
            conjunction: ExpressionConjunction
            operands: Array<
              | {
                  __typename?: 'DateOperand'
                  field: string
                  dateCondition: DateOperandCondition
                  days?: number | null
                  date?: any | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'IdsOperand'
                  isEvery: boolean
                  isNot: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'ManagersOperand'
                  isEvery: boolean
                  isNot: boolean
                  managesDirectly: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectIdsOperand'
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectOperand'
                  field: string
                  path?: string | null
                  isEvery: boolean
                  isNot: boolean
                  values?: Array<string> | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'TextOperand'
                  field: string
                  isNot: boolean
                  textCondition: TextOperandCondition
                  text: string
                  id?: string | null
                  type: OperandType
                }
            >
          }>
        }
        columnKeys: Array<{
          __typename?: 'SavedReportColumnKey'
          field: string
          path?: string | null
        }>
        order: {
          __typename?: 'SortOrder'
          field: string
          direction: SortDirection
        }
        createdBy?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
        updatedBy?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
      }
    | {
        __typename?: 'PeopleSavedReport'
        id: number
        type: SavedReportType
        name: string
        description?: string | null
        showArchived: boolean
        createdAt: any
        updatedAt: any
        archivedAt?: any | null
        expression: {
          __typename?: 'RootExpression'
          conjunction: ExpressionConjunction
          expressions: Array<{
            __typename?: 'Expression'
            conjunction: ExpressionConjunction
            operands: Array<
              | {
                  __typename?: 'DateOperand'
                  field: string
                  dateCondition: DateOperandCondition
                  days?: number | null
                  date?: any | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'IdsOperand'
                  isEvery: boolean
                  isNot: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'ManagersOperand'
                  isEvery: boolean
                  isNot: boolean
                  managesDirectly: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectIdsOperand'
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectOperand'
                  field: string
                  path?: string | null
                  isEvery: boolean
                  isNot: boolean
                  values?: Array<string> | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'TextOperand'
                  field: string
                  isNot: boolean
                  textCondition: TextOperandCondition
                  text: string
                  id?: string | null
                  type: OperandType
                }
            >
          }>
        }
        columnKeys: Array<{
          __typename?: 'SavedReportColumnKey'
          field: string
          path?: string | null
        }>
        order: {
          __typename?: 'SortOrder'
          field: string
          direction: SortDirection
        }
        createdBy?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
        updatedBy?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
      }
}

export type UpdateDocumentSavedReportMutationVariables = Exact<{
  savedReportId: Scalars['Int']
  input: UpdateDocumentSavedReportInput
}>

export type UpdateDocumentSavedReportMutation = {
  __typename?: 'Mutation'
  savedReport:
    | {
        __typename?: 'DocumentSavedReport'
        handbookId: number
        id: number
        type: SavedReportType
        name: string
        description?: string | null
        showArchived: boolean
        createdAt: any
        updatedAt: any
        archivedAt?: any | null
        expression: {
          __typename?: 'RootExpression'
          conjunction: ExpressionConjunction
          expressions: Array<{
            __typename?: 'Expression'
            conjunction: ExpressionConjunction
            operands: Array<
              | {
                  __typename?: 'DateOperand'
                  field: string
                  dateCondition: DateOperandCondition
                  days?: number | null
                  date?: any | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'IdsOperand'
                  isEvery: boolean
                  isNot: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'ManagersOperand'
                  isEvery: boolean
                  isNot: boolean
                  managesDirectly: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectIdsOperand'
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectOperand'
                  field: string
                  path?: string | null
                  isEvery: boolean
                  isNot: boolean
                  values?: Array<string> | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'TextOperand'
                  field: string
                  isNot: boolean
                  textCondition: TextOperandCondition
                  text: string
                  id?: string | null
                  type: OperandType
                }
            >
          }>
        }
        columnKeys: Array<{
          __typename?: 'SavedReportColumnKey'
          field: string
          path?: string | null
        }>
        order: {
          __typename?: 'SortOrder'
          field: string
          direction: SortDirection
        }
        createdBy?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
        updatedBy?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
      }
    | {
        __typename?: 'PeopleSavedReport'
        id: number
        type: SavedReportType
        name: string
        description?: string | null
        showArchived: boolean
        createdAt: any
        updatedAt: any
        archivedAt?: any | null
        expression: {
          __typename?: 'RootExpression'
          conjunction: ExpressionConjunction
          expressions: Array<{
            __typename?: 'Expression'
            conjunction: ExpressionConjunction
            operands: Array<
              | {
                  __typename?: 'DateOperand'
                  field: string
                  dateCondition: DateOperandCondition
                  days?: number | null
                  date?: any | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'IdsOperand'
                  isEvery: boolean
                  isNot: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'ManagersOperand'
                  isEvery: boolean
                  isNot: boolean
                  managesDirectly: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectIdsOperand'
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectOperand'
                  field: string
                  path?: string | null
                  isEvery: boolean
                  isNot: boolean
                  values?: Array<string> | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'TextOperand'
                  field: string
                  isNot: boolean
                  textCondition: TextOperandCondition
                  text: string
                  id?: string | null
                  type: OperandType
                }
            >
          }>
        }
        columnKeys: Array<{
          __typename?: 'SavedReportColumnKey'
          field: string
          path?: string | null
        }>
        order: {
          __typename?: 'SortOrder'
          field: string
          direction: SortDirection
        }
        createdBy?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
        updatedBy?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
      }
}

export type CreatePeopleSavedReportMutationVariables = Exact<{
  input: CreatePeopleSavedReportInput
}>

export type CreatePeopleSavedReportMutation = {
  __typename?: 'Mutation'
  savedReport:
    | {
        __typename?: 'DocumentSavedReport'
        handbookId: number
        id: number
        type: SavedReportType
        name: string
        description?: string | null
        showArchived: boolean
        createdAt: any
        updatedAt: any
        archivedAt?: any | null
        expression: {
          __typename?: 'RootExpression'
          conjunction: ExpressionConjunction
          expressions: Array<{
            __typename?: 'Expression'
            conjunction: ExpressionConjunction
            operands: Array<
              | {
                  __typename?: 'DateOperand'
                  field: string
                  dateCondition: DateOperandCondition
                  days?: number | null
                  date?: any | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'IdsOperand'
                  isEvery: boolean
                  isNot: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'ManagersOperand'
                  isEvery: boolean
                  isNot: boolean
                  managesDirectly: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectIdsOperand'
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectOperand'
                  field: string
                  path?: string | null
                  isEvery: boolean
                  isNot: boolean
                  values?: Array<string> | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'TextOperand'
                  field: string
                  isNot: boolean
                  textCondition: TextOperandCondition
                  text: string
                  id?: string | null
                  type: OperandType
                }
            >
          }>
        }
        columnKeys: Array<{
          __typename?: 'SavedReportColumnKey'
          field: string
          path?: string | null
        }>
        order: {
          __typename?: 'SortOrder'
          field: string
          direction: SortDirection
        }
        createdBy?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
        updatedBy?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
      }
    | {
        __typename?: 'PeopleSavedReport'
        id: number
        type: SavedReportType
        name: string
        description?: string | null
        showArchived: boolean
        createdAt: any
        updatedAt: any
        archivedAt?: any | null
        expression: {
          __typename?: 'RootExpression'
          conjunction: ExpressionConjunction
          expressions: Array<{
            __typename?: 'Expression'
            conjunction: ExpressionConjunction
            operands: Array<
              | {
                  __typename?: 'DateOperand'
                  field: string
                  dateCondition: DateOperandCondition
                  days?: number | null
                  date?: any | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'IdsOperand'
                  isEvery: boolean
                  isNot: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'ManagersOperand'
                  isEvery: boolean
                  isNot: boolean
                  managesDirectly: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectIdsOperand'
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectOperand'
                  field: string
                  path?: string | null
                  isEvery: boolean
                  isNot: boolean
                  values?: Array<string> | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'TextOperand'
                  field: string
                  isNot: boolean
                  textCondition: TextOperandCondition
                  text: string
                  id?: string | null
                  type: OperandType
                }
            >
          }>
        }
        columnKeys: Array<{
          __typename?: 'SavedReportColumnKey'
          field: string
          path?: string | null
        }>
        order: {
          __typename?: 'SortOrder'
          field: string
          direction: SortDirection
        }
        createdBy?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
        updatedBy?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
      }
}

export type UpdatePeopleSavedReportMutationVariables = Exact<{
  savedReportId: Scalars['Int']
  input: UpdatePeopleSavedReportInput
}>

export type UpdatePeopleSavedReportMutation = {
  __typename?: 'Mutation'
  savedReport:
    | {
        __typename?: 'DocumentSavedReport'
        handbookId: number
        id: number
        type: SavedReportType
        name: string
        description?: string | null
        showArchived: boolean
        createdAt: any
        updatedAt: any
        archivedAt?: any | null
        expression: {
          __typename?: 'RootExpression'
          conjunction: ExpressionConjunction
          expressions: Array<{
            __typename?: 'Expression'
            conjunction: ExpressionConjunction
            operands: Array<
              | {
                  __typename?: 'DateOperand'
                  field: string
                  dateCondition: DateOperandCondition
                  days?: number | null
                  date?: any | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'IdsOperand'
                  isEvery: boolean
                  isNot: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'ManagersOperand'
                  isEvery: boolean
                  isNot: boolean
                  managesDirectly: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectIdsOperand'
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectOperand'
                  field: string
                  path?: string | null
                  isEvery: boolean
                  isNot: boolean
                  values?: Array<string> | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'TextOperand'
                  field: string
                  isNot: boolean
                  textCondition: TextOperandCondition
                  text: string
                  id?: string | null
                  type: OperandType
                }
            >
          }>
        }
        columnKeys: Array<{
          __typename?: 'SavedReportColumnKey'
          field: string
          path?: string | null
        }>
        order: {
          __typename?: 'SortOrder'
          field: string
          direction: SortDirection
        }
        createdBy?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
        updatedBy?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
      }
    | {
        __typename?: 'PeopleSavedReport'
        id: number
        type: SavedReportType
        name: string
        description?: string | null
        showArchived: boolean
        createdAt: any
        updatedAt: any
        archivedAt?: any | null
        expression: {
          __typename?: 'RootExpression'
          conjunction: ExpressionConjunction
          expressions: Array<{
            __typename?: 'Expression'
            conjunction: ExpressionConjunction
            operands: Array<
              | {
                  __typename?: 'DateOperand'
                  field: string
                  dateCondition: DateOperandCondition
                  days?: number | null
                  date?: any | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'IdsOperand'
                  isEvery: boolean
                  isNot: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'ManagersOperand'
                  isEvery: boolean
                  isNot: boolean
                  managesDirectly: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectIdsOperand'
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectOperand'
                  field: string
                  path?: string | null
                  isEvery: boolean
                  isNot: boolean
                  values?: Array<string> | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'TextOperand'
                  field: string
                  isNot: boolean
                  textCondition: TextOperandCondition
                  text: string
                  id?: string | null
                  type: OperandType
                }
            >
          }>
        }
        columnKeys: Array<{
          __typename?: 'SavedReportColumnKey'
          field: string
          path?: string | null
        }>
        order: {
          __typename?: 'SortOrder'
          field: string
          direction: SortDirection
        }
        createdBy?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
        updatedBy?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
      }
}

export type ArchiveSavedReportMutationVariables = Exact<{
  savedReportId: Scalars['Int']
}>

export type ArchiveSavedReportMutation = {
  __typename?: 'Mutation'
  savedReport:
    | {
        __typename?: 'DocumentSavedReport'
        handbookId: number
        id: number
        type: SavedReportType
        name: string
        description?: string | null
        showArchived: boolean
        createdAt: any
        updatedAt: any
        archivedAt?: any | null
        expression: {
          __typename?: 'RootExpression'
          conjunction: ExpressionConjunction
          expressions: Array<{
            __typename?: 'Expression'
            conjunction: ExpressionConjunction
            operands: Array<
              | {
                  __typename?: 'DateOperand'
                  field: string
                  dateCondition: DateOperandCondition
                  days?: number | null
                  date?: any | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'IdsOperand'
                  isEvery: boolean
                  isNot: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'ManagersOperand'
                  isEvery: boolean
                  isNot: boolean
                  managesDirectly: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectIdsOperand'
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectOperand'
                  field: string
                  path?: string | null
                  isEvery: boolean
                  isNot: boolean
                  values?: Array<string> | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'TextOperand'
                  field: string
                  isNot: boolean
                  textCondition: TextOperandCondition
                  text: string
                  id?: string | null
                  type: OperandType
                }
            >
          }>
        }
        columnKeys: Array<{
          __typename?: 'SavedReportColumnKey'
          field: string
          path?: string | null
        }>
        order: {
          __typename?: 'SortOrder'
          field: string
          direction: SortDirection
        }
        createdBy?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
        updatedBy?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
      }
    | {
        __typename?: 'PeopleSavedReport'
        id: number
        type: SavedReportType
        name: string
        description?: string | null
        showArchived: boolean
        createdAt: any
        updatedAt: any
        archivedAt?: any | null
        expression: {
          __typename?: 'RootExpression'
          conjunction: ExpressionConjunction
          expressions: Array<{
            __typename?: 'Expression'
            conjunction: ExpressionConjunction
            operands: Array<
              | {
                  __typename?: 'DateOperand'
                  field: string
                  dateCondition: DateOperandCondition
                  days?: number | null
                  date?: any | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'IdsOperand'
                  isEvery: boolean
                  isNot: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'ManagersOperand'
                  isEvery: boolean
                  isNot: boolean
                  managesDirectly: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectIdsOperand'
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectOperand'
                  field: string
                  path?: string | null
                  isEvery: boolean
                  isNot: boolean
                  values?: Array<string> | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'TextOperand'
                  field: string
                  isNot: boolean
                  textCondition: TextOperandCondition
                  text: string
                  id?: string | null
                  type: OperandType
                }
            >
          }>
        }
        columnKeys: Array<{
          __typename?: 'SavedReportColumnKey'
          field: string
          path?: string | null
        }>
        order: {
          __typename?: 'SortOrder'
          field: string
          direction: SortDirection
        }
        createdBy?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
        updatedBy?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
      }
}

export type UnarchiveSavedReportMutationVariables = Exact<{
  savedReportId: Scalars['Int']
}>

export type UnarchiveSavedReportMutation = {
  __typename?: 'Mutation'
  savedReport:
    | {
        __typename?: 'DocumentSavedReport'
        handbookId: number
        id: number
        type: SavedReportType
        name: string
        description?: string | null
        showArchived: boolean
        createdAt: any
        updatedAt: any
        archivedAt?: any | null
        expression: {
          __typename?: 'RootExpression'
          conjunction: ExpressionConjunction
          expressions: Array<{
            __typename?: 'Expression'
            conjunction: ExpressionConjunction
            operands: Array<
              | {
                  __typename?: 'DateOperand'
                  field: string
                  dateCondition: DateOperandCondition
                  days?: number | null
                  date?: any | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'IdsOperand'
                  isEvery: boolean
                  isNot: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'ManagersOperand'
                  isEvery: boolean
                  isNot: boolean
                  managesDirectly: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectIdsOperand'
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectOperand'
                  field: string
                  path?: string | null
                  isEvery: boolean
                  isNot: boolean
                  values?: Array<string> | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'TextOperand'
                  field: string
                  isNot: boolean
                  textCondition: TextOperandCondition
                  text: string
                  id?: string | null
                  type: OperandType
                }
            >
          }>
        }
        columnKeys: Array<{
          __typename?: 'SavedReportColumnKey'
          field: string
          path?: string | null
        }>
        order: {
          __typename?: 'SortOrder'
          field: string
          direction: SortDirection
        }
        createdBy?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
        updatedBy?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
      }
    | {
        __typename?: 'PeopleSavedReport'
        id: number
        type: SavedReportType
        name: string
        description?: string | null
        showArchived: boolean
        createdAt: any
        updatedAt: any
        archivedAt?: any | null
        expression: {
          __typename?: 'RootExpression'
          conjunction: ExpressionConjunction
          expressions: Array<{
            __typename?: 'Expression'
            conjunction: ExpressionConjunction
            operands: Array<
              | {
                  __typename?: 'DateOperand'
                  field: string
                  dateCondition: DateOperandCondition
                  days?: number | null
                  date?: any | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'IdsOperand'
                  isEvery: boolean
                  isNot: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'ManagersOperand'
                  isEvery: boolean
                  isNot: boolean
                  managesDirectly: boolean
                  ids: Array<number>
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectIdsOperand'
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'SelectOperand'
                  field: string
                  path?: string | null
                  isEvery: boolean
                  isNot: boolean
                  values?: Array<string> | null
                  id?: string | null
                  type: OperandType
                }
              | {
                  __typename?: 'TextOperand'
                  field: string
                  isNot: boolean
                  textCondition: TextOperandCondition
                  text: string
                  id?: string | null
                  type: OperandType
                }
            >
          }>
        }
        columnKeys: Array<{
          __typename?: 'SavedReportColumnKey'
          field: string
          path?: string | null
        }>
        order: {
          __typename?: 'SortOrder'
          field: string
          direction: SortDirection
        }
        createdBy?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
        updatedBy?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
      }
}

export type SavedSegmentsQueryVariables = Exact<{ [key: string]: never }>

export type SavedSegmentsQuery = {
  __typename?: 'Query'
  savedSegments: Array<{
    __typename?: 'SavedSegment'
    id: number
    name: string
    description?: string | null
    peopleCount: number
    createdAt: any
    updatedAt: any
    archivedAt?: any | null
    expression: {
      __typename?: 'RootExpression'
      conjunction: ExpressionConjunction
      expressions: Array<{
        __typename?: 'Expression'
        conjunction: ExpressionConjunction
        operands: Array<
          | {
              __typename?: 'DateOperand'
              field: string
              dateCondition: DateOperandCondition
              days?: number | null
              date?: any | null
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'IdsOperand'
              isEvery: boolean
              isNot: boolean
              ids: Array<number>
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'ManagersOperand'
              isEvery: boolean
              isNot: boolean
              managesDirectly: boolean
              ids: Array<number>
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'SelectIdsOperand'
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'SelectOperand'
              field: string
              path?: string | null
              isEvery: boolean
              isNot: boolean
              values?: Array<string> | null
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'TextOperand'
              field: string
              isNot: boolean
              textCondition: TextOperandCondition
              text: string
              id?: string | null
              type: OperandType
            }
        >
      }>
    }
    createdBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    updatedBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
  }>
}

export type CreateSavedSegmentMutationVariables = Exact<{
  input: CreateSavedSegmentInput
}>

export type CreateSavedSegmentMutation = {
  __typename?: 'Mutation'
  savedSegment: {
    __typename?: 'SavedSegment'
    id: number
    name: string
    description?: string | null
    peopleCount: number
    createdAt: any
    updatedAt: any
    archivedAt?: any | null
    expression: {
      __typename?: 'RootExpression'
      conjunction: ExpressionConjunction
      expressions: Array<{
        __typename?: 'Expression'
        conjunction: ExpressionConjunction
        operands: Array<
          | {
              __typename?: 'DateOperand'
              field: string
              dateCondition: DateOperandCondition
              days?: number | null
              date?: any | null
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'IdsOperand'
              isEvery: boolean
              isNot: boolean
              ids: Array<number>
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'ManagersOperand'
              isEvery: boolean
              isNot: boolean
              managesDirectly: boolean
              ids: Array<number>
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'SelectIdsOperand'
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'SelectOperand'
              field: string
              path?: string | null
              isEvery: boolean
              isNot: boolean
              values?: Array<string> | null
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'TextOperand'
              field: string
              isNot: boolean
              textCondition: TextOperandCondition
              text: string
              id?: string | null
              type: OperandType
            }
        >
      }>
    }
    createdBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    updatedBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
  }
}

export type UpdateSavedSegmentMutationVariables = Exact<{
  id: Scalars['Int']
  input: UpdateSavedSegmentInput
  sendNotifications?: InputMaybe<Scalars['Boolean']>
}>

export type UpdateSavedSegmentMutation = {
  __typename?: 'Mutation'
  savedSegment: {
    __typename?: 'SavedSegment'
    id: number
    name: string
    description?: string | null
    peopleCount: number
    createdAt: any
    updatedAt: any
    archivedAt?: any | null
    expression: {
      __typename?: 'RootExpression'
      conjunction: ExpressionConjunction
      expressions: Array<{
        __typename?: 'Expression'
        conjunction: ExpressionConjunction
        operands: Array<
          | {
              __typename?: 'DateOperand'
              field: string
              dateCondition: DateOperandCondition
              days?: number | null
              date?: any | null
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'IdsOperand'
              isEvery: boolean
              isNot: boolean
              ids: Array<number>
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'ManagersOperand'
              isEvery: boolean
              isNot: boolean
              managesDirectly: boolean
              ids: Array<number>
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'SelectIdsOperand'
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'SelectOperand'
              field: string
              path?: string | null
              isEvery: boolean
              isNot: boolean
              values?: Array<string> | null
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'TextOperand'
              field: string
              isNot: boolean
              textCondition: TextOperandCondition
              text: string
              id?: string | null
              type: OperandType
            }
        >
      }>
    }
    createdBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    updatedBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
  }
}

export type ArchiveSavedSegmentMutationVariables = Exact<{
  id: Scalars['Int']
}>

export type ArchiveSavedSegmentMutation = {
  __typename?: 'Mutation'
  savedSegment: {
    __typename?: 'SavedSegment'
    id: number
    name: string
    description?: string | null
    peopleCount: number
    createdAt: any
    updatedAt: any
    archivedAt?: any | null
    expression: {
      __typename?: 'RootExpression'
      conjunction: ExpressionConjunction
      expressions: Array<{
        __typename?: 'Expression'
        conjunction: ExpressionConjunction
        operands: Array<
          | {
              __typename?: 'DateOperand'
              field: string
              dateCondition: DateOperandCondition
              days?: number | null
              date?: any | null
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'IdsOperand'
              isEvery: boolean
              isNot: boolean
              ids: Array<number>
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'ManagersOperand'
              isEvery: boolean
              isNot: boolean
              managesDirectly: boolean
              ids: Array<number>
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'SelectIdsOperand'
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'SelectOperand'
              field: string
              path?: string | null
              isEvery: boolean
              isNot: boolean
              values?: Array<string> | null
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'TextOperand'
              field: string
              isNot: boolean
              textCondition: TextOperandCondition
              text: string
              id?: string | null
              type: OperandType
            }
        >
      }>
    }
    createdBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    updatedBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
  }
}

export type UnarchiveSavedSegmentMutationVariables = Exact<{
  id: Scalars['Int']
}>

export type UnarchiveSavedSegmentMutation = {
  __typename?: 'Mutation'
  savedSegment: {
    __typename?: 'SavedSegment'
    id: number
    name: string
    description?: string | null
    peopleCount: number
    createdAt: any
    updatedAt: any
    archivedAt?: any | null
    expression: {
      __typename?: 'RootExpression'
      conjunction: ExpressionConjunction
      expressions: Array<{
        __typename?: 'Expression'
        conjunction: ExpressionConjunction
        operands: Array<
          | {
              __typename?: 'DateOperand'
              field: string
              dateCondition: DateOperandCondition
              days?: number | null
              date?: any | null
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'IdsOperand'
              isEvery: boolean
              isNot: boolean
              ids: Array<number>
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'ManagersOperand'
              isEvery: boolean
              isNot: boolean
              managesDirectly: boolean
              ids: Array<number>
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'SelectIdsOperand'
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'SelectOperand'
              field: string
              path?: string | null
              isEvery: boolean
              isNot: boolean
              values?: Array<string> | null
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'TextOperand'
              field: string
              isNot: boolean
              textCondition: TextOperandCondition
              text: string
              id?: string | null
              type: OperandType
            }
        >
      }>
    }
    createdBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    updatedBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
  }
}

export type GetHandbookAudienceInvitationsCountQueryVariables = Exact<{
  handbookId: Scalars['Int']
  audience: HandbookAudienceInput
}>

export type GetHandbookAudienceInvitationsCountQuery = {
  __typename?: 'Query'
  handbook: { __typename?: 'Handbook'; audienceInvitationsCount: number }
}

export type RecordHandbookSignatureMutationVariables = Exact<{
  handbookId: Scalars['Int']
  input: RecordHandbookSignatureInput
}>

export type RecordHandbookSignatureMutation = {
  __typename?: 'Mutation'
  signature: {
    __typename?: 'HandbookSignature'
    id: number
    createdAt: any
    handbookId: number
    handbookVersion: number
    languageCode: string
    personId: number
    recordedByPersonId?: number | null
    recordedByUserId?: number | null
    roundId: number
    signature?: string | null
    signedAt: any
    acknowledgementForm: {
      __typename?: 'HandbookSignatureAcknowledgement'
      body: any
      wysiwyg?: string | null
    }
    personalization: {
      __typename?: 'Personalization'
      groupIds: Array<number>
      metadata?: any | null
      personId: number
      savedSegmentIds: Array<number>
    }
    toc: Array<{
      __typename?: 'HandbookSignatureTocItem'
      bookmark: string
      printUrl: string
      title: string
    }>
    user?: {
      __typename?: 'HandbookUser'
      id: number
      authType: UserAuthType
      authId: string
    } | null
  }
}

export type HandbookVersionQueryVariables = Exact<{
  handbookId: Scalars['Int']
  versionNumber: Scalars['Int']
}>

export type HandbookVersionQuery = {
  __typename?: 'Query'
  handbookVersion: {
    __typename?: 'HandbookVersion'
    content: any
    defaultLanguageCode: string
    handbookId: number
    languageCodes: Array<string>
    version: number
    acknowledgementForm: {
      __typename?: 'HandbookAcknowledgement'
      title?: any | null
      body?: any | null
      forceSign: boolean
    }
    branding: {
      __typename?: 'HandbookBranding'
      blackColor: any
      favicon?: any | null
      handbookCss?: string | null
      lessButtonText: string
      moreButtonText: string
      primaryColor: any
      printCss?: string | null
      customHtml?: string | null
      whiteColor: any
      bodyFont: {
        __typename?: 'HandbookBrandingFont'
        type: HandbookBrandingFontType
        fontId?: number | null
        family?: string | null
        bold?: number | null
        normal?: number | null
        variants?: Array<string> | null
      }
      headingFont: {
        __typename?: 'HandbookBrandingFont'
        type: HandbookBrandingFontType
        fontId?: number | null
        family?: string | null
        bold?: number | null
        normal?: number | null
        variants?: Array<string> | null
      }
      scriptFont: {
        __typename?: 'HandbookBrandingFont'
        type: HandbookBrandingFontType
        fontId?: number | null
        family?: string | null
        bold?: number | null
        normal?: number | null
        variants?: Array<string> | null
      }
    }
    contactFragments: {
      __typename?: 'HandbookContact'
      title?: any | null
      body?: any | null
    }
    sections: Array<{
      __typename?: 'HandbookSectionVersion'
      createdAt?: any | null
      createdBy?: number | null
      hideToc: boolean
      hidden: boolean
      languageCode?: string | null
      sectionId: number
      sectionVersion: number
      theme?: string | null
      title?: string | null
      type: HandbookSectionType
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      attrs: {
        __typename?: 'HandbookSectionAttributes'
        keys: Array<string>
        goalAlign?: HandbookSectionTextAlign | null
        image?: any | null
        photo?: any | null
        signatureImage?: any | null
        statementAlign?: HandbookSectionTextAlign | null
        subtitleAlign?: HandbookSectionTextAlign | null
        titleAlign?: HandbookSectionTextAlign | null
        animation?: boolean | null
        backgroundAnimation?: string | null
        backgroundColor?: any | null
        backgroundImage?: any | null
        backgroundOverlayColor?: any | null
        backgroundOverlayOpacity?: number | null
        bottom?: string | null
        bottomArrow?: boolean | null
        headingBackgroundImage?: any | null
        height?: string | null
        entryAngle?: string | null
        entryAnimation?: boolean | null
        maxColumns?: number | null
        showChapterIcon?: boolean | null
        signatureType?: string | null
        textShadow?: boolean | null
        titleHighlight?: string | null
        titleHighlightColor?: any | null
        video?: {
          __typename?: 'HandbookVideo'
          url: any
          height: number
          width: number
        } | null
      }
      fragments: {
        __typename?: 'HandbookSectionContent'
        keys: Array<string>
        body?: any | null
        description?: any | null
        goal?: any | null
        headline?: any | null
        introduction?: any | null
        signature?: any | null
        statement?: any | null
        subtitle?: any | null
        title?: any | null
      }
      listEntries?: Array<{
        __typename?: 'HandbookSectionEntry'
        keys: Array<string>
        uid: string
        body?: any | null
        image?: any | null
        subtitle?: any | null
        subtitleAlign?: HandbookSectionTextAlign | null
        title?: any | null
        titleAlign?: HandbookSectionTextAlign | null
      }> | null
    }>
  }
}

export type PublishHandbookNotifyCountsQueryVariables = Exact<{
  handbookId: Scalars['Int']
}>

export type PublishHandbookNotifyCountsQuery = {
  __typename?: 'Query'
  viewersCounts: { __typename?: 'Handbook'; viewers: number }
  signersCounts: { __typename?: 'Handbook'; signers: number }
}

export type UpdateHandbookVersionMutationVariables = Exact<{
  handbookId: Scalars['Int']
  versionNumber: Scalars['Int']
  input: UpdateHandbookVersionInput
}>

export type UpdateHandbookVersionMutation = {
  __typename?: 'Mutation'
  handbookVersion: {
    __typename?: 'HandbookVersionSummary'
    createdAt?: any | null
    createdBy?: number | null
    defaultLanguageCode: string
    handbookId: number
    label?: string | null
    languageCodes: Array<string>
    printUrl: any
    url: any
    version: number
    createdByPerson?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
  }
}

export type GetHandbookSectionHistoryQueryVariables = Exact<{
  sectionIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>
}>

export type GetHandbookSectionHistoryQuery = {
  __typename?: 'Query'
  handbookSectionHistory: Array<{
    __typename?: 'HandbookSectionVersion'
    sectionId: number
    sectionVersion: number
    handbookId: number
    handbookVersion: number
    type: HandbookSectionType
    theme?: string | null
    title?: string | null
    languageCode?: string | null
    hideToc: boolean
    viewAnnotation?: string | null
    editAnnotation?: string | null
    hideAnnotations: Array<number>
    createdAt?: any | null
    createdBy?: number | null
    attrs: {
      __typename?: 'HandbookSectionAttributes'
      keys: Array<string>
      goalAlign?: HandbookSectionTextAlign | null
      image?: any | null
      photo?: any | null
      signatureImage?: any | null
      statementAlign?: HandbookSectionTextAlign | null
      subtitleAlign?: HandbookSectionTextAlign | null
      titleAlign?: HandbookSectionTextAlign | null
      animation?: boolean | null
      backgroundAnimation?: string | null
      backgroundColor?: any | null
      backgroundImage?: any | null
      backgroundOverlayColor?: any | null
      backgroundOverlayOpacity?: number | null
      bottom?: string | null
      bottomArrow?: boolean | null
      headingBackgroundImage?: any | null
      height?: string | null
      entryAngle?: string | null
      entryAnimation?: boolean | null
      maxColumns?: number | null
      showChapterIcon?: boolean | null
      signatureType?: string | null
      textShadow?: boolean | null
      titleHighlight?: string | null
      titleHighlightColor?: any | null
      video?: {
        __typename?: 'HandbookVideo'
        url: any
        height: number
        width: number
      } | null
    }
    fragments: {
      __typename?: 'HandbookSectionContent'
      keys: Array<string>
      body?: any | null
      description?: any | null
      goal?: any | null
      headline?: any | null
      introduction?: any | null
      signature?: any | null
      statement?: any | null
      subtitle?: any | null
      title?: any | null
    }
    listEntries?: Array<{
      __typename?: 'HandbookSectionEntry'
      keys: Array<string>
      uid: string
      body?: any | null
      image?: any | null
      subtitle?: any | null
      subtitleAlign?: HandbookSectionTextAlign | null
      title?: any | null
      titleAlign?: HandbookSectionTextAlign | null
    }> | null
    audienceExpression?: {
      __typename?: 'RootExpression'
      conjunction: ExpressionConjunction
      expressions: Array<{
        __typename?: 'Expression'
        conjunction: ExpressionConjunction
        operands: Array<
          | {
              __typename?: 'DateOperand'
              field: string
              dateCondition: DateOperandCondition
              days?: number | null
              date?: any | null
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'IdsOperand'
              isEvery: boolean
              isNot: boolean
              ids: Array<number>
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'ManagersOperand'
              isEvery: boolean
              isNot: boolean
              managesDirectly: boolean
              ids: Array<number>
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'SelectIdsOperand'
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'SelectOperand'
              field: string
              path?: string | null
              isEvery: boolean
              isNot: boolean
              values?: Array<string> | null
              id?: string | null
              type: OperandType
            }
          | {
              __typename?: 'TextOperand'
              field: string
              isNot: boolean
              textCondition: TextOperandCondition
              text: string
              id?: string | null
              type: OperandType
            }
        >
      }>
    } | null
  }>
}

export type EventsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<EventsFilter>
  limit?: InputMaybe<Scalars['Int']>
  order?: InputMaybe<EventsSortOrder>
}>

export type EventsQuery = {
  __typename?: 'Query'
  events: Array<
    | {
        __typename?: 'DocumentAddedEvent'
        id: string
        type: EventType
        targetId: number
        targetType: EventTargetType
        createdAt: any
        updatedAt: any
        sortKey: string
        document: { __typename?: 'EventDocument'; id: number; name: string }
        handbook: { __typename?: 'EventHandbook'; id: number; name: string }
        person?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
      }
    | {
        __typename?: 'DocumentArchivedEvent'
        id: string
        type: EventType
        targetId: number
        targetType: EventTargetType
        createdAt: any
        updatedAt: any
        sortKey: string
        document: { __typename?: 'EventDocument'; id: number; name: string }
        person?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
      }
    | {
        __typename?: 'DocumentCreatedEvent'
        id: string
        type: EventType
        targetId: number
        targetType: EventTargetType
        createdAt: any
        updatedAt: any
        sortKey: string
        document: { __typename?: 'EventDocument'; id: number; name: string }
        person?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
      }
    | {
        __typename?: 'DocumentPublishedEvent'
        id: string
        type: EventType
        targetId: number
        targetType: EventTargetType
        createdAt: any
        updatedAt: any
        sortKey: string
        document: { __typename?: 'EventDocument'; id: number; name: string }
        attributes: {
          __typename?: 'DocumentPublishedEventAttributes'
          versionNumber: number
        }
        person?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
      }
    | {
        __typename?: 'DocumentRemovedEvent'
        id: string
        type: EventType
        targetId: number
        targetType: EventTargetType
        createdAt: any
        updatedAt: any
        sortKey: string
        document: { __typename?: 'EventDocument'; id: number; name: string }
        handbook: { __typename?: 'EventHandbook'; id: number; name: string }
        person?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
      }
    | {
        __typename?: 'DocumentReviewerAddedEvent'
        id: string
        type: EventType
        targetId: number
        targetType: EventTargetType
        createdAt: any
        updatedAt: any
        sortKey: string
        attributes: {
          __typename?: 'DocumentReviewerAddedEventAttributes'
          documentVersionNumber: number
        }
        document: { __typename?: 'EventDocument'; id: number; name: string }
        documentReviewerPerson: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        }
        person?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
      }
    | {
        __typename?: 'DocumentReviewerApprovedEvent'
        id: string
        type: EventType
        targetId: number
        targetType: EventTargetType
        createdAt: any
        updatedAt: any
        sortKey: string
        attributes: {
          __typename?: 'DocumentReviewerApprovedEventAttributes'
          documentVersionNumber: number
        }
        document: { __typename?: 'EventDocument'; id: number; name: string }
        person?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
      }
    | {
        __typename?: 'DocumentReviewerRejectedEvent'
        id: string
        type: EventType
        targetId: number
        targetType: EventTargetType
        createdAt: any
        updatedAt: any
        sortKey: string
        attributes: {
          __typename?: 'DocumentReviewerRejectedEventAttributes'
          documentVersionNumber: number
        }
        document: { __typename?: 'EventDocument'; id: number; name: string }
        person?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
      }
    | {
        __typename?: 'DocumentReviewerRemovedEvent'
        id: string
        type: EventType
        targetId: number
        targetType: EventTargetType
        createdAt: any
        updatedAt: any
        sortKey: string
        attributes: {
          __typename?: 'DocumentReviewerRemovedEventAttributes'
          documentVersionNumber: number
        }
        document: { __typename?: 'EventDocument'; id: number; name: string }
        documentReviewerPerson: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        }
        person?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
      }
    | {
        __typename?: 'DocumentSettingsUpdatedEvent'
        id: string
        type: EventType
        targetId: number
        targetType: EventTargetType
        createdAt: any
        updatedAt: any
        sortKey: string
        attributes: {
          __typename?: 'DocumentSettingsUpdatedEventAttributes'
          isPolicyWorkflowEnabled: boolean
        }
        person?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
      }
    | {
        __typename?: 'DocumentUnarchivedEvent'
        id: string
        type: EventType
        targetId: number
        targetType: EventTargetType
        createdAt: any
        updatedAt: any
        sortKey: string
        document: { __typename?: 'EventDocument'; id: number; name: string }
        person?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
      }
    | {
        __typename?: 'DocumentUpdatedEvent'
        versionNumber: number
        html: boolean
        id: string
        type: EventType
        targetId: number
        targetType: EventTargetType
        createdAt: any
        updatedAt: any
        sortKey: string
        document: { __typename?: 'EventDocument'; id: number; name: string }
        audienceExpression?: {
          __typename?: 'MaybeRootExpressionUpdated'
          prevValue?: {
            __typename?: 'RootExpression'
            conjunction: ExpressionConjunction
            expressions: Array<{
              __typename?: 'Expression'
              conjunction: ExpressionConjunction
              operands: Array<
                | {
                    __typename?: 'DateOperand'
                    field: string
                    dateCondition: DateOperandCondition
                    days?: number | null
                    date?: any | null
                    id?: string | null
                    type: OperandType
                  }
                | {
                    __typename?: 'IdsOperand'
                    isEvery: boolean
                    isNot: boolean
                    ids: Array<number>
                    id?: string | null
                    type: OperandType
                  }
                | {
                    __typename?: 'ManagersOperand'
                    isEvery: boolean
                    isNot: boolean
                    managesDirectly: boolean
                    ids: Array<number>
                    id?: string | null
                    type: OperandType
                  }
                | {
                    __typename?: 'SelectIdsOperand'
                    id?: string | null
                    type: OperandType
                  }
                | {
                    __typename?: 'SelectOperand'
                    field: string
                    path?: string | null
                    isEvery: boolean
                    isNot: boolean
                    values?: Array<string> | null
                    id?: string | null
                    type: OperandType
                  }
                | {
                    __typename?: 'TextOperand'
                    field: string
                    isNot: boolean
                    textCondition: TextOperandCondition
                    text: string
                    id?: string | null
                    type: OperandType
                  }
              >
            }>
          } | null
          value?: {
            __typename?: 'RootExpression'
            conjunction: ExpressionConjunction
            expressions: Array<{
              __typename?: 'Expression'
              conjunction: ExpressionConjunction
              operands: Array<
                | {
                    __typename?: 'DateOperand'
                    field: string
                    dateCondition: DateOperandCondition
                    days?: number | null
                    date?: any | null
                    id?: string | null
                    type: OperandType
                  }
                | {
                    __typename?: 'IdsOperand'
                    isEvery: boolean
                    isNot: boolean
                    ids: Array<number>
                    id?: string | null
                    type: OperandType
                  }
                | {
                    __typename?: 'ManagersOperand'
                    isEvery: boolean
                    isNot: boolean
                    managesDirectly: boolean
                    ids: Array<number>
                    id?: string | null
                    type: OperandType
                  }
                | {
                    __typename?: 'SelectIdsOperand'
                    id?: string | null
                    type: OperandType
                  }
                | {
                    __typename?: 'SelectOperand'
                    field: string
                    path?: string | null
                    isEvery: boolean
                    isNot: boolean
                    values?: Array<string> | null
                    id?: string | null
                    type: OperandType
                  }
                | {
                    __typename?: 'TextOperand'
                    field: string
                    isNot: boolean
                    textCondition: TextOperandCondition
                    text: string
                    id?: string | null
                    type: OperandType
                  }
              >
            }>
          } | null
        } | null
        name?: {
          __typename?: 'StringUpdated'
          prevValue: string
          value: string
        } | null
        person?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
      }
    | {
        __typename?: 'DocumentVersionCreatedEvent'
        id: string
        type: EventType
        targetId: number
        targetType: EventTargetType
        createdAt: any
        updatedAt: any
        sortKey: string
        document: { __typename?: 'EventDocument'; id: number; name: string }
        attributes: {
          __typename?: 'DocumentVersionCreatedEventAttributes'
          versionNumber: number
        }
        person?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
      }
    | {
        __typename?: 'FeatureFlagAddedEvent'
        id: string
        type: EventType
        targetId: number
        targetType: EventTargetType
        createdAt: any
        updatedAt: any
        sortKey: string
        attributes: {
          __typename?: 'FeatureFlagEventAttributes'
          featureFlagId: string
        }
        person?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
      }
    | {
        __typename?: 'FeatureFlagRemovedEvent'
        id: string
        type: EventType
        targetId: number
        targetType: EventTargetType
        createdAt: any
        updatedAt: any
        sortKey: string
        attributes: {
          __typename?: 'FeatureFlagEventAttributes'
          featureFlagId: string
        }
        person?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
      }
    | {
        __typename?: 'HandbookCreatedEvent'
        id: string
        type: EventType
        targetId: number
        targetType: EventTargetType
        createdAt: any
        updatedAt: any
        sortKey: string
        handbook: { __typename?: 'EventHandbook'; id: number; name: string }
        person?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
      }
    | {
        __typename?: 'HandbookPublishedEvent'
        id: string
        type: EventType
        targetId: number
        targetType: EventTargetType
        createdAt: any
        updatedAt: any
        sortKey: string
        handbook: { __typename?: 'EventHandbook'; id: number; name: string }
        attributes: {
          __typename?: 'HandbookPublishedEventAttributes'
          versionNumber: number
        }
        person?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
      }
    | {
        __typename?: 'HandbookSignatureRoundCreatedEvent'
        id: string
        type: EventType
        targetId: number
        targetType: EventTargetType
        createdAt: any
        updatedAt: any
        sortKey: string
        handbook: { __typename?: 'EventHandbook'; id: number; name: string }
        signatureRound: {
          __typename?: 'EventHandbookSignatureRound'
          id: number
          number: number
        }
        person?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
      }
    | {
        __typename?: 'HandbookSignedEvent'
        id: string
        type: EventType
        targetId: number
        targetType: EventTargetType
        createdAt: any
        updatedAt: any
        sortKey: string
        handbook: { __typename?: 'EventHandbook'; id: number; name: string }
        attributes: {
          __typename?: 'HandbookSignedEventAttributes'
          handbookSignatureId: number
        }
        person?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
      }
    | {
        __typename?: 'HandbookViewedEvent'
        id: string
        type: EventType
        targetId: number
        targetType: EventTargetType
        createdAt: any
        updatedAt: any
        sortKey: string
        handbook: { __typename?: 'EventHandbook'; id: number; name: string }
        attributes: {
          __typename?: 'HandbookViewedEventAttributes'
          handbookSessionId: number
        }
        person?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
      }
    | {
        __typename?: 'PeopleSyncEvent'
        id: string
        type: EventType
        targetId: number
        targetType: EventTargetType
        createdAt: any
        updatedAt: any
        sortKey: string
        person?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
      }
    | {
        __typename?: 'PersonCreatedEvent'
        id: string
        type: EventType
        targetId: number
        targetType: EventTargetType
        createdAt: any
        updatedAt: any
        sortKey: string
        person?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
      }
    | {
        __typename?: 'PersonNotifiedEvent'
        id: string
        type: EventType
        targetId: number
        targetType: EventTargetType
        createdAt: any
        updatedAt: any
        sortKey: string
        notification: {
          __typename?: 'EventNotification'
          id: number
          subject?: string | null
          handbookId?: number | null
        }
        person?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
      }
  >
}

export type TriggerHrisSyncMutationVariables = Exact<{ [key: string]: never }>

export type TriggerHrisSyncMutation = {
  __typename?: 'Mutation'
  triggerHrisSync: boolean
}

export type PolicyLibraryQueryVariables = Exact<{
  filter?: InputMaybe<DocumentsFilter>
  groupBy: DocumentsGroupByField
  showArchived?: InputMaybe<Scalars['Boolean']>
}>

export type PolicyLibraryQuery = {
  __typename?: 'Query'
  documentGroups: Array<
    | {
        __typename?: 'FolderDocumentGroup'
        field: DocumentsGroupByField
        documentsCount: number
        folder?: {
          __typename?: 'Folder'
          id: number
          parentFolderId?: number | null
          name: string
        } | null
      }
    | {
        __typename?: 'PersonDocumentGroup'
        field: DocumentsGroupByField
        documentsCount: number
        person?: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        } | null
      }
    | {
        __typename?: 'OrganizationDocumentGroup'
        field: DocumentsGroupByField
        documentsCount: number
      }
  >
  documentSettings: {
    __typename?: 'DocumentSettings'
    isPolicyWorkflowEnabled: boolean
    organizationId: number
  }
  folders: Array<{
    __typename?: 'Folder'
    id: number
    parentFolderId?: number | null
    name: string
  }>
  teams: Array<{
    __typename?: 'Team'
    id: number
    permissionIds: Array<string>
    createdAt: any
    updatedAt: any
    handbookCollaborators: Array<{
      __typename?: 'HandbookCollaborator'
      id: number
      handbookId: number
      personId: number
      roleId?: HandbookCollaboratorRoleId | null
    }>
    teammates: Array<{
      __typename?: 'Teammate'
      id: number
      teamId: number
      personId: number
      canReport: boolean
      canSuperBrand: boolean
      roleId?: TeamRoleId | null
      person: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      }
    }>
  }>
}

export type PolicyLibraryDocumentsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>
  filter?: InputMaybe<DocumentsFilter>
  limit?: InputMaybe<Scalars['Int']>
  order?: InputMaybe<DocumentsSortOrder>
  showArchived?: InputMaybe<Scalars['Boolean']>
}>

export type PolicyLibraryDocumentsQuery = {
  __typename?: 'Query'
  documents: Array<{
    __typename?: 'Document'
    id: number
    parentFolderId?: number | null
    name: string
    archivedAt?: any | null
    lastPublishedVersionNumber: number
    sortPosition: number
    createdAt: any
    updatedAt: any
    sortKey?: string | null
    parentFolder?: {
      __typename?: 'Folder'
      id: number
      parentFolderId?: number | null
      name: string
    } | null
    createdBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    updatedBy?: {
      __typename?: 'WhodunnitPerson'
      id: number
      fullName: string
      isBlissbook: boolean
      avatarUrl?: string | null
    } | null
    draftVersion?: {
      __typename?: 'DocumentVersion'
      id: number
      versionNumber: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      reviews: Array<{
        __typename?: 'DocumentReview'
        id: number
        documentId: number
        personId: number
        isApproved: boolean
        createdAt: any
        person: {
          __typename?: 'WhodunnitPerson'
          id: number
          fullName: string
          isBlissbook: boolean
          avatarUrl?: string | null
        }
      }>
    } | null
    lastPublishedVersion?: {
      __typename?: 'DocumentVersion'
      id: number
      createdAt: any
      versionNumber: number
      audienceExpression?: {
        __typename?: 'RootExpression'
        conjunction: ExpressionConjunction
        expressions: Array<{
          __typename?: 'Expression'
          conjunction: ExpressionConjunction
          operands: Array<
            | {
                __typename?: 'DateOperand'
                field: string
                dateCondition: DateOperandCondition
                days?: number | null
                date?: any | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'IdsOperand'
                isEvery: boolean
                isNot: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'ManagersOperand'
                isEvery: boolean
                isNot: boolean
                managesDirectly: boolean
                ids: Array<number>
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectIdsOperand'
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'SelectOperand'
                field: string
                path?: string | null
                isEvery: boolean
                isNot: boolean
                values?: Array<string> | null
                id?: string | null
                type: OperandType
              }
            | {
                __typename?: 'TextOperand'
                field: string
                isNot: boolean
                textCondition: TextOperandCondition
                text: string
                id?: string | null
                type: OperandType
              }
          >
        }>
      } | null
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    } | null
    reviewers: Array<{
      __typename?: 'DocumentReviewer'
      id: number
      documentId: number
      personId: number
      createdAt: any
      updatedAt: any
      person: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      }
      createdBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
      updatedBy?: {
        __typename?: 'WhodunnitPerson'
        id: number
        fullName: string
        isBlissbook: boolean
        avatarUrl?: string | null
      } | null
    }>
  }>
}

export const SessionFragmentDoc = gql`
    fragment session on Session {
  expiresAt
  isImpersonating
  permissionIds
  person {
    id
    idHash
    email
    fullName
    member
    adminRoleId
    createdAt
  }
  user {
    id
    type
    authType
    authId
    createdAt
    lastSignedInAt
    ... on SessionSuperUser {
      email
      fullName
      roleId
    }
  }
}
    `
export const ChargebeeSubscriptionDetailsFragmentDoc = gql`
    fragment chargebeeSubscriptionDetails on ChargebeeSubscriptionDetails {
  currency_code
  customer_id
  id
  next_billing_at
  status
  cancelled_at
  payment_source_id
  subscription_items {
    item_price_id
    item_type
    name
    external_name
    amount
    currency_code
  }
  charged_items {
    item_price_id
  }
  invoices {
    id
    date
    paidAt
    total
    currency_code
    download_url
  }
  discounts {
    amount
  }
}
    `
export const ChargebeePortalSessionFragmentDoc = gql`
    fragment chargebeePortalSession on ChargebeePortalSession {
  id
  token
  access_url
  status
  customer_id
}
    `
export const EmailDomainStatusFragmentDoc = gql`
    fragment emailDomainStatus on EmailDomainStatus {
  dkimStatus {
    verified
    lastVerifiedAt
  }
  dkimDns {
    host
    textValue
    pendingHost
    pendingTextValue
  }
  returnPathStatus {
    verified
    lastVerifiedAt
  }
  returnPathDns {
    domain
    cnameValue
  }
}
    `
export const ExpressionFragmentDoc = gql`
    fragment expression on RootExpression {
  conjunction
  expressions {
    conjunction
    operands {
      id
      type
      ... on DateOperand {
        field
        dateCondition
        days
        date
      }
      ... on IdsOperand {
        isEvery
        isNot
        ids
      }
      ... on ManagersOperand {
        isEvery
        isNot
        managesDirectly
        ids
      }
      ... on SelectOperand {
        field
        path
        isEvery
        isNot
        values
      }
      ... on TextOperand {
        field
        isNot
        textCondition
        text
      }
    }
  }
}
    `
export const WhodunnitFragmentDoc = gql`
    fragment whodunnit on WhodunnitPerson {
  id
  fullName
  isBlissbook
  avatarUrl
}
    `
export const HandbookAudienceFragmentDoc = gql`
    fragment handbookAudience on HandbookAudience {
  member
  groupIds
  personIds
  savedSegmentIds
}
    `
export const HandbookSharingFragmentDoc = gql`
    fragment handbookSharing on Handbook {
  audience {
    ...handbookAudience
  }
  hasAudience
  viewersCount
}
    ${HandbookAudienceFragmentDoc}`
export const HandbookBrandingFontFragmentDoc = gql`
    fragment handbookBrandingFont on HandbookBrandingFont {
  type
  fontId
  family
  bold
  normal
  variants
}
    `
export const HandbookBrandingFragmentDoc = gql`
    fragment handbookBranding on HandbookBranding {
  blackColor
  bodyFont {
    ...handbookBrandingFont
  }
  favicon
  handbookCss
  headingFont {
    ...handbookBrandingFont
  }
  lessButtonText
  moreButtonText
  primaryColor
  printCss
  customHtml
  scriptFont {
    ...handbookBrandingFont
  }
  whiteColor
}
    ${HandbookBrandingFontFragmentDoc}`
export const HandbookPreviewLinkFragmentDoc = gql`
    fragment handbookPreviewLink on HandbookPreviewLink {
  handbookId
  personId
  token
  previewUrl
  createdAt
}
    `
export const HandbookSignatureRoundFragmentDoc = gql`
    fragment handbookSignatureRound on HandbookSignatureRound {
  id
  number
  audienceExpression {
    conjunction
    expressions {
      conjunction
      operands {
        id
        type
        ... on DateOperand {
          field
          dateCondition
          days
          date
        }
        ... on IdsOperand {
          isEvery
          isNot
          ids
        }
        ... on ManagersOperand {
          isEvery
          isNot
          managesDirectly
          ids
        }
        ... on SelectOperand {
          field
          path
          isEvery
          isNot
          values
        }
        ... on TextOperand {
          field
          isNot
          textCondition
          text
        }
      }
    }
  }
  onlyAtLaunch
  onlyMembers
  dueDays
  dueDaysFuture
  archived
  active
  createdAt
  createdBy {
    ...whodunnit
  }
  updatedAt
  updatedBy {
    ...whodunnit
  }
  publishedAt
  peopleCount
}
    ${WhodunnitFragmentDoc}`
export const HandbookStatisticsFragmentDoc = gql`
    fragment handbookStatistics on HandbookStatistics {
  peopleCount
  canNotifyPeopleCount
  canNotifyNonCompliantPeopleCount
  nonCompliantPeopleCount
  notifiedPeopleCount
  maxNotifiedPeopleCount
  viewedPeopleCount
  maxViewedPeopleCount
  signedPeopleCount
  maxSignedPeopleCount
  validSignedPeopleCount
  maxValidSignedPeopleCount
  score
}
    `
export const HandbookTaskFragmentDoc = gql`
    fragment handbookTask on HandbookTask {
  id
  completedAt
  completedBy
}
    `
export const HandbookVersionSummaryFragmentDoc = gql`
    fragment handbookVersionSummary on HandbookVersionSummary {
  createdAt
  createdBy
  createdByPerson {
    ...whodunnit
  }
  defaultLanguageCode
  handbookId
  label
  languageCodes
  printUrl
  url
  version
}
    ${WhodunnitFragmentDoc}`
export const HandbookSummaryFragmentDoc = gql`
    fragment handbookSummary on Handbook {
  teamId
  id
  name
  defaultLanguageCode
  customDomain
  customDomainOrigin
  isPrintDisabled
  position
  public
  publicToken
  version
  createdAt
  updatedAt
  lastModifiedAt
  lastModifiedBy
  publishedAt
  lastPublishedAt
  lastPublishedByPerson {
    ...whodunnit
  }
  url
  publishedUrl
  permissionIds
  ...handbookSharing
  branding {
    ...handbookBranding
  }
  contentLength
  deletePreviewLinks
  hasPersonalizedContent
  previewLinks {
    ...handbookPreviewLink
  }
  signatureRounds {
    ...handbookSignatureRound
  }
  statistics(canView: true) {
    ...handbookStatistics
  }
  tasks {
    ...handbookTask
  }
  versions {
    ...handbookVersionSummary
  }
}
    ${WhodunnitFragmentDoc}
${HandbookSharingFragmentDoc}
${HandbookBrandingFragmentDoc}
${HandbookPreviewLinkFragmentDoc}
${HandbookSignatureRoundFragmentDoc}
${HandbookStatisticsFragmentDoc}
${HandbookTaskFragmentDoc}
${HandbookVersionSummaryFragmentDoc}`
export const HandbookAcknowledgementFormFragmentDoc = gql`
    fragment handbookAcknowledgementForm on HandbookAcknowledgement {
  title
  body
  forceSign
}
    `
export const HandbookAssetFragmentDoc = gql`
    fragment handbookAsset on HandbookAsset {
  id
  type
  filename
  filesize
  path
  thumbnailUrl
  url
  archived
  mimeType
  createdAt
  ... on HandbookImage {
    width
    height
  }
}
    `
export const HandbookContactFragmentDoc = gql`
    fragment handbookContact on HandbookContact {
  title
  body
}
    `
export const HandbookFontFragmentDoc = gql`
    fragment handbookFont on HandbookFont {
  id
  filename
  archived
  createdAt
}
    `
export const PublishHandbookNotificationFragmentDoc = gql`
    fragment publishHandbookNotification on PublishHandbookNotification {
  toViewers
  signers {
    subjectContent
    buttonContent
    messageContent
  }
  viewers {
    subjectContent
    buttonContent
    messageContent
  }
}
    `
export const HandbookSectionSettingsFragmentDoc = gql`
    fragment handbookSectionSettings on HandbookSection {
  audienceExpression {
    conjunction
    expressions {
      conjunction
      operands {
        id
        type
        ... on DateOperand {
          field
          dateCondition
          days
          date
        }
        ... on IdsOperand {
          isEvery
          isNot
          ids
        }
        ... on ManagersOperand {
          isEvery
          isNot
          managesDirectly
          ids
        }
        ... on SelectOperand {
          field
          path
          isEvery
          isNot
          values
        }
        ... on TextOperand {
          field
          isNot
          textCondition
          text
        }
      }
    }
  }
  hidden
  hideToc
  languageCode
  theme
  title
  type
  hideAnnotations
  editAnnotation
  viewAnnotation
}
    `
export const HandbookSectionAttributesFragmentDoc = gql`
    fragment handbookSectionAttributes on HandbookSectionAttributes {
  keys
  goalAlign
  image
  photo
  signatureImage
  statementAlign
  subtitleAlign
  titleAlign
  video {
    url
    height
    width
  }
  animation
  backgroundAnimation
  backgroundColor
  backgroundImage
  backgroundOverlayColor
  backgroundOverlayOpacity
  bottom
  bottomArrow
  headingBackgroundImage
  height
  entryAngle
  entryAnimation
  maxColumns
  showChapterIcon
  signatureType
  textShadow
  titleHighlight
  titleHighlightColor
}
    `
export const HandbookSectionContentFragmentDoc = gql`
    fragment handbookSectionContent on HandbookSectionContent {
  keys
  body
  description
  goal
  headline
  introduction
  signature
  statement
  subtitle
  title
}
    `
export const HandbookSectionEntryFragmentDoc = gql`
    fragment handbookSectionEntry on HandbookSectionEntry {
  keys
  uid
  body
  image
  subtitle
  subtitleAlign
  title
  titleAlign
}
    `
export const HandbookSectionFragmentDoc = gql`
    fragment handbookSection on HandbookSection {
  handbookId
  id
  version
  ...handbookSectionSettings
  attrs {
    ...handbookSectionAttributes
  }
  fragments {
    ...handbookSectionContent
  }
  listEntries {
    ...handbookSectionEntry
  }
  createdAt
  createdBy
  updatedAt
  updatedBy
  lastPublishedAt
  lastPublishedBy
  dirtyFields
}
    ${HandbookSectionSettingsFragmentDoc}
${HandbookSectionAttributesFragmentDoc}
${HandbookSectionContentFragmentDoc}
${HandbookSectionEntryFragmentDoc}`
export const HandbookFragmentDoc = gql`
    fragment handbook on Handbook {
  ...handbookSummary
  acknowledgementForm {
    ...handbookAcknowledgementForm
  }
  assets {
    ...handbookAsset
  }
  contactFragments {
    ...handbookContact
  }
  content
  fonts(archived: false) {
    ...handbookFont
  }
  publishNotification {
    ...publishHandbookNotification
  }
  sections {
    ...handbookSection
  }
}
    ${HandbookSummaryFragmentDoc}
${HandbookAcknowledgementFormFragmentDoc}
${HandbookAssetFragmentDoc}
${HandbookContactFragmentDoc}
${HandbookFontFragmentDoc}
${PublishHandbookNotificationFragmentDoc}
${HandbookSectionFragmentDoc}`
export const HandbookNotificationFragmentDoc = gql`
    fragment handbookNotification on HandbookNotification {
  id
  handbookId
  personId
  email
  toName
  fromEmail
  fromName
  subject
  body
  createdAt
  sentAt
  lastError
  lastErrorAt
}
    `
export const HandbookRecipientReportingFragmentDoc = gql`
    fragment handbookRecipientReporting on HandbookRecipient {
  handbookId
  personId
  canView
  signatureRoundId
  signatureRequestedAt
  signatureDueDate
  signatureDueAt
  isSignatureRequired
  hasValidSignature
  lastNotificationQueuedAt
  lastNotifiedAt
  lastViewedAt
  lastViewedVersion
  lastSignedAt
  lastSignedVersion
  score
}
    `
export const HandbookRecipientFragmentDoc = gql`
    fragment handbookRecipient on HandbookRecipient {
  canNotify
  email
  fullName
  employeeId
  groupIds
  isManager
  lastHiredOn
  managers {
    id
    email
    employeeId
    fullName
  }
  metadata
  ...handbookRecipientReporting
}
    ${HandbookRecipientReportingFragmentDoc}`
export const PersonalizationFragmentDoc = gql`
    fragment personalization on Personalization {
  groupIds
  metadata
  personId
  savedSegmentIds
}
    `
export const HandbookSignatureFragmentDoc = gql`
    fragment handbookSignature on HandbookSignature {
  id
  acknowledgementForm {
    body
    wysiwyg
  }
  createdAt
  handbookId
  handbookVersion
  languageCode
  personId
  personalization {
    ...personalization
  }
  recordedByPersonId
  recordedByUserId
  roundId
  toc {
    bookmark
    printUrl
    title
  }
  signature
  signedAt
  user {
    id
    authType
    authId
  }
}
    ${PersonalizationFragmentDoc}`
export const HandbookSignatureDueDateOverrideFragmentDoc = gql`
    fragment handbookSignatureDueDateOverride on HandbookSignatureDueDateOverride {
  id
  roundId
  previousDueDate
  dueDate
  note
  createdAt
  createdBy
}
    `
export const IntegrationFragmentDoc = gql`
    fragment integration on Integration {
  id
  providerId
  rejectPeopleExpression {
    conjunction
    expressions {
      conjunction
      operands {
        id
        type
        ... on DateOperand {
          field
          dateCondition
          days
          date
        }
        ... on IdsOperand {
          isEvery
          isNot
          ids
        }
        ... on ManagersOperand {
          isEvery
          isNot
          managesDirectly
          ids
        }
        ... on SelectOperand {
          field
          path
          isEvery
          isNot
          values
        }
        ... on TextOperand {
          field
          isNot
          textCondition
          text
        }
      }
    }
  }
  lastSyncAt
  lastErrorAt
  lastError
  createdAt
  updatedAt
  ... on BambooHRIntegration {
    groups
    hostname
    subdomain
    syncTiming
    userId
  }
  ... on MergeDevIntegration {
    externalId
    integrationName
    syncHourUtc
  }
  ... on RipplingIntegration {
    customFieldKeys
    ripplingGroupIds
  }
  ... on SftpIntegration {
    fingerprints {
      sha256
      md5
    }
    username
  }
}
    `
export const IntegrationDetailsFragmentDoc = gql`
    fragment integrationDetails on Integration {
  ...integration
  ... on RipplingIntegration {
    customFields {
      key
      label
      description
    }
  }
}
    ${IntegrationFragmentDoc}`
export const InvoiceFragmentDoc = gql`
    fragment invoice on Invoice {
  id
  termMonths
  credits
  creditPrice
  totalPrice
  dueDate
  paidAt
  capturedAt
  stripeChargeId
  checkId
  lastErrorAt
  lastError
  createdBy
  createdAt
  updatedAt
}
    `
export const ManagerSettingsFragmentDoc = gql`
    fragment managerSettings on ManagerSettings {
  allowNotificationsToManagers
  allowNotificationsToReports
  organizationId
  updatedAt
}
    `
export const AssetFragmentDoc = gql`
    fragment asset on Asset {
  id
  type
  tag
  filename
  filesize
  path
  thumbnailUrl
  url
  mimeType
  archived
  createdAt
  ... on Image {
    width
    height
  }
}
    `
export const AudienceDashboardSettingsFragmentDoc = gql`
    fragment audienceDashboardSettings on AudienceDashboardSettings {
  primaryColor
  accentColor
  faviconImage {
    ...asset
  }
  logoImage {
    ...asset
  }
  bannerImage {
    ...asset
  }
  updatedAt
  createdAt
}
    ${AssetFragmentDoc}`
export const BillingFragmentDoc = gql`
    fragment billing on Billing {
  name
  contactName
  address
  emailSettings {
    toAdmins
    toEmails
  }
  toEmails
  credits
  peopleCount
  exempt
  monthlyGrowthRate
  monthlyCreditPrice
  yearlyCreditPrice
  termStartCount
  termMonths
  termMonthlyGrowthRate
  paymentMethod
  paymentDays
  expiredAt
  canceledAt
  terminatedAt
}
    `
export const ChargebeeSubscriptionFragmentDoc = gql`
    fragment chargebeeSubscription on ChargebeeSubscription {
  status
  lastSyncAt
}
    `
export const CustomDomainFragmentDoc = gql`
    fragment customDomain on CustomDomain {
  domain
  domainTarget
  isAutoRenew
  isDnsValid
  dnsLastValidAt
  certificateDigest
  certificates {
    digest
    expiresAt
    createdAt
  }
}
    `
export const EmailDomainFragmentDoc = gql`
    fragment emailDomain on EmailDomain {
  id
  domain
  environment
  fromEmail
  fromName
  dkimStatus {
    verified
    lastVerifiedAt
  }
  returnPathStatus {
    verified
    lastVerifiedAt
  }
  updatedAt
  createdAt
}
    `
export const EmailSettingsFragmentDoc = gql`
    fragment emailSettings on EmailSettings {
  autoSendInvitations
  fromEmail
  fromName
  replyToEmail
  defaultSenderFromName
  defaultSenderReplyToEmail
  bannerImage {
    ...asset
  }
  bodyFont
  footerLinks {
    text
    url
  }
  footerText
  logoImage {
    ...asset
  }
  primaryColor
  hasCustomBranding
  updatedAt
}
    ${AssetFragmentDoc}`
export const EmailTemplateFragmentDoc = gql`
    fragment emailTemplate on EmailTemplate {
  id
  subjectContent
  ccManagers
  buttonContent
  messageContent
  createdAt
  updatedAt
}
    `
export const GroupFragmentDoc = gql`
    fragment group on Group {
  id
  name
  archived
  createdAt
  updatedAt
  peopleCount
}
    `
export const HandbookReminderFragmentDoc = gql`
    fragment handbookReminder on HandbookReminder {
  id
  isEnabled
  days
  date
  cc
  ccManagers
  subjectContent
  buttonContent
  messageContent
  createdAt
  createdBy
  updatedAt
  updatedBy
}
    `
export const ManagerDigestSettingsFragmentDoc = gql`
    fragment managerDigestSettings on ManagerDigestSettings {
  isEnabled
  isDirectReportsOnly
  daysAfter
  date
  subjectContent
  buttonContent
  messageContent
  updatedAt
}
    `
export const OrganizationFragmentDoc = gql`
    fragment organization on Organization {
  id
  name
  subdomain
  origin
  blissbookOrigin
  isFullServe
  isCrmDisabled
  isIntercomDisabled
  isAuthDisabled
  isHandbookPositionEnabled
  showPrivacyPolicy
  logoImage {
    ...asset
  }
  createdAt
  createdBy
  updatedAt
  emailPeopleCount
  employeeIdPeopleCount
  friendsCount
  lastHiredOnPeopleCount
  managersCount
  membersCount
  peopleCount
  phonePeopleCount
  publishedAt
}
    ${AssetFragmentDoc}`
export const OrganizationTaskFragmentDoc = gql`
    fragment organizationTask on OrganizationTask {
  id
  completedAt
  completedBy
}
    `
export const EmailAuthSettingsFragmentDoc = gql`
    fragment emailAuthSettings on EmailAuthSettings {
  isEnabled
  updatedAt
}
    `
export const KioskModeSettingsFragmentDoc = gql`
    fragment kioskModeSettings on KioskModeSettings {
  isEnabled
  inactivitySeconds
  timeoutSeconds
  updatedAt
}
    `
export const PhoneAuthSettingsFragmentDoc = gql`
    fragment phoneAuthSettings on PhoneAuthSettings {
  isEnabled
  helpContent
  updatedAt
}
    `
export const SingleSignOnFragmentDoc = gql`
    fragment singleSignOn on SingleSignOn {
  providerId
  issuer
  loginUrl
  authKey
  buttonText
  createdAt
  updatedAt
  certificates {
    digest
    createdAt
    archivedAt
  }
}
    `
export const SecuritySettingsFragmentDoc = gql`
    fragment securitySettings on SecuritySettings {
  emailAuth {
    ...emailAuthSettings
  }
  kioskMode {
    ...kioskModeSettings
  }
  phoneAuth {
    ...phoneAuthSettings
  }
  singleSignOns {
    ...singleSignOn
  }
}
    ${EmailAuthSettingsFragmentDoc}
${KioskModeSettingsFragmentDoc}
${PhoneAuthSettingsFragmentDoc}
${SingleSignOnFragmentDoc}`
export const HandbookCollaboratorFragmentDoc = gql`
    fragment handbookCollaborator on HandbookCollaborator {
  id
  handbookId
  personId
  roleId
}
    `
export const TeammateFragmentDoc = gql`
    fragment teammate on Teammate {
  id
  teamId
  personId
  person {
    ...whodunnit
  }
  canReport
  canSuperBrand
  roleId
}
    ${WhodunnitFragmentDoc}`
export const TeamFragmentDoc = gql`
    fragment team on Team {
  id
  permissionIds
  createdAt
  updatedAt
  handbookCollaborators {
    ...handbookCollaborator
  }
  teammates {
    ...teammate
  }
}
    ${HandbookCollaboratorFragmentDoc}
${TeammateFragmentDoc}`
export const OrganizationQueryFragmentDoc = gql`
    fragment organizationQuery on Query {
  audienceDashboardSettings {
    ...audienceDashboardSettings
  }
  billing {
    ...billing
  }
  chargebeeSubscription {
    ...chargebeeSubscription
  }
  customDomain {
    ...customDomain
  }
  emailDomains {
    ...emailDomain
  }
  emailSettings {
    ...emailSettings
  }
  emailTemplates {
    ...emailTemplate
  }
  featureFlagIds
  groups {
    ...group
  }
  handbooks {
    ...handbookSummary
  }
  handbookReminders {
    ...handbookReminder
  }
  integrations {
    ...integration
  }
  managerDigestSettings {
    ...managerDigestSettings
  }
  metadataKeys
  organization {
    ...organization
  }
  organizationTasks {
    ...organizationTask
  }
  securitySettings {
    ...securitySettings
  }
  teams {
    ...team
  }
}
    ${AudienceDashboardSettingsFragmentDoc}
${BillingFragmentDoc}
${ChargebeeSubscriptionFragmentDoc}
${CustomDomainFragmentDoc}
${EmailDomainFragmentDoc}
${EmailSettingsFragmentDoc}
${EmailTemplateFragmentDoc}
${GroupFragmentDoc}
${HandbookSummaryFragmentDoc}
${HandbookReminderFragmentDoc}
${IntegrationFragmentDoc}
${ManagerDigestSettingsFragmentDoc}
${OrganizationFragmentDoc}
${OrganizationTaskFragmentDoc}
${SecuritySettingsFragmentDoc}
${TeamFragmentDoc}`
export const HrisPersonFragmentDoc = gql`
    fragment hrisPerson on HrisPerson {
  email
  externalId
  employeeId
  phone
  fullName
  lastHiredOn
  groupNames
  metadata
  sortKey
}
    `
export const PersonFragmentDoc = gql`
    fragment person on Person {
  id
  email
  externalId
  employeeId
  phone
  fullName
  archived
  archivedAt
  member
  adminRoleId
  isSuperUser
  lastHiredOn
  avatarUrl
  lastActivatedAt
  isSignatureRequired
  isEmployeeSignatureRequired
  totalDirectReportsCount
  nonCompliantDirectReportsCount
  nonCompliantDirectReportsPercentage
  totalReportsCount
  nonCompliantReportsCount
  nonCompliantReportsPercentage
  lastEmployeeDigestQueuedAt
  lastManagerDigestQueuedAt
  lastNotifiedAt
  lastViewedAt
  lastSignedAt
  autoSendReminders
  isManager
  managers {
    id
    email
    employeeId
    fullName
  }
  createdAt
  updatedAt
  groupIds
  handbookIds
  savedSegmentIds
  metadata
  userIds
  sortKey
}
    `
export const PreferencesFragmentDoc = gql`
    fragment preferences on Preferences {
  documentationHandbooksSort
  handbooksSort
  handbooksView
  hideProTips
}
    `
export const SavedReportFragmentDoc = gql`
    fragment savedReport on SavedReport {
  id
  type
  name
  description
  expression {
    conjunction
    expressions {
      conjunction
      operands {
        id
        type
        ... on DateOperand {
          field
          dateCondition
          days
          date
        }
        ... on IdsOperand {
          isEvery
          isNot
          ids
        }
        ... on ManagersOperand {
          isEvery
          isNot
          managesDirectly
          ids
        }
        ... on SelectOperand {
          field
          path
          isEvery
          isNot
          values
        }
        ... on TextOperand {
          field
          isNot
          textCondition
          text
        }
      }
    }
  }
  showArchived
  columnKeys {
    field
    path
  }
  order {
    field
    direction
  }
  createdAt
  createdBy {
    ...whodunnit
  }
  updatedAt
  updatedBy {
    ...whodunnit
  }
  archivedAt
  ... on DocumentSavedReport {
    handbookId
  }
}
    ${WhodunnitFragmentDoc}`
export const SavedSegmentFragmentDoc = gql`
    fragment savedSegment on SavedSegment {
  id
  name
  description
  expression {
    conjunction
    expressions {
      conjunction
      operands {
        id
        type
        ... on DateOperand {
          field
          dateCondition
          days
          date
        }
        ... on IdsOperand {
          isEvery
          isNot
          ids
        }
        ... on ManagersOperand {
          isEvery
          isNot
          managesDirectly
          ids
        }
        ... on SelectOperand {
          field
          path
          isEvery
          isNot
          values
        }
        ... on TextOperand {
          field
          isNot
          textCondition
          text
        }
      }
    }
  }
  peopleCount
  createdAt
  createdBy {
    ...whodunnit
  }
  updatedAt
  updatedBy {
    ...whodunnit
  }
  archivedAt
}
    ${WhodunnitFragmentDoc}`
export const DocumentSettingsFragmentDoc = gql`
    fragment documentSettings on DocumentSettings {
  isPolicyWorkflowEnabled
  organizationId
}
    `
export const DocumentReviewFragmentDoc = gql`
    fragment documentReview on DocumentReview {
  id
  documentId
  personId
  person {
    ...whodunnit
  }
  isApproved
  createdAt
}
    ${WhodunnitFragmentDoc}`
export const DocumentVersionFragmentDoc = gql`
    fragment documentVersion on DocumentVersion {
  audienceExpression {
    conjunction
    expressions {
      conjunction
      operands {
        id
        type
        ... on DateOperand {
          field
          dateCondition
          days
          date
        }
        ... on IdsOperand {
          isEvery
          isNot
          ids
        }
        ... on ManagersOperand {
          isEvery
          isNot
          managesDirectly
          ids
        }
        ... on SelectOperand {
          field
          path
          isEvery
          isNot
          values
        }
        ... on TextOperand {
          field
          isNot
          textCondition
          text
        }
      }
    }
  }
  audienceExpressionHash
  createdAt
  createdBy {
    ...whodunnit
  }
  documentId
  html
  htmlHash
  htmlTitle
  id
  isPublished
  reviews {
    ...documentReview
  }
  updatedAt
  updatedBy {
    ...whodunnit
  }
  versionNumber
}
    ${WhodunnitFragmentDoc}
${DocumentReviewFragmentDoc}`
export const DocumentReviewerFragmentDoc = gql`
    fragment documentReviewer on DocumentReviewer {
  id
  documentId
  personId
  person {
    ...whodunnit
  }
  createdAt
  createdBy {
    ...whodunnit
  }
  updatedAt
  updatedBy {
    ...whodunnit
  }
}
    ${WhodunnitFragmentDoc}`
export const DocumentFragmentDoc = gql`
    fragment document on Document {
  id
  ckeditorChannelId
  parentFolderId
  sourceHandbookId
  sourceHandbookSectionId
  sortPosition
  type
  name
  archivedAt
  archivedBy {
    ...whodunnit
  }
  createdAt
  createdBy {
    ...whodunnit
  }
  updatedAt
  updatedBy {
    ...whodunnit
  }
  handbookIds
  draftVersion {
    ...documentVersion
  }
  lastPublishedVersion {
    ...documentVersion
  }
  lastPublishedVersionNumber
  reviewers {
    ...documentReviewer
  }
}
    ${WhodunnitFragmentDoc}
${DocumentVersionFragmentDoc}
${DocumentReviewerFragmentDoc}`
export const FolderFragmentDoc = gql`
    fragment folder on Folder {
  id
  parentFolderId
  name
}
    `
export const PolicyLibraryDocumentFragmentDoc = gql`
    fragment policyLibraryDocument on Document {
  id
  parentFolderId
  parentFolder {
    ...folder
  }
  name
  archivedAt
  lastPublishedVersionNumber
  sortPosition
  createdAt
  createdBy {
    ...whodunnit
  }
  updatedAt
  updatedBy {
    ...whodunnit
  }
  draftVersion {
    id
    audienceExpression {
      conjunction
      expressions {
        conjunction
        operands {
          id
          type
          ... on DateOperand {
            field
            dateCondition
            days
            date
          }
          ... on IdsOperand {
            isEvery
            isNot
            ids
          }
          ... on ManagersOperand {
            isEvery
            isNot
            managesDirectly
            ids
          }
          ... on SelectOperand {
            field
            path
            isEvery
            isNot
            values
          }
          ... on TextOperand {
            field
            isNot
            textCondition
            text
          }
        }
      }
    }
    reviews {
      ...documentReview
    }
    versionNumber
  }
  lastPublishedVersion {
    id
    audienceExpression {
      conjunction
      expressions {
        conjunction
        operands {
          id
          type
          ... on DateOperand {
            field
            dateCondition
            days
            date
          }
          ... on IdsOperand {
            isEvery
            isNot
            ids
          }
          ... on ManagersOperand {
            isEvery
            isNot
            managesDirectly
            ids
          }
          ... on SelectOperand {
            field
            path
            isEvery
            isNot
            values
          }
          ... on TextOperand {
            field
            isNot
            textCondition
            text
          }
        }
      }
    }
    createdAt
    createdBy {
      ...whodunnit
    }
    versionNumber
  }
  reviewers {
    ...documentReviewer
  }
  sortKey
}
    ${FolderFragmentDoc}
${WhodunnitFragmentDoc}
${DocumentReviewFragmentDoc}
${DocumentReviewerFragmentDoc}`
export const HandbookEditorDocumentFragmentDoc = gql`
    fragment handbookEditorDocument on Query {
  documents {
    id
    type
    name
    archivedAt
    lastPublishedVersionNumber
    parentFolderId
    sourceHandbookId
    sourceHandbookSectionId
    draftVersion {
      audienceExpression {
        conjunction
        expressions {
          conjunction
          operands {
            id
            type
            ... on DateOperand {
              field
              dateCondition
              days
              date
            }
            ... on IdsOperand {
              isEvery
              isNot
              ids
            }
            ... on ManagersOperand {
              isEvery
              isNot
              managesDirectly
              ids
            }
            ... on SelectOperand {
              field
              path
              isEvery
              isNot
              values
            }
            ... on TextOperand {
              field
              isNot
              textCondition
              text
            }
          }
        }
      }
      versionNumber
    }
    lastPublishedVersion {
      audienceExpression {
        conjunction
        expressions {
          conjunction
          operands {
            id
            type
            ... on DateOperand {
              field
              dateCondition
              days
              date
            }
            ... on IdsOperand {
              isEvery
              isNot
              ids
            }
            ... on ManagersOperand {
              isEvery
              isNot
              managesDirectly
              ids
            }
            ... on SelectOperand {
              field
              path
              isEvery
              isNot
              values
            }
            ... on TextOperand {
              field
              isNot
              textCondition
              text
            }
          }
        }
      }
      versionNumber
    }
  }
  folders {
    ...folder
  }
}
    ${FolderFragmentDoc}`
export const DocumentGroupFragmentDoc = gql`
    fragment documentGroup on DocumentGroup {
  field
  documentsCount
  ... on FolderDocumentGroup {
    folder {
      ...folder
    }
  }
  ... on PersonDocumentGroup {
    person {
      ...whodunnit
    }
  }
}
    ${FolderFragmentDoc}
${WhodunnitFragmentDoc}`
export const EventFragmentDoc = gql`
    fragment event on Event {
  id
  type
  targetId
  targetType
  person {
    ...whodunnit
  }
  createdAt
  updatedAt
  sortKey
  ... on DocumentAddedEvent {
    document {
      id
      name
    }
    handbook {
      id
      name
    }
  }
  ... on DocumentArchivedEvent {
    document {
      id
      name
    }
  }
  ... on DocumentCreatedEvent {
    document {
      id
      name
    }
  }
  ... on DocumentPublishedEvent {
    document {
      id
      name
    }
    attributes {
      versionNumber
    }
  }
  ... on DocumentRemovedEvent {
    document {
      id
      name
    }
    handbook {
      id
      name
    }
  }
  ... on DocumentReviewerAddedEvent {
    attributes {
      documentVersionNumber
    }
    document {
      id
      name
    }
    documentReviewerPerson {
      ...whodunnit
    }
  }
  ... on DocumentReviewerApprovedEvent {
    attributes {
      documentVersionNumber
    }
    document {
      id
      name
    }
  }
  ... on DocumentReviewerRejectedEvent {
    attributes {
      documentVersionNumber
    }
    document {
      id
      name
    }
  }
  ... on DocumentReviewerRemovedEvent {
    attributes {
      documentVersionNumber
    }
    document {
      id
      name
    }
    documentReviewerPerson {
      ...whodunnit
    }
  }
  ... on DocumentSettingsUpdatedEvent {
    attributes {
      isPolicyWorkflowEnabled
    }
  }
  ... on DocumentUnarchivedEvent {
    document {
      id
      name
    }
  }
  ... on DocumentUpdatedEvent {
    document {
      id
      name
    }
    versionNumber
    audienceExpression {
      prevValue {
        conjunction
        expressions {
          conjunction
          operands {
            id
            type
            ... on DateOperand {
              field
              dateCondition
              days
              date
            }
            ... on IdsOperand {
              isEvery
              isNot
              ids
            }
            ... on ManagersOperand {
              isEvery
              isNot
              managesDirectly
              ids
            }
            ... on SelectOperand {
              field
              path
              isEvery
              isNot
              values
            }
            ... on TextOperand {
              field
              isNot
              textCondition
              text
            }
          }
        }
      }
      value {
        conjunction
        expressions {
          conjunction
          operands {
            id
            type
            ... on DateOperand {
              field
              dateCondition
              days
              date
            }
            ... on IdsOperand {
              isEvery
              isNot
              ids
            }
            ... on ManagersOperand {
              isEvery
              isNot
              managesDirectly
              ids
            }
            ... on SelectOperand {
              field
              path
              isEvery
              isNot
              values
            }
            ... on TextOperand {
              field
              isNot
              textCondition
              text
            }
          }
        }
      }
    }
    html
    name {
      prevValue
      value
    }
  }
  ... on DocumentVersionCreatedEvent {
    document {
      id
      name
    }
    attributes {
      versionNumber
    }
  }
  ... on FeatureFlagAddedEvent {
    attributes {
      featureFlagId
    }
  }
  ... on FeatureFlagRemovedEvent {
    attributes {
      featureFlagId
    }
  }
  ... on HandbookCreatedEvent {
    handbook {
      id
      name
    }
  }
  ... on HandbookPublishedEvent {
    handbook {
      id
      name
    }
    attributes {
      versionNumber
    }
  }
  ... on HandbookSignatureRoundCreatedEvent {
    handbook {
      id
      name
    }
    signatureRound {
      id
      number
    }
  }
  ... on HandbookSignedEvent {
    handbook {
      id
      name
    }
    attributes {
      handbookSignatureId
    }
  }
  ... on HandbookViewedEvent {
    handbook {
      id
      name
    }
    attributes {
      handbookSessionId
    }
  }
  ... on PersonNotifiedEvent {
    notification {
      id
      subject
      handbookId
    }
  }
}
    ${WhodunnitFragmentDoc}`
export const ApplicationDocument = gql`
    query application {
  ...organizationQuery
  preferences {
    ...preferences
  }
  session {
    ...session
  }
}
    ${OrganizationQueryFragmentDoc}
${PreferencesFragmentDoc}
${SessionFragmentDoc}`

/**
 * __useApplicationQuery__
 *
 * To run a query within a React component, call `useApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicationQuery({
 *   variables: {
 *   },
 * });
 */
export function useApplicationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ApplicationQuery,
    ApplicationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ApplicationQuery, ApplicationQueryVariables>(
    ApplicationDocument,
    options,
  )
}
export function useApplicationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApplicationQuery,
    ApplicationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ApplicationQuery, ApplicationQueryVariables>(
    ApplicationDocument,
    options,
  )
}
export type ApplicationQueryHookResult = ReturnType<typeof useApplicationQuery>
export type ApplicationLazyQueryHookResult = ReturnType<
  typeof useApplicationLazyQuery
>
export type ApplicationQueryResult = Apollo.QueryResult<
  ApplicationQuery,
  ApplicationQueryVariables
>
export const FeatureFlagsDocument = gql`
    query featureFlags {
  featureFlagIds
}
    `

/**
 * __useFeatureFlagsQuery__
 *
 * To run a query within a React component, call `useFeatureFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureFlagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeatureFlagsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FeatureFlagsQuery,
    FeatureFlagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FeatureFlagsQuery, FeatureFlagsQueryVariables>(
    FeatureFlagsDocument,
    options,
  )
}
export function useFeatureFlagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FeatureFlagsQuery,
    FeatureFlagsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FeatureFlagsQuery, FeatureFlagsQueryVariables>(
    FeatureFlagsDocument,
    options,
  )
}
export type FeatureFlagsQueryHookResult = ReturnType<
  typeof useFeatureFlagsQuery
>
export type FeatureFlagsLazyQueryHookResult = ReturnType<
  typeof useFeatureFlagsLazyQuery
>
export type FeatureFlagsQueryResult = Apollo.QueryResult<
  FeatureFlagsQuery,
  FeatureFlagsQueryVariables
>
export const HandbooksDocument = gql`
    query handbooks {
  handbooks {
    ...handbookSummary
  }
}
    ${HandbookSummaryFragmentDoc}`

/**
 * __useHandbooksQuery__
 *
 * To run a query within a React component, call `useHandbooksQuery` and pass it any options that fit your needs.
 * When your component renders, `useHandbooksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHandbooksQuery({
 *   variables: {
 *   },
 * });
 */
export function useHandbooksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HandbooksQuery,
    HandbooksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<HandbooksQuery, HandbooksQueryVariables>(
    HandbooksDocument,
    options,
  )
}
export function useHandbooksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HandbooksQuery,
    HandbooksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<HandbooksQuery, HandbooksQueryVariables>(
    HandbooksDocument,
    options,
  )
}
export type HandbooksQueryHookResult = ReturnType<typeof useHandbooksQuery>
export type HandbooksLazyQueryHookResult = ReturnType<
  typeof useHandbooksLazyQuery
>
export type HandbooksQueryResult = Apollo.QueryResult<
  HandbooksQuery,
  HandbooksQueryVariables
>
export const HandbookDocument = gql`
    query handbook($handbookId: Int!) {
  handbook(id: $handbookId) {
    ...handbook
  }
  ...handbookEditorDocument
}
    ${HandbookFragmentDoc}
${HandbookEditorDocumentFragmentDoc}`

/**
 * __useHandbookQuery__
 *
 * To run a query within a React component, call `useHandbookQuery` and pass it any options that fit your needs.
 * When your component renders, `useHandbookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHandbookQuery({
 *   variables: {
 *      handbookId: // value for 'handbookId'
 *   },
 * });
 */
export function useHandbookQuery(
  baseOptions: Apollo.QueryHookOptions<HandbookQuery, HandbookQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<HandbookQuery, HandbookQueryVariables>(
    HandbookDocument,
    options,
  )
}
export function useHandbookLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HandbookQuery,
    HandbookQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<HandbookQuery, HandbookQueryVariables>(
    HandbookDocument,
    options,
  )
}
export type HandbookQueryHookResult = ReturnType<typeof useHandbookQuery>
export type HandbookLazyQueryHookResult = ReturnType<
  typeof useHandbookLazyQuery
>
export type HandbookQueryResult = Apollo.QueryResult<
  HandbookQuery,
  HandbookQueryVariables
>
export const HandbookEditorDocument = gql`
    query handbookEditor($handbookId: Int!) {
  handbook(id: $handbookId) {
    ...handbook
  }
  ...handbookEditorDocument
}
    ${HandbookFragmentDoc}
${HandbookEditorDocumentFragmentDoc}`

/**
 * __useHandbookEditorQuery__
 *
 * To run a query within a React component, call `useHandbookEditorQuery` and pass it any options that fit your needs.
 * When your component renders, `useHandbookEditorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHandbookEditorQuery({
 *   variables: {
 *      handbookId: // value for 'handbookId'
 *   },
 * });
 */
export function useHandbookEditorQuery(
  baseOptions: Apollo.QueryHookOptions<
    HandbookEditorQuery,
    HandbookEditorQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<HandbookEditorQuery, HandbookEditorQueryVariables>(
    HandbookEditorDocument,
    options,
  )
}
export function useHandbookEditorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HandbookEditorQuery,
    HandbookEditorQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<HandbookEditorQuery, HandbookEditorQueryVariables>(
    HandbookEditorDocument,
    options,
  )
}
export type HandbookEditorQueryHookResult = ReturnType<
  typeof useHandbookEditorQuery
>
export type HandbookEditorLazyQueryHookResult = ReturnType<
  typeof useHandbookEditorLazyQuery
>
export type HandbookEditorQueryResult = Apollo.QueryResult<
  HandbookEditorQuery,
  HandbookEditorQueryVariables
>
export const HandbookEditorDocumentsDocument = gql`
    query handbookEditorDocuments {
  ...handbookEditorDocument
}
    ${HandbookEditorDocumentFragmentDoc}`

/**
 * __useHandbookEditorDocumentsQuery__
 *
 * To run a query within a React component, call `useHandbookEditorDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHandbookEditorDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHandbookEditorDocumentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useHandbookEditorDocumentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HandbookEditorDocumentsQuery,
    HandbookEditorDocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    HandbookEditorDocumentsQuery,
    HandbookEditorDocumentsQueryVariables
  >(HandbookEditorDocumentsDocument, options)
}
export function useHandbookEditorDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HandbookEditorDocumentsQuery,
    HandbookEditorDocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    HandbookEditorDocumentsQuery,
    HandbookEditorDocumentsQueryVariables
  >(HandbookEditorDocumentsDocument, options)
}
export type HandbookEditorDocumentsQueryHookResult = ReturnType<
  typeof useHandbookEditorDocumentsQuery
>
export type HandbookEditorDocumentsLazyQueryHookResult = ReturnType<
  typeof useHandbookEditorDocumentsLazyQuery
>
export type HandbookEditorDocumentsQueryResult = Apollo.QueryResult<
  HandbookEditorDocumentsQuery,
  HandbookEditorDocumentsQueryVariables
>
export const ExportHandbookDocument = gql`
    query exportHandbook($handbookId: Int!) {
  handbook(id: $handbookId) {
    ...handbook
    documents {
      id
      name
      lastPublishedVersion {
        html
        versionNumber
      }
    }
  }
}
    ${HandbookFragmentDoc}`

/**
 * __useExportHandbookQuery__
 *
 * To run a query within a React component, call `useExportHandbookQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportHandbookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportHandbookQuery({
 *   variables: {
 *      handbookId: // value for 'handbookId'
 *   },
 * });
 */
export function useExportHandbookQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExportHandbookQuery,
    ExportHandbookQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ExportHandbookQuery, ExportHandbookQueryVariables>(
    ExportHandbookDocument,
    options,
  )
}
export function useExportHandbookLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExportHandbookQuery,
    ExportHandbookQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ExportHandbookQuery, ExportHandbookQueryVariables>(
    ExportHandbookDocument,
    options,
  )
}
export type ExportHandbookQueryHookResult = ReturnType<
  typeof useExportHandbookQuery
>
export type ExportHandbookLazyQueryHookResult = ReturnType<
  typeof useExportHandbookLazyQuery
>
export type ExportHandbookQueryResult = Apollo.QueryResult<
  ExportHandbookQuery,
  ExportHandbookQueryVariables
>
export const HandbookRecipientsDocument = gql`
    query handbookRecipients($after: String, $filter: HandbookRecipientsFilter, $handbookId: Int!, $limit: Int, $order: HandbookRecipientsSortOrder) {
  handbookRecipients(
    after: $after
    filter: $filter
    handbookId: $handbookId
    limit: $limit
    order: $order
  ) {
    ...handbookRecipient
    sortKey
  }
}
    ${HandbookRecipientFragmentDoc}`

/**
 * __useHandbookRecipientsQuery__
 *
 * To run a query within a React component, call `useHandbookRecipientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHandbookRecipientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHandbookRecipientsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      handbookId: // value for 'handbookId'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useHandbookRecipientsQuery(
  baseOptions: Apollo.QueryHookOptions<
    HandbookRecipientsQuery,
    HandbookRecipientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    HandbookRecipientsQuery,
    HandbookRecipientsQueryVariables
  >(HandbookRecipientsDocument, options)
}
export function useHandbookRecipientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HandbookRecipientsQuery,
    HandbookRecipientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    HandbookRecipientsQuery,
    HandbookRecipientsQueryVariables
  >(HandbookRecipientsDocument, options)
}
export type HandbookRecipientsQueryHookResult = ReturnType<
  typeof useHandbookRecipientsQuery
>
export type HandbookRecipientsLazyQueryHookResult = ReturnType<
  typeof useHandbookRecipientsLazyQuery
>
export type HandbookRecipientsQueryResult = Apollo.QueryResult<
  HandbookRecipientsQuery,
  HandbookRecipientsQueryVariables
>
export const HandbookStatisticsDocument = gql`
    query handbookStatistics($filter: HandbookRecipientsFilter, $handbookId: Int!) {
  handbookStatistics(filter: $filter, handbookId: $handbookId) {
    ...handbookStatistics
  }
}
    ${HandbookStatisticsFragmentDoc}`

/**
 * __useHandbookStatisticsQuery__
 *
 * To run a query within a React component, call `useHandbookStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHandbookStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHandbookStatisticsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      handbookId: // value for 'handbookId'
 *   },
 * });
 */
export function useHandbookStatisticsQuery(
  baseOptions: Apollo.QueryHookOptions<
    HandbookStatisticsQuery,
    HandbookStatisticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    HandbookStatisticsQuery,
    HandbookStatisticsQueryVariables
  >(HandbookStatisticsDocument, options)
}
export function useHandbookStatisticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HandbookStatisticsQuery,
    HandbookStatisticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    HandbookStatisticsQuery,
    HandbookStatisticsQueryVariables
  >(HandbookStatisticsDocument, options)
}
export type HandbookStatisticsQueryHookResult = ReturnType<
  typeof useHandbookStatisticsQuery
>
export type HandbookStatisticsLazyQueryHookResult = ReturnType<
  typeof useHandbookStatisticsLazyQuery
>
export type HandbookStatisticsQueryResult = Apollo.QueryResult<
  HandbookStatisticsQuery,
  HandbookStatisticsQueryVariables
>
export const HandbookSignatureRoundsDocument = gql`
    query handbookSignatureRounds($handbookId: Int!) {
  handbook(id: $handbookId) {
    id
    signatureRounds {
      ...handbookSignatureRound
    }
  }
}
    ${HandbookSignatureRoundFragmentDoc}`

/**
 * __useHandbookSignatureRoundsQuery__
 *
 * To run a query within a React component, call `useHandbookSignatureRoundsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHandbookSignatureRoundsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHandbookSignatureRoundsQuery({
 *   variables: {
 *      handbookId: // value for 'handbookId'
 *   },
 * });
 */
export function useHandbookSignatureRoundsQuery(
  baseOptions: Apollo.QueryHookOptions<
    HandbookSignatureRoundsQuery,
    HandbookSignatureRoundsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    HandbookSignatureRoundsQuery,
    HandbookSignatureRoundsQueryVariables
  >(HandbookSignatureRoundsDocument, options)
}
export function useHandbookSignatureRoundsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HandbookSignatureRoundsQuery,
    HandbookSignatureRoundsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    HandbookSignatureRoundsQuery,
    HandbookSignatureRoundsQueryVariables
  >(HandbookSignatureRoundsDocument, options)
}
export type HandbookSignatureRoundsQueryHookResult = ReturnType<
  typeof useHandbookSignatureRoundsQuery
>
export type HandbookSignatureRoundsLazyQueryHookResult = ReturnType<
  typeof useHandbookSignatureRoundsLazyQuery
>
export type HandbookSignatureRoundsQueryResult = Apollo.QueryResult<
  HandbookSignatureRoundsQuery,
  HandbookSignatureRoundsQueryVariables
>
export const ManagerSettingsDocument = gql`
    query managerSettings {
  managerSettings {
    ...managerSettings
  }
}
    ${ManagerSettingsFragmentDoc}`

/**
 * __useManagerSettingsQuery__
 *
 * To run a query within a React component, call `useManagerSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useManagerSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagerSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useManagerSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ManagerSettingsQuery,
    ManagerSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ManagerSettingsQuery, ManagerSettingsQueryVariables>(
    ManagerSettingsDocument,
    options,
  )
}
export function useManagerSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ManagerSettingsQuery,
    ManagerSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ManagerSettingsQuery,
    ManagerSettingsQueryVariables
  >(ManagerSettingsDocument, options)
}
export type ManagerSettingsQueryHookResult = ReturnType<
  typeof useManagerSettingsQuery
>
export type ManagerSettingsLazyQueryHookResult = ReturnType<
  typeof useManagerSettingsLazyQuery
>
export type ManagerSettingsQueryResult = Apollo.QueryResult<
  ManagerSettingsQuery,
  ManagerSettingsQueryVariables
>
export const OrganizationDocument = gql`
    query organization {
  ...organizationQuery
}
    ${OrganizationQueryFragmentDoc}`

/**
 * __useOrganizationQuery__
 *
 * To run a query within a React component, call `useOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrganizationQuery,
    OrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<OrganizationQuery, OrganizationQueryVariables>(
    OrganizationDocument,
    options,
  )
}
export function useOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrganizationQuery,
    OrganizationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<OrganizationQuery, OrganizationQueryVariables>(
    OrganizationDocument,
    options,
  )
}
export type OrganizationQueryHookResult = ReturnType<
  typeof useOrganizationQuery
>
export type OrganizationLazyQueryHookResult = ReturnType<
  typeof useOrganizationLazyQuery
>
export type OrganizationQueryResult = Apollo.QueryResult<
  OrganizationQuery,
  OrganizationQueryVariables
>
export const HrisPeopleDocument = gql`
    query hrisPeople($after: String, $filter: HrisPeopleFilter, $integrationId: Int!, $limit: Int, $order: PeopleSortOrder) {
  hrisPeople(
    after: $after
    filter: $filter
    integrationId: $integrationId
    limit: $limit
    order: $order
  ) {
    ...hrisPerson
  }
}
    ${HrisPersonFragmentDoc}`

/**
 * __useHrisPeopleQuery__
 *
 * To run a query within a React component, call `useHrisPeopleQuery` and pass it any options that fit your needs.
 * When your component renders, `useHrisPeopleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHrisPeopleQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      integrationId: // value for 'integrationId'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useHrisPeopleQuery(
  baseOptions: Apollo.QueryHookOptions<
    HrisPeopleQuery,
    HrisPeopleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<HrisPeopleQuery, HrisPeopleQueryVariables>(
    HrisPeopleDocument,
    options,
  )
}
export function useHrisPeopleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HrisPeopleQuery,
    HrisPeopleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<HrisPeopleQuery, HrisPeopleQueryVariables>(
    HrisPeopleDocument,
    options,
  )
}
export type HrisPeopleQueryHookResult = ReturnType<typeof useHrisPeopleQuery>
export type HrisPeopleLazyQueryHookResult = ReturnType<
  typeof useHrisPeopleLazyQuery
>
export type HrisPeopleQueryResult = Apollo.QueryResult<
  HrisPeopleQuery,
  HrisPeopleQueryVariables
>
export const HrisPeopleStatisticsDocument = gql`
    query hrisPeopleStatistics($filter: HrisPeopleFilter, $integrationId: Int!) {
  hrisPeopleStatistics(filter: $filter, integrationId: $integrationId) {
    totalCount
  }
}
    `

/**
 * __useHrisPeopleStatisticsQuery__
 *
 * To run a query within a React component, call `useHrisPeopleStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHrisPeopleStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHrisPeopleStatisticsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useHrisPeopleStatisticsQuery(
  baseOptions: Apollo.QueryHookOptions<
    HrisPeopleStatisticsQuery,
    HrisPeopleStatisticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    HrisPeopleStatisticsQuery,
    HrisPeopleStatisticsQueryVariables
  >(HrisPeopleStatisticsDocument, options)
}
export function useHrisPeopleStatisticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HrisPeopleStatisticsQuery,
    HrisPeopleStatisticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    HrisPeopleStatisticsQuery,
    HrisPeopleStatisticsQueryVariables
  >(HrisPeopleStatisticsDocument, options)
}
export type HrisPeopleStatisticsQueryHookResult = ReturnType<
  typeof useHrisPeopleStatisticsQuery
>
export type HrisPeopleStatisticsLazyQueryHookResult = ReturnType<
  typeof useHrisPeopleStatisticsLazyQuery
>
export type HrisPeopleStatisticsQueryResult = Apollo.QueryResult<
  HrisPeopleStatisticsQuery,
  HrisPeopleStatisticsQueryVariables
>
export const PersonDocument = gql`
    query person($personId: Int!) {
  directReports: people(
    filter: {expression: {conjunction: and, expressions: {conjunction: and, operands: {managers: {managesDirectly: true, ids: [$personId]}}}}}
  ) {
    id
    email
    employeeId
    fullName
  }
  people(ids: [$personId], showArchived: true) {
    ...person
    handbookRecipients {
      ...handbookRecipientReporting
      canNotify
      fullName
      signatureRoundIds
      signatures {
        id
      }
    }
  }
}
    ${PersonFragmentDoc}
${HandbookRecipientReportingFragmentDoc}`

/**
 * __usePersonQuery__
 *
 * To run a query within a React component, call `usePersonQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function usePersonQuery(
  baseOptions: Apollo.QueryHookOptions<PersonQuery, PersonQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PersonQuery, PersonQueryVariables>(
    PersonDocument,
    options,
  )
}
export function usePersonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PersonQuery, PersonQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PersonQuery, PersonQueryVariables>(
    PersonDocument,
    options,
  )
}
export type PersonQueryHookResult = ReturnType<typeof usePersonQuery>
export type PersonLazyQueryHookResult = ReturnType<typeof usePersonLazyQuery>
export type PersonQueryResult = Apollo.QueryResult<
  PersonQuery,
  PersonQueryVariables
>
export const PersonSignaturesDocument = gql`
    query personSignatures($personId: Int!) {
  people(ids: [$personId], showArchived: true) {
    ...person
    handbookRecipients {
      ...handbookRecipientReporting
      canNotify
      fullName
      signatureRoundIds
      signatures {
        ...handbookSignature
      }
      signatureDueDateOverrides {
        ...handbookSignatureDueDateOverride
      }
    }
  }
}
    ${PersonFragmentDoc}
${HandbookRecipientReportingFragmentDoc}
${HandbookSignatureFragmentDoc}
${HandbookSignatureDueDateOverrideFragmentDoc}`

/**
 * __usePersonSignaturesQuery__
 *
 * To run a query within a React component, call `usePersonSignaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonSignaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonSignaturesQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function usePersonSignaturesQuery(
  baseOptions: Apollo.QueryHookOptions<
    PersonSignaturesQuery,
    PersonSignaturesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PersonSignaturesQuery, PersonSignaturesQueryVariables>(
    PersonSignaturesDocument,
    options,
  )
}
export function usePersonSignaturesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PersonSignaturesQuery,
    PersonSignaturesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    PersonSignaturesQuery,
    PersonSignaturesQueryVariables
  >(PersonSignaturesDocument, options)
}
export type PersonSignaturesQueryHookResult = ReturnType<
  typeof usePersonSignaturesQuery
>
export type PersonSignaturesLazyQueryHookResult = ReturnType<
  typeof usePersonSignaturesLazyQuery
>
export type PersonSignaturesQueryResult = Apollo.QueryResult<
  PersonSignaturesQuery,
  PersonSignaturesQueryVariables
>
export const PersonEventDetailsDocument = gql`
    query personEventDetails($personId: Int!) {
  people(ids: [$personId], showArchived: true) {
    notifications {
      ...handbookNotification
    }
  }
}
    ${HandbookNotificationFragmentDoc}`

/**
 * __usePersonEventDetailsQuery__
 *
 * To run a query within a React component, call `usePersonEventDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonEventDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonEventDetailsQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function usePersonEventDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PersonEventDetailsQuery,
    PersonEventDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    PersonEventDetailsQuery,
    PersonEventDetailsQueryVariables
  >(PersonEventDetailsDocument, options)
}
export function usePersonEventDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PersonEventDetailsQuery,
    PersonEventDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    PersonEventDetailsQuery,
    PersonEventDetailsQueryVariables
  >(PersonEventDetailsDocument, options)
}
export type PersonEventDetailsQueryHookResult = ReturnType<
  typeof usePersonEventDetailsQuery
>
export type PersonEventDetailsLazyQueryHookResult = ReturnType<
  typeof usePersonEventDetailsLazyQuery
>
export type PersonEventDetailsQueryResult = Apollo.QueryResult<
  PersonEventDetailsQuery,
  PersonEventDetailsQueryVariables
>
export const PeopleDocument = gql`
    query people($after: String, $canNotify: Boolean, $filter: PeopleFilter, $ids: [Int!], $limit: Int, $notIds: [Int!], $order: PeopleSortOrder, $role: OrganizationRoleQuery, $search: String, $showArchived: Boolean) {
  people(
    after: $after
    canNotify: $canNotify
    filter: $filter
    ids: $ids
    limit: $limit
    notIds: $notIds
    order: $order
    role: $role
    search: $search
    showArchived: $showArchived
  ) {
    ...person
    handbookRecipients {
      handbookId
      personId
      isSignatureRequired
    }
  }
}
    ${PersonFragmentDoc}`

/**
 * __usePeopleQuery__
 *
 * To run a query within a React component, call `usePeopleQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeopleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeopleQuery({
 *   variables: {
 *      after: // value for 'after'
 *      canNotify: // value for 'canNotify'
 *      filter: // value for 'filter'
 *      ids: // value for 'ids'
 *      limit: // value for 'limit'
 *      notIds: // value for 'notIds'
 *      order: // value for 'order'
 *      role: // value for 'role'
 *      search: // value for 'search'
 *      showArchived: // value for 'showArchived'
 *   },
 * });
 */
export function usePeopleQuery(
  baseOptions?: Apollo.QueryHookOptions<PeopleQuery, PeopleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PeopleQuery, PeopleQueryVariables>(
    PeopleDocument,
    options,
  )
}
export function usePeopleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PeopleQuery, PeopleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PeopleQuery, PeopleQueryVariables>(
    PeopleDocument,
    options,
  )
}
export type PeopleQueryHookResult = ReturnType<typeof usePeopleQuery>
export type PeopleLazyQueryHookResult = ReturnType<typeof usePeopleLazyQuery>
export type PeopleQueryResult = Apollo.QueryResult<
  PeopleQuery,
  PeopleQueryVariables
>
export const PeopleStatisticsDocument = gql`
    query peopleStatistics($filter: PeopleFilter, $showArchived: Boolean) {
  peopleStatistics(filter: $filter, showArchived: $showArchived) {
    canNotifyCount
    canNotifyNonCompliantCount
    canNotifyNonCompliantManagersCount
    nonCompliantCount
    nonCompliantManagersCount
    totalCount
    totalManagersCount
  }
}
    `

/**
 * __usePeopleStatisticsQuery__
 *
 * To run a query within a React component, call `usePeopleStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeopleStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeopleStatisticsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      showArchived: // value for 'showArchived'
 *   },
 * });
 */
export function usePeopleStatisticsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PeopleStatisticsQuery,
    PeopleStatisticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PeopleStatisticsQuery, PeopleStatisticsQueryVariables>(
    PeopleStatisticsDocument,
    options,
  )
}
export function usePeopleStatisticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PeopleStatisticsQuery,
    PeopleStatisticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    PeopleStatisticsQuery,
    PeopleStatisticsQueryVariables
  >(PeopleStatisticsDocument, options)
}
export type PeopleStatisticsQueryHookResult = ReturnType<
  typeof usePeopleStatisticsQuery
>
export type PeopleStatisticsLazyQueryHookResult = ReturnType<
  typeof usePeopleStatisticsLazyQuery
>
export type PeopleStatisticsQueryResult = Apollo.QueryResult<
  PeopleStatisticsQuery,
  PeopleStatisticsQueryVariables
>
export const SessionDocument = gql`
    query session {
  session {
    ...session
  }
}
    ${SessionFragmentDoc}`

/**
 * __useSessionQuery__
 *
 * To run a query within a React component, call `useSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useSessionQuery(
  baseOptions?: Apollo.QueryHookOptions<SessionQuery, SessionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SessionQuery, SessionQueryVariables>(
    SessionDocument,
    options,
  )
}
export function useSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SessionQuery,
    SessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SessionQuery, SessionQueryVariables>(
    SessionDocument,
    options,
  )
}
export type SessionQueryHookResult = ReturnType<typeof useSessionQuery>
export type SessionLazyQueryHookResult = ReturnType<typeof useSessionLazyQuery>
export type SessionQueryResult = Apollo.QueryResult<
  SessionQuery,
  SessionQueryVariables
>
export const ChargebeeSubscriptionDetailsDocument = gql`
    query chargebeeSubscriptionDetails {
  chargebeeSubscriptionDetails {
    ...chargebeeSubscriptionDetails
  }
}
    ${ChargebeeSubscriptionDetailsFragmentDoc}`

/**
 * __useChargebeeSubscriptionDetailsQuery__
 *
 * To run a query within a React component, call `useChargebeeSubscriptionDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChargebeeSubscriptionDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChargebeeSubscriptionDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useChargebeeSubscriptionDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ChargebeeSubscriptionDetailsQuery,
    ChargebeeSubscriptionDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ChargebeeSubscriptionDetailsQuery,
    ChargebeeSubscriptionDetailsQueryVariables
  >(ChargebeeSubscriptionDetailsDocument, options)
}
export function useChargebeeSubscriptionDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChargebeeSubscriptionDetailsQuery,
    ChargebeeSubscriptionDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ChargebeeSubscriptionDetailsQuery,
    ChargebeeSubscriptionDetailsQueryVariables
  >(ChargebeeSubscriptionDetailsDocument, options)
}
export type ChargebeeSubscriptionDetailsQueryHookResult = ReturnType<
  typeof useChargebeeSubscriptionDetailsQuery
>
export type ChargebeeSubscriptionDetailsLazyQueryHookResult = ReturnType<
  typeof useChargebeeSubscriptionDetailsLazyQuery
>
export type ChargebeeSubscriptionDetailsQueryResult = Apollo.QueryResult<
  ChargebeeSubscriptionDetailsQuery,
  ChargebeeSubscriptionDetailsQueryVariables
>
export const ChargebeeSubscriptionDocument = gql`
    query chargebeeSubscription {
  chargebeeSubscription {
    ...chargebeeSubscription
  }
}
    ${ChargebeeSubscriptionFragmentDoc}`

/**
 * __useChargebeeSubscriptionQuery__
 *
 * To run a query within a React component, call `useChargebeeSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useChargebeeSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChargebeeSubscriptionQuery({
 *   variables: {
 *   },
 * });
 */
export function useChargebeeSubscriptionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ChargebeeSubscriptionQuery,
    ChargebeeSubscriptionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ChargebeeSubscriptionQuery,
    ChargebeeSubscriptionQueryVariables
  >(ChargebeeSubscriptionDocument, options)
}
export function useChargebeeSubscriptionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChargebeeSubscriptionQuery,
    ChargebeeSubscriptionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ChargebeeSubscriptionQuery,
    ChargebeeSubscriptionQueryVariables
  >(ChargebeeSubscriptionDocument, options)
}
export type ChargebeeSubscriptionQueryHookResult = ReturnType<
  typeof useChargebeeSubscriptionQuery
>
export type ChargebeeSubscriptionLazyQueryHookResult = ReturnType<
  typeof useChargebeeSubscriptionLazyQuery
>
export type ChargebeeSubscriptionQueryResult = Apollo.QueryResult<
  ChargebeeSubscriptionQuery,
  ChargebeeSubscriptionQueryVariables
>
export const ChargebeePortalSessionDocument = gql`
    query chargebeePortalSession($customerId: String!) {
  chargebeePortalSession(customerId: $customerId) {
    ...chargebeePortalSession
  }
}
    ${ChargebeePortalSessionFragmentDoc}`

/**
 * __useChargebeePortalSessionQuery__
 *
 * To run a query within a React component, call `useChargebeePortalSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useChargebeePortalSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChargebeePortalSessionQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useChargebeePortalSessionQuery(
  baseOptions: Apollo.QueryHookOptions<
    ChargebeePortalSessionQuery,
    ChargebeePortalSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ChargebeePortalSessionQuery,
    ChargebeePortalSessionQueryVariables
  >(ChargebeePortalSessionDocument, options)
}
export function useChargebeePortalSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ChargebeePortalSessionQuery,
    ChargebeePortalSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ChargebeePortalSessionQuery,
    ChargebeePortalSessionQueryVariables
  >(ChargebeePortalSessionDocument, options)
}
export type ChargebeePortalSessionQueryHookResult = ReturnType<
  typeof useChargebeePortalSessionQuery
>
export type ChargebeePortalSessionLazyQueryHookResult = ReturnType<
  typeof useChargebeePortalSessionLazyQuery
>
export type ChargebeePortalSessionQueryResult = Apollo.QueryResult<
  ChargebeePortalSessionQuery,
  ChargebeePortalSessionQueryVariables
>
export const GroupNamesDocument = gql`
    query groupNames($source: PeopleSource) {
  groupNames(source: $source)
}
    `

/**
 * __useGroupNamesQuery__
 *
 * To run a query within a React component, call `useGroupNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupNamesQuery({
 *   variables: {
 *      source: // value for 'source'
 *   },
 * });
 */
export function useGroupNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GroupNamesQuery,
    GroupNamesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GroupNamesQuery, GroupNamesQueryVariables>(
    GroupNamesDocument,
    options,
  )
}
export function useGroupNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupNamesQuery,
    GroupNamesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GroupNamesQuery, GroupNamesQueryVariables>(
    GroupNamesDocument,
    options,
  )
}
export type GroupNamesQueryHookResult = ReturnType<typeof useGroupNamesQuery>
export type GroupNamesLazyQueryHookResult = ReturnType<
  typeof useGroupNamesLazyQuery
>
export type GroupNamesQueryResult = Apollo.QueryResult<
  GroupNamesQuery,
  GroupNamesQueryVariables
>
export const MetadataKeysDocument = gql`
    query metadataKeys($source: PeopleSource) {
  metadataKeys(source: $source)
}
    `

/**
 * __useMetadataKeysQuery__
 *
 * To run a query within a React component, call `useMetadataKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetadataKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetadataKeysQuery({
 *   variables: {
 *      source: // value for 'source'
 *   },
 * });
 */
export function useMetadataKeysQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MetadataKeysQuery,
    MetadataKeysQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MetadataKeysQuery, MetadataKeysQueryVariables>(
    MetadataKeysDocument,
    options,
  )
}
export function useMetadataKeysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MetadataKeysQuery,
    MetadataKeysQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MetadataKeysQuery, MetadataKeysQueryVariables>(
    MetadataKeysDocument,
    options,
  )
}
export type MetadataKeysQueryHookResult = ReturnType<
  typeof useMetadataKeysQuery
>
export type MetadataKeysLazyQueryHookResult = ReturnType<
  typeof useMetadataKeysLazyQuery
>
export type MetadataKeysQueryResult = Apollo.QueryResult<
  MetadataKeysQuery,
  MetadataKeysQueryVariables
>
export const MetadataValuesDocument = gql`
    query metadataValues($metadataKey: String!, $source: PeopleSource) {
  metadataValues(metadataKey: $metadataKey, source: $source)
}
    `

/**
 * __useMetadataValuesQuery__
 *
 * To run a query within a React component, call `useMetadataValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMetadataValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMetadataValuesQuery({
 *   variables: {
 *      metadataKey: // value for 'metadataKey'
 *      source: // value for 'source'
 *   },
 * });
 */
export function useMetadataValuesQuery(
  baseOptions: Apollo.QueryHookOptions<
    MetadataValuesQuery,
    MetadataValuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MetadataValuesQuery, MetadataValuesQueryVariables>(
    MetadataValuesDocument,
    options,
  )
}
export function useMetadataValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MetadataValuesQuery,
    MetadataValuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MetadataValuesQuery, MetadataValuesQueryVariables>(
    MetadataValuesDocument,
    options,
  )
}
export type MetadataValuesQueryHookResult = ReturnType<
  typeof useMetadataValuesQuery
>
export type MetadataValuesLazyQueryHookResult = ReturnType<
  typeof useMetadataValuesLazyQuery
>
export type MetadataValuesQueryResult = Apollo.QueryResult<
  MetadataValuesQuery,
  MetadataValuesQueryVariables
>
export const OrgCollaboratorsDocument = gql`
    query orgCollaborators {
  orgCollaborators: people(isOrgCollaborator: true) {
    ...person
  }
}
    ${PersonFragmentDoc}`

/**
 * __useOrgCollaboratorsQuery__
 *
 * To run a query within a React component, call `useOrgCollaboratorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgCollaboratorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgCollaboratorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrgCollaboratorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrgCollaboratorsQuery,
    OrgCollaboratorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<OrgCollaboratorsQuery, OrgCollaboratorsQueryVariables>(
    OrgCollaboratorsDocument,
    options,
  )
}
export function useOrgCollaboratorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrgCollaboratorsQuery,
    OrgCollaboratorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    OrgCollaboratorsQuery,
    OrgCollaboratorsQueryVariables
  >(OrgCollaboratorsDocument, options)
}
export type OrgCollaboratorsQueryHookResult = ReturnType<
  typeof useOrgCollaboratorsQuery
>
export type OrgCollaboratorsLazyQueryHookResult = ReturnType<
  typeof useOrgCollaboratorsLazyQuery
>
export type OrgCollaboratorsQueryResult = Apollo.QueryResult<
  OrgCollaboratorsQuery,
  OrgCollaboratorsQueryVariables
>
export const TeamsDocument = gql`
    query teams {
  teams {
    ...team
  }
}
    ${TeamFragmentDoc}`

/**
 * __useTeamsQuery__
 *
 * To run a query within a React component, call `useTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeamsQuery(
  baseOptions?: Apollo.QueryHookOptions<TeamsQuery, TeamsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TeamsQuery, TeamsQueryVariables>(
    TeamsDocument,
    options,
  )
}
export function useTeamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TeamsQuery, TeamsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TeamsQuery, TeamsQueryVariables>(
    TeamsDocument,
    options,
  )
}
export type TeamsQueryHookResult = ReturnType<typeof useTeamsQuery>
export type TeamsLazyQueryHookResult = ReturnType<typeof useTeamsLazyQuery>
export type TeamsQueryResult = Apollo.QueryResult<
  TeamsQuery,
  TeamsQueryVariables
>
export const DocumentSettingsDocument = gql`
    query documentSettings {
  documentSettings {
    ...documentSettings
  }
}
    ${DocumentSettingsFragmentDoc}`

/**
 * __useDocumentSettingsQuery__
 *
 * To run a query within a React component, call `useDocumentSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDocumentSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DocumentSettingsQuery,
    DocumentSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DocumentSettingsQuery, DocumentSettingsQueryVariables>(
    DocumentSettingsDocument,
    options,
  )
}
export function useDocumentSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DocumentSettingsQuery,
    DocumentSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    DocumentSettingsQuery,
    DocumentSettingsQueryVariables
  >(DocumentSettingsDocument, options)
}
export type DocumentSettingsQueryHookResult = ReturnType<
  typeof useDocumentSettingsQuery
>
export type DocumentSettingsLazyQueryHookResult = ReturnType<
  typeof useDocumentSettingsLazyQuery
>
export type DocumentSettingsQueryResult = Apollo.QueryResult<
  DocumentSettingsQuery,
  DocumentSettingsQueryVariables
>
export const DocumentsDocument = gql`
    query documents($after: String, $filter: DocumentsFilter, $limit: Int, $order: DocumentsSortOrder) {
  documents(after: $after, filter: $filter, limit: $limit, order: $order) {
    ...document
    sortKey
  }
  folders {
    ...folder
  }
}
    ${DocumentFragmentDoc}
${FolderFragmentDoc}`

/**
 * __useDocumentsQuery__
 *
 * To run a query within a React component, call `useDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useDocumentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DocumentsQuery,
    DocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DocumentsQuery, DocumentsQueryVariables>(
    DocumentsDocument,
    options,
  )
}
export function useDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DocumentsQuery,
    DocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<DocumentsQuery, DocumentsQueryVariables>(
    DocumentsDocument,
    options,
  )
}
export type DocumentsQueryHookResult = ReturnType<typeof useDocumentsQuery>
export type DocumentsLazyQueryHookResult = ReturnType<
  typeof useDocumentsLazyQuery
>
export type DocumentsQueryResult = Apollo.QueryResult<
  DocumentsQuery,
  DocumentsQueryVariables
>
export const DocumentDocument = gql`
    query document($documentId: Int!) {
  document(documentId: $documentId) {
    ...document
    publishedVersions {
      ...documentVersion
    }
  }
  documentSettings {
    ...documentSettings
  }
  documents {
    id
    name
  }
}
    ${DocumentFragmentDoc}
${DocumentVersionFragmentDoc}
${DocumentSettingsFragmentDoc}`

/**
 * __useDocumentQuery__
 *
 * To run a query within a React component, call `useDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useDocumentQuery(
  baseOptions: Apollo.QueryHookOptions<DocumentQuery, DocumentQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DocumentQuery, DocumentQueryVariables>(
    DocumentDocument,
    options,
  )
}
export function useDocumentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DocumentQuery,
    DocumentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<DocumentQuery, DocumentQueryVariables>(
    DocumentDocument,
    options,
  )
}
export type DocumentQueryHookResult = ReturnType<typeof useDocumentQuery>
export type DocumentLazyQueryHookResult = ReturnType<
  typeof useDocumentLazyQuery
>
export type DocumentQueryResult = Apollo.QueryResult<
  DocumentQuery,
  DocumentQueryVariables
>
export const SetDocumentReviewersDocument = gql`
    mutation setDocumentReviewers($documentId: Int!, $personIds: [Int!]!) {
  reviewers: setDocumentReviewers(documentId: $documentId, personIds: $personIds) {
    ...documentReviewer
  }
}
    ${DocumentReviewerFragmentDoc}`
export type SetDocumentReviewersMutationFn = Apollo.MutationFunction<
  SetDocumentReviewersMutation,
  SetDocumentReviewersMutationVariables
>

/**
 * __useSetDocumentReviewersMutation__
 *
 * To run a mutation, you first call `useSetDocumentReviewersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDocumentReviewersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDocumentReviewersMutation, { data, loading, error }] = useSetDocumentReviewersMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      personIds: // value for 'personIds'
 *   },
 * });
 */
export function useSetDocumentReviewersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetDocumentReviewersMutation,
    SetDocumentReviewersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SetDocumentReviewersMutation,
    SetDocumentReviewersMutationVariables
  >(SetDocumentReviewersDocument, options)
}
export type SetDocumentReviewersMutationHookResult = ReturnType<
  typeof useSetDocumentReviewersMutation
>
export type SetDocumentReviewersMutationResult =
  Apollo.MutationResult<SetDocumentReviewersMutation>
export type SetDocumentReviewersMutationOptions = Apollo.BaseMutationOptions<
  SetDocumentReviewersMutation,
  SetDocumentReviewersMutationVariables
>
export const AddDocumentReviewDocument = gql`
    mutation addDocumentReview($documentId: Int!, $isApproved: Boolean!) {
  addDocumentReview(documentId: $documentId, isApproved: $isApproved) {
    ...documentReview
  }
}
    ${DocumentReviewFragmentDoc}`
export type AddDocumentReviewMutationFn = Apollo.MutationFunction<
  AddDocumentReviewMutation,
  AddDocumentReviewMutationVariables
>

/**
 * __useAddDocumentReviewMutation__
 *
 * To run a mutation, you first call `useAddDocumentReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDocumentReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDocumentReviewMutation, { data, loading, error }] = useAddDocumentReviewMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      isApproved: // value for 'isApproved'
 *   },
 * });
 */
export function useAddDocumentReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddDocumentReviewMutation,
    AddDocumentReviewMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddDocumentReviewMutation,
    AddDocumentReviewMutationVariables
  >(AddDocumentReviewDocument, options)
}
export type AddDocumentReviewMutationHookResult = ReturnType<
  typeof useAddDocumentReviewMutation
>
export type AddDocumentReviewMutationResult =
  Apollo.MutationResult<AddDocumentReviewMutation>
export type AddDocumentReviewMutationOptions = Apollo.BaseMutationOptions<
  AddDocumentReviewMutation,
  AddDocumentReviewMutationVariables
>
export const CreateFolderDocument = gql`
    mutation createFolder($input: CreateFolderInput!) {
  folder: createFolder(input: $input) {
    ...folder
  }
}
    ${FolderFragmentDoc}`
export type CreateFolderMutationFn = Apollo.MutationFunction<
  CreateFolderMutation,
  CreateFolderMutationVariables
>

/**
 * __useCreateFolderMutation__
 *
 * To run a mutation, you first call `useCreateFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFolderMutation, { data, loading, error }] = useCreateFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFolderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFolderMutation,
    CreateFolderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateFolderMutation,
    CreateFolderMutationVariables
  >(CreateFolderDocument, options)
}
export type CreateFolderMutationHookResult = ReturnType<
  typeof useCreateFolderMutation
>
export type CreateFolderMutationResult =
  Apollo.MutationResult<CreateFolderMutation>
export type CreateFolderMutationOptions = Apollo.BaseMutationOptions<
  CreateFolderMutation,
  CreateFolderMutationVariables
>
export const UpdateFolderDocument = gql`
    mutation updateFolder($folderId: Int!, $input: UpdateFolderInput!) {
  folder: updateFolder(folderId: $folderId, input: $input) {
    ...folder
  }
}
    ${FolderFragmentDoc}`
export type UpdateFolderMutationFn = Apollo.MutationFunction<
  UpdateFolderMutation,
  UpdateFolderMutationVariables
>

/**
 * __useUpdateFolderMutation__
 *
 * To run a mutation, you first call `useUpdateFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFolderMutation, { data, loading, error }] = useUpdateFolderMutation({
 *   variables: {
 *      folderId: // value for 'folderId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFolderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFolderMutation,
    UpdateFolderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateFolderMutation,
    UpdateFolderMutationVariables
  >(UpdateFolderDocument, options)
}
export type UpdateFolderMutationHookResult = ReturnType<
  typeof useUpdateFolderMutation
>
export type UpdateFolderMutationResult =
  Apollo.MutationResult<UpdateFolderMutation>
export type UpdateFolderMutationOptions = Apollo.BaseMutationOptions<
  UpdateFolderMutation,
  UpdateFolderMutationVariables
>
export const DeleteFolderDocument = gql`
    mutation deleteFolder($folderId: Int!) {
  deleteFolder(folderId: $folderId)
}
    `
export type DeleteFolderMutationFn = Apollo.MutationFunction<
  DeleteFolderMutation,
  DeleteFolderMutationVariables
>

/**
 * __useDeleteFolderMutation__
 *
 * To run a mutation, you first call `useDeleteFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFolderMutation, { data, loading, error }] = useDeleteFolderMutation({
 *   variables: {
 *      folderId: // value for 'folderId'
 *   },
 * });
 */
export function useDeleteFolderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteFolderMutation,
    DeleteFolderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteFolderMutation,
    DeleteFolderMutationVariables
  >(DeleteFolderDocument, options)
}
export type DeleteFolderMutationHookResult = ReturnType<
  typeof useDeleteFolderMutation
>
export type DeleteFolderMutationResult =
  Apollo.MutationResult<DeleteFolderMutation>
export type DeleteFolderMutationOptions = Apollo.BaseMutationOptions<
  DeleteFolderMutation,
  DeleteFolderMutationVariables
>
export const LinkChargebeeSubscriptionDocument = gql`
    mutation linkChargebeeSubscription($subscriptionId: String!) {
  chargebeeSubscription: linkChargebeeSubscription(
    subscriptionId: $subscriptionId
  ) {
    ...chargebeeSubscription
  }
}
    ${ChargebeeSubscriptionFragmentDoc}`
export type LinkChargebeeSubscriptionMutationFn = Apollo.MutationFunction<
  LinkChargebeeSubscriptionMutation,
  LinkChargebeeSubscriptionMutationVariables
>

/**
 * __useLinkChargebeeSubscriptionMutation__
 *
 * To run a mutation, you first call `useLinkChargebeeSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkChargebeeSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkChargebeeSubscriptionMutation, { data, loading, error }] = useLinkChargebeeSubscriptionMutation({
 *   variables: {
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useLinkChargebeeSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LinkChargebeeSubscriptionMutation,
    LinkChargebeeSubscriptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    LinkChargebeeSubscriptionMutation,
    LinkChargebeeSubscriptionMutationVariables
  >(LinkChargebeeSubscriptionDocument, options)
}
export type LinkChargebeeSubscriptionMutationHookResult = ReturnType<
  typeof useLinkChargebeeSubscriptionMutation
>
export type LinkChargebeeSubscriptionMutationResult =
  Apollo.MutationResult<LinkChargebeeSubscriptionMutation>
export type LinkChargebeeSubscriptionMutationOptions =
  Apollo.BaseMutationOptions<
    LinkChargebeeSubscriptionMutation,
    LinkChargebeeSubscriptionMutationVariables
  >
export const UnlinkChargebeeSubscriptionDocument = gql`
    mutation unlinkChargebeeSubscription {
  unlinked: unlinkChargebeeSubscription
}
    `
export type UnlinkChargebeeSubscriptionMutationFn = Apollo.MutationFunction<
  UnlinkChargebeeSubscriptionMutation,
  UnlinkChargebeeSubscriptionMutationVariables
>

/**
 * __useUnlinkChargebeeSubscriptionMutation__
 *
 * To run a mutation, you first call `useUnlinkChargebeeSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkChargebeeSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkChargebeeSubscriptionMutation, { data, loading, error }] = useUnlinkChargebeeSubscriptionMutation({
 *   variables: {
 *   },
 * });
 */
export function useUnlinkChargebeeSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnlinkChargebeeSubscriptionMutation,
    UnlinkChargebeeSubscriptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UnlinkChargebeeSubscriptionMutation,
    UnlinkChargebeeSubscriptionMutationVariables
  >(UnlinkChargebeeSubscriptionDocument, options)
}
export type UnlinkChargebeeSubscriptionMutationHookResult = ReturnType<
  typeof useUnlinkChargebeeSubscriptionMutation
>
export type UnlinkChargebeeSubscriptionMutationResult =
  Apollo.MutationResult<UnlinkChargebeeSubscriptionMutation>
export type UnlinkChargebeeSubscriptionMutationOptions =
  Apollo.BaseMutationOptions<
    UnlinkChargebeeSubscriptionMutation,
    UnlinkChargebeeSubscriptionMutationVariables
  >
export const AddAdminDocument = gql`
    mutation addAdmin($adminRoleId: AdminRoleId!, $notification: OrgCollaboratorNotificationInput, $personId: Int!) {
  addAdmin(
    adminRoleId: $adminRoleId
    notification: $notification
    personId: $personId
  ) {
    ...person
  }
}
    ${PersonFragmentDoc}`
export type AddAdminMutationFn = Apollo.MutationFunction<
  AddAdminMutation,
  AddAdminMutationVariables
>

/**
 * __useAddAdminMutation__
 *
 * To run a mutation, you first call `useAddAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAdminMutation, { data, loading, error }] = useAddAdminMutation({
 *   variables: {
 *      adminRoleId: // value for 'adminRoleId'
 *      notification: // value for 'notification'
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useAddAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddAdminMutation,
    AddAdminMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddAdminMutation, AddAdminMutationVariables>(
    AddAdminDocument,
    options,
  )
}
export type AddAdminMutationHookResult = ReturnType<typeof useAddAdminMutation>
export type AddAdminMutationResult = Apollo.MutationResult<AddAdminMutation>
export type AddAdminMutationOptions = Apollo.BaseMutationOptions<
  AddAdminMutation,
  AddAdminMutationVariables
>
export const AddNewAdminDocument = gql`
    mutation addNewAdmin($adminRoleId: AdminRoleId!, $notification: OrgCollaboratorNotificationInput, $personInput: NewOrgCollaboratorInput!) {
  addNewAdmin(
    adminRoleId: $adminRoleId
    notification: $notification
    personInput: $personInput
  ) {
    ...person
  }
}
    ${PersonFragmentDoc}`
export type AddNewAdminMutationFn = Apollo.MutationFunction<
  AddNewAdminMutation,
  AddNewAdminMutationVariables
>

/**
 * __useAddNewAdminMutation__
 *
 * To run a mutation, you first call `useAddNewAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNewAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNewAdminMutation, { data, loading, error }] = useAddNewAdminMutation({
 *   variables: {
 *      adminRoleId: // value for 'adminRoleId'
 *      notification: // value for 'notification'
 *      personInput: // value for 'personInput'
 *   },
 * });
 */
export function useAddNewAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddNewAdminMutation,
    AddNewAdminMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddNewAdminMutation, AddNewAdminMutationVariables>(
    AddNewAdminDocument,
    options,
  )
}
export type AddNewAdminMutationHookResult = ReturnType<
  typeof useAddNewAdminMutation
>
export type AddNewAdminMutationResult =
  Apollo.MutationResult<AddNewAdminMutation>
export type AddNewAdminMutationOptions = Apollo.BaseMutationOptions<
  AddNewAdminMutation,
  AddNewAdminMutationVariables
>
export const InviteOrgCollaboratorDocument = gql`
    mutation inviteOrgCollaborator($personId: Int!) {
  inviteOrgCollaborator(personId: $personId) {
    ...person
  }
}
    ${PersonFragmentDoc}`
export type InviteOrgCollaboratorMutationFn = Apollo.MutationFunction<
  InviteOrgCollaboratorMutation,
  InviteOrgCollaboratorMutationVariables
>

/**
 * __useInviteOrgCollaboratorMutation__
 *
 * To run a mutation, you first call `useInviteOrgCollaboratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteOrgCollaboratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteOrgCollaboratorMutation, { data, loading, error }] = useInviteOrgCollaboratorMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useInviteOrgCollaboratorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteOrgCollaboratorMutation,
    InviteOrgCollaboratorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InviteOrgCollaboratorMutation,
    InviteOrgCollaboratorMutationVariables
  >(InviteOrgCollaboratorDocument, options)
}
export type InviteOrgCollaboratorMutationHookResult = ReturnType<
  typeof useInviteOrgCollaboratorMutation
>
export type InviteOrgCollaboratorMutationResult =
  Apollo.MutationResult<InviteOrgCollaboratorMutation>
export type InviteOrgCollaboratorMutationOptions = Apollo.BaseMutationOptions<
  InviteOrgCollaboratorMutation,
  InviteOrgCollaboratorMutationVariables
>
export const UpdateAdminDocument = gql`
    mutation updateAdmin($personId: Int!, $adminRoleId: AdminRoleId!) {
  updateAdmin(personId: $personId, adminRoleId: $adminRoleId) {
    ...person
  }
}
    ${PersonFragmentDoc}`
export type UpdateAdminMutationFn = Apollo.MutationFunction<
  UpdateAdminMutation,
  UpdateAdminMutationVariables
>

/**
 * __useUpdateAdminMutation__
 *
 * To run a mutation, you first call `useUpdateAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminMutation, { data, loading, error }] = useUpdateAdminMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      adminRoleId: // value for 'adminRoleId'
 *   },
 * });
 */
export function useUpdateAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAdminMutation,
    UpdateAdminMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateAdminMutation, UpdateAdminMutationVariables>(
    UpdateAdminDocument,
    options,
  )
}
export type UpdateAdminMutationHookResult = ReturnType<
  typeof useUpdateAdminMutation
>
export type UpdateAdminMutationResult =
  Apollo.MutationResult<UpdateAdminMutation>
export type UpdateAdminMutationOptions = Apollo.BaseMutationOptions<
  UpdateAdminMutation,
  UpdateAdminMutationVariables
>
export const RemoveAdminDocument = gql`
    mutation removeAdmin($personId: Int!) {
  removeAdmin(personId: $personId) {
    ...person
  }
}
    ${PersonFragmentDoc}`
export type RemoveAdminMutationFn = Apollo.MutationFunction<
  RemoveAdminMutation,
  RemoveAdminMutationVariables
>

/**
 * __useRemoveAdminMutation__
 *
 * To run a mutation, you first call `useRemoveAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAdminMutation, { data, loading, error }] = useRemoveAdminMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useRemoveAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveAdminMutation,
    RemoveAdminMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RemoveAdminMutation, RemoveAdminMutationVariables>(
    RemoveAdminDocument,
    options,
  )
}
export type RemoveAdminMutationHookResult = ReturnType<
  typeof useRemoveAdminMutation
>
export type RemoveAdminMutationResult =
  Apollo.MutationResult<RemoveAdminMutation>
export type RemoveAdminMutationOptions = Apollo.BaseMutationOptions<
  RemoveAdminMutation,
  RemoveAdminMutationVariables
>
export const AddTeammateDocument = gql`
    mutation addTeammate($notification: OrgCollaboratorNotificationInput, $personId: Int!, $teamId: Int!, $teammateInput: TeammateInput!) {
  addTeammate(
    notification: $notification
    personId: $personId
    teamId: $teamId
    teammateInput: $teammateInput
  ) {
    ...teammate
  }
}
    ${TeammateFragmentDoc}`
export type AddTeammateMutationFn = Apollo.MutationFunction<
  AddTeammateMutation,
  AddTeammateMutationVariables
>

/**
 * __useAddTeammateMutation__
 *
 * To run a mutation, you first call `useAddTeammateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTeammateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTeammateMutation, { data, loading, error }] = useAddTeammateMutation({
 *   variables: {
 *      notification: // value for 'notification'
 *      personId: // value for 'personId'
 *      teamId: // value for 'teamId'
 *      teammateInput: // value for 'teammateInput'
 *   },
 * });
 */
export function useAddTeammateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddTeammateMutation,
    AddTeammateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddTeammateMutation, AddTeammateMutationVariables>(
    AddTeammateDocument,
    options,
  )
}
export type AddTeammateMutationHookResult = ReturnType<
  typeof useAddTeammateMutation
>
export type AddTeammateMutationResult =
  Apollo.MutationResult<AddTeammateMutation>
export type AddTeammateMutationOptions = Apollo.BaseMutationOptions<
  AddTeammateMutation,
  AddTeammateMutationVariables
>
export const AddNewTeammateDocument = gql`
    mutation addNewTeammate($notification: OrgCollaboratorNotificationInput, $personInput: NewOrgCollaboratorInput!, $teamId: Int!, $teammateInput: TeammateInput!) {
  addNewTeammate(
    notification: $notification
    personInput: $personInput
    teamId: $teamId
    teammateInput: $teammateInput
  ) {
    ...teammate
  }
}
    ${TeammateFragmentDoc}`
export type AddNewTeammateMutationFn = Apollo.MutationFunction<
  AddNewTeammateMutation,
  AddNewTeammateMutationVariables
>

/**
 * __useAddNewTeammateMutation__
 *
 * To run a mutation, you first call `useAddNewTeammateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNewTeammateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNewTeammateMutation, { data, loading, error }] = useAddNewTeammateMutation({
 *   variables: {
 *      notification: // value for 'notification'
 *      personInput: // value for 'personInput'
 *      teamId: // value for 'teamId'
 *      teammateInput: // value for 'teammateInput'
 *   },
 * });
 */
export function useAddNewTeammateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddNewTeammateMutation,
    AddNewTeammateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddNewTeammateMutation,
    AddNewTeammateMutationVariables
  >(AddNewTeammateDocument, options)
}
export type AddNewTeammateMutationHookResult = ReturnType<
  typeof useAddNewTeammateMutation
>
export type AddNewTeammateMutationResult =
  Apollo.MutationResult<AddNewTeammateMutation>
export type AddNewTeammateMutationOptions = Apollo.BaseMutationOptions<
  AddNewTeammateMutation,
  AddNewTeammateMutationVariables
>
export const RemoveTeammateDocument = gql`
    mutation removeTeammate($teamId: Int!, $personId: Int!) {
  removeTeammate(teamId: $teamId, personId: $personId)
}
    `
export type RemoveTeammateMutationFn = Apollo.MutationFunction<
  RemoveTeammateMutation,
  RemoveTeammateMutationVariables
>

/**
 * __useRemoveTeammateMutation__
 *
 * To run a mutation, you first call `useRemoveTeammateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTeammateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTeammateMutation, { data, loading, error }] = useRemoveTeammateMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useRemoveTeammateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveTeammateMutation,
    RemoveTeammateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemoveTeammateMutation,
    RemoveTeammateMutationVariables
  >(RemoveTeammateDocument, options)
}
export type RemoveTeammateMutationHookResult = ReturnType<
  typeof useRemoveTeammateMutation
>
export type RemoveTeammateMutationResult =
  Apollo.MutationResult<RemoveTeammateMutation>
export type RemoveTeammateMutationOptions = Apollo.BaseMutationOptions<
  RemoveTeammateMutation,
  RemoveTeammateMutationVariables
>
export const UpdateTeammateDocument = gql`
    mutation updateTeammate($teamId: Int!, $personId: Int!, $teammateInput: TeammateInput!) {
  updateTeammate(
    teamId: $teamId
    personId: $personId
    teammateInput: $teammateInput
  ) {
    ...teammate
  }
}
    ${TeammateFragmentDoc}`
export type UpdateTeammateMutationFn = Apollo.MutationFunction<
  UpdateTeammateMutation,
  UpdateTeammateMutationVariables
>

/**
 * __useUpdateTeammateMutation__
 *
 * To run a mutation, you first call `useUpdateTeammateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeammateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeammateMutation, { data, loading, error }] = useUpdateTeammateMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      personId: // value for 'personId'
 *      teammateInput: // value for 'teammateInput'
 *   },
 * });
 */
export function useUpdateTeammateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTeammateMutation,
    UpdateTeammateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateTeammateMutation,
    UpdateTeammateMutationVariables
  >(UpdateTeammateDocument, options)
}
export type UpdateTeammateMutationHookResult = ReturnType<
  typeof useUpdateTeammateMutation
>
export type UpdateTeammateMutationResult =
  Apollo.MutationResult<UpdateTeammateMutation>
export type UpdateTeammateMutationOptions = Apollo.BaseMutationOptions<
  UpdateTeammateMutation,
  UpdateTeammateMutationVariables
>
export const AddHandbookCollaboratorDocument = gql`
    mutation addHandbookCollaborator($handbookCollaboratorInput: HandbookCollaboratorInput!, $handbookId: Int!, $notification: OrgCollaboratorNotificationInput, $personId: Int!) {
  addHandbookCollaborator(
    handbookCollaboratorInput: $handbookCollaboratorInput
    handbookId: $handbookId
    notification: $notification
    personId: $personId
  ) {
    ...handbookCollaborator
  }
}
    ${HandbookCollaboratorFragmentDoc}`
export type AddHandbookCollaboratorMutationFn = Apollo.MutationFunction<
  AddHandbookCollaboratorMutation,
  AddHandbookCollaboratorMutationVariables
>

/**
 * __useAddHandbookCollaboratorMutation__
 *
 * To run a mutation, you first call `useAddHandbookCollaboratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddHandbookCollaboratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addHandbookCollaboratorMutation, { data, loading, error }] = useAddHandbookCollaboratorMutation({
 *   variables: {
 *      handbookCollaboratorInput: // value for 'handbookCollaboratorInput'
 *      handbookId: // value for 'handbookId'
 *      notification: // value for 'notification'
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useAddHandbookCollaboratorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddHandbookCollaboratorMutation,
    AddHandbookCollaboratorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddHandbookCollaboratorMutation,
    AddHandbookCollaboratorMutationVariables
  >(AddHandbookCollaboratorDocument, options)
}
export type AddHandbookCollaboratorMutationHookResult = ReturnType<
  typeof useAddHandbookCollaboratorMutation
>
export type AddHandbookCollaboratorMutationResult =
  Apollo.MutationResult<AddHandbookCollaboratorMutation>
export type AddHandbookCollaboratorMutationOptions = Apollo.BaseMutationOptions<
  AddHandbookCollaboratorMutation,
  AddHandbookCollaboratorMutationVariables
>
export const AddNewHandbookCollaboratorDocument = gql`
    mutation addNewHandbookCollaborator($handbookCollaboratorInput: HandbookCollaboratorInput!, $handbookId: Int!, $notification: OrgCollaboratorNotificationInput, $personInput: NewOrgCollaboratorInput!) {
  addNewHandbookCollaborator(
    handbookCollaboratorInput: $handbookCollaboratorInput
    handbookId: $handbookId
    notification: $notification
    personInput: $personInput
  ) {
    ...handbookCollaborator
  }
}
    ${HandbookCollaboratorFragmentDoc}`
export type AddNewHandbookCollaboratorMutationFn = Apollo.MutationFunction<
  AddNewHandbookCollaboratorMutation,
  AddNewHandbookCollaboratorMutationVariables
>

/**
 * __useAddNewHandbookCollaboratorMutation__
 *
 * To run a mutation, you first call `useAddNewHandbookCollaboratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNewHandbookCollaboratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNewHandbookCollaboratorMutation, { data, loading, error }] = useAddNewHandbookCollaboratorMutation({
 *   variables: {
 *      handbookCollaboratorInput: // value for 'handbookCollaboratorInput'
 *      handbookId: // value for 'handbookId'
 *      notification: // value for 'notification'
 *      personInput: // value for 'personInput'
 *   },
 * });
 */
export function useAddNewHandbookCollaboratorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddNewHandbookCollaboratorMutation,
    AddNewHandbookCollaboratorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddNewHandbookCollaboratorMutation,
    AddNewHandbookCollaboratorMutationVariables
  >(AddNewHandbookCollaboratorDocument, options)
}
export type AddNewHandbookCollaboratorMutationHookResult = ReturnType<
  typeof useAddNewHandbookCollaboratorMutation
>
export type AddNewHandbookCollaboratorMutationResult =
  Apollo.MutationResult<AddNewHandbookCollaboratorMutation>
export type AddNewHandbookCollaboratorMutationOptions =
  Apollo.BaseMutationOptions<
    AddNewHandbookCollaboratorMutation,
    AddNewHandbookCollaboratorMutationVariables
  >
export const RemoveHandbookCollaboratorDocument = gql`
    mutation removeHandbookCollaborator($handbookId: Int!, $personId: Int!) {
  removeHandbookCollaborator(handbookId: $handbookId, personId: $personId)
}
    `
export type RemoveHandbookCollaboratorMutationFn = Apollo.MutationFunction<
  RemoveHandbookCollaboratorMutation,
  RemoveHandbookCollaboratorMutationVariables
>

/**
 * __useRemoveHandbookCollaboratorMutation__
 *
 * To run a mutation, you first call `useRemoveHandbookCollaboratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveHandbookCollaboratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeHandbookCollaboratorMutation, { data, loading, error }] = useRemoveHandbookCollaboratorMutation({
 *   variables: {
 *      handbookId: // value for 'handbookId'
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useRemoveHandbookCollaboratorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveHandbookCollaboratorMutation,
    RemoveHandbookCollaboratorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemoveHandbookCollaboratorMutation,
    RemoveHandbookCollaboratorMutationVariables
  >(RemoveHandbookCollaboratorDocument, options)
}
export type RemoveHandbookCollaboratorMutationHookResult = ReturnType<
  typeof useRemoveHandbookCollaboratorMutation
>
export type RemoveHandbookCollaboratorMutationResult =
  Apollo.MutationResult<RemoveHandbookCollaboratorMutation>
export type RemoveHandbookCollaboratorMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveHandbookCollaboratorMutation,
    RemoveHandbookCollaboratorMutationVariables
  >
export const UpdateHandbookCollaboratorDocument = gql`
    mutation updateHandbookCollaborator($handbookCollaboratorInput: HandbookCollaboratorInput!, $handbookId: Int!, $personId: Int!) {
  updateHandbookCollaborator(
    handbookCollaboratorInput: $handbookCollaboratorInput
    handbookId: $handbookId
    personId: $personId
  ) {
    ...handbookCollaborator
  }
}
    ${HandbookCollaboratorFragmentDoc}`
export type UpdateHandbookCollaboratorMutationFn = Apollo.MutationFunction<
  UpdateHandbookCollaboratorMutation,
  UpdateHandbookCollaboratorMutationVariables
>

/**
 * __useUpdateHandbookCollaboratorMutation__
 *
 * To run a mutation, you first call `useUpdateHandbookCollaboratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHandbookCollaboratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHandbookCollaboratorMutation, { data, loading, error }] = useUpdateHandbookCollaboratorMutation({
 *   variables: {
 *      handbookCollaboratorInput: // value for 'handbookCollaboratorInput'
 *      handbookId: // value for 'handbookId'
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useUpdateHandbookCollaboratorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateHandbookCollaboratorMutation,
    UpdateHandbookCollaboratorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateHandbookCollaboratorMutation,
    UpdateHandbookCollaboratorMutationVariables
  >(UpdateHandbookCollaboratorDocument, options)
}
export type UpdateHandbookCollaboratorMutationHookResult = ReturnType<
  typeof useUpdateHandbookCollaboratorMutation
>
export type UpdateHandbookCollaboratorMutationResult =
  Apollo.MutationResult<UpdateHandbookCollaboratorMutation>
export type UpdateHandbookCollaboratorMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateHandbookCollaboratorMutation,
    UpdateHandbookCollaboratorMutationVariables
  >
export const SetFeatureFlagDocument = gql`
    mutation setFeatureFlag($flagId: FeatureFlagId!, $enabled: Boolean!) {
  featureFlagIds: setFeatureFlag(flagId: $flagId, enabled: $enabled)
}
    `
export type SetFeatureFlagMutationFn = Apollo.MutationFunction<
  SetFeatureFlagMutation,
  SetFeatureFlagMutationVariables
>

/**
 * __useSetFeatureFlagMutation__
 *
 * To run a mutation, you first call `useSetFeatureFlagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetFeatureFlagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setFeatureFlagMutation, { data, loading, error }] = useSetFeatureFlagMutation({
 *   variables: {
 *      flagId: // value for 'flagId'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useSetFeatureFlagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetFeatureFlagMutation,
    SetFeatureFlagMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SetFeatureFlagMutation,
    SetFeatureFlagMutationVariables
  >(SetFeatureFlagDocument, options)
}
export type SetFeatureFlagMutationHookResult = ReturnType<
  typeof useSetFeatureFlagMutation
>
export type SetFeatureFlagMutationResult =
  Apollo.MutationResult<SetFeatureFlagMutation>
export type SetFeatureFlagMutationOptions = Apollo.BaseMutationOptions<
  SetFeatureFlagMutation,
  SetFeatureFlagMutationVariables
>
export const CreateHandbookDocument = gql`
    mutation createHandbook($name: String!) {
  handbook: createHandbook(name: $name) {
    ...handbook
  }
}
    ${HandbookFragmentDoc}`
export type CreateHandbookMutationFn = Apollo.MutationFunction<
  CreateHandbookMutation,
  CreateHandbookMutationVariables
>

/**
 * __useCreateHandbookMutation__
 *
 * To run a mutation, you first call `useCreateHandbookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHandbookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHandbookMutation, { data, loading, error }] = useCreateHandbookMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateHandbookMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateHandbookMutation,
    CreateHandbookMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateHandbookMutation,
    CreateHandbookMutationVariables
  >(CreateHandbookDocument, options)
}
export type CreateHandbookMutationHookResult = ReturnType<
  typeof useCreateHandbookMutation
>
export type CreateHandbookMutationResult =
  Apollo.MutationResult<CreateHandbookMutation>
export type CreateHandbookMutationOptions = Apollo.BaseMutationOptions<
  CreateHandbookMutation,
  CreateHandbookMutationVariables
>
export const CloneHandbookDocument = gql`
    mutation cloneHandbook($handbookId: Int!, $name: String!) {
  handbook: cloneHandbook(handbookId: $handbookId, name: $name) {
    ...handbook
  }
}
    ${HandbookFragmentDoc}`
export type CloneHandbookMutationFn = Apollo.MutationFunction<
  CloneHandbookMutation,
  CloneHandbookMutationVariables
>

/**
 * __useCloneHandbookMutation__
 *
 * To run a mutation, you first call `useCloneHandbookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneHandbookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneHandbookMutation, { data, loading, error }] = useCloneHandbookMutation({
 *   variables: {
 *      handbookId: // value for 'handbookId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCloneHandbookMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CloneHandbookMutation,
    CloneHandbookMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CloneHandbookMutation,
    CloneHandbookMutationVariables
  >(CloneHandbookDocument, options)
}
export type CloneHandbookMutationHookResult = ReturnType<
  typeof useCloneHandbookMutation
>
export type CloneHandbookMutationResult =
  Apollo.MutationResult<CloneHandbookMutation>
export type CloneHandbookMutationOptions = Apollo.BaseMutationOptions<
  CloneHandbookMutation,
  CloneHandbookMutationVariables
>
export const InitializeHandbookAudienceDocument = gql`
    mutation initializeHandbookAudience($id: Int!, $hasAudience: Boolean!, $hasSignatures: Boolean!) {
  handbook: initializeHandbookAudience(
    id: $id
    hasAudience: $hasAudience
    hasSignatures: $hasSignatures
  ) {
    ...handbook
  }
}
    ${HandbookFragmentDoc}`
export type InitializeHandbookAudienceMutationFn = Apollo.MutationFunction<
  InitializeHandbookAudienceMutation,
  InitializeHandbookAudienceMutationVariables
>

/**
 * __useInitializeHandbookAudienceMutation__
 *
 * To run a mutation, you first call `useInitializeHandbookAudienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitializeHandbookAudienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initializeHandbookAudienceMutation, { data, loading, error }] = useInitializeHandbookAudienceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      hasAudience: // value for 'hasAudience'
 *      hasSignatures: // value for 'hasSignatures'
 *   },
 * });
 */
export function useInitializeHandbookAudienceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InitializeHandbookAudienceMutation,
    InitializeHandbookAudienceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InitializeHandbookAudienceMutation,
    InitializeHandbookAudienceMutationVariables
  >(InitializeHandbookAudienceDocument, options)
}
export type InitializeHandbookAudienceMutationHookResult = ReturnType<
  typeof useInitializeHandbookAudienceMutation
>
export type InitializeHandbookAudienceMutationResult =
  Apollo.MutationResult<InitializeHandbookAudienceMutation>
export type InitializeHandbookAudienceMutationOptions =
  Apollo.BaseMutationOptions<
    InitializeHandbookAudienceMutation,
    InitializeHandbookAudienceMutationVariables
  >
export const InitializeHandbookContentDocument = gql`
    mutation initializeHandbookContent($id: Int!, $seedType: HandbookSeedType!, $branding: HandbookBrandingInput) {
  handbook: initializeHandbookContent(
    id: $id
    seedType: $seedType
    branding: $branding
  ) {
    ...handbook
  }
}
    ${HandbookFragmentDoc}`
export type InitializeHandbookContentMutationFn = Apollo.MutationFunction<
  InitializeHandbookContentMutation,
  InitializeHandbookContentMutationVariables
>

/**
 * __useInitializeHandbookContentMutation__
 *
 * To run a mutation, you first call `useInitializeHandbookContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitializeHandbookContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initializeHandbookContentMutation, { data, loading, error }] = useInitializeHandbookContentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      seedType: // value for 'seedType'
 *      branding: // value for 'branding'
 *   },
 * });
 */
export function useInitializeHandbookContentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InitializeHandbookContentMutation,
    InitializeHandbookContentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InitializeHandbookContentMutation,
    InitializeHandbookContentMutationVariables
  >(InitializeHandbookContentDocument, options)
}
export type InitializeHandbookContentMutationHookResult = ReturnType<
  typeof useInitializeHandbookContentMutation
>
export type InitializeHandbookContentMutationResult =
  Apollo.MutationResult<InitializeHandbookContentMutation>
export type InitializeHandbookContentMutationOptions =
  Apollo.BaseMutationOptions<
    InitializeHandbookContentMutation,
    InitializeHandbookContentMutationVariables
  >
export const PatchHandbookDocument = gql`
    mutation patchHandbook($handbookId: Int!, $input: HandbookInput!) {
  handbook: patchHandbook(handbookId: $handbookId, input: $input) {
    customDomain
    customDomainOrigin
    defaultLanguageCode
    isPrintDisabled
    name
    public
    publicToken
    updatedAt
  }
}
    `
export type PatchHandbookMutationFn = Apollo.MutationFunction<
  PatchHandbookMutation,
  PatchHandbookMutationVariables
>

/**
 * __usePatchHandbookMutation__
 *
 * To run a mutation, you first call `usePatchHandbookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatchHandbookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patchHandbookMutation, { data, loading, error }] = usePatchHandbookMutation({
 *   variables: {
 *      handbookId: // value for 'handbookId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePatchHandbookMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PatchHandbookMutation,
    PatchHandbookMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    PatchHandbookMutation,
    PatchHandbookMutationVariables
  >(PatchHandbookDocument, options)
}
export type PatchHandbookMutationHookResult = ReturnType<
  typeof usePatchHandbookMutation
>
export type PatchHandbookMutationResult =
  Apollo.MutationResult<PatchHandbookMutation>
export type PatchHandbookMutationOptions = Apollo.BaseMutationOptions<
  PatchHandbookMutation,
  PatchHandbookMutationVariables
>
export const PatchHandbookCssDocument = gql`
    mutation patchHandbookCss($handbookId: Int!, $input: HandbookCssInput!) {
  handbook: patchHandbookCss(handbookId: $handbookId, input: $input) {
    updatedAt
  }
}
    `
export type PatchHandbookCssMutationFn = Apollo.MutationFunction<
  PatchHandbookCssMutation,
  PatchHandbookCssMutationVariables
>

/**
 * __usePatchHandbookCssMutation__
 *
 * To run a mutation, you first call `usePatchHandbookCssMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatchHandbookCssMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patchHandbookCssMutation, { data, loading, error }] = usePatchHandbookCssMutation({
 *   variables: {
 *      handbookId: // value for 'handbookId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePatchHandbookCssMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PatchHandbookCssMutation,
    PatchHandbookCssMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    PatchHandbookCssMutation,
    PatchHandbookCssMutationVariables
  >(PatchHandbookCssDocument, options)
}
export type PatchHandbookCssMutationHookResult = ReturnType<
  typeof usePatchHandbookCssMutation
>
export type PatchHandbookCssMutationResult =
  Apollo.MutationResult<PatchHandbookCssMutation>
export type PatchHandbookCssMutationOptions = Apollo.BaseMutationOptions<
  PatchHandbookCssMutation,
  PatchHandbookCssMutationVariables
>
export const PatchHandbookCustomHtmlDocument = gql`
    mutation patchHandbookCustomHtml($handbookId: Int!, $customHtml: String!) {
  handbook: patchHandbookCustomHtml(
    handbookId: $handbookId
    customHtml: $customHtml
  ) {
    updatedAt
  }
}
    `
export type PatchHandbookCustomHtmlMutationFn = Apollo.MutationFunction<
  PatchHandbookCustomHtmlMutation,
  PatchHandbookCustomHtmlMutationVariables
>

/**
 * __usePatchHandbookCustomHtmlMutation__
 *
 * To run a mutation, you first call `usePatchHandbookCustomHtmlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatchHandbookCustomHtmlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patchHandbookCustomHtmlMutation, { data, loading, error }] = usePatchHandbookCustomHtmlMutation({
 *   variables: {
 *      handbookId: // value for 'handbookId'
 *      customHtml: // value for 'customHtml'
 *   },
 * });
 */
export function usePatchHandbookCustomHtmlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PatchHandbookCustomHtmlMutation,
    PatchHandbookCustomHtmlMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    PatchHandbookCustomHtmlMutation,
    PatchHandbookCustomHtmlMutationVariables
  >(PatchHandbookCustomHtmlDocument, options)
}
export type PatchHandbookCustomHtmlMutationHookResult = ReturnType<
  typeof usePatchHandbookCustomHtmlMutation
>
export type PatchHandbookCustomHtmlMutationResult =
  Apollo.MutationResult<PatchHandbookCustomHtmlMutation>
export type PatchHandbookCustomHtmlMutationOptions = Apollo.BaseMutationOptions<
  PatchHandbookCustomHtmlMutation,
  PatchHandbookCustomHtmlMutationVariables
>
export const PublishHandbookDocument = gql`
    mutation publishHandbook($handbookId: Int!, $content: PublishHandbookContentInput, $notifyViewers: HandbookViewersNotificationInput) {
  handbook: publishHandbook(
    handbookId: $handbookId
    content: $content
    notifyViewers: $notifyViewers
  ) {
    ...handbook
  }
}
    ${HandbookFragmentDoc}`
export type PublishHandbookMutationFn = Apollo.MutationFunction<
  PublishHandbookMutation,
  PublishHandbookMutationVariables
>

/**
 * __usePublishHandbookMutation__
 *
 * To run a mutation, you first call `usePublishHandbookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishHandbookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishHandbookMutation, { data, loading, error }] = usePublishHandbookMutation({
 *   variables: {
 *      handbookId: // value for 'handbookId'
 *      content: // value for 'content'
 *      notifyViewers: // value for 'notifyViewers'
 *   },
 * });
 */
export function usePublishHandbookMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublishHandbookMutation,
    PublishHandbookMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    PublishHandbookMutation,
    PublishHandbookMutationVariables
  >(PublishHandbookDocument, options)
}
export type PublishHandbookMutationHookResult = ReturnType<
  typeof usePublishHandbookMutation
>
export type PublishHandbookMutationResult =
  Apollo.MutationResult<PublishHandbookMutation>
export type PublishHandbookMutationOptions = Apollo.BaseMutationOptions<
  PublishHandbookMutation,
  PublishHandbookMutationVariables
>
export const DeleteHandbookDocument = gql`
    mutation deleteHandbook($handbookId: Int!) {
  deleteHandbook(id: $handbookId)
}
    `
export type DeleteHandbookMutationFn = Apollo.MutationFunction<
  DeleteHandbookMutation,
  DeleteHandbookMutationVariables
>

/**
 * __useDeleteHandbookMutation__
 *
 * To run a mutation, you first call `useDeleteHandbookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteHandbookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHandbookMutation, { data, loading, error }] = useDeleteHandbookMutation({
 *   variables: {
 *      handbookId: // value for 'handbookId'
 *   },
 * });
 */
export function useDeleteHandbookMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteHandbookMutation,
    DeleteHandbookMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteHandbookMutation,
    DeleteHandbookMutationVariables
  >(DeleteHandbookDocument, options)
}
export type DeleteHandbookMutationHookResult = ReturnType<
  typeof useDeleteHandbookMutation
>
export type DeleteHandbookMutationResult =
  Apollo.MutationResult<DeleteHandbookMutation>
export type DeleteHandbookMutationOptions = Apollo.BaseMutationOptions<
  DeleteHandbookMutation,
  DeleteHandbookMutationVariables
>
export const AddDocumentRefsToHandbookDocument = gql`
    mutation addDocumentRefsToHandbook($documentIds: [Int!]!, $handbookId: Int!, $index: Int) {
  nodeIds: addDocumentRefsToHandbook(
    documentIds: $documentIds
    handbookId: $handbookId
    index: $index
  )
}
    `
export type AddDocumentRefsToHandbookMutationFn = Apollo.MutationFunction<
  AddDocumentRefsToHandbookMutation,
  AddDocumentRefsToHandbookMutationVariables
>

/**
 * __useAddDocumentRefsToHandbookMutation__
 *
 * To run a mutation, you first call `useAddDocumentRefsToHandbookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDocumentRefsToHandbookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDocumentRefsToHandbookMutation, { data, loading, error }] = useAddDocumentRefsToHandbookMutation({
 *   variables: {
 *      documentIds: // value for 'documentIds'
 *      handbookId: // value for 'handbookId'
 *      index: // value for 'index'
 *   },
 * });
 */
export function useAddDocumentRefsToHandbookMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddDocumentRefsToHandbookMutation,
    AddDocumentRefsToHandbookMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddDocumentRefsToHandbookMutation,
    AddDocumentRefsToHandbookMutationVariables
  >(AddDocumentRefsToHandbookDocument, options)
}
export type AddDocumentRefsToHandbookMutationHookResult = ReturnType<
  typeof useAddDocumentRefsToHandbookMutation
>
export type AddDocumentRefsToHandbookMutationResult =
  Apollo.MutationResult<AddDocumentRefsToHandbookMutation>
export type AddDocumentRefsToHandbookMutationOptions =
  Apollo.BaseMutationOptions<
    AddDocumentRefsToHandbookMutation,
    AddDocumentRefsToHandbookMutationVariables
  >
export const AddHandbookSectionDocument = gql`
    mutation addHandbookSection($handbookId: Int!, $input: NewHandbookSectionInput!, $position: Int) {
  section: addHandbookSection(
    handbookId: $handbookId
    input: $input
    position: $position
  ) {
    ...handbookSection
  }
}
    ${HandbookSectionFragmentDoc}`
export type AddHandbookSectionMutationFn = Apollo.MutationFunction<
  AddHandbookSectionMutation,
  AddHandbookSectionMutationVariables
>

/**
 * __useAddHandbookSectionMutation__
 *
 * To run a mutation, you first call `useAddHandbookSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddHandbookSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addHandbookSectionMutation, { data, loading, error }] = useAddHandbookSectionMutation({
 *   variables: {
 *      handbookId: // value for 'handbookId'
 *      input: // value for 'input'
 *      position: // value for 'position'
 *   },
 * });
 */
export function useAddHandbookSectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddHandbookSectionMutation,
    AddHandbookSectionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddHandbookSectionMutation,
    AddHandbookSectionMutationVariables
  >(AddHandbookSectionDocument, options)
}
export type AddHandbookSectionMutationHookResult = ReturnType<
  typeof useAddHandbookSectionMutation
>
export type AddHandbookSectionMutationResult =
  Apollo.MutationResult<AddHandbookSectionMutation>
export type AddHandbookSectionMutationOptions = Apollo.BaseMutationOptions<
  AddHandbookSectionMutation,
  AddHandbookSectionMutationVariables
>
export const CloneHandbookSectionDocument = gql`
    mutation cloneHandbookSection($sectionId: Int!, $children: Boolean) {
  cloneHandbookSection(sectionId: $sectionId, children: $children) {
    position
    sections {
      ...handbookSection
    }
  }
}
    ${HandbookSectionFragmentDoc}`
export type CloneHandbookSectionMutationFn = Apollo.MutationFunction<
  CloneHandbookSectionMutation,
  CloneHandbookSectionMutationVariables
>

/**
 * __useCloneHandbookSectionMutation__
 *
 * To run a mutation, you first call `useCloneHandbookSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneHandbookSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneHandbookSectionMutation, { data, loading, error }] = useCloneHandbookSectionMutation({
 *   variables: {
 *      sectionId: // value for 'sectionId'
 *      children: // value for 'children'
 *   },
 * });
 */
export function useCloneHandbookSectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CloneHandbookSectionMutation,
    CloneHandbookSectionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CloneHandbookSectionMutation,
    CloneHandbookSectionMutationVariables
  >(CloneHandbookSectionDocument, options)
}
export type CloneHandbookSectionMutationHookResult = ReturnType<
  typeof useCloneHandbookSectionMutation
>
export type CloneHandbookSectionMutationResult =
  Apollo.MutationResult<CloneHandbookSectionMutation>
export type CloneHandbookSectionMutationOptions = Apollo.BaseMutationOptions<
  CloneHandbookSectionMutation,
  CloneHandbookSectionMutationVariables
>
export const PatchHandbookSectionDocument = gql`
    mutation patchHandbookSection($sectionId: Int!, $input: HandbookSectionInput!) {
  section: patchHandbookSection(sectionId: $sectionId, input: $input) {
    id
    ...handbookSectionSettings
    dirtyFields
  }
}
    ${HandbookSectionSettingsFragmentDoc}`
export type PatchHandbookSectionMutationFn = Apollo.MutationFunction<
  PatchHandbookSectionMutation,
  PatchHandbookSectionMutationVariables
>

/**
 * __usePatchHandbookSectionMutation__
 *
 * To run a mutation, you first call `usePatchHandbookSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatchHandbookSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patchHandbookSectionMutation, { data, loading, error }] = usePatchHandbookSectionMutation({
 *   variables: {
 *      sectionId: // value for 'sectionId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePatchHandbookSectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PatchHandbookSectionMutation,
    PatchHandbookSectionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    PatchHandbookSectionMutation,
    PatchHandbookSectionMutationVariables
  >(PatchHandbookSectionDocument, options)
}
export type PatchHandbookSectionMutationHookResult = ReturnType<
  typeof usePatchHandbookSectionMutation
>
export type PatchHandbookSectionMutationResult =
  Apollo.MutationResult<PatchHandbookSectionMutation>
export type PatchHandbookSectionMutationOptions = Apollo.BaseMutationOptions<
  PatchHandbookSectionMutation,
  PatchHandbookSectionMutationVariables
>
export const MoveHandbookNodeDocument = gql`
    mutation moveHandbookNode($handbookId: Int!, $fromIndex: Int!, $toIndex: Int!) {
  moveHandbookNode(
    handbookId: $handbookId
    fromIndex: $fromIndex
    toIndex: $toIndex
  )
}
    `
export type MoveHandbookNodeMutationFn = Apollo.MutationFunction<
  MoveHandbookNodeMutation,
  MoveHandbookNodeMutationVariables
>

/**
 * __useMoveHandbookNodeMutation__
 *
 * To run a mutation, you first call `useMoveHandbookNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveHandbookNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveHandbookNodeMutation, { data, loading, error }] = useMoveHandbookNodeMutation({
 *   variables: {
 *      handbookId: // value for 'handbookId'
 *      fromIndex: // value for 'fromIndex'
 *      toIndex: // value for 'toIndex'
 *   },
 * });
 */
export function useMoveHandbookNodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MoveHandbookNodeMutation,
    MoveHandbookNodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    MoveHandbookNodeMutation,
    MoveHandbookNodeMutationVariables
  >(MoveHandbookNodeDocument, options)
}
export type MoveHandbookNodeMutationHookResult = ReturnType<
  typeof useMoveHandbookNodeMutation
>
export type MoveHandbookNodeMutationResult =
  Apollo.MutationResult<MoveHandbookNodeMutation>
export type MoveHandbookNodeMutationOptions = Apollo.BaseMutationOptions<
  MoveHandbookNodeMutation,
  MoveHandbookNodeMutationVariables
>
export const MoveHandbookSectionToHandbookDocument = gql`
    mutation moveHandbookSectionToHandbook($sectionId: Int!, $handbookId: Int!) {
  moveHandbookSectionToHandbook(sectionId: $sectionId, handbookId: $handbookId)
}
    `
export type MoveHandbookSectionToHandbookMutationFn = Apollo.MutationFunction<
  MoveHandbookSectionToHandbookMutation,
  MoveHandbookSectionToHandbookMutationVariables
>

/**
 * __useMoveHandbookSectionToHandbookMutation__
 *
 * To run a mutation, you first call `useMoveHandbookSectionToHandbookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveHandbookSectionToHandbookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveHandbookSectionToHandbookMutation, { data, loading, error }] = useMoveHandbookSectionToHandbookMutation({
 *   variables: {
 *      sectionId: // value for 'sectionId'
 *      handbookId: // value for 'handbookId'
 *   },
 * });
 */
export function useMoveHandbookSectionToHandbookMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MoveHandbookSectionToHandbookMutation,
    MoveHandbookSectionToHandbookMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    MoveHandbookSectionToHandbookMutation,
    MoveHandbookSectionToHandbookMutationVariables
  >(MoveHandbookSectionToHandbookDocument, options)
}
export type MoveHandbookSectionToHandbookMutationHookResult = ReturnType<
  typeof useMoveHandbookSectionToHandbookMutation
>
export type MoveHandbookSectionToHandbookMutationResult =
  Apollo.MutationResult<MoveHandbookSectionToHandbookMutation>
export type MoveHandbookSectionToHandbookMutationOptions =
  Apollo.BaseMutationOptions<
    MoveHandbookSectionToHandbookMutation,
    MoveHandbookSectionToHandbookMutationVariables
  >
export const UpdateDocumentRefNodeDocument = gql`
    mutation updateDocumentRefNode($handbookId: Int!, $nodeId: String!, $attrs: DocumentRefNodeAttrsInput!) {
  updateDocumentRefNode(handbookId: $handbookId, nodeId: $nodeId, attrs: $attrs)
}
    `
export type UpdateDocumentRefNodeMutationFn = Apollo.MutationFunction<
  UpdateDocumentRefNodeMutation,
  UpdateDocumentRefNodeMutationVariables
>

/**
 * __useUpdateDocumentRefNodeMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentRefNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentRefNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentRefNodeMutation, { data, loading, error }] = useUpdateDocumentRefNodeMutation({
 *   variables: {
 *      handbookId: // value for 'handbookId'
 *      nodeId: // value for 'nodeId'
 *      attrs: // value for 'attrs'
 *   },
 * });
 */
export function useUpdateDocumentRefNodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDocumentRefNodeMutation,
    UpdateDocumentRefNodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateDocumentRefNodeMutation,
    UpdateDocumentRefNodeMutationVariables
  >(UpdateDocumentRefNodeDocument, options)
}
export type UpdateDocumentRefNodeMutationHookResult = ReturnType<
  typeof useUpdateDocumentRefNodeMutation
>
export type UpdateDocumentRefNodeMutationResult =
  Apollo.MutationResult<UpdateDocumentRefNodeMutation>
export type UpdateDocumentRefNodeMutationOptions = Apollo.BaseMutationOptions<
  UpdateDocumentRefNodeMutation,
  UpdateDocumentRefNodeMutationVariables
>
export const RemoveHandbookNodeDocument = gql`
    mutation removeHandbookNode($handbookId: Int!, $nodeId: String!) {
  removeHandbookNode(handbookId: $handbookId, nodeId: $nodeId)
}
    `
export type RemoveHandbookNodeMutationFn = Apollo.MutationFunction<
  RemoveHandbookNodeMutation,
  RemoveHandbookNodeMutationVariables
>

/**
 * __useRemoveHandbookNodeMutation__
 *
 * To run a mutation, you first call `useRemoveHandbookNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveHandbookNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeHandbookNodeMutation, { data, loading, error }] = useRemoveHandbookNodeMutation({
 *   variables: {
 *      handbookId: // value for 'handbookId'
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useRemoveHandbookNodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveHandbookNodeMutation,
    RemoveHandbookNodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemoveHandbookNodeMutation,
    RemoveHandbookNodeMutationVariables
  >(RemoveHandbookNodeDocument, options)
}
export type RemoveHandbookNodeMutationHookResult = ReturnType<
  typeof useRemoveHandbookNodeMutation
>
export type RemoveHandbookNodeMutationResult =
  Apollo.MutationResult<RemoveHandbookNodeMutation>
export type RemoveHandbookNodeMutationOptions = Apollo.BaseMutationOptions<
  RemoveHandbookNodeMutation,
  RemoveHandbookNodeMutationVariables
>
export const RemoveHandbookSectionDocument = gql`
    mutation removeHandbookSection($sectionId: Int!, $children: Boolean) {
  removeHandbookSection(sectionId: $sectionId, children: $children)
}
    `
export type RemoveHandbookSectionMutationFn = Apollo.MutationFunction<
  RemoveHandbookSectionMutation,
  RemoveHandbookSectionMutationVariables
>

/**
 * __useRemoveHandbookSectionMutation__
 *
 * To run a mutation, you first call `useRemoveHandbookSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveHandbookSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeHandbookSectionMutation, { data, loading, error }] = useRemoveHandbookSectionMutation({
 *   variables: {
 *      sectionId: // value for 'sectionId'
 *      children: // value for 'children'
 *   },
 * });
 */
export function useRemoveHandbookSectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveHandbookSectionMutation,
    RemoveHandbookSectionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemoveHandbookSectionMutation,
    RemoveHandbookSectionMutationVariables
  >(RemoveHandbookSectionDocument, options)
}
export type RemoveHandbookSectionMutationHookResult = ReturnType<
  typeof useRemoveHandbookSectionMutation
>
export type RemoveHandbookSectionMutationResult =
  Apollo.MutationResult<RemoveHandbookSectionMutation>
export type RemoveHandbookSectionMutationOptions = Apollo.BaseMutationOptions<
  RemoveHandbookSectionMutation,
  RemoveHandbookSectionMutationVariables
>
export const AddHandbookSectionEntryDocument = gql`
    mutation addHandbookSectionEntry($sectionId: Int!, $input: HandbookSectionEntryInput) {
  addHandbookSectionEntry(sectionId: $sectionId, input: $input) {
    sectionState {
      dirtyFields
    }
    entry {
      ...handbookSectionEntry
    }
  }
}
    ${HandbookSectionEntryFragmentDoc}`
export type AddHandbookSectionEntryMutationFn = Apollo.MutationFunction<
  AddHandbookSectionEntryMutation,
  AddHandbookSectionEntryMutationVariables
>

/**
 * __useAddHandbookSectionEntryMutation__
 *
 * To run a mutation, you first call `useAddHandbookSectionEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddHandbookSectionEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addHandbookSectionEntryMutation, { data, loading, error }] = useAddHandbookSectionEntryMutation({
 *   variables: {
 *      sectionId: // value for 'sectionId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddHandbookSectionEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddHandbookSectionEntryMutation,
    AddHandbookSectionEntryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddHandbookSectionEntryMutation,
    AddHandbookSectionEntryMutationVariables
  >(AddHandbookSectionEntryDocument, options)
}
export type AddHandbookSectionEntryMutationHookResult = ReturnType<
  typeof useAddHandbookSectionEntryMutation
>
export type AddHandbookSectionEntryMutationResult =
  Apollo.MutationResult<AddHandbookSectionEntryMutation>
export type AddHandbookSectionEntryMutationOptions = Apollo.BaseMutationOptions<
  AddHandbookSectionEntryMutation,
  AddHandbookSectionEntryMutationVariables
>
export const PatchHandbookSectionEntryDocument = gql`
    mutation patchHandbookSectionEntry($sectionId: Int!, $entryUid: String!, $input: HandbookSectionEntryInput!) {
  patchHandbookSectionEntry(
    sectionId: $sectionId
    entryUid: $entryUid
    input: $input
  ) {
    sectionState {
      dirtyFields
    }
  }
}
    `
export type PatchHandbookSectionEntryMutationFn = Apollo.MutationFunction<
  PatchHandbookSectionEntryMutation,
  PatchHandbookSectionEntryMutationVariables
>

/**
 * __usePatchHandbookSectionEntryMutation__
 *
 * To run a mutation, you first call `usePatchHandbookSectionEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatchHandbookSectionEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patchHandbookSectionEntryMutation, { data, loading, error }] = usePatchHandbookSectionEntryMutation({
 *   variables: {
 *      sectionId: // value for 'sectionId'
 *      entryUid: // value for 'entryUid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePatchHandbookSectionEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PatchHandbookSectionEntryMutation,
    PatchHandbookSectionEntryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    PatchHandbookSectionEntryMutation,
    PatchHandbookSectionEntryMutationVariables
  >(PatchHandbookSectionEntryDocument, options)
}
export type PatchHandbookSectionEntryMutationHookResult = ReturnType<
  typeof usePatchHandbookSectionEntryMutation
>
export type PatchHandbookSectionEntryMutationResult =
  Apollo.MutationResult<PatchHandbookSectionEntryMutation>
export type PatchHandbookSectionEntryMutationOptions =
  Apollo.BaseMutationOptions<
    PatchHandbookSectionEntryMutation,
    PatchHandbookSectionEntryMutationVariables
  >
export const MoveHandbookSectionEntryDocument = gql`
    mutation moveHandbookSectionEntry($sectionId: Int!, $entryUid: String!, $position: Int!) {
  moveHandbookSectionEntry(
    sectionId: $sectionId
    entryUid: $entryUid
    position: $position
  ) {
    sectionState {
      dirtyFields
    }
  }
}
    `
export type MoveHandbookSectionEntryMutationFn = Apollo.MutationFunction<
  MoveHandbookSectionEntryMutation,
  MoveHandbookSectionEntryMutationVariables
>

/**
 * __useMoveHandbookSectionEntryMutation__
 *
 * To run a mutation, you first call `useMoveHandbookSectionEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveHandbookSectionEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveHandbookSectionEntryMutation, { data, loading, error }] = useMoveHandbookSectionEntryMutation({
 *   variables: {
 *      sectionId: // value for 'sectionId'
 *      entryUid: // value for 'entryUid'
 *      position: // value for 'position'
 *   },
 * });
 */
export function useMoveHandbookSectionEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MoveHandbookSectionEntryMutation,
    MoveHandbookSectionEntryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    MoveHandbookSectionEntryMutation,
    MoveHandbookSectionEntryMutationVariables
  >(MoveHandbookSectionEntryDocument, options)
}
export type MoveHandbookSectionEntryMutationHookResult = ReturnType<
  typeof useMoveHandbookSectionEntryMutation
>
export type MoveHandbookSectionEntryMutationResult =
  Apollo.MutationResult<MoveHandbookSectionEntryMutation>
export type MoveHandbookSectionEntryMutationOptions =
  Apollo.BaseMutationOptions<
    MoveHandbookSectionEntryMutation,
    MoveHandbookSectionEntryMutationVariables
  >
export const RemoveHandbookSectionEntryDocument = gql`
    mutation removeHandbookSectionEntry($sectionId: Int!, $entryUid: String!) {
  removeHandbookSectionEntry(sectionId: $sectionId, entryUid: $entryUid) {
    sectionState {
      dirtyFields
    }
  }
}
    `
export type RemoveHandbookSectionEntryMutationFn = Apollo.MutationFunction<
  RemoveHandbookSectionEntryMutation,
  RemoveHandbookSectionEntryMutationVariables
>

/**
 * __useRemoveHandbookSectionEntryMutation__
 *
 * To run a mutation, you first call `useRemoveHandbookSectionEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveHandbookSectionEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeHandbookSectionEntryMutation, { data, loading, error }] = useRemoveHandbookSectionEntryMutation({
 *   variables: {
 *      sectionId: // value for 'sectionId'
 *      entryUid: // value for 'entryUid'
 *   },
 * });
 */
export function useRemoveHandbookSectionEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveHandbookSectionEntryMutation,
    RemoveHandbookSectionEntryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemoveHandbookSectionEntryMutation,
    RemoveHandbookSectionEntryMutationVariables
  >(RemoveHandbookSectionEntryDocument, options)
}
export type RemoveHandbookSectionEntryMutationHookResult = ReturnType<
  typeof useRemoveHandbookSectionEntryMutation
>
export type RemoveHandbookSectionEntryMutationResult =
  Apollo.MutationResult<RemoveHandbookSectionEntryMutation>
export type RemoveHandbookSectionEntryMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveHandbookSectionEntryMutation,
    RemoveHandbookSectionEntryMutationVariables
  >
export const ClearHandbookSectionDirtyDocument = gql`
    mutation clearHandbookSectionDirty($sectionId: Int!) {
  section: clearHandbookSectionDirty(sectionId: $sectionId) {
    ...handbookSection
  }
}
    ${HandbookSectionFragmentDoc}`
export type ClearHandbookSectionDirtyMutationFn = Apollo.MutationFunction<
  ClearHandbookSectionDirtyMutation,
  ClearHandbookSectionDirtyMutationVariables
>

/**
 * __useClearHandbookSectionDirtyMutation__
 *
 * To run a mutation, you first call `useClearHandbookSectionDirtyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearHandbookSectionDirtyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearHandbookSectionDirtyMutation, { data, loading, error }] = useClearHandbookSectionDirtyMutation({
 *   variables: {
 *      sectionId: // value for 'sectionId'
 *   },
 * });
 */
export function useClearHandbookSectionDirtyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClearHandbookSectionDirtyMutation,
    ClearHandbookSectionDirtyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ClearHandbookSectionDirtyMutation,
    ClearHandbookSectionDirtyMutationVariables
  >(ClearHandbookSectionDirtyDocument, options)
}
export type ClearHandbookSectionDirtyMutationHookResult = ReturnType<
  typeof useClearHandbookSectionDirtyMutation
>
export type ClearHandbookSectionDirtyMutationResult =
  Apollo.MutationResult<ClearHandbookSectionDirtyMutation>
export type ClearHandbookSectionDirtyMutationOptions =
  Apollo.BaseMutationOptions<
    ClearHandbookSectionDirtyMutation,
    ClearHandbookSectionDirtyMutationVariables
  >
export const ConvertHandbookSectionsToPoliciesDocument = gql`
    mutation convertHandbookSectionsToPolicies($sectionIds: [Int!]!) {
  content: convertHandbookSectionsToPolicies(sectionIds: $sectionIds)
}
    `
export type ConvertHandbookSectionsToPoliciesMutationFn =
  Apollo.MutationFunction<
    ConvertHandbookSectionsToPoliciesMutation,
    ConvertHandbookSectionsToPoliciesMutationVariables
  >

/**
 * __useConvertHandbookSectionsToPoliciesMutation__
 *
 * To run a mutation, you first call `useConvertHandbookSectionsToPoliciesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertHandbookSectionsToPoliciesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertHandbookSectionsToPoliciesMutation, { data, loading, error }] = useConvertHandbookSectionsToPoliciesMutation({
 *   variables: {
 *      sectionIds: // value for 'sectionIds'
 *   },
 * });
 */
export function useConvertHandbookSectionsToPoliciesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConvertHandbookSectionsToPoliciesMutation,
    ConvertHandbookSectionsToPoliciesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ConvertHandbookSectionsToPoliciesMutation,
    ConvertHandbookSectionsToPoliciesMutationVariables
  >(ConvertHandbookSectionsToPoliciesDocument, options)
}
export type ConvertHandbookSectionsToPoliciesMutationHookResult = ReturnType<
  typeof useConvertHandbookSectionsToPoliciesMutation
>
export type ConvertHandbookSectionsToPoliciesMutationResult =
  Apollo.MutationResult<ConvertHandbookSectionsToPoliciesMutation>
export type ConvertHandbookSectionsToPoliciesMutationOptions =
  Apollo.BaseMutationOptions<
    ConvertHandbookSectionsToPoliciesMutation,
    ConvertHandbookSectionsToPoliciesMutationVariables
  >
export const RevertHandbookDocumentRefsDocument = gql`
    mutation revertHandbookDocumentRefs($handbookId: Int!, $documentIds: [Int!]!) {
  revertHandbookDocumentRefs(handbookId: $handbookId, documentIds: $documentIds) {
    content
    sections {
      ...handbookSection
    }
  }
}
    ${HandbookSectionFragmentDoc}`
export type RevertHandbookDocumentRefsMutationFn = Apollo.MutationFunction<
  RevertHandbookDocumentRefsMutation,
  RevertHandbookDocumentRefsMutationVariables
>

/**
 * __useRevertHandbookDocumentRefsMutation__
 *
 * To run a mutation, you first call `useRevertHandbookDocumentRefsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevertHandbookDocumentRefsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revertHandbookDocumentRefsMutation, { data, loading, error }] = useRevertHandbookDocumentRefsMutation({
 *   variables: {
 *      handbookId: // value for 'handbookId'
 *      documentIds: // value for 'documentIds'
 *   },
 * });
 */
export function useRevertHandbookDocumentRefsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RevertHandbookDocumentRefsMutation,
    RevertHandbookDocumentRefsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RevertHandbookDocumentRefsMutation,
    RevertHandbookDocumentRefsMutationVariables
  >(RevertHandbookDocumentRefsDocument, options)
}
export type RevertHandbookDocumentRefsMutationHookResult = ReturnType<
  typeof useRevertHandbookDocumentRefsMutation
>
export type RevertHandbookDocumentRefsMutationResult =
  Apollo.MutationResult<RevertHandbookDocumentRefsMutation>
export type RevertHandbookDocumentRefsMutationOptions =
  Apollo.BaseMutationOptions<
    RevertHandbookDocumentRefsMutation,
    RevertHandbookDocumentRefsMutationVariables
  >
export const ArchiveHandbookFontDocument = gql`
    mutation archiveHandbookFont($handbookId: Int!, $fontId: Int!) {
  archiveHandbookFont(handbookId: $handbookId, fontId: $fontId)
}
    `
export type ArchiveHandbookFontMutationFn = Apollo.MutationFunction<
  ArchiveHandbookFontMutation,
  ArchiveHandbookFontMutationVariables
>

/**
 * __useArchiveHandbookFontMutation__
 *
 * To run a mutation, you first call `useArchiveHandbookFontMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveHandbookFontMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveHandbookFontMutation, { data, loading, error }] = useArchiveHandbookFontMutation({
 *   variables: {
 *      handbookId: // value for 'handbookId'
 *      fontId: // value for 'fontId'
 *   },
 * });
 */
export function useArchiveHandbookFontMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveHandbookFontMutation,
    ArchiveHandbookFontMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ArchiveHandbookFontMutation,
    ArchiveHandbookFontMutationVariables
  >(ArchiveHandbookFontDocument, options)
}
export type ArchiveHandbookFontMutationHookResult = ReturnType<
  typeof useArchiveHandbookFontMutation
>
export type ArchiveHandbookFontMutationResult =
  Apollo.MutationResult<ArchiveHandbookFontMutation>
export type ArchiveHandbookFontMutationOptions = Apollo.BaseMutationOptions<
  ArchiveHandbookFontMutation,
  ArchiveHandbookFontMutationVariables
>
export const SetManagerSettingsDocument = gql`
    mutation setManagerSettings($input: ManagerSettingsInput!) {
  managerSettings: setManagerSettings(input: $input) {
    ...managerSettings
  }
}
    ${ManagerSettingsFragmentDoc}`
export type SetManagerSettingsMutationFn = Apollo.MutationFunction<
  SetManagerSettingsMutation,
  SetManagerSettingsMutationVariables
>

/**
 * __useSetManagerSettingsMutation__
 *
 * To run a mutation, you first call `useSetManagerSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetManagerSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setManagerSettingsMutation, { data, loading, error }] = useSetManagerSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetManagerSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetManagerSettingsMutation,
    SetManagerSettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SetManagerSettingsMutation,
    SetManagerSettingsMutationVariables
  >(SetManagerSettingsDocument, options)
}
export type SetManagerSettingsMutationHookResult = ReturnType<
  typeof useSetManagerSettingsMutation
>
export type SetManagerSettingsMutationResult =
  Apollo.MutationResult<SetManagerSettingsMutation>
export type SetManagerSettingsMutationOptions = Apollo.BaseMutationOptions<
  SetManagerSettingsMutation,
  SetManagerSettingsMutationVariables
>
export const CreateDocumentDocument = gql`
    mutation createDocument($input: CreateDocumentInput!) {
  document: createDocument(input: $input) {
    ...document
    publishedVersions {
      ...documentVersion
    }
  }
}
    ${DocumentFragmentDoc}
${DocumentVersionFragmentDoc}`
export type CreateDocumentMutationFn = Apollo.MutationFunction<
  CreateDocumentMutation,
  CreateDocumentMutationVariables
>

/**
 * __useCreateDocumentMutation__
 *
 * To run a mutation, you first call `useCreateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentMutation, { data, loading, error }] = useCreateDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDocumentMutation,
    CreateDocumentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateDocumentMutation,
    CreateDocumentMutationVariables
  >(CreateDocumentDocument, options)
}
export type CreateDocumentMutationHookResult = ReturnType<
  typeof useCreateDocumentMutation
>
export type CreateDocumentMutationResult =
  Apollo.MutationResult<CreateDocumentMutation>
export type CreateDocumentMutationOptions = Apollo.BaseMutationOptions<
  CreateDocumentMutation,
  CreateDocumentMutationVariables
>
export const CreateDocumentsDocument = gql`
    mutation createDocuments($inputs: [CreateDocumentInput!]!) {
  documents: createDocuments(inputs: $inputs) {
    ...document
    publishedVersions {
      ...documentVersion
    }
  }
}
    ${DocumentFragmentDoc}
${DocumentVersionFragmentDoc}`
export type CreateDocumentsMutationFn = Apollo.MutationFunction<
  CreateDocumentsMutation,
  CreateDocumentsMutationVariables
>

/**
 * __useCreateDocumentsMutation__
 *
 * To run a mutation, you first call `useCreateDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentsMutation, { data, loading, error }] = useCreateDocumentsMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useCreateDocumentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDocumentsMutation,
    CreateDocumentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateDocumentsMutation,
    CreateDocumentsMutationVariables
  >(CreateDocumentsDocument, options)
}
export type CreateDocumentsMutationHookResult = ReturnType<
  typeof useCreateDocumentsMutation
>
export type CreateDocumentsMutationResult =
  Apollo.MutationResult<CreateDocumentsMutation>
export type CreateDocumentsMutationOptions = Apollo.BaseMutationOptions<
  CreateDocumentsMutation,
  CreateDocumentsMutationVariables
>
export const CloneDocumentsDocument = gql`
    mutation cloneDocuments($documentIds: [Int!]!) {
  documents: cloneDocuments(documentIds: $documentIds) {
    ...document
    publishedVersions {
      ...documentVersion
    }
  }
}
    ${DocumentFragmentDoc}
${DocumentVersionFragmentDoc}`
export type CloneDocumentsMutationFn = Apollo.MutationFunction<
  CloneDocumentsMutation,
  CloneDocumentsMutationVariables
>

/**
 * __useCloneDocumentsMutation__
 *
 * To run a mutation, you first call `useCloneDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneDocumentsMutation, { data, loading, error }] = useCloneDocumentsMutation({
 *   variables: {
 *      documentIds: // value for 'documentIds'
 *   },
 * });
 */
export function useCloneDocumentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CloneDocumentsMutation,
    CloneDocumentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CloneDocumentsMutation,
    CloneDocumentsMutationVariables
  >(CloneDocumentsDocument, options)
}
export type CloneDocumentsMutationHookResult = ReturnType<
  typeof useCloneDocumentsMutation
>
export type CloneDocumentsMutationResult =
  Apollo.MutationResult<CloneDocumentsMutation>
export type CloneDocumentsMutationOptions = Apollo.BaseMutationOptions<
  CloneDocumentsMutation,
  CloneDocumentsMutationVariables
>
export const ArchiveDocumentsDocument = gql`
    mutation archiveDocuments($documentIds: [Int!]!) {
  archiveDocuments(documentIds: $documentIds) {
    ...document
  }
}
    ${DocumentFragmentDoc}`
export type ArchiveDocumentsMutationFn = Apollo.MutationFunction<
  ArchiveDocumentsMutation,
  ArchiveDocumentsMutationVariables
>

/**
 * __useArchiveDocumentsMutation__
 *
 * To run a mutation, you first call `useArchiveDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveDocumentsMutation, { data, loading, error }] = useArchiveDocumentsMutation({
 *   variables: {
 *      documentIds: // value for 'documentIds'
 *   },
 * });
 */
export function useArchiveDocumentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveDocumentsMutation,
    ArchiveDocumentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ArchiveDocumentsMutation,
    ArchiveDocumentsMutationVariables
  >(ArchiveDocumentsDocument, options)
}
export type ArchiveDocumentsMutationHookResult = ReturnType<
  typeof useArchiveDocumentsMutation
>
export type ArchiveDocumentsMutationResult =
  Apollo.MutationResult<ArchiveDocumentsMutation>
export type ArchiveDocumentsMutationOptions = Apollo.BaseMutationOptions<
  ArchiveDocumentsMutation,
  ArchiveDocumentsMutationVariables
>
export const UnarchiveDocumentsDocument = gql`
    mutation unarchiveDocuments($documentIds: [Int!]!) {
  unarchiveDocuments(documentIds: $documentIds) {
    ...document
  }
}
    ${DocumentFragmentDoc}`
export type UnarchiveDocumentsMutationFn = Apollo.MutationFunction<
  UnarchiveDocumentsMutation,
  UnarchiveDocumentsMutationVariables
>

/**
 * __useUnarchiveDocumentsMutation__
 *
 * To run a mutation, you first call `useUnarchiveDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveDocumentsMutation, { data, loading, error }] = useUnarchiveDocumentsMutation({
 *   variables: {
 *      documentIds: // value for 'documentIds'
 *   },
 * });
 */
export function useUnarchiveDocumentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnarchiveDocumentsMutation,
    UnarchiveDocumentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UnarchiveDocumentsMutation,
    UnarchiveDocumentsMutationVariables
  >(UnarchiveDocumentsDocument, options)
}
export type UnarchiveDocumentsMutationHookResult = ReturnType<
  typeof useUnarchiveDocumentsMutation
>
export type UnarchiveDocumentsMutationResult =
  Apollo.MutationResult<UnarchiveDocumentsMutation>
export type UnarchiveDocumentsMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveDocumentsMutation,
  UnarchiveDocumentsMutationVariables
>
export const DeleteDocumentsDocument = gql`
    mutation deleteDocuments($documentIds: [Int!]!) {
  deleteDocuments(documentIds: $documentIds)
}
    `
export type DeleteDocumentsMutationFn = Apollo.MutationFunction<
  DeleteDocumentsMutation,
  DeleteDocumentsMutationVariables
>

/**
 * __useDeleteDocumentsMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentsMutation, { data, loading, error }] = useDeleteDocumentsMutation({
 *   variables: {
 *      documentIds: // value for 'documentIds'
 *   },
 * });
 */
export function useDeleteDocumentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteDocumentsMutation,
    DeleteDocumentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DeleteDocumentsMutation,
    DeleteDocumentsMutationVariables
  >(DeleteDocumentsDocument, options)
}
export type DeleteDocumentsMutationHookResult = ReturnType<
  typeof useDeleteDocumentsMutation
>
export type DeleteDocumentsMutationResult =
  Apollo.MutationResult<DeleteDocumentsMutation>
export type DeleteDocumentsMutationOptions = Apollo.BaseMutationOptions<
  DeleteDocumentsMutation,
  DeleteDocumentsMutationVariables
>
export const CreateDocumentDraftVersionDocument = gql`
    mutation createDocumentDraftVersion($documentId: Int!, $input: UpdateDocumentDraftVersionInput) {
  document: createDocumentDraftVersion(documentId: $documentId, input: $input) {
    ...document
    publishedVersions {
      ...documentVersion
    }
  }
}
    ${DocumentFragmentDoc}
${DocumentVersionFragmentDoc}`
export type CreateDocumentDraftVersionMutationFn = Apollo.MutationFunction<
  CreateDocumentDraftVersionMutation,
  CreateDocumentDraftVersionMutationVariables
>

/**
 * __useCreateDocumentDraftVersionMutation__
 *
 * To run a mutation, you first call `useCreateDocumentDraftVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentDraftVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentDraftVersionMutation, { data, loading, error }] = useCreateDocumentDraftVersionMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDocumentDraftVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDocumentDraftVersionMutation,
    CreateDocumentDraftVersionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateDocumentDraftVersionMutation,
    CreateDocumentDraftVersionMutationVariables
  >(CreateDocumentDraftVersionDocument, options)
}
export type CreateDocumentDraftVersionMutationHookResult = ReturnType<
  typeof useCreateDocumentDraftVersionMutation
>
export type CreateDocumentDraftVersionMutationResult =
  Apollo.MutationResult<CreateDocumentDraftVersionMutation>
export type CreateDocumentDraftVersionMutationOptions =
  Apollo.BaseMutationOptions<
    CreateDocumentDraftVersionMutation,
    CreateDocumentDraftVersionMutationVariables
  >
export const UpdateDocumentsDocument = gql`
    mutation updateDocuments($updates: [UpdateDocumentArgs!]!) {
  documents: updateDocuments(updates: $updates) {
    ...document
  }
}
    ${DocumentFragmentDoc}`
export type UpdateDocumentsMutationFn = Apollo.MutationFunction<
  UpdateDocumentsMutation,
  UpdateDocumentsMutationVariables
>

/**
 * __useUpdateDocumentsMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentsMutation, { data, loading, error }] = useUpdateDocumentsMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdateDocumentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDocumentsMutation,
    UpdateDocumentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateDocumentsMutation,
    UpdateDocumentsMutationVariables
  >(UpdateDocumentsDocument, options)
}
export type UpdateDocumentsMutationHookResult = ReturnType<
  typeof useUpdateDocumentsMutation
>
export type UpdateDocumentsMutationResult =
  Apollo.MutationResult<UpdateDocumentsMutation>
export type UpdateDocumentsMutationOptions = Apollo.BaseMutationOptions<
  UpdateDocumentsMutation,
  UpdateDocumentsMutationVariables
>
export const UpdateDocumentDraftVersionDocument = gql`
    mutation updateDocumentDraftVersion($documentId: Int!, $input: UpdateDocumentDraftVersionInput!) {
  document: updateDocumentDraftVersion(documentId: $documentId, input: $input) {
    ...document
    publishedVersions {
      ...documentVersion
    }
  }
}
    ${DocumentFragmentDoc}
${DocumentVersionFragmentDoc}`
export type UpdateDocumentDraftVersionMutationFn = Apollo.MutationFunction<
  UpdateDocumentDraftVersionMutation,
  UpdateDocumentDraftVersionMutationVariables
>

/**
 * __useUpdateDocumentDraftVersionMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentDraftVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentDraftVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentDraftVersionMutation, { data, loading, error }] = useUpdateDocumentDraftVersionMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDocumentDraftVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDocumentDraftVersionMutation,
    UpdateDocumentDraftVersionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateDocumentDraftVersionMutation,
    UpdateDocumentDraftVersionMutationVariables
  >(UpdateDocumentDraftVersionDocument, options)
}
export type UpdateDocumentDraftVersionMutationHookResult = ReturnType<
  typeof useUpdateDocumentDraftVersionMutation
>
export type UpdateDocumentDraftVersionMutationResult =
  Apollo.MutationResult<UpdateDocumentDraftVersionMutation>
export type UpdateDocumentDraftVersionMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateDocumentDraftVersionMutation,
    UpdateDocumentDraftVersionMutationVariables
  >
export const MoveDocumentsDocument = gql`
    mutation moveDocuments($documentIds: [Int!]!, $input: MoveDocumentsInput!) {
  documents: moveDocuments(documentIds: $documentIds, input: $input) {
    id
    sortPosition
  }
}
    `
export type MoveDocumentsMutationFn = Apollo.MutationFunction<
  MoveDocumentsMutation,
  MoveDocumentsMutationVariables
>

/**
 * __useMoveDocumentsMutation__
 *
 * To run a mutation, you first call `useMoveDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveDocumentsMutation, { data, loading, error }] = useMoveDocumentsMutation({
 *   variables: {
 *      documentIds: // value for 'documentIds'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveDocumentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MoveDocumentsMutation,
    MoveDocumentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    MoveDocumentsMutation,
    MoveDocumentsMutationVariables
  >(MoveDocumentsDocument, options)
}
export type MoveDocumentsMutationHookResult = ReturnType<
  typeof useMoveDocumentsMutation
>
export type MoveDocumentsMutationResult =
  Apollo.MutationResult<MoveDocumentsMutation>
export type MoveDocumentsMutationOptions = Apollo.BaseMutationOptions<
  MoveDocumentsMutation,
  MoveDocumentsMutationVariables
>
export const PublishDocumentsDocument = gql`
    mutation publishDocuments($documentIds: [Int!]!) {
  documents: publishDocuments(documentIds: $documentIds) {
    ...document
    publishedVersions {
      ...documentVersion
    }
  }
}
    ${DocumentFragmentDoc}
${DocumentVersionFragmentDoc}`
export type PublishDocumentsMutationFn = Apollo.MutationFunction<
  PublishDocumentsMutation,
  PublishDocumentsMutationVariables
>

/**
 * __usePublishDocumentsMutation__
 *
 * To run a mutation, you first call `usePublishDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishDocumentsMutation, { data, loading, error }] = usePublishDocumentsMutation({
 *   variables: {
 *      documentIds: // value for 'documentIds'
 *   },
 * });
 */
export function usePublishDocumentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PublishDocumentsMutation,
    PublishDocumentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    PublishDocumentsMutation,
    PublishDocumentsMutationVariables
  >(PublishDocumentsDocument, options)
}
export type PublishDocumentsMutationHookResult = ReturnType<
  typeof usePublishDocumentsMutation
>
export type PublishDocumentsMutationResult =
  Apollo.MutationResult<PublishDocumentsMutation>
export type PublishDocumentsMutationOptions = Apollo.BaseMutationOptions<
  PublishDocumentsMutation,
  PublishDocumentsMutationVariables
>
export const UpdateDocumentSettingsDocument = gql`
    mutation updateDocumentSettings($input: UpdateDocumentSettingsInput!) {
  documentSettings: updateDocumentSettings(input: $input) {
    ...documentSettings
  }
}
    ${DocumentSettingsFragmentDoc}`
export type UpdateDocumentSettingsMutationFn = Apollo.MutationFunction<
  UpdateDocumentSettingsMutation,
  UpdateDocumentSettingsMutationVariables
>

/**
 * __useUpdateDocumentSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentSettingsMutation, { data, loading, error }] = useUpdateDocumentSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDocumentSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDocumentSettingsMutation,
    UpdateDocumentSettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateDocumentSettingsMutation,
    UpdateDocumentSettingsMutationVariables
  >(UpdateDocumentSettingsDocument, options)
}
export type UpdateDocumentSettingsMutationHookResult = ReturnType<
  typeof useUpdateDocumentSettingsMutation
>
export type UpdateDocumentSettingsMutationResult =
  Apollo.MutationResult<UpdateDocumentSettingsMutation>
export type UpdateDocumentSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateDocumentSettingsMutation,
  UpdateDocumentSettingsMutationVariables
>
export const CreateMergeDevLinkTokenDocument = gql`
    mutation createMergeDevLinkToken {
  createMergeDevLinkToken {
    linkToken
  }
}
    `
export type CreateMergeDevLinkTokenMutationFn = Apollo.MutationFunction<
  CreateMergeDevLinkTokenMutation,
  CreateMergeDevLinkTokenMutationVariables
>

/**
 * __useCreateMergeDevLinkTokenMutation__
 *
 * To run a mutation, you first call `useCreateMergeDevLinkTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMergeDevLinkTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMergeDevLinkTokenMutation, { data, loading, error }] = useCreateMergeDevLinkTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateMergeDevLinkTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMergeDevLinkTokenMutation,
    CreateMergeDevLinkTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateMergeDevLinkTokenMutation,
    CreateMergeDevLinkTokenMutationVariables
  >(CreateMergeDevLinkTokenDocument, options)
}
export type CreateMergeDevLinkTokenMutationHookResult = ReturnType<
  typeof useCreateMergeDevLinkTokenMutation
>
export type CreateMergeDevLinkTokenMutationResult =
  Apollo.MutationResult<CreateMergeDevLinkTokenMutation>
export type CreateMergeDevLinkTokenMutationOptions = Apollo.BaseMutationOptions<
  CreateMergeDevLinkTokenMutation,
  CreateMergeDevLinkTokenMutationVariables
>
export const CreateMergeDevIntegrationDocument = gql`
    mutation createMergeDevIntegration($publicToken: String!) {
  integration: createMergeDevIntegration(publicToken: $publicToken) {
    ...integration
  }
}
    ${IntegrationFragmentDoc}`
export type CreateMergeDevIntegrationMutationFn = Apollo.MutationFunction<
  CreateMergeDevIntegrationMutation,
  CreateMergeDevIntegrationMutationVariables
>

/**
 * __useCreateMergeDevIntegrationMutation__
 *
 * To run a mutation, you first call `useCreateMergeDevIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMergeDevIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMergeDevIntegrationMutation, { data, loading, error }] = useCreateMergeDevIntegrationMutation({
 *   variables: {
 *      publicToken: // value for 'publicToken'
 *   },
 * });
 */
export function useCreateMergeDevIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMergeDevIntegrationMutation,
    CreateMergeDevIntegrationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateMergeDevIntegrationMutation,
    CreateMergeDevIntegrationMutationVariables
  >(CreateMergeDevIntegrationDocument, options)
}
export type CreateMergeDevIntegrationMutationHookResult = ReturnType<
  typeof useCreateMergeDevIntegrationMutation
>
export type CreateMergeDevIntegrationMutationResult =
  Apollo.MutationResult<CreateMergeDevIntegrationMutation>
export type CreateMergeDevIntegrationMutationOptions =
  Apollo.BaseMutationOptions<
    CreateMergeDevIntegrationMutation,
    CreateMergeDevIntegrationMutationVariables
  >
export const UpdateIntegrationDocument = gql`
    mutation updateIntegration($id: Int!, $input: UpdateIntegrationInput!) {
  integration: updateIntegration(id: $id, input: $input) {
    ...integration
  }
}
    ${IntegrationFragmentDoc}`
export type UpdateIntegrationMutationFn = Apollo.MutationFunction<
  UpdateIntegrationMutation,
  UpdateIntegrationMutationVariables
>

/**
 * __useUpdateIntegrationMutation__
 *
 * To run a mutation, you first call `useUpdateIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIntegrationMutation, { data, loading, error }] = useUpdateIntegrationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateIntegrationMutation,
    UpdateIntegrationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateIntegrationMutation,
    UpdateIntegrationMutationVariables
  >(UpdateIntegrationDocument, options)
}
export type UpdateIntegrationMutationHookResult = ReturnType<
  typeof useUpdateIntegrationMutation
>
export type UpdateIntegrationMutationResult =
  Apollo.MutationResult<UpdateIntegrationMutation>
export type UpdateIntegrationMutationOptions = Apollo.BaseMutationOptions<
  UpdateIntegrationMutation,
  UpdateIntegrationMutationVariables
>
export const ExportHandbookRecipientsToCsvDocument = gql`
    mutation exportHandbookRecipientsToCSV($columnKeys: [HandbookRecipientsColumnKey!]!, $filter: HandbookRecipientsFilter, $handbookId: Int!, $order: HandbookRecipientsSortOrder) {
  exportHandbookRecipientsToCSV(
    columnKeys: $columnKeys
    filter: $filter
    handbookId: $handbookId
    order: $order
  )
}
    `
export type ExportHandbookRecipientsToCsvMutationFn = Apollo.MutationFunction<
  ExportHandbookRecipientsToCsvMutation,
  ExportHandbookRecipientsToCsvMutationVariables
>

/**
 * __useExportHandbookRecipientsToCsvMutation__
 *
 * To run a mutation, you first call `useExportHandbookRecipientsToCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportHandbookRecipientsToCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportHandbookRecipientsToCsvMutation, { data, loading, error }] = useExportHandbookRecipientsToCsvMutation({
 *   variables: {
 *      columnKeys: // value for 'columnKeys'
 *      filter: // value for 'filter'
 *      handbookId: // value for 'handbookId'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useExportHandbookRecipientsToCsvMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportHandbookRecipientsToCsvMutation,
    ExportHandbookRecipientsToCsvMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ExportHandbookRecipientsToCsvMutation,
    ExportHandbookRecipientsToCsvMutationVariables
  >(ExportHandbookRecipientsToCsvDocument, options)
}
export type ExportHandbookRecipientsToCsvMutationHookResult = ReturnType<
  typeof useExportHandbookRecipientsToCsvMutation
>
export type ExportHandbookRecipientsToCsvMutationResult =
  Apollo.MutationResult<ExportHandbookRecipientsToCsvMutation>
export type ExportHandbookRecipientsToCsvMutationOptions =
  Apollo.BaseMutationOptions<
    ExportHandbookRecipientsToCsvMutation,
    ExportHandbookRecipientsToCsvMutationVariables
  >
export const ExportPeopleToCsvDocument = gql`
    mutation exportPeopleToCSV($columnKeys: [PeopleColumnKey!]!, $filter: PeopleFilter, $order: PeopleSortOrder, $showArchived: Boolean) {
  exportPeopleToCSV(
    columnKeys: $columnKeys
    filter: $filter
    order: $order
    showArchived: $showArchived
  )
}
    `
export type ExportPeopleToCsvMutationFn = Apollo.MutationFunction<
  ExportPeopleToCsvMutation,
  ExportPeopleToCsvMutationVariables
>

/**
 * __useExportPeopleToCsvMutation__
 *
 * To run a mutation, you first call `useExportPeopleToCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportPeopleToCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportPeopleToCsvMutation, { data, loading, error }] = useExportPeopleToCsvMutation({
 *   variables: {
 *      columnKeys: // value for 'columnKeys'
 *      filter: // value for 'filter'
 *      order: // value for 'order'
 *      showArchived: // value for 'showArchived'
 *   },
 * });
 */
export function useExportPeopleToCsvMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExportPeopleToCsvMutation,
    ExportPeopleToCsvMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ExportPeopleToCsvMutation,
    ExportPeopleToCsvMutationVariables
  >(ExportPeopleToCsvDocument, options)
}
export type ExportPeopleToCsvMutationHookResult = ReturnType<
  typeof useExportPeopleToCsvMutation
>
export type ExportPeopleToCsvMutationResult =
  Apollo.MutationResult<ExportPeopleToCsvMutation>
export type ExportPeopleToCsvMutationOptions = Apollo.BaseMutationOptions<
  ExportPeopleToCsvMutation,
  ExportPeopleToCsvMutationVariables
>
export const SendHandbookRemindersDocument = gql`
    mutation sendHandbookReminders($filter: HandbookRecipientsFilter, $handbookId: Int!, $isSignatureRequired: Boolean!, $template: EmailTemplateInput) {
  sendHandbookReminders(
    filter: $filter
    handbookId: $handbookId
    isSignatureRequired: $isSignatureRequired
    template: $template
  )
}
    `
export type SendHandbookRemindersMutationFn = Apollo.MutationFunction<
  SendHandbookRemindersMutation,
  SendHandbookRemindersMutationVariables
>

/**
 * __useSendHandbookRemindersMutation__
 *
 * To run a mutation, you first call `useSendHandbookRemindersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendHandbookRemindersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendHandbookRemindersMutation, { data, loading, error }] = useSendHandbookRemindersMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *      handbookId: // value for 'handbookId'
 *      isSignatureRequired: // value for 'isSignatureRequired'
 *      template: // value for 'template'
 *   },
 * });
 */
export function useSendHandbookRemindersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendHandbookRemindersMutation,
    SendHandbookRemindersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SendHandbookRemindersMutation,
    SendHandbookRemindersMutationVariables
  >(SendHandbookRemindersDocument, options)
}
export type SendHandbookRemindersMutationHookResult = ReturnType<
  typeof useSendHandbookRemindersMutation
>
export type SendHandbookRemindersMutationResult =
  Apollo.MutationResult<SendHandbookRemindersMutation>
export type SendHandbookRemindersMutationOptions = Apollo.BaseMutationOptions<
  SendHandbookRemindersMutation,
  SendHandbookRemindersMutationVariables
>
export const SendEmployeeDigestDocument = gql`
    mutation sendEmployeeDigest($filter: PeopleFilter, $template: EmailTemplateInput) {
  sendEmployeeDigest(filter: $filter, template: $template)
}
    `
export type SendEmployeeDigestMutationFn = Apollo.MutationFunction<
  SendEmployeeDigestMutation,
  SendEmployeeDigestMutationVariables
>

/**
 * __useSendEmployeeDigestMutation__
 *
 * To run a mutation, you first call `useSendEmployeeDigestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmployeeDigestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmployeeDigestMutation, { data, loading, error }] = useSendEmployeeDigestMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *      template: // value for 'template'
 *   },
 * });
 */
export function useSendEmployeeDigestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendEmployeeDigestMutation,
    SendEmployeeDigestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SendEmployeeDigestMutation,
    SendEmployeeDigestMutationVariables
  >(SendEmployeeDigestDocument, options)
}
export type SendEmployeeDigestMutationHookResult = ReturnType<
  typeof useSendEmployeeDigestMutation
>
export type SendEmployeeDigestMutationResult =
  Apollo.MutationResult<SendEmployeeDigestMutation>
export type SendEmployeeDigestMutationOptions = Apollo.BaseMutationOptions<
  SendEmployeeDigestMutation,
  SendEmployeeDigestMutationVariables
>
export const SendManagerDigestDocument = gql`
    mutation sendManagerDigest($filter: PeopleFilter, $template: EmailTemplateInput) {
  sendManagerDigest(filter: $filter, template: $template)
}
    `
export type SendManagerDigestMutationFn = Apollo.MutationFunction<
  SendManagerDigestMutation,
  SendManagerDigestMutationVariables
>

/**
 * __useSendManagerDigestMutation__
 *
 * To run a mutation, you first call `useSendManagerDigestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendManagerDigestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendManagerDigestMutation, { data, loading, error }] = useSendManagerDigestMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *      template: // value for 'template'
 *   },
 * });
 */
export function useSendManagerDigestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendManagerDigestMutation,
    SendManagerDigestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SendManagerDigestMutation,
    SendManagerDigestMutationVariables
  >(SendManagerDigestDocument, options)
}
export type SendManagerDigestMutationHookResult = ReturnType<
  typeof useSendManagerDigestMutation
>
export type SendManagerDigestMutationResult =
  Apollo.MutationResult<SendManagerDigestMutation>
export type SendManagerDigestMutationOptions = Apollo.BaseMutationOptions<
  SendManagerDigestMutation,
  SendManagerDigestMutationVariables
>
export const SavedReportsDocument = gql`
    query savedReports {
  savedReports {
    ...savedReport
  }
}
    ${SavedReportFragmentDoc}`

/**
 * __useSavedReportsQuery__
 *
 * To run a query within a React component, call `useSavedReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSavedReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSavedReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSavedReportsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SavedReportsQuery,
    SavedReportsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SavedReportsQuery, SavedReportsQueryVariables>(
    SavedReportsDocument,
    options,
  )
}
export function useSavedReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SavedReportsQuery,
    SavedReportsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SavedReportsQuery, SavedReportsQueryVariables>(
    SavedReportsDocument,
    options,
  )
}
export type SavedReportsQueryHookResult = ReturnType<
  typeof useSavedReportsQuery
>
export type SavedReportsLazyQueryHookResult = ReturnType<
  typeof useSavedReportsLazyQuery
>
export type SavedReportsQueryResult = Apollo.QueryResult<
  SavedReportsQuery,
  SavedReportsQueryVariables
>
export const CreateDocumentSavedReportDocument = gql`
    mutation createDocumentSavedReport($input: CreateDocumentSavedReportInput!) {
  savedReport: createDocumentSavedReport(input: $input) {
    ...savedReport
  }
}
    ${SavedReportFragmentDoc}`
export type CreateDocumentSavedReportMutationFn = Apollo.MutationFunction<
  CreateDocumentSavedReportMutation,
  CreateDocumentSavedReportMutationVariables
>

/**
 * __useCreateDocumentSavedReportMutation__
 *
 * To run a mutation, you first call `useCreateDocumentSavedReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentSavedReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentSavedReportMutation, { data, loading, error }] = useCreateDocumentSavedReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDocumentSavedReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDocumentSavedReportMutation,
    CreateDocumentSavedReportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateDocumentSavedReportMutation,
    CreateDocumentSavedReportMutationVariables
  >(CreateDocumentSavedReportDocument, options)
}
export type CreateDocumentSavedReportMutationHookResult = ReturnType<
  typeof useCreateDocumentSavedReportMutation
>
export type CreateDocumentSavedReportMutationResult =
  Apollo.MutationResult<CreateDocumentSavedReportMutation>
export type CreateDocumentSavedReportMutationOptions =
  Apollo.BaseMutationOptions<
    CreateDocumentSavedReportMutation,
    CreateDocumentSavedReportMutationVariables
  >
export const UpdateDocumentSavedReportDocument = gql`
    mutation updateDocumentSavedReport($savedReportId: Int!, $input: UpdateDocumentSavedReportInput!) {
  savedReport: updateDocumentSavedReport(
    savedReportId: $savedReportId
    input: $input
  ) {
    ...savedReport
  }
}
    ${SavedReportFragmentDoc}`
export type UpdateDocumentSavedReportMutationFn = Apollo.MutationFunction<
  UpdateDocumentSavedReportMutation,
  UpdateDocumentSavedReportMutationVariables
>

/**
 * __useUpdateDocumentSavedReportMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentSavedReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentSavedReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentSavedReportMutation, { data, loading, error }] = useUpdateDocumentSavedReportMutation({
 *   variables: {
 *      savedReportId: // value for 'savedReportId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDocumentSavedReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDocumentSavedReportMutation,
    UpdateDocumentSavedReportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateDocumentSavedReportMutation,
    UpdateDocumentSavedReportMutationVariables
  >(UpdateDocumentSavedReportDocument, options)
}
export type UpdateDocumentSavedReportMutationHookResult = ReturnType<
  typeof useUpdateDocumentSavedReportMutation
>
export type UpdateDocumentSavedReportMutationResult =
  Apollo.MutationResult<UpdateDocumentSavedReportMutation>
export type UpdateDocumentSavedReportMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateDocumentSavedReportMutation,
    UpdateDocumentSavedReportMutationVariables
  >
export const CreatePeopleSavedReportDocument = gql`
    mutation createPeopleSavedReport($input: CreatePeopleSavedReportInput!) {
  savedReport: createPeopleSavedReport(input: $input) {
    ...savedReport
  }
}
    ${SavedReportFragmentDoc}`
export type CreatePeopleSavedReportMutationFn = Apollo.MutationFunction<
  CreatePeopleSavedReportMutation,
  CreatePeopleSavedReportMutationVariables
>

/**
 * __useCreatePeopleSavedReportMutation__
 *
 * To run a mutation, you first call `useCreatePeopleSavedReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePeopleSavedReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPeopleSavedReportMutation, { data, loading, error }] = useCreatePeopleSavedReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePeopleSavedReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePeopleSavedReportMutation,
    CreatePeopleSavedReportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreatePeopleSavedReportMutation,
    CreatePeopleSavedReportMutationVariables
  >(CreatePeopleSavedReportDocument, options)
}
export type CreatePeopleSavedReportMutationHookResult = ReturnType<
  typeof useCreatePeopleSavedReportMutation
>
export type CreatePeopleSavedReportMutationResult =
  Apollo.MutationResult<CreatePeopleSavedReportMutation>
export type CreatePeopleSavedReportMutationOptions = Apollo.BaseMutationOptions<
  CreatePeopleSavedReportMutation,
  CreatePeopleSavedReportMutationVariables
>
export const UpdatePeopleSavedReportDocument = gql`
    mutation updatePeopleSavedReport($savedReportId: Int!, $input: UpdatePeopleSavedReportInput!) {
  savedReport: updatePeopleSavedReport(
    savedReportId: $savedReportId
    input: $input
  ) {
    ...savedReport
  }
}
    ${SavedReportFragmentDoc}`
export type UpdatePeopleSavedReportMutationFn = Apollo.MutationFunction<
  UpdatePeopleSavedReportMutation,
  UpdatePeopleSavedReportMutationVariables
>

/**
 * __useUpdatePeopleSavedReportMutation__
 *
 * To run a mutation, you first call `useUpdatePeopleSavedReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePeopleSavedReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePeopleSavedReportMutation, { data, loading, error }] = useUpdatePeopleSavedReportMutation({
 *   variables: {
 *      savedReportId: // value for 'savedReportId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePeopleSavedReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePeopleSavedReportMutation,
    UpdatePeopleSavedReportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdatePeopleSavedReportMutation,
    UpdatePeopleSavedReportMutationVariables
  >(UpdatePeopleSavedReportDocument, options)
}
export type UpdatePeopleSavedReportMutationHookResult = ReturnType<
  typeof useUpdatePeopleSavedReportMutation
>
export type UpdatePeopleSavedReportMutationResult =
  Apollo.MutationResult<UpdatePeopleSavedReportMutation>
export type UpdatePeopleSavedReportMutationOptions = Apollo.BaseMutationOptions<
  UpdatePeopleSavedReportMutation,
  UpdatePeopleSavedReportMutationVariables
>
export const ArchiveSavedReportDocument = gql`
    mutation archiveSavedReport($savedReportId: Int!) {
  savedReport: archiveSavedReport(savedReportId: $savedReportId) {
    ...savedReport
  }
}
    ${SavedReportFragmentDoc}`
export type ArchiveSavedReportMutationFn = Apollo.MutationFunction<
  ArchiveSavedReportMutation,
  ArchiveSavedReportMutationVariables
>

/**
 * __useArchiveSavedReportMutation__
 *
 * To run a mutation, you first call `useArchiveSavedReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveSavedReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveSavedReportMutation, { data, loading, error }] = useArchiveSavedReportMutation({
 *   variables: {
 *      savedReportId: // value for 'savedReportId'
 *   },
 * });
 */
export function useArchiveSavedReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveSavedReportMutation,
    ArchiveSavedReportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ArchiveSavedReportMutation,
    ArchiveSavedReportMutationVariables
  >(ArchiveSavedReportDocument, options)
}
export type ArchiveSavedReportMutationHookResult = ReturnType<
  typeof useArchiveSavedReportMutation
>
export type ArchiveSavedReportMutationResult =
  Apollo.MutationResult<ArchiveSavedReportMutation>
export type ArchiveSavedReportMutationOptions = Apollo.BaseMutationOptions<
  ArchiveSavedReportMutation,
  ArchiveSavedReportMutationVariables
>
export const UnarchiveSavedReportDocument = gql`
    mutation unarchiveSavedReport($savedReportId: Int!) {
  savedReport: unarchiveSavedReport(savedReportId: $savedReportId) {
    ...savedReport
  }
}
    ${SavedReportFragmentDoc}`
export type UnarchiveSavedReportMutationFn = Apollo.MutationFunction<
  UnarchiveSavedReportMutation,
  UnarchiveSavedReportMutationVariables
>

/**
 * __useUnarchiveSavedReportMutation__
 *
 * To run a mutation, you first call `useUnarchiveSavedReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveSavedReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveSavedReportMutation, { data, loading, error }] = useUnarchiveSavedReportMutation({
 *   variables: {
 *      savedReportId: // value for 'savedReportId'
 *   },
 * });
 */
export function useUnarchiveSavedReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnarchiveSavedReportMutation,
    UnarchiveSavedReportMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UnarchiveSavedReportMutation,
    UnarchiveSavedReportMutationVariables
  >(UnarchiveSavedReportDocument, options)
}
export type UnarchiveSavedReportMutationHookResult = ReturnType<
  typeof useUnarchiveSavedReportMutation
>
export type UnarchiveSavedReportMutationResult =
  Apollo.MutationResult<UnarchiveSavedReportMutation>
export type UnarchiveSavedReportMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveSavedReportMutation,
  UnarchiveSavedReportMutationVariables
>
export const SavedSegmentsDocument = gql`
    query savedSegments {
  savedSegments {
    ...savedSegment
  }
}
    ${SavedSegmentFragmentDoc}`

/**
 * __useSavedSegmentsQuery__
 *
 * To run a query within a React component, call `useSavedSegmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSavedSegmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSavedSegmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSavedSegmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SavedSegmentsQuery,
    SavedSegmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SavedSegmentsQuery, SavedSegmentsQueryVariables>(
    SavedSegmentsDocument,
    options,
  )
}
export function useSavedSegmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SavedSegmentsQuery,
    SavedSegmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SavedSegmentsQuery, SavedSegmentsQueryVariables>(
    SavedSegmentsDocument,
    options,
  )
}
export type SavedSegmentsQueryHookResult = ReturnType<
  typeof useSavedSegmentsQuery
>
export type SavedSegmentsLazyQueryHookResult = ReturnType<
  typeof useSavedSegmentsLazyQuery
>
export type SavedSegmentsQueryResult = Apollo.QueryResult<
  SavedSegmentsQuery,
  SavedSegmentsQueryVariables
>
export const CreateSavedSegmentDocument = gql`
    mutation createSavedSegment($input: CreateSavedSegmentInput!) {
  savedSegment: createSavedSegment(input: $input) {
    ...savedSegment
  }
}
    ${SavedSegmentFragmentDoc}`
export type CreateSavedSegmentMutationFn = Apollo.MutationFunction<
  CreateSavedSegmentMutation,
  CreateSavedSegmentMutationVariables
>

/**
 * __useCreateSavedSegmentMutation__
 *
 * To run a mutation, you first call `useCreateSavedSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSavedSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSavedSegmentMutation, { data, loading, error }] = useCreateSavedSegmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSavedSegmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSavedSegmentMutation,
    CreateSavedSegmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateSavedSegmentMutation,
    CreateSavedSegmentMutationVariables
  >(CreateSavedSegmentDocument, options)
}
export type CreateSavedSegmentMutationHookResult = ReturnType<
  typeof useCreateSavedSegmentMutation
>
export type CreateSavedSegmentMutationResult =
  Apollo.MutationResult<CreateSavedSegmentMutation>
export type CreateSavedSegmentMutationOptions = Apollo.BaseMutationOptions<
  CreateSavedSegmentMutation,
  CreateSavedSegmentMutationVariables
>
export const UpdateSavedSegmentDocument = gql`
    mutation updateSavedSegment($id: Int!, $input: UpdateSavedSegmentInput!, $sendNotifications: Boolean) {
  savedSegment: updateSavedSegment(
    id: $id
    input: $input
    sendNotifications: $sendNotifications
  ) {
    ...savedSegment
  }
}
    ${SavedSegmentFragmentDoc}`
export type UpdateSavedSegmentMutationFn = Apollo.MutationFunction<
  UpdateSavedSegmentMutation,
  UpdateSavedSegmentMutationVariables
>

/**
 * __useUpdateSavedSegmentMutation__
 *
 * To run a mutation, you first call `useUpdateSavedSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSavedSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSavedSegmentMutation, { data, loading, error }] = useUpdateSavedSegmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      sendNotifications: // value for 'sendNotifications'
 *   },
 * });
 */
export function useUpdateSavedSegmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSavedSegmentMutation,
    UpdateSavedSegmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateSavedSegmentMutation,
    UpdateSavedSegmentMutationVariables
  >(UpdateSavedSegmentDocument, options)
}
export type UpdateSavedSegmentMutationHookResult = ReturnType<
  typeof useUpdateSavedSegmentMutation
>
export type UpdateSavedSegmentMutationResult =
  Apollo.MutationResult<UpdateSavedSegmentMutation>
export type UpdateSavedSegmentMutationOptions = Apollo.BaseMutationOptions<
  UpdateSavedSegmentMutation,
  UpdateSavedSegmentMutationVariables
>
export const ArchiveSavedSegmentDocument = gql`
    mutation archiveSavedSegment($id: Int!) {
  savedSegment: archiveSavedSegment(id: $id) {
    ...savedSegment
  }
}
    ${SavedSegmentFragmentDoc}`
export type ArchiveSavedSegmentMutationFn = Apollo.MutationFunction<
  ArchiveSavedSegmentMutation,
  ArchiveSavedSegmentMutationVariables
>

/**
 * __useArchiveSavedSegmentMutation__
 *
 * To run a mutation, you first call `useArchiveSavedSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveSavedSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveSavedSegmentMutation, { data, loading, error }] = useArchiveSavedSegmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchiveSavedSegmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArchiveSavedSegmentMutation,
    ArchiveSavedSegmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ArchiveSavedSegmentMutation,
    ArchiveSavedSegmentMutationVariables
  >(ArchiveSavedSegmentDocument, options)
}
export type ArchiveSavedSegmentMutationHookResult = ReturnType<
  typeof useArchiveSavedSegmentMutation
>
export type ArchiveSavedSegmentMutationResult =
  Apollo.MutationResult<ArchiveSavedSegmentMutation>
export type ArchiveSavedSegmentMutationOptions = Apollo.BaseMutationOptions<
  ArchiveSavedSegmentMutation,
  ArchiveSavedSegmentMutationVariables
>
export const UnarchiveSavedSegmentDocument = gql`
    mutation unarchiveSavedSegment($id: Int!) {
  savedSegment: unarchiveSavedSegment(id: $id) {
    ...savedSegment
  }
}
    ${SavedSegmentFragmentDoc}`
export type UnarchiveSavedSegmentMutationFn = Apollo.MutationFunction<
  UnarchiveSavedSegmentMutation,
  UnarchiveSavedSegmentMutationVariables
>

/**
 * __useUnarchiveSavedSegmentMutation__
 *
 * To run a mutation, you first call `useUnarchiveSavedSegmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveSavedSegmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveSavedSegmentMutation, { data, loading, error }] = useUnarchiveSavedSegmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnarchiveSavedSegmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnarchiveSavedSegmentMutation,
    UnarchiveSavedSegmentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UnarchiveSavedSegmentMutation,
    UnarchiveSavedSegmentMutationVariables
  >(UnarchiveSavedSegmentDocument, options)
}
export type UnarchiveSavedSegmentMutationHookResult = ReturnType<
  typeof useUnarchiveSavedSegmentMutation
>
export type UnarchiveSavedSegmentMutationResult =
  Apollo.MutationResult<UnarchiveSavedSegmentMutation>
export type UnarchiveSavedSegmentMutationOptions = Apollo.BaseMutationOptions<
  UnarchiveSavedSegmentMutation,
  UnarchiveSavedSegmentMutationVariables
>
export const GetHandbookAudienceInvitationsCountDocument = gql`
    query getHandbookAudienceInvitationsCount($handbookId: Int!, $audience: HandbookAudienceInput!) {
  handbook(id: $handbookId) {
    audienceInvitationsCount(audience: $audience)
  }
}
    `

/**
 * __useGetHandbookAudienceInvitationsCountQuery__
 *
 * To run a query within a React component, call `useGetHandbookAudienceInvitationsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHandbookAudienceInvitationsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHandbookAudienceInvitationsCountQuery({
 *   variables: {
 *      handbookId: // value for 'handbookId'
 *      audience: // value for 'audience'
 *   },
 * });
 */
export function useGetHandbookAudienceInvitationsCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetHandbookAudienceInvitationsCountQuery,
    GetHandbookAudienceInvitationsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetHandbookAudienceInvitationsCountQuery,
    GetHandbookAudienceInvitationsCountQueryVariables
  >(GetHandbookAudienceInvitationsCountDocument, options)
}
export function useGetHandbookAudienceInvitationsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHandbookAudienceInvitationsCountQuery,
    GetHandbookAudienceInvitationsCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetHandbookAudienceInvitationsCountQuery,
    GetHandbookAudienceInvitationsCountQueryVariables
  >(GetHandbookAudienceInvitationsCountDocument, options)
}
export type GetHandbookAudienceInvitationsCountQueryHookResult = ReturnType<
  typeof useGetHandbookAudienceInvitationsCountQuery
>
export type GetHandbookAudienceInvitationsCountLazyQueryHookResult = ReturnType<
  typeof useGetHandbookAudienceInvitationsCountLazyQuery
>
export type GetHandbookAudienceInvitationsCountQueryResult = Apollo.QueryResult<
  GetHandbookAudienceInvitationsCountQuery,
  GetHandbookAudienceInvitationsCountQueryVariables
>
export const RecordHandbookSignatureDocument = gql`
    mutation recordHandbookSignature($handbookId: Int!, $input: RecordHandbookSignatureInput!) {
  signature: recordHandbookSignature(handbookId: $handbookId, input: $input) {
    ...handbookSignature
  }
}
    ${HandbookSignatureFragmentDoc}`
export type RecordHandbookSignatureMutationFn = Apollo.MutationFunction<
  RecordHandbookSignatureMutation,
  RecordHandbookSignatureMutationVariables
>

/**
 * __useRecordHandbookSignatureMutation__
 *
 * To run a mutation, you first call `useRecordHandbookSignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecordHandbookSignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recordHandbookSignatureMutation, { data, loading, error }] = useRecordHandbookSignatureMutation({
 *   variables: {
 *      handbookId: // value for 'handbookId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecordHandbookSignatureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RecordHandbookSignatureMutation,
    RecordHandbookSignatureMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RecordHandbookSignatureMutation,
    RecordHandbookSignatureMutationVariables
  >(RecordHandbookSignatureDocument, options)
}
export type RecordHandbookSignatureMutationHookResult = ReturnType<
  typeof useRecordHandbookSignatureMutation
>
export type RecordHandbookSignatureMutationResult =
  Apollo.MutationResult<RecordHandbookSignatureMutation>
export type RecordHandbookSignatureMutationOptions = Apollo.BaseMutationOptions<
  RecordHandbookSignatureMutation,
  RecordHandbookSignatureMutationVariables
>
export const HandbookVersionDocument = gql`
    query handbookVersion($handbookId: Int!, $versionNumber: Int!) {
  handbookVersion(handbookId: $handbookId, versionNumber: $versionNumber) {
    content
    defaultLanguageCode
    handbookId
    languageCodes
    version
    acknowledgementForm {
      ...handbookAcknowledgementForm
    }
    branding {
      ...handbookBranding
    }
    contactFragments {
      ...handbookContact
    }
    sections {
      audienceExpression {
        conjunction
        expressions {
          conjunction
          operands {
            id
            type
            ... on DateOperand {
              field
              dateCondition
              days
              date
            }
            ... on IdsOperand {
              isEvery
              isNot
              ids
            }
            ... on ManagersOperand {
              isEvery
              isNot
              managesDirectly
              ids
            }
            ... on SelectOperand {
              field
              path
              isEvery
              isNot
              values
            }
            ... on TextOperand {
              field
              isNot
              textCondition
              text
            }
          }
        }
      }
      createdAt
      createdBy
      hideToc
      hidden
      languageCode
      sectionId
      sectionVersion
      theme
      title
      type
      attrs {
        ...handbookSectionAttributes
      }
      fragments {
        ...handbookSectionContent
      }
      listEntries {
        ...handbookSectionEntry
      }
    }
  }
}
    ${HandbookAcknowledgementFormFragmentDoc}
${HandbookBrandingFragmentDoc}
${HandbookContactFragmentDoc}
${HandbookSectionAttributesFragmentDoc}
${HandbookSectionContentFragmentDoc}
${HandbookSectionEntryFragmentDoc}`

/**
 * __useHandbookVersionQuery__
 *
 * To run a query within a React component, call `useHandbookVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useHandbookVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHandbookVersionQuery({
 *   variables: {
 *      handbookId: // value for 'handbookId'
 *      versionNumber: // value for 'versionNumber'
 *   },
 * });
 */
export function useHandbookVersionQuery(
  baseOptions: Apollo.QueryHookOptions<
    HandbookVersionQuery,
    HandbookVersionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<HandbookVersionQuery, HandbookVersionQueryVariables>(
    HandbookVersionDocument,
    options,
  )
}
export function useHandbookVersionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HandbookVersionQuery,
    HandbookVersionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    HandbookVersionQuery,
    HandbookVersionQueryVariables
  >(HandbookVersionDocument, options)
}
export type HandbookVersionQueryHookResult = ReturnType<
  typeof useHandbookVersionQuery
>
export type HandbookVersionLazyQueryHookResult = ReturnType<
  typeof useHandbookVersionLazyQuery
>
export type HandbookVersionQueryResult = Apollo.QueryResult<
  HandbookVersionQuery,
  HandbookVersionQueryVariables
>
export const PublishHandbookNotifyCountsDocument = gql`
    query publishHandbookNotifyCounts($handbookId: Int!) {
  viewersCounts: handbook(id: $handbookId) {
    viewers: viewersCount(canNotify: true, canSign: false, notMe: true)
  }
  signersCounts: handbook(id: $handbookId) {
    signers: viewersCount(canNotify: true, canSign: true, notMe: true)
  }
}
    `

/**
 * __usePublishHandbookNotifyCountsQuery__
 *
 * To run a query within a React component, call `usePublishHandbookNotifyCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishHandbookNotifyCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishHandbookNotifyCountsQuery({
 *   variables: {
 *      handbookId: // value for 'handbookId'
 *   },
 * });
 */
export function usePublishHandbookNotifyCountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PublishHandbookNotifyCountsQuery,
    PublishHandbookNotifyCountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    PublishHandbookNotifyCountsQuery,
    PublishHandbookNotifyCountsQueryVariables
  >(PublishHandbookNotifyCountsDocument, options)
}
export function usePublishHandbookNotifyCountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PublishHandbookNotifyCountsQuery,
    PublishHandbookNotifyCountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    PublishHandbookNotifyCountsQuery,
    PublishHandbookNotifyCountsQueryVariables
  >(PublishHandbookNotifyCountsDocument, options)
}
export type PublishHandbookNotifyCountsQueryHookResult = ReturnType<
  typeof usePublishHandbookNotifyCountsQuery
>
export type PublishHandbookNotifyCountsLazyQueryHookResult = ReturnType<
  typeof usePublishHandbookNotifyCountsLazyQuery
>
export type PublishHandbookNotifyCountsQueryResult = Apollo.QueryResult<
  PublishHandbookNotifyCountsQuery,
  PublishHandbookNotifyCountsQueryVariables
>
export const UpdateHandbookVersionDocument = gql`
    mutation updateHandbookVersion($handbookId: Int!, $versionNumber: Int!, $input: UpdateHandbookVersionInput!) {
  handbookVersion: updateHandbookVersion(
    handbookId: $handbookId
    versionNumber: $versionNumber
    input: $input
  ) {
    ...handbookVersionSummary
  }
}
    ${HandbookVersionSummaryFragmentDoc}`
export type UpdateHandbookVersionMutationFn = Apollo.MutationFunction<
  UpdateHandbookVersionMutation,
  UpdateHandbookVersionMutationVariables
>

/**
 * __useUpdateHandbookVersionMutation__
 *
 * To run a mutation, you first call `useUpdateHandbookVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHandbookVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHandbookVersionMutation, { data, loading, error }] = useUpdateHandbookVersionMutation({
 *   variables: {
 *      handbookId: // value for 'handbookId'
 *      versionNumber: // value for 'versionNumber'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateHandbookVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateHandbookVersionMutation,
    UpdateHandbookVersionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateHandbookVersionMutation,
    UpdateHandbookVersionMutationVariables
  >(UpdateHandbookVersionDocument, options)
}
export type UpdateHandbookVersionMutationHookResult = ReturnType<
  typeof useUpdateHandbookVersionMutation
>
export type UpdateHandbookVersionMutationResult =
  Apollo.MutationResult<UpdateHandbookVersionMutation>
export type UpdateHandbookVersionMutationOptions = Apollo.BaseMutationOptions<
  UpdateHandbookVersionMutation,
  UpdateHandbookVersionMutationVariables
>
export const GetHandbookSectionHistoryDocument = gql`
    query getHandbookSectionHistory($sectionIds: [Int!]) {
  handbookSectionHistory(sectionIds: $sectionIds) {
    sectionId
    sectionVersion
    handbookId
    handbookVersion
    type
    theme
    title
    languageCode
    hideToc
    attrs {
      ...handbookSectionAttributes
    }
    fragments {
      ...handbookSectionContent
    }
    listEntries {
      ...handbookSectionEntry
    }
    audienceExpression {
      conjunction
      expressions {
        conjunction
        operands {
          id
          type
          ... on DateOperand {
            field
            dateCondition
            days
            date
          }
          ... on IdsOperand {
            isEvery
            isNot
            ids
          }
          ... on ManagersOperand {
            isEvery
            isNot
            managesDirectly
            ids
          }
          ... on SelectOperand {
            field
            path
            isEvery
            isNot
            values
          }
          ... on TextOperand {
            field
            isNot
            textCondition
            text
          }
        }
      }
    }
    viewAnnotation
    editAnnotation
    hideAnnotations
    createdAt
    createdBy
  }
}
    ${HandbookSectionAttributesFragmentDoc}
${HandbookSectionContentFragmentDoc}
${HandbookSectionEntryFragmentDoc}`

/**
 * __useGetHandbookSectionHistoryQuery__
 *
 * To run a query within a React component, call `useGetHandbookSectionHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHandbookSectionHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHandbookSectionHistoryQuery({
 *   variables: {
 *      sectionIds: // value for 'sectionIds'
 *   },
 * });
 */
export function useGetHandbookSectionHistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetHandbookSectionHistoryQuery,
    GetHandbookSectionHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetHandbookSectionHistoryQuery,
    GetHandbookSectionHistoryQueryVariables
  >(GetHandbookSectionHistoryDocument, options)
}
export function useGetHandbookSectionHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHandbookSectionHistoryQuery,
    GetHandbookSectionHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetHandbookSectionHistoryQuery,
    GetHandbookSectionHistoryQueryVariables
  >(GetHandbookSectionHistoryDocument, options)
}
export type GetHandbookSectionHistoryQueryHookResult = ReturnType<
  typeof useGetHandbookSectionHistoryQuery
>
export type GetHandbookSectionHistoryLazyQueryHookResult = ReturnType<
  typeof useGetHandbookSectionHistoryLazyQuery
>
export type GetHandbookSectionHistoryQueryResult = Apollo.QueryResult<
  GetHandbookSectionHistoryQuery,
  GetHandbookSectionHistoryQueryVariables
>
export const EventsDocument = gql`
    query events($after: String, $filter: EventsFilter, $limit: Int, $order: EventsSortOrder) {
  events(after: $after, filter: $filter, limit: $limit, order: $order) {
    ...event
  }
}
    ${EventFragmentDoc}`

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useEventsQuery(
  baseOptions?: Apollo.QueryHookOptions<EventsQuery, EventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<EventsQuery, EventsQueryVariables>(
    EventsDocument,
    options,
  )
}
export function useEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EventsQuery, EventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<EventsQuery, EventsQueryVariables>(
    EventsDocument,
    options,
  )
}
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>
export type EventsLazyQueryHookResult = ReturnType<typeof useEventsLazyQuery>
export type EventsQueryResult = Apollo.QueryResult<
  EventsQuery,
  EventsQueryVariables
>
export const TriggerHrisSyncDocument = gql`
    mutation triggerHrisSync {
  triggerHrisSync
}
    `
export type TriggerHrisSyncMutationFn = Apollo.MutationFunction<
  TriggerHrisSyncMutation,
  TriggerHrisSyncMutationVariables
>

/**
 * __useTriggerHrisSyncMutation__
 *
 * To run a mutation, you first call `useTriggerHrisSyncMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerHrisSyncMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerHrisSyncMutation, { data, loading, error }] = useTriggerHrisSyncMutation({
 *   variables: {
 *   },
 * });
 */
export function useTriggerHrisSyncMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TriggerHrisSyncMutation,
    TriggerHrisSyncMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    TriggerHrisSyncMutation,
    TriggerHrisSyncMutationVariables
  >(TriggerHrisSyncDocument, options)
}
export type TriggerHrisSyncMutationHookResult = ReturnType<
  typeof useTriggerHrisSyncMutation
>
export type TriggerHrisSyncMutationResult =
  Apollo.MutationResult<TriggerHrisSyncMutation>
export type TriggerHrisSyncMutationOptions = Apollo.BaseMutationOptions<
  TriggerHrisSyncMutation,
  TriggerHrisSyncMutationVariables
>
export const PolicyLibraryDocument = gql`
    query policyLibrary($filter: DocumentsFilter, $groupBy: DocumentsGroupByField!, $showArchived: Boolean) {
  documentGroups(filter: $filter, groupBy: $groupBy, showArchived: $showArchived) {
    ...documentGroup
  }
  documentSettings {
    ...documentSettings
  }
  folders {
    ...folder
  }
  teams {
    ...team
  }
}
    ${DocumentGroupFragmentDoc}
${DocumentSettingsFragmentDoc}
${FolderFragmentDoc}
${TeamFragmentDoc}`

/**
 * __usePolicyLibraryQuery__
 *
 * To run a query within a React component, call `usePolicyLibraryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePolicyLibraryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePolicyLibraryQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      groupBy: // value for 'groupBy'
 *      showArchived: // value for 'showArchived'
 *   },
 * });
 */
export function usePolicyLibraryQuery(
  baseOptions: Apollo.QueryHookOptions<
    PolicyLibraryQuery,
    PolicyLibraryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PolicyLibraryQuery, PolicyLibraryQueryVariables>(
    PolicyLibraryDocument,
    options,
  )
}
export function usePolicyLibraryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PolicyLibraryQuery,
    PolicyLibraryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PolicyLibraryQuery, PolicyLibraryQueryVariables>(
    PolicyLibraryDocument,
    options,
  )
}
export type PolicyLibraryQueryHookResult = ReturnType<
  typeof usePolicyLibraryQuery
>
export type PolicyLibraryLazyQueryHookResult = ReturnType<
  typeof usePolicyLibraryLazyQuery
>
export type PolicyLibraryQueryResult = Apollo.QueryResult<
  PolicyLibraryQuery,
  PolicyLibraryQueryVariables
>
export const PolicyLibraryDocumentsDocument = gql`
    query policyLibraryDocuments($after: String, $filter: DocumentsFilter, $limit: Int, $order: DocumentsSortOrder, $showArchived: Boolean) {
  documents(
    after: $after
    filter: $filter
    limit: $limit
    order: $order
    showArchived: $showArchived
  ) {
    ...policyLibraryDocument
  }
}
    ${PolicyLibraryDocumentFragmentDoc}`

/**
 * __usePolicyLibraryDocumentsQuery__
 *
 * To run a query within a React component, call `usePolicyLibraryDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePolicyLibraryDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePolicyLibraryDocumentsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      showArchived: // value for 'showArchived'
 *   },
 * });
 */
export function usePolicyLibraryDocumentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PolicyLibraryDocumentsQuery,
    PolicyLibraryDocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    PolicyLibraryDocumentsQuery,
    PolicyLibraryDocumentsQueryVariables
  >(PolicyLibraryDocumentsDocument, options)
}
export function usePolicyLibraryDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PolicyLibraryDocumentsQuery,
    PolicyLibraryDocumentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    PolicyLibraryDocumentsQuery,
    PolicyLibraryDocumentsQueryVariables
  >(PolicyLibraryDocumentsDocument, options)
}
export type PolicyLibraryDocumentsQueryHookResult = ReturnType<
  typeof usePolicyLibraryDocumentsQuery
>
export type PolicyLibraryDocumentsLazyQueryHookResult = ReturnType<
  typeof usePolicyLibraryDocumentsLazyQuery
>
export type PolicyLibraryDocumentsQueryResult = Apollo.QueryResult<
  PolicyLibraryDocumentsQuery,
  PolicyLibraryDocumentsQueryVariables
>
