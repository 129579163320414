import isString from 'lodash/isString'

export type SortDirection = 'asc' | 'desc'

export type SortOrder = {
  field: string
  direction: SortDirection
}

export function parseSortOrder(
  value: SortOrder | string,
): SortOrder | undefined {
  if (!value) return

  if (!isString(value)) {
    return {
      field: value.field,
      direction: value.direction,
    }
  }

  if (value[0] === '-') {
    return {
      field: value.slice(1),
      direction: 'desc',
    }
  }

  return {
    field: value,
    direction: 'asc',
  }
}

export function stringifySortOrder(order: SortOrder) {
  const { field, direction } = order
  return direction === 'desc' ? `-${field}` : field
}

export function getNextSortOrder(
  columnOrder: SortOrder,
  order: SortOrder | undefined,
  canRemove?: boolean,
): SortOrder | undefined {
  // If not on our field, move to the default sort order
  if (!order || columnOrder.field !== order.field) {
    return columnOrder
  }

  // If the same direction (or we cannot remove the sort), switch the direction
  if (!canRemove || columnOrder.direction === order.direction) {
    return {
      ...order,
      direction: order.direction === 'asc' ? 'desc' : 'asc',
    }
  }

  // If we can remove the sort, remove it
}
